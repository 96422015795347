import { useEffect } from 'react';
// when click outside of an element, calling some functions
const useOnClickOutside = (ref: any, onOutsideClick: () => void) => {
    useEffect(() => {
        const listener = (event: any) => {
            if (ref?.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, onOutsideClick]);
};

export default useOnClickOutside;
