import { CustomAzureMap } from 'components/Map';
import ModalLayout from './ModalLayout';
import { Divider } from '@mui/material';
import { useEffect } from 'react';
import useMapPropertyStore, { COUNT_PER_PAGE } from 'store/useMapPropertyStore';
import { useMutation } from '@tanstack/react-query';
import LoadingAnimation from 'components/Loading/LoadingAnimation';
import { LocationType } from 'types/Azure';
import { useToast } from 'components/Providers/ToastProvider';
import MapPropertyPanel from 'components/MapPropertyPanel';
import { ModalLayoutProps } from '.';

const AzureMapModal = ({ visible, onClose }: ModalLayoutProps) => {
    const {
        init,
        coordinates,
        currentPage,
        totalCount,
        getMapSearchWithPagination,
        getMapSearch,
    } = useMapPropertyStore();
    const { showMessage } = useToast();

    //mutation
    const { isPending: isPagePending, mutate: getMapSearchPaginationResult } =
        useMutation({
            mutationFn: getMapSearchWithPagination,
            onSuccess: () => {},
            onError: (error) => {
                showMessage('Error Occured', error.message);
            },
        });
    //mutation
    const { isPending: isTotalPending, mutate: getMapSearchMutation } =
        useMutation({
            mutationFn: getMapSearch,
            onSuccess: () => {},
            onError: (error) => {
                showMessage(
                    'Error Occured while get Map Search result!',
                    error.message,
                );
            },
        });

    useEffect(() => {
        if (totalCount > 0 && visible) {
            getMapSearchPaginationResult({
                coordinates,
                count: COUNT_PER_PAGE,
                page: currentPage,
            });
        }

        if (!visible) {
            init();
        }
    }, [currentPage, totalCount, visible]);

    const onSearch = (coordinates: LocationType[]) => {
        getMapSearchMutation(coordinates);
    };

    return (
        <ModalLayout visible={visible} onClose={onClose} showCloseIcon={true}>
            <div className="fixed left-0 top-0 w-screen h-screen bg-white">
                {/* header */}
                <div className="text-my-blue font-medium p-[8px] flex justify-between">
                    <span>{'Map Search.'}</span>

                    <span
                        onClick={onClose}
                        className="cursor-pointer text-[18px]"
                    >
                        {'✖'}
                    </span>
                </div>
                <Divider />
                <div
                    className="lg:grid grid-cols-2 grid-rows-1"
                    style={{ height: 'calc(100vh - 45px)' }}
                >
                    <CustomAzureMap
                        onSearch={onSearch}
                        isPending={isTotalPending}
                    />
                    <div className="lg:block hidden relative h-full">
                        {isPagePending && <LoadingAnimation />}
                        <MapPropertyPanel />
                    </div>
                </div>
            </div>
        </ModalLayout>
    );
};

export default AzureMapModal;
