const NoItems = () => {
    return (
        <div className="pt-[60px]">
            <div className="flex flex-col items-center gap-[28px]">
                <img alt="No Shareable Links" src="/image/NoSharebleLinks.png" />
                <div className="flex flex-col gap-[8px] text-center">
                    <div className="text-[18px] font-semibold">No Shareable Links Yet</div>
                    <div className="max-w-[700px]">
                        You haven't generated any links. Add your favorite properties to a collection, create a shareable link, and send it to your client to
                        help them find the best deal!
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NoItems;
