import { ReactComponent as FeatureBedroomSvg } from 'assets/svg/feature-bedroom.svg';
import { ReactComponent as FeatureBathroomSvg } from 'assets/svg/feature-bathroom.svg';
import { ReactComponent as FeatureBuildAreaSvg } from 'assets/svg/feature-buildarea.svg';
import { ReactComponent as FeaturePlotSizeSvg } from 'assets/svg/feature-plotsize.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatNumber } from 'util/Funcs';
import { PropertyDTO } from 'util/Types';
import { Divider } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import { PropertyAdditionalDetail } from 'types/Property';
import ExplorerListingButtonGroup from './ExploreListingButtonGroup';
import Skeleton from 'react-loading-skeleton';
import { useModals } from 'pages/MainProvider';

interface DetailPaneProps {
    property: PropertyDTO | null;
    additionalDetails: PropertyAdditionalDetail | null;
    onExplorerListingClicked?: () => void;
    isDetailLoading?: boolean;
    isPublic?: boolean;
}

const DetailPane = (props: DetailPaneProps) => {
    const { additionalDetails, property, isDetailLoading = true, isPublic = false } = props;
    const { isCompetitorPreviewOpened } = useModals();

    if (!property?.details) return null;

    const details = property.details;
    return (
        <div className="flex flex-col gap-[12px] w-full">
            <div className="flex justify-between items-center">
                {/* Price */}
                <div className="text-[28px] font-bold text-[#63C3C1] pt-[8px]">
                    {details.price && details.price > 0 ? `${formatNumber(details.price)} ${getSymbolFromCurrency(details.currency) || ''} ` : 'On Request'}
                </div>
                {additionalDetails && (
                    <div className="text-[10px] leading-[10px] lg:block hidden">
                        <div className="font-semibold">{`Views`}</div>

                        <div className="flex items-center gap-[5px]">
                            <VisibilityIcon />
                            <span className="font-semibold text-[18px]">{additionalDetails.view || 0}</span>
                            {additionalDetails?.view && (
                                <>
                                    <span className="text-my-blue p-[4px] bg-my-blue bg-opacity-10 font-medium">{`+${additionalDetails.view}`}</span>
                                    <span>{`In this month`}</span>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <Divider className="w-full" />
            <div className="flex justify-between items-center">
                <div className="relative flex flex-wrap gap-[12px]">
                    {details.bedrooms > 0 && (
                        <>
                            <div className="flex items-center gap-[8px]">
                                <FeatureBedroomSvg />

                                <span className="text-my-black">
                                    <span className="font-bold">{formatNumber(details.bedrooms)}</span>
                                    <span className="text-opacity-80">{` Bedroom`}</span>
                                </span>
                            </div>
                            <Divider className="h-full" orientation="vertical" />
                        </>
                    )}
                    {details.bathrooms > 0 && (
                        <>
                            <div className="flex items-center gap-[8px]">
                                <FeatureBathroomSvg />

                                <span className="text-my-black">
                                    <span className="font-bold">{formatNumber(details.bathrooms)}</span>
                                    <span className="text-opacity-80">{` Bathroom`}</span>
                                </span>
                            </div>
                            <Divider className="h-full" orientation="vertical" />
                        </>
                    )}

                    {details.sizeLand > 0 && (
                        <>
                            <div className="flex items-center gap-[8px]">
                                <FeatureBuildAreaSvg />

                                <span className="text-my-black">
                                    <span className="font-bold">{`${formatNumber(details.sizeLand)}m2`}</span>
                                    <span className="text-opacity-80">{` Built areas`}</span>
                                </span>
                            </div>
                            <Divider className="h-full" orientation="vertical" />
                        </>
                    )}
                    {details.sizePlot > 0 && (
                        <div className="flex items-center gap-[8px]">
                            <FeaturePlotSizeSvg />

                            <span className="text-my-black">
                                <span className="font-bold">{`${formatNumber(details.sizePlot)}m2`}</span>
                                <span className="text-opacity-80">{` Plot size`}</span>
                            </span>
                        </div>
                    )}
                </div>
                {!isCompetitorPreviewOpened && !isPublic && (
                    <div className="xl:block hidden">
                        {isDetailLoading ? (
                            <Skeleton height={32} width={80} />
                        ) : (
                            <ExplorerListingButtonGroup property={property} additionalDetails={additionalDetails} />
                        )}
                    </div>
                )}
            </div>
            {additionalDetails && (
                <div className="text-[10px] leading-[10px] lg:hidden flex flex-col gap-[4px]">
                    <Divider className="w-full" />
                    <div className="font-semibold pt-[8px]">{`Views`}</div>

                    <div className="flex items-center gap-[5px]">
                        <VisibilityIcon />
                        <span className="font-semibold text-[18px]">{additionalDetails.view || 0}</span>
                        {additionalDetails?.view && (
                            <>
                                <span className="text-my-blue p-[4px] bg-my-blue bg-opacity-10 font-medium">{`+${additionalDetails.view}`}</span>
                                <span>{`In this month`}</span>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DetailPane;
