import { FormControl, MenuItem, OutlinedInput, Select } from '@mui/material';
import { ReactComponent as CreateShareLinkSvg } from 'assets/svg/Create-Shareable-Link.svg';
import LoadingAnimation from 'components/Loading/LoadingAnimation';
import { HelloMuiButton } from 'components/Mui';
import HelloSwitch from 'components/Mui/HelloSwitch';
import { useEffect, useState } from 'react';
import { LocalShareLinkDto, ShareContactInfo } from 'types/SharableLink';
import { ModalLayoutProps } from '..';
import ModalLayout from '../ModalLayout';
import AddContactToShareableLinkModal from './AddContact';

const DefaultValueForShareLink: LocalShareLinkDto = {
    title: '',
    customMessage: '',
    password: '',
    collectionId: '',
    expirationDate: '7',
    propertyIds: [],
};

interface CreateSharableLinkMoalProps extends ModalLayoutProps {
    isSaving?: boolean;
    onCreate: (shareableLinkItem: LocalShareLinkDto) => void;
}

const Content = ({
    shareItem,
    includeContactFlag,
    setIncludeContactFlag,
    onFieldChanged,
    showEditDialog,
    onCreateClicked,
}: {
    shareItem: LocalShareLinkDto;
    includeContactFlag: boolean;
    setIncludeContactFlag: (b: boolean) => void;
    onFieldChanged: (key: keyof LocalShareLinkDto, val: any) => void;
    showEditDialog: () => void;
    onCreateClicked: () => void;
}) => {
    const [expirationDate, setExpirationDate] = useState<number>(7);

    const disabled = !shareItem.title || shareItem.password.length < 7;

    const onExpirationChanged = (expiryDays: number) => {
        onFieldChanged('expirationDate', expiryDays);
        setExpirationDate(expiryDays);
    };

    const onPassCodeChanged = (txt: string) => {
        if (/^\d{0,3}(-\d{0,3})?$/.test(txt)) {
            if (shareItem.password.length === 2 && txt.length === 3) {
                onFieldChanged('password', txt + '-');
            } else onFieldChanged('password', txt);
        }
    };

    return (
        <div className="bg-white rounded-[10px] p-[20px] max-w-[770px]">
            <div className="flex flex-col gap-[16px]">
                <div className="py-[4px] pt-[24px]">
                    <div className="font-semibold text-[18px]">{`Create Shareable link`}</div>
                    <div>{`You're about to create a shareable link for the selected listings. Please configure the options below:`}</div>
                </div>

                <div className="flex flex-col gap-[10px]">
                    <div className="font-medium">{`Link Title`}</div>
                    <OutlinedInput
                        className="!w-full !text-[12px] !h-[42px] !bg-my-gray !bg-opacity-10"
                        placeholder="e.g.  Luxury Mallorca Properties"
                        value={shareItem.title}
                        onChange={(e) => onFieldChanged('title', e.target.value)}
                    />
                </div>

                <div className="flex flex-col gap-[10px]">
                    <div className="font-medium">{`Custom message (Optional)`}</div>

                    <OutlinedInput
                        minRows={3}
                        maxRows={10}
                        multiline
                        className="!w-full !text-[12px] !bg-my-gray !bg-opacity-10"
                        placeholder="e.g. Here are some properties I think you'll love. Let me know if you'd like more information!"
                        value={shareItem.customMessage}
                        onChange={(e) => onFieldChanged('customMessage', e.target.value)}
                    />
                </div>

                <div className="flex flex-col gap-[10px]">
                    <div className="font-medium">{`Add Passcode for Link Protection`}</div>
                    <div className="text-[12px]">
                        <span>{`Set a `}</span>

                        <span className="font-semibold">{`6-digit passcode`}</span>

                        <span>{` to restrict access to this link. Share the passcode with your client to allow them to view the property listings.`}</span>
                    </div>
                    <OutlinedInput
                        className="!w-full !text-[12px] !h-[42px] !bg-my-gray !bg-opacity-10"
                        placeholder="000-000"
                        value={shareItem.password}
                        onChange={(e) => onPassCodeChanged(e.target.value)}
                    />
                </div>

                <div className="flex flex-col gap-[10px]">
                    <div className="font-medium">{`Expiration Date`}</div>
                    <div className="h-[42px] flex text-[12px]">
                        <div className="min-w-[150px]">
                            <FormControl className="!w-full !h-full">
                                <Select
                                    value={expirationDate}
                                    onChange={(e) => onExpirationChanged(Number(e.target.value))}
                                    displayEmpty
                                    inputProps={{
                                        'aria-label': 'Without label',
                                    }}
                                    renderValue={(val) => {
                                        return val + ' days';
                                    }}
                                    className="w-full h-full rounded-[8px] border-[#DFE1E1] !text-[12px] leading-6 px-1"
                                    id="referral-source"
                                >
                                    {[7, 14, 21, 30].map((val, index) => (
                                        <MenuItem value={val} className="text-[14px]" key={index}>
                                            {val + ' days'}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className="bg-my-gray bg-opacity-10 p-[16px] flex justify-between items-center rounded-[8px] border border-my-gray">
                        <div>
                            <div className="font-medium">{`Include your Contact Information`}</div>
                            <div>
                                <HelloMuiButton
                                    sx={{
                                        height: '18px',
                                        paddingX: 0,
                                    }}
                                    onClick={showEditDialog}
                                    disabled={!includeContactFlag}
                                >
                                    <span
                                        className={`text-[10px] cursor-pointer ${includeContactFlag ? 'text-my-blue' : 'text-my-gray'}`}
                                    >{`Edit Contact`}</span>
                                </HelloMuiButton>
                            </div>
                        </div>

                        <HelloSwitch checked={includeContactFlag} onChange={(e) => setIncludeContactFlag(e.target.checked)} />
                    </div>
                </div>
                <div className="md:hidden h-[50px]"></div>
                <div className="md:relative fixed w-full bottom-0 left-0 md:p-0 px-[20px] py-[8px] bg-white">
                    <HelloMuiButton
                        variant="contained"
                        className={`!bg-my-black !w-full !text-[12px] !text-white ${disabled ? 'opacity-30' : ''}`}
                        disabled={disabled}
                        onClick={onCreateClicked}
                    >
                        <div className="flex items-center gap-[4px]">
                            <CreateShareLinkSvg />
                            <span>{`Create Shareable Link`}</span>
                        </div>
                    </HelloMuiButton>
                </div>
            </div>
        </div>
    );
};

const CreateSharableLinkMoal = ({ visible, onClose, onCreate, isSaving = false }: CreateSharableLinkMoalProps) => {
    const [shareItem, setShareItem] = useState<LocalShareLinkDto>(DefaultValueForShareLink);
    const [includeContactFlag, setIncludeContactFlag] = useState<boolean>(false);
    const [showEditDialogFlag, setShowEditDialogFlag] = useState<boolean>(false);

    useEffect(() => {
        setIncludeContactFlag(false);
        setShareItem(DefaultValueForShareLink);
    }, [visible]);

    const onCreateClicked = () => {
        onCreate(shareItem);
    };

    const onFieldChanged = (key: keyof LocalShareLinkDto, val: any) => {
        setShareItem({
            ...shareItem,
            [key]: val,
        });
    };

    const onContactInfoChanged = (contactInfo: ShareContactInfo) => {
        onFieldChanged('contactInfo', contactInfo);
    };

    return (
        <>
            <ModalLayout visible={visible} onClose={onClose} showCloseIcon={true}>
                <div className="md:block hidden relative">
                    {isSaving && <LoadingAnimation />}
                    <Content
                        onFieldChanged={onFieldChanged}
                        includeContactFlag={includeContactFlag}
                        setIncludeContactFlag={setIncludeContactFlag}
                        onCreateClicked={onCreateClicked}
                        shareItem={shareItem}
                        showEditDialog={() => setShowEditDialogFlag(true)}
                    />
                </div>

                <div className="md:hidden fixed w-screen h-screen top-0 left-0 bg-white overflow-auto">
                    <div className="absolute right-2 top-2 cursor-pointer text-[18px] leading-[20px]" onClick={onClose}>
                        {'✖'}
                    </div>
                    {isSaving && <LoadingAnimation />}
                    <Content
                        onFieldChanged={onFieldChanged}
                        includeContactFlag={includeContactFlag}
                        setIncludeContactFlag={setIncludeContactFlag}
                        onCreateClicked={onCreateClicked}
                        shareItem={shareItem}
                        showEditDialog={() => setShowEditDialogFlag(true)}
                    />
                </div>
            </ModalLayout>

            <AddContactToShareableLinkModal visible={showEditDialogFlag} onClose={() => setShowEditDialogFlag(false)} onSave={onContactInfoChanged} />
        </>
    );
};

export default CreateSharableLinkMoal;
