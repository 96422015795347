import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { OutlinedInput } from '@mui/material';
import { CityFilter, CountryFilter } from 'components/Filter';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { queryClient } from 'routes';
import useCompetitorsStore from 'store/useCompetitorsStore';
import { AgentFilterType } from 'types/AgentFilterType';
import { DEFAULT_REGION, Region } from 'types/Region';
import { DEFAULT_DEBOUNCE_TIME } from 'util/Constants';

interface FormData {
    searchTerm: string;
}

const debouncedSetFilter = _.debounce(
    (
        setFilter: (filter: AgentFilterType) => void,
        newFilter: AgentFilterType,
    ) => {
        setFilter(newFilter);
    },
    DEFAULT_DEBOUNCE_TIME,
);

const FilterSection = () => {
    const [region, setRegion] = useState<Region>(DEFAULT_REGION);

    const { setFilter, filter, currentPage } = useCompetitorsStore();
    const { control } = useForm<FormData>();

    const updateFilterAndInvalidateQueries = useCallback(
        (newFilter: AgentFilterType) => {
            debouncedSetFilter(setFilter, newFilter);
            queryClient.invalidateQueries({
                queryKey: ['fetchAllCompetitors', currentPage, newFilter],
                exact: true,
            });
        },
        [setFilter, currentPage],
    );

    const handleFilterChange = useCallback(
        (key: keyof AgentFilterType, value: string) => {
            const newFilter = { ...filter, [key]: value };
            updateFilterAndInvalidateQueries(newFilter);
        },
        [filter, updateFilterAndInvalidateQueries],
    );

    const handleCountryChange = useCallback(
        (region: Region) => {
            setRegion(region);
            const newFilter = {
                country: region.name,
                city: '',
                searchTerm: filter.searchTerm,
            };
            updateFilterAndInvalidateQueries(newFilter);
        },
        [filter.searchTerm, updateFilterAndInvalidateQueries],
    );

    const handleSearchTermChange = useCallback(
        (searchTerm: string) => {
            const newFilter = {
                country: region.name,
                city: filter.city,
                searchTerm,
            };
            updateFilterAndInvalidateQueries(newFilter);
        },
        [filter.city, region.name, updateFilterAndInvalidateQueries],
    );

    return (
        <div className="px-[20px] py-[14px] border-b border-my-gray">
            <div className="md:h-[36px] flex md:justify-between md:items-center gap-[12px] md:flex-row flex-col">
                <Controller
                    name="searchTerm"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <div className="flex-grow">
                            <OutlinedInput
                                onChange={(e) => {
                                    onChange(e);
                                    handleSearchTermChange(e.target.value);
                                }}
                                name="searchTerm"
                                value={value}
                                autoFocus={true}
                                fullWidth
                                placeholder="Search Competitors"
                                className="!rounded-[5px] h-[36px] !text-[14px]"
                                startAdornment={<SearchOutlinedIcon />}
                                sx={{
                                    '& .MuiOutlinedInput-input': {
                                        p: '10px',
                                    },
                                }}
                            />
                        </div>
                    )}
                />

                <div className="flex gap-[12px] h-full flex-col md:flex-row">
                    <div className="min-w-[200px] h-[36px] text-[12px]">
                        <CountryFilter
                            selectedRegion={region}
                            onChanged={handleCountryChange}
                        />
                    </div>

                    <div className="min-w-[200px] h-[36px] text-[12px]">
                        <CityFilter
                            selectedCity={filter.city}
                            onChanged={(city) =>
                                handleFilterChange('city', city)
                            }
                            region={region}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterSection;
