import { Divider } from '@mui/material';
import ButtonWithIcon from 'components/ButtonWithIcon';
import LoadingSpinner from 'components/LoadingSpinner';
import { UseFormRegister } from 'react-hook-form';
import useUserStore from 'store/useUserStore';
import { AccountFormData } from './Account';

interface PhoneSectionProps {
    register: UseFormRegister<AccountFormData>;
    handleUpdatePhone: () => void;
    isUpdatingPhone: boolean;
    watchPhone: string;
}

const PhoneSection = ({ register, handleUpdatePhone, isUpdatingPhone, watchPhone }: PhoneSectionProps) => {
    const { userInfo: user } = useUserStore();

    return (
        <div className="phone">
            <div className="font-semibold text-[18px] mb-[14px]">Phone</div>
            <div className="flex gap-[10px] flex-wrap">
                <input
                    type="text"
                    placeholder="+34 1 xxxx xxxx"
                    className="border border-my-gray rounded-[8px] h-[42px] px-[20px] md:w-[342px] w-full"
                    {...register('phone')}
                    data-cy="account-phone-input"
                />
                {isUpdatingPhone ? (
                    <LoadingSpinner loading={isUpdatingPhone} className="!mt-[4px]" />
                ) : (
                    <ButtonWithIcon
                        onClick={handleUpdatePhone}
                        text="Update"
                        disabled={watchPhone === user?.phone || !watchPhone}
                        className={`!rounded-[8px] !h-[42px] ${watchPhone === user?.phone || !watchPhone ? '!bg-my-gray' : '!bg-my-black'}`}
                    />
                )}
            </div>
            <Divider className="!my-8 !max-w-setting" />
        </div>
    );
};

export default PhoneSection;
