import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { CollectionDto } from 'types/Collection';
import { create } from 'zustand';
import useSharableLinkStore from './sharableLinksStore';

interface CollectionStore {
    collectionList: CollectionDto[];
    loadCollections: () => Promise<CollectionDto[]>;
    removeCollection: (collectionId: string) => Promise<boolean>;
    saveCollection: (collectionName: string) => Promise<CollectionDto>;
    updateCollection: (collectionId: string, title: string) => Promise<boolean>;
    savePropertiesToCollection: (propertyId: string, tobeAddedCollectionIds: string[], tobeRemovedCollectionIds: string[]) => Promise<CollectionDto>;

    loadPropertiesInCollection: (collectionId: string) => Promise<CollectionDto>;

    removePropertyInCollection: (collectionId: string, propertyId: string) => Promise<boolean>;
    downloadCSV: (collectionId: string) => Promise<string>;
}

const useCollectionStore = create<CollectionStore>((set, get) => ({
    collectionList: [],

    loadCollections: async () => {
        try {
            const { data } = await axiosInstance.get(`/collections`);
            set({ collectionList: data.data });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },

    removeCollection: async (collectionId: string) => {
        try {
            const { data: result } = await axiosInstance.delete(`/collections/${collectionId}`);
            set({ collectionList: get().collectionList.filter((c) => c.id !== collectionId) });
            useSharableLinkStore.setState((state) => {
                return {
                    ...state,
                    sharablelinks: state.sharablelinks.filter((link) => link.collectionId !== collectionId),
                };
            });
            return result;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },

    saveCollection: async (collectionName: string) => {
        try {
            const { data } = await axiosInstance.post(`/collections`, {
                name: collectionName,
            });
            const newCollection = {
                ...data.data,
                properties: [],
            };
            set({ collectionList: [...get().collectionList, newCollection] });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },

    updateCollection: async (collectionId: string, title: string) => {
        try {
            const { data: result } = await axiosInstance.patch(`/collections/${collectionId}`, {
                name: title,
            });
            set({
                collectionList: get().collectionList.map((c) => (c.id === collectionId ? { ...c, name: title } : c)),
            });
            return result as boolean;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },

    loadPropertiesInCollection: async (id: string) => {
        try {
            const { data: result } = await axiosInstance.get(`/collections/${id}/properties`);
            return result.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },

    savePropertiesToCollection: async (propertyId: string, tobeAddedCollectionIds: string[], tobeRemovedCollectionIds: string[]) => {
        try {
            const { data } = await axiosInstance.post(`/collections/add-remove-property-to-collections`, {
                propertyId,
                collectionsToBeAdded: tobeAddedCollectionIds,
                collectionsToBeRemoved: tobeRemovedCollectionIds,
            });
            set({ collectionList: data.data });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },

    removePropertyInCollection: async (collectionId: string, propertyId: string) => {
        try {
            const { data: result } = await axiosInstance.delete(`/collections/${collectionId}/properties/${propertyId}`);
            return result.data as boolean;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    downloadCSV: async (collectionId: string) => {
        try {
            const { data: result } = await axiosInstance.get(`/collections/download-csv/${collectionId}`);
            return result.data.url as string;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
}));

export default useCollectionStore;
