import { Box } from '@mui/material';
import { useOnboardingStore } from 'store/useOnboardingStore';
import CopmanyAccountSection from './CompanyAccount';
import PersonalAccountSection from './PersonalAccount';

const IntroduceSection = () => {
    const { account, setAccount } = useOnboardingStore();

    return (
        <Box
            className="lg:h-full lg:flex"
            sx={{
                height: 'calc(100vh - 135px)',
            }}
        >
            <div className="lg:w-2/5 lg:flex items-center lg:pt-0 px-[20px] py-[42px]">
                <div className="text-center lg:text-left flex flex-col gap-[16px] lg:translate-y-[-50%]">
                    <div className="font-semibold">TELL US ABOUT YOURSELF</div>
                    <div className="font-semibold text-[24px] text-my-blue leading-[21px]">
                        {"We're thrilled to have you join us!"}
                    </div>
                    <div className="leading-[24px]">
                        To provide you with the best possible experience, we
                        require some relevant information.
                    </div>
                </div>
            </div>

            <div className="lg:w-3/5 lg:p-[20px] pb-[60px]">
                <div className="lg:border border-my-gray lg:rounded-[10px] p-[20px] flex flex-col gap-[16px] h-full bg-white bg-opacity-20 overflow-auto">
                    <div className="font-semibold text-[18px]">{`Account Type`}</div>
                    <div className="">
                        Are you creating this account on behalf of a company or
                        for yourself?
                    </div>
                    <div className="flex h-[42px] min-h-[42px] overflow-hidden w-fit">
                        <div
                            className={`flex cursor-pointer bg-my-gray bg-opacity-20 px-[20px] items-center gap-[10px] border rounded-l-[10px] ${account.type === 'Personal' ? 'bg-my-green bg-opacity-20 border-my-green' : 'border-my-gray '}`}
                            onClick={() =>
                                setAccount({
                                    type: 'Personal',
                                    websiteUrl: '',
                                    referralSource: '',
                                })
                            }
                            data-cy="personal-account"
                        >
                            <img
                                alt={'Personal '}
                                src="/image/OnBoarding/PersonalAccount.png"
                                className="w-[24px] h-[24px]"
                            />
                            <div>Personal</div>
                        </div>

                        <div
                            className={`flex cursor-pointer bg-my-gray bg-opacity-20 px-[20px] items-center gap-[10px] border rounded-r-[10px] ${account.type === 'Company' ? 'bg-my-green bg-opacity-20 border-my-green' : 'border-my-gray'}`}
                            onClick={() =>
                                setAccount({
                                    type: 'Company',
                                    websiteUrl: '',
                                    referralSource: '',
                                })
                            }
                            data-cy="company-account"
                        >
                            <img
                                alt={'Company '}
                                src="/image/OnBoarding/CompanyAccount.png"
                                className="w-[24px] h-[24px]"
                            />
                            <div>{'Company'}</div>
                        </div>
                    </div>
                    {account.type === 'Personal' && (
                        <PersonalAccountSection onChange={setAccount} />
                    )}
                    {account.type === 'Company' && (
                        <CopmanyAccountSection onChange={setAccount} />
                    )}
                </div>
            </div>
        </Box>
    );
};

export default IntroduceSection;
