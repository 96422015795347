import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ButtonWithIcon from 'components/ButtonWithIcon';

interface DeleteAccountSectionProps {
    onDeleteAccount: () => void;
}

const DeleteAccountSection = ({
    onDeleteAccount,
}: DeleteAccountSectionProps) => {
    return (
        <div>
            <div
                className="font-semibold text-[18px] mb-[14px]"
                data-cy="account-delete-account-text"
            >
                Delete account
            </div>
            <div className="text-my-black">
                If you no longer want to use hello:data, you can permanently
                delete your account.
            </div>
            <div className="mt-[20px]">
                <ButtonWithIcon
                    onClick={onDeleteAccount}
                    text="Delete My Account"
                    disabled={false}
                    className="!rounded-[8px] !h-[42px] !bg-my-red"
                    icon={<InfoOutlinedIcon />}
                />
            </div>
        </div>
    );
};

export default DeleteAccountSection;
