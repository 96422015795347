import { yupResolver } from '@hookform/resolvers/yup';
import { OutlinedInput } from '@mui/material';
import ErrorMessage from 'components/ErrorMessage';
import { HelloMuiButton } from 'components/Mui';
import axiosInstance from 'config/axiosConfig';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { contactInfoSchema } from 'schemas/contactInfoSchema';
import useUserStore from 'store/useUserStore';
import { ShareContactInfo } from 'types/SharableLink';
import { ModalLayoutProps } from '..';
import ModalLayout from '../ModalLayout';

interface FormData {
    emailAddress: string;
    phoneNumber: string;
    companyName?: string;
    officeAddress?: string;
}

interface AddContactToShareableLinkModalProps extends ModalLayoutProps {
    onSave: (contact: ShareContactInfo) => void;
}

const Content = ({ onClose, onSave }: { onClose: () => void; onSave: (contact: ShareContactInfo) => void }) => {
    const { userInfo: user } = useUserStore();
    const { handleSubmit, control, formState } = useForm<FormData>({
        resolver: yupResolver<FormData>(contactInfoSchema),
        defaultValues: async () => {
            const { data } = await axiosInstance(`${process.env.REACT_APP_BACKEND_HOST}/shareable-links/contact-info`);

            if (data.data && Object.keys(data?.data).length > 0) {
                const { emailAddress, phoneNumber, companyName, officeAddress } = data.data;
                return {
                    emailAddress,
                    phoneNumber,
                    companyName,
                    officeAddress,
                };
            }
            return {
                emailAddress: user?.email || '',
                phoneNumber: user?.phone || '',
                companyName: '',
                officeAddress: '',
            };
        },
    });

    const onSubmit: SubmitHandler<FormData> = (data) => onSave(data as ShareContactInfo);
    return (
        <form className="rounded-[10px] bg-white p-[16px] md:max-w-[550px] w-full" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-[16px] pt-[20px]">
                <div className="flex flex-col gap-[8px]">
                    <div className="font-medium text-[16px]">Add Your Contact Information</div>
                    <div>Please provide your contact information so that your clients can reach out to you directly.</div>
                </div>

                <div className="flex flex-col gap-[8px]">
                    <div className="font-medium">Email Address</div>
                    <Controller
                        name="emailAddress"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedInput
                                className="!h-[42px] w-full !bg-my-gray !bg-opacity-10 !text-[12px]"
                                placeholder="Enter your professional email"
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                    {formState.errors.emailAddress && <ErrorMessage msg={`${formState.errors.emailAddress.message}`} />}
                </div>

                <div className="flex flex-col gap-[8px]">
                    <div className="font-medium">{`Phone Number`}</div>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedInput
                                className="!h-[42px] w-full !bg-my-gray !bg-opacity-10 !text-[12px]"
                                placeholder="Enter your phone number"
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                    {formState.errors.phoneNumber && <ErrorMessage msg={`${formState.errors.phoneNumber.message}`} />}
                </div>

                <div className="flex flex-col gap-[8px]">
                    <div className="font-medium">Agent/company Name (Optional)</div>
                    <Controller
                        name="companyName"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedInput
                                className="!h-[42px] w-full !bg-my-gray !bg-opacity-10 !text-[12px]"
                                placeholder="Enter your company or brokerage name"
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </div>

                <div className="flex flex-col gap-[8px]">
                    <div className="font-medium">Office Address (Optional)</div>
                    <Controller
                        name="officeAddress"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <OutlinedInput
                                className="!h-[42px] w-full !bg-my-gray !bg-opacity-10 !text-[12px]"
                                placeholder="Enter your office address"
                                value={value}
                                onChange={onChange}
                            />
                        )}
                    />
                </div>

                <div className="flex flex-col gap-[4px]">
                    <HelloMuiButton variant="contained" type="submit">
                        Save
                    </HelloMuiButton>

                    <HelloMuiButton onClick={onClose}>
                        <span className="text-my-black"> Cancel </span>
                    </HelloMuiButton>
                </div>
            </div>
        </form>
    );
};

const AddContactToShareableLinkModal = ({ visible, onClose, onSave }: AddContactToShareableLinkModalProps) => {
    const onSaveClicked = (contact: ShareContactInfo) => {
        onSave(contact);
        onClose();
    };

    return (
        <ModalLayout visible={visible} onClose={onClose} showCloseIcon={false}>
            <div className="md:block hidden">
                <Content onClose={onClose} onSave={onSaveClicked} />
            </div>

            <div className="md:hidden fixed bottom-0 left-0 w-screen">
                <Content onClose={onClose} onSave={onSaveClicked} />
            </div>
        </ModalLayout>
    );
};

export default AddContactToShareableLinkModal;
