import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useToast } from 'components/Providers/ToastProvider';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    AzureLocationResult,
    getAzureLocations,
} from 'sevices/azureLocationService';
import { AzureLocation } from 'types/Azure';
import { useOnClickOutside } from 'util/index';
import CustomOutlinedInput from '../CustomOutlinedInput';

interface LocationInputProps {
    showIcon?: boolean;
    value: string;
    onLocationClicked: (addr: AzureLocation) => void;
    'data-cy'?: string;
}

const LocationInput = ({
    value,
    onLocationClicked: setLocation,
    showIcon = true,
    'data-cy': dataCy,
}: LocationInputProps) => {
    const { showMessage } = useToast();
    const [expanded, setExpanded] = useState<boolean>(false);
    const [address, setAddress] = useState<string>('');
    const [addrList, setAddrList] = useState<AzureLocation[]>([]);
    const addrRef = useRef(null);

    useEffect(() => setAddress(value), [value]);

    const searchTextChanged = (_txt: string) => {
        setAddress(_txt);
        debounceFn(_txt);
    };

    const onLocationClicked = (addrItem: AzureLocation) => {
        setExpanded(false);

        setAddress(addrItem.title || '');
        setLocation(addrItem);
    };

    const loadAddressList = async (_query: string) => {
        const trimmed = _query.trim();
        if (trimmed.length < 3) {
            setAddrList([]);
            setExpanded(false);
            return;
        }

        const results: AzureLocationResult = await getAzureLocations(trimmed);

        if (results.error) {
            showMessage('Error', results.error);
            return;
        }

        setAddrList(results.locations);

        setExpanded(results.locations.length > 0);
    };

    const debounceFn = useCallback(_.debounce(loadAddressList, 500), []);
    useOnClickOutside(addrRef, () => setExpanded(false));

    return (
        <div className="relative h-full">
            <CustomOutlinedInput
                name={'address'}
                value={address}
                placeholder="Your Location"
                startIcon={showIcon && <PlaceOutlinedIcon />}
                endIcon={showIcon && <SearchOutlinedIcon />}
                onChange={(e: any) => searchTextChanged(e.target.value)}
                onFocus={() => setExpanded(true)}
                className="!h-full"
                data-cy={dataCy}
            />

            {expanded && (
                <div
                    ref={addrRef}
                    className="z-[10] absolute top-[20px] left-0 bg-white rounded-[5px] border border-[#DADADA] w-full translate-y-[30px] max-h-[200px] overflow-y-scroll"
                >
                    <div
                        className="flex flex-col gap-[5px] "
                        data-cy="location-list"
                    >
                        {addrList.map((addr: AzureLocation, index) => (
                            <span
                                key={index}
                                className="px-[10px] py-[5px] truncate cursor-pointer hover:bg-slate-200"
                                onClick={() => onLocationClicked(addr)}
                                title={addr.title}
                            >
                                {addr.title}
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default LocationInput;
