import { useMinAndMaxValuesForProperties } from 'hooks/useMinAndMaxValuesForProperties';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { DEFAULT_REGION, Region } from 'types/Region';
import { DEFAULT_FILTER, PROPERTY_TYPES, SALE_TYPE } from 'util/Constants';
import { FilterType } from 'util/Types';
import BedBathFilter from './BedBathFilter';
import CityFilter from './CityFilter';
import CountryFilter from './CountryFilter';
import RangeFilter from './RangeFilter';
import SimpleFilter from './SimpleFilter';
import StateFilter from './StateFilter';

interface FilterSetPaneProps {
    lock?: boolean;
    value?: FilterType;
    heightClass?: string;
    handleChanged?: (f: FilterType) => void;
    visible?: (keyof FilterType)[];
}

const FilterSetPane = forwardRef((props: FilterSetPaneProps, ref) => {
    const { lock = false, heightClass = 'h-[42px]', handleChanged = () => {}, visible = [] } = props;

    const [filter, setFilter] = useState<FilterType>(DEFAULT_FILTER);

    const [region, setRegion] = useState<Region>(DEFAULT_REGION);
    const { data } = useMinAndMaxValuesForProperties();

    const onFilterChanged = (key: keyof FilterType, value: Record<string, any> | string) => {
        const newFilter = { ...filter, [key]: value };
        setFilter(newFilter);
        handleChanged(newFilter);
    };

    const regionChanged = (region: Region) => {
        setRegion(region);
        const newFilter = { ...filter, country: region.name, city: '', state: '' };
        setFilter(newFilter);
        handleChanged(newFilter);
    };

    const onCityChanged = (cityName: string) => onFilterChanged('city', cityName);

    const onStateChanged = (stateName: string) => {
        const newFilter = { ...filter, state: stateName, city: '' };
        setFilter(newFilter);
        handleChanged(newFilter);
    };

    const onBedBathChanged = (bedCount: number, bathCount: number) => onFilterChanged('bedbath', { bedIdx: bedCount, bathIdx: bathCount });

    const onPropertyTypeChanged = (propertyType: string) => onFilterChanged('type', propertyType);

    const onSaleTypeChanged = (saleType: string) => onFilterChanged('listingType', saleType);

    const onConstAreaChanged = (constructionAreaRange: [number, number]) =>
        onFilterChanged('constructionArea', {
            from: constructionAreaRange[0],
            to: constructionAreaRange[1],
        });

    const onPlotAreaChanged = (plotAreaRange: [number, number]) =>
        onFilterChanged('plotArea', {
            from: plotAreaRange[0],
            to: plotAreaRange[1],
        });

    const onPriceChanged = (priceRange: [number, number]) => onFilterChanged('price', { from: priceRange[0], to: priceRange[1] });

    useImperativeHandle(ref, () => ({
        getFilter() {
            return filter;
        },
        clearFilter() {
            setFilter(DEFAULT_FILTER);
            setRegion(DEFAULT_REGION);
        },
    }));

    return (
        <>
            <div className={`${heightClass} ${visible.length === 0 || visible.includes('country') ? '' : 'hidden'}`} data-cy="country-filter">
                <CountryFilter lock={lock} selectedRegion={region} onChanged={regionChanged} />
            </div>

            <div className={`${heightClass} ${visible.length === 0 || visible.includes('state') ? '' : 'hidden'}`} data-cy="state-filter">
                <StateFilter lock={lock} selectedState={filter.state} onChanged={onStateChanged} region={region} />
            </div>
            <div className={`${heightClass} ${visible.length === 0 || visible.includes('city') ? '' : 'hidden'}`} data-cy="city-filter">
                <CityFilter lock={lock} selectedCity={filter.city} onChanged={onCityChanged} region={region} selectedState={filter.state} />
            </div>

            <div className={`${heightClass} ${visible.length === 0 || visible.includes('type') ? '' : 'hidden'}`} data-cy="property-type-filter">
                <SimpleFilter
                    stringlist={PROPERTY_TYPES}
                    placeholder="Type"
                    value={filter.type}
                    onChanged={onPropertyTypeChanged}
                    data-cy="property-type-filter-item"
                />
            </div>

            <div className={`${heightClass} ${visible.length === 0 || visible.includes('price') ? '' : 'hidden'}`} data-cy="price-filter">
                <RangeFilter
                    title="Price"
                    limit={[+data?.minPrice, +data?.maxPrice]}
                    unit="€"
                    value={[filter.price.from, filter.price.to]}
                    onChanged={onPriceChanged}
                    step={100}
                    data-cy="price-slider"
                />
            </div>

            <div className={`${heightClass} ${visible.length === 0 || visible.includes('bedbath') ? '' : 'hidden'}`} data-cy="bed-bath-filter">
                <BedBathFilter onCallback={onBedBathChanged} bedIdx={filter.bedbath.bedIdx} bathIdx={filter.bedbath.bathIdx} />
            </div>

            <div className={`${heightClass} ${visible.length === 0 || visible.includes('listingType') ? '' : 'hidden'}`} data-cy="sale-rental-filter">
                <SimpleFilter
                    stringlist={SALE_TYPE}
                    placeholder="Rental, Sales"
                    value={filter.listingType}
                    onChanged={onSaleTypeChanged}
                    data-cy="sale-rental-filter-item"
                />
            </div>

            <div
                className={`${heightClass} ${visible.length === 0 || visible.includes('constructionArea') ? '' : 'hidden'}`}
                data-cy="construction-area-filter"
            >
                <RangeFilter
                    title="Construction Area"
                    limit={[data?.minSizeLand, data?.maxSizeLand]}
                    unit="m2"
                    value={[filter.constructionArea.from, filter.constructionArea.to]}
                    onChanged={onConstAreaChanged}
                    step={1000}
                    data-cy="construction-area-slider"
                />
            </div>

            <div className={`${heightClass} ${visible.length === 0 || visible.includes('plotArea') ? '' : 'hidden'}`} data-cy="plot-area-filter">
                <RangeFilter
                    title="Plot Area"
                    limit={[data?.minSizePlot, data?.maxSizePlot]}
                    unit="m2"
                    value={[filter.plotArea.from, filter.plotArea.to]}
                    onChanged={onPlotAreaChanged}
                    step={1000}
                    data-cy="plot-area-slider"
                />
            </div>
        </>
    );
});

export default FilterSetPane;
