import { DuplicatePropertiesDto } from 'types/Property';
import { ModalLayoutProps } from '.';
import ModalLayout from './ModalLayout';
import FavAvatar from 'components/FavAvatar';
import CloseIcon from '@mui/icons-material/Close';
import { useModals } from 'pages/MainProvider';
import useCompetitorsStore from 'store/useCompetitorsStore';
import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';

interface DuplicatedCompetitorsModalProps extends ModalLayoutProps {
    duplicatedList?: DuplicatePropertiesDto[]; // Optional
}

const CopmetitorItem = ({
    item,
    onClick,
}: {
    item: DuplicatePropertiesDto;
    onClick: () => void;
}) => {
    return (
        <div
            className="flex items-center gap-[8px] cursor-pointer"
            onClick={onClick}
        >
            <FavAvatar
                url={item.websiteUrl}
                imageSize={16}
                containerSize={28}
            />
            <div className="font-medium truncate">{item.companyName}</div>
        </div>
    );
};

const DuplicatedCompetitorsModal = ({
    visible,
    onClose,
    duplicatedList,
}: DuplicatedCompetitorsModalProps) => {
    const { isCompetitorPreviewOpened, showPreviewCompetitorModal } =
        useModals();
    const { loadAgentById } = useCompetitorsStore();
    const { showMessage } = useToast();
    //mutation
    const { isPending: isPagePending, mutate: getAgentMutation } = useMutation({
        mutationFn: loadAgentById,
        onSuccess: (data) => {
            showPreviewCompetitorModal(data);
        },
        onError: (error) => {
            showMessage('Error Occured', error.message);
        },
    });

    const onClickPreviewCompetitor = (dp: DuplicatePropertiesDto) => {
        if (isCompetitorPreviewOpened) return;

        getAgentMutation(dp.companyId);
    };

    return (
        <ModalLayout visible={visible} onClose={onClose} showCloseIcon={false}>
            <div className="bg-white rounded-[10px] max-w-[350px] overflow-hidden md:min-w-[350px] min-w-[200px]">
                <div className="p-[20px] flex items-center justify-between">
                    <span className="font-semibold text-[16px]">
                        {'Competitors'}
                    </span>

                    <div
                        className="!min-w-[20px] !w-[20px] !min-h-[20px] !h-[20px] border border-my-black border-opacity-20 rounded-full flex items-center justify-center cursor-pointer"
                        onClick={onClose}
                    >
                        <CloseIcon className="!text-[12px]" />
                    </div>
                </div>

                <div className="relative overflow-hidden">
                    <div className="relative p-[20px] max-h-[300px] overflow-auto  flex flex-col gap-[14px] z-[5]">
                        {duplicatedList?.map((item, index) => (
                            <CopmetitorItem
                                key={index}
                                item={item}
                                onClick={() => onClickPreviewCompetitor(item)}
                            />
                        ))}
                    </div>

                    <div className="h-[20px]"></div>

                    <div className="absolute top-[-50%] left-[30px] w-[450px] h-[470px] bg-my-green bg-opacity-40 rounded-full blur-[200px] z-[4]"></div>
                </div>
            </div>
        </ModalLayout>
    );
};

export default DuplicatedCompetitorsModal;
