import { useQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useUserStore from 'store/useUserStore';
import { ROUTES } from 'util/Constants';
import BlackButton from '../BlackButton';
import HeaderTitle from '../HeaderTitle';

const VerifyEmailPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get('token')!;

    const { verifyUserEmail } = useUserStore();
    const { isPending, isError, error, isSuccess, data } = useQuery({
        queryKey: ['verifyUserEmail', token],
        queryFn: () => verifyUserEmail(token),
        enabled: !!token,
        refetchOnWindowFocus: false,
        retry: false,
    });

    if (!token) {
        return <Navigate to={ROUTES.SIGN_IN} />;
    }

    const renderContent = () => {
        if (isPending) {
            return <Skeleton className="w-[250px] h-[35px]" />;
        }

        if (isError) {
            return <span>{error.message}</span>;
        }

        if (isSuccess) {
            return (
                <div className="flex-col">
                    <span className="font-semibold">{data}</span>
                    <div className="text-[#121212] text-[14px] leading-[24px] opacity-80 my-4">
                        We're thrilled to welcome you on board. Log in to
                        hellod:ata and unlock your competitive edge!
                    </div>
                    <BlackButton
                        onClick={() => navigate(ROUTES.SIGN_IN)}
                        className="w-full"
                        data-cy="login-button"
                    >
                        <div className="text-[14px]">
                            <span>Log in</span>
                        </div>
                    </BlackButton>
                </div>
            );
        }

        return null;
    };

    return (
        <div className="h-screen w-screen md:flex lg:items-center md:justify-center">
            <div className="flex flex-col gap-[40px] md:w-[620px] md:px-[30px] px-[18px] pt-[70px] lg:pt-0">
                <HeaderTitle />
                <div className="verifyEmailDialog bg-white rounded-[20px] md:px-[36px] sm:px-[24px] px-[16px] py-[48px] flex flex-col gap-[25px]">
                    <div className="flex justify-center">
                        <img
                            src="/image/Email-Verified-Logo.png"
                            className="w-fit"
                            alt="Email Verified Logo"
                        />
                    </div>
                    <div className="flex flex-col gap-[10px] text-center">
                        <div className="text-black text-[20px] leading-[21px] flex justify-center">
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmailPage;
