import {
    InfiniteData,
    useMutation,
    useQueryClient,
} from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import useNotificationStore from 'store/useNotificationStore';
import {
    NotificationCount,
    NotificationMessage,
    NotificationType,
} from 'types/Notification';

export const useDeleteNotification = (activeTab: NotificationType) => {
    const countKey =
        activeTab === 'listingUpdate' ? 'listingUpdateCount' : 'reportCount';
    const { deleteNotification } = useNotificationStore();
    const toast = useToast();

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: deleteNotification,
        onMutate: async (id) => {
            await queryClient.cancelQueries({
                queryKey: ['loadNotifications', activeTab],
            });
            await queryClient.cancelQueries({
                queryKey: ['readUnreadNotificationCount'],
            });
            const previousData = queryClient.getQueryData([
                'loadNotifications',
                activeTab,
            ]);

            const previousCount = queryClient.getQueryData([
                'readUnreadNotificationCount',
            ]);

            queryClient.setQueryData(
                ['loadNotifications', activeTab],
                (oldData: InfiniteData<NotificationMessage[], unknown>) => {
                    const newData = oldData.pages.map(
                        (page: NotificationMessage[]) =>
                            page.filter(
                                (notification) => notification.id !== id,
                            ),
                    );
                    return { ...oldData, pages: newData };
                },
            );
            queryClient.setQueryData(
                ['readUnreadNotificationCount'],
                (oldData: NotificationCount) => {
                    return {
                        ...oldData,
                        [countKey]: Math.max(0, oldData[countKey] - 1),
                    };
                },
            );

            return () => {
                queryClient.setQueryData(
                    ['loadNotifications', activeTab],
                    previousData,
                );
                queryClient.setQueryData(
                    ['readUnreadNotificationCount'],
                    previousCount,
                );
            };
        },
        onError: (error, _, rollback) => {
            rollback?.();
            toast.showMessage('Error', error.message);
        },
    });
};
