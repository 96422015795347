import { MenuItem, Select } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import useUserStore from 'store/useUserStore';
import { User } from 'types/User';

interface UserPreferences {
    language: 'English';
    currency: string;
    notificationFrequency: 'Daily' | 'Weekly' | 'Monthly';
}

const AVAILABLE_LANGUAGES: UserPreferences['language'][] = ['English'];
const AVAILABLE_CURRENCIES = ['EUR', 'USD', 'GBP', 'AUD', 'CAD'];
const NOTIFICATION_FREQUENCIES: UserPreferences['notificationFrequency'][] = ['Daily', 'Weekly', 'Monthly'];

const FREQUENCY_MAPPING: {
    displayText: UserPreferences['notificationFrequency'];
    value: string;
}[] = [
    { displayText: 'Daily', value: 'day' },
    { displayText: 'Weekly', value: 'week' },
    { displayText: 'Monthly', value: 'month' },
];

const DEFAULT_PREFERENCES: UserPreferences = {
    language: 'English',
    currency: 'EUR',
    notificationFrequency: 'Daily',
};

const CurrencyMenuItem = ({ currencyCode }: { currencyCode: UserPreferences['currency'] }) => (
    <div className="flex items-center gap-[10px]">
        <ReactCountryFlag svg countryCode={currencyCode.slice(0, 2)} style={{ width: '2em', height: '2em' }} />
        <span>{currencyCode}</span>
    </div>
);

const PreferenceTab = () => {
    const { userInfo: user } = useUserStore();

    const [userPreferences, setUserPreferences] = useState<UserPreferences>({
        currency: user?.currency ?? DEFAULT_PREFERENCES.currency,
        language: DEFAULT_PREFERENCES.language,
        notificationFrequency:
            FREQUENCY_MAPPING.find((mapping) => mapping.value === (user?.notificationListings as UserPreferences['notificationFrequency']))?.displayText ??
            DEFAULT_PREFERENCES.notificationFrequency,
    });
    const { showMessage } = useToast();
    const { updateUserInfo } = useUserStore();

    const { mutate } = useMutation({
        mutationFn: updateUserInfo,
        onSuccess: (data) => {
            const { notificationListings, currency } = data;
            useUserStore.setState({
                userInfo: {
                    ...user,
                    notificationListings,
                    currency,
                } as User,
            });
            showMessage('Success', 'User preferences updated successfully');
        },
        onError: (error) => {
            showMessage('Error', error.message);
        },
    });

    const handlePreferenceChange = (preferenceKey: keyof UserPreferences, newValue: string) => {
        const updatedPreferences = {
            ...userPreferences,
            [preferenceKey]: newValue,
        };
        setUserPreferences(updatedPreferences);
        savePreferences(updatedPreferences);
    };

    const savePreferences = (updatedPreferences: UserPreferences) => {
        const updatedUserInfo = {
            notificationListings: FREQUENCY_MAPPING.find((mapping) => mapping.displayText === updatedPreferences.notificationFrequency)?.value,
            language: updatedPreferences.language.slice(0, 2).toLowerCase(),
            currency: updatedPreferences.currency,
        };

        mutate(updatedUserInfo);
    };

    const renderPreferenceSelect = (
        label: string,
        preferenceKey: keyof UserPreferences,
        options: string[],
        disabled?: boolean,
        renderValue?: (value: string) => React.ReactNode,
    ) => (
        <div className="flex justify-between items-center">
            <div className="font-medium leading-6">{label}</div>
            <Select
                value={userPreferences[preferenceKey]}
                onChange={(event) => handlePreferenceChange(preferenceKey, event.target.value)}
                renderValue={renderValue || ((selected) => selected)}
                className="!w-[120px] h-[36px] !text-[12px]"
                disabled={disabled}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option} className="!text-[12px]">
                        {renderValue ? renderValue(option) : option}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );

    return (
        <div className="flex flex-col gap-[15px] md:w-[722px]">
            {renderPreferenceSelect('Language', 'language', AVAILABLE_LANGUAGES, true)}
            {renderPreferenceSelect('Currency', 'currency', AVAILABLE_CURRENCIES, false, (currency) => (
                <CurrencyMenuItem currencyCode={currency as UserPreferences['currency']} />
            ))}
            {renderPreferenceSelect('New Listing Email', 'notificationFrequency', NOTIFICATION_FREQUENCIES, false)}
        </div>
    );
};

export default PreferenceTab;
