import MainLayout from 'pages/layout/MainLayout';
import NotificationContent from 'pages/layout/NotificationContent';

const NotificationPage = () => {
    return (
        <MainLayout>
            <div className="md:hidden block">
                <NotificationContent />
            </div>
        </MainLayout>
    );
};

export default NotificationPage;
