import { ReactComponent as SaveFilterPreferenceSvg } from 'assets/svg/SaveFilterPreference.svg';
import MobileFilter from 'components/MobileFilter';
import { useFiltersStore } from 'store/useFiltersStore';
import usePropertiesStore from 'store/usePropertiesStore';
import { DEFAULT_FILTER } from 'util/Constants';
import { getFilterCount } from 'util/Funcs';

const MobileFilterSection = ({
    onOpenSaveModal,
}: {
    onOpenSaveModal: () => void;
}) => {
    const { totalPropertiesCount } = usePropertiesStore();
    const { currentFilter, setCurrentFilter } = useFiltersStore();

    const onClearClicked = () => {
        setCurrentFilter(DEFAULT_FILTER);
    };

    return (
        <>
            <MobileFilter
                prevFilter={currentFilter}
                onChange={setCurrentFilter}
                resultCount={totalPropertiesCount}
            />

            <div className="flex gap-[10px] w-max pt-[10px] text-[12px]">
                {getFilterCount(currentFilter) > 0 && (
                    <div
                        className="flex items-center gap-[5px] cursor-pointer text-my-red"
                        onClick={onClearClicked}
                    >
                        <div
                            className={`rounded-full w-[20px] h-[20px] flex justify-center items-center cursor-pointer border border-my-red`}
                        >
                            <div className="leading-[25px] text-[25px]">
                                {'×'}
                            </div>
                        </div>
                        <div>{'Clear'}</div>
                    </div>
                )}
                <div
                    className="flex items-center gap-[5px] cursor-pointer text-my-brown"
                    onClick={onOpenSaveModal}
                >
                    <SaveFilterPreferenceSvg />
                    <span> {'Save Filter Preferences'} </span>
                </div>
            </div>
        </>
    );
};

export default MobileFilterSection;
