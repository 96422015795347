import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { Region, ResponseItem } from 'types/Region';
import { containsWithoutSpecialChars } from 'util/containsWithoutSpecialChars';
import { create } from 'zustand';

interface Item {
    title: string;
    lock: boolean;
}

interface RegionState {
    fetchRegions: () => Promise<Region[]>;
    regionList: Region[];
    totalCities: Item[];
    allStates: Item[];
    getStates: (lock: boolean, region: Region) => ResponseItem[];
    getNonStates: (region: Region) => ResponseItem[];
    getFilteredCities: (searchTerm: string) => Item[];
    getSelectedStateCities: (selectedState: string) => (string | undefined)[];
    getSelectedRegionCities: (selectedState: string, region: Region) => string[];
    getCities: (selectedState: string, region: Region) => (string | undefined)[];
    getNonCities: (selectedState: string, region: Region) => (string | undefined)[];
    getFilteredStates: (searchTerm: string) => Item[];
}

const useRegionStore = create<RegionState>((set, get) => ({
    regionList: [],
    totalCities: [],
    allStates: [],
    fetchRegions: async () => {
        try {
            const { data } = await axiosInstance.get(`/properties/filters`);

            let allCities: Item[] = [];
            let allStates: Item[] = [];
            data.data.map((region: Region) => {
                allCities = allCities.concat(
                    region.citiesAndStates
                        .map((cityAndState) => {
                            return cityAndState.Cities.map((city) => ({ title: city, lock: false }));
                        })
                        .flat(),
                );
                allCities = allCities.concat(
                    region.nonCitiesAndStates
                        .filter((cityAndState) => !region.citiesAndStates.some((state) => state.Cities.some((city) => cityAndState.Cities.includes(city))))
                        .map((cityAndState) => {
                            return cityAndState.Cities.map((city) => ({ title: city, lock: true }));
                        })
                        .flat(),
                );

                allStates = allStates.concat(region.citiesAndStates.map((state) => ({ title: state.State, lock: false })));
                allStates = allStates.concat(
                    region.nonCitiesAndStates
                        .filter((state) => !region.citiesAndStates.some((cityState) => cityState.State === state.State))
                        .map((state) => ({ title: state.State, lock: true })),
                );
            });

            set({
                regionList: data.data,
                totalCities: allCities,
                allStates: allStates,
            });

            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
        }
    },
    getStates: (lock, region) => {
        return lock
            ? region.citiesAndStates
            : [
                  ...region.citiesAndStates,
                  ...region.nonCitiesAndStates.filter((state) => !region.citiesAndStates.some((cityState) => cityState.State === state.State)),
              ];
    },

    getNonStates: (region) => {
        return region.nonCitiesAndStates.filter((state) => !region.citiesAndStates.some((cityState) => cityState.State === state.State));
    },
    getFilteredCities: (searchTerm) => {
        const { totalCities } = get();
        return totalCities
            .sort((a, b) => {
                if (a.lock && !b.lock) return 1;
                if (!a.lock && b.lock) return -1;
                return a.title.localeCompare(b.title);
            })
            .filter((city) => containsWithoutSpecialChars(city.title, searchTerm))
            .slice(0, 100);
    },
    getSelectedStateCities: (selectedState) => {
        const { regionList } = get();
        return regionList.map((region) => region.citiesAndStates.find((city) => city.State === selectedState)?.Cities).flat();
    },

    getSelectedRegionCities: (selectedState, region) => {
        return region.citiesAndStates
            .filter((city) => !selectedState || city.State === selectedState)
            .map((city) => city.Cities)
            .flat();
    },
    getCities: (selectedState, region) => {
        const { getSelectedStateCities, getSelectedRegionCities } = get();
        return selectedState ? getSelectedStateCities(selectedState) : getSelectedRegionCities(selectedState, region);
    },
    getNonCities: (selectedState, region) => {
        const { regionList, getSelectedStateCities, getSelectedRegionCities } = get();
        return selectedState
            ? regionList
                  .map((region) => region.nonCitiesAndStates.find((city) => city.State === selectedState)?.Cities)
                  .flat()
                  .filter((city) => !getSelectedStateCities(selectedState).includes(city))
            : region.nonCitiesAndStates
                  .filter((city) => !selectedState || city.State === selectedState)
                  .map((city) => city.Cities)
                  .flat()
                  .filter((city) => !getSelectedRegionCities(selectedState, region).includes(city));
    },
    getFilteredStates: (searchTerm) => {
        const { allStates } = get();
        return allStates
            .filter((state) => containsWithoutSpecialChars(state.title, searchTerm))
            .sort((a, b) => (a.lock ? 1 : -1) - (b.lock ? 1 : -1))
            .slice(0, 100);
    },
}));

export default useRegionStore;
