export interface Region {
    name: string;
    citiesAndStates: ResponseItem[];
    nonCitiesAndStates: ResponseItem[];
}
export const DEFAULT_REGION: Region = { name: '', citiesAndStates: [], nonCitiesAndStates: [] };

export interface ResponseItem {
    State: string;
    Cities: string[];
}
