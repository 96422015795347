import { Divider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as ExportIconSvg } from 'assets/svg/ExportSharableLink.svg';
import { HelloMuiButton, LoadingAnimation } from 'components';
import ViewPropertiesInLinkModal from 'components/Modals/SharableLink/ViewProperties';
import HelloSwitch from 'components/Mui/HelloSwitch';
import { useToast } from 'components/Providers/ToastProvider';
import { useLoadShareableLinks } from 'hooks/useShareableLinks';
import { useState } from 'react';
import useSharableLinkStore from 'store/sharableLinksStore';
import styled from 'styled-components';
import { ShareLinkDto } from 'types/SharableLink';
import { isExpiredLink } from 'util/dateTimeUtils';
import LargeDevicesContent from './LargeDevicesContent';
import MediumDevicesContent from './MediumDevicesContent';
import NoItems from './NoShareableLinks';

const DEFAULT_REFRESH_EXP_DAY = 7;

const LoadingContainter = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 140px);
    @media (max-width: 744px) {
        height: calc(100vh - 200px);
    }
`;

const SharableLinkSection = () => {
    const [showExpired, setShowExpired] = useState<boolean>(false);
    const [viewPropertiesFlag, setViewPropertiesFlag] = useState<boolean>(false);
    const [viewLinkId, setViewLinkId] = useState<string>('');

    const { sharablelinks, removeSharableLinks, downloadCSV, udpateLink } = useSharableLinkStore();

    const linksCount = sharablelinks.length;
    const filteredlinks = showExpired ? sharablelinks : sharablelinks.filter((link) => !isExpiredLink(link.expirationDate));
    const expiredCount = linksCount - filteredlinks.length;

    const { isPending } = useLoadShareableLinks();

    const { showMessage } = useToast();

    const { mutate: removeMutate } = useMutation({
        mutationFn: removeSharableLinks,
        mutationKey: ['removeSharableLinks'],
        onSuccess: () => showMessage('Success!', 'Successfully Removed'),
        onError: (error) => showMessage('Error!', error.message),
    });

    const { mutate: downloadMutate } = useMutation({
        mutationFn: downloadCSV,
        onSuccess: (url: string) => window.open(url),
    });

    const { mutate: updateLinkMutation } = useMutation({
        mutationFn: async (param: { linkId: string; expDate: string }) => {
            await udpateLink(param.linkId, param.expDate, 'expirationDate');
        },
        mutationKey: ['updateLink'],
        onSuccess: () => showMessage('Success!', 'Successfully Extended Expire Date!'),
        onError: (error) => showMessage('Error!', error.message),
    });

    const onViewClicked = (item: ShareLinkDto) => {
        setViewLinkId(item.id);
        setViewPropertiesFlag(true);
    };

    const onRemoveClicked = (linkdId: string) => removeMutate(linkdId);

    const onDownloadCSVClicked = async () => downloadMutate();

    const onRefreshClicked = (linkId: string) => {
        const now: Date = new Date();
        now.setDate(new Date().getDate() + DEFAULT_REFRESH_EXP_DAY);

        updateLinkMutation({ linkId: linkId, expDate: now.toISOString() });
    };

    if (isPending)
        return (
            <LoadingContainter>
                <LoadingAnimation />
            </LoadingContainter>
        );

    if (linksCount === 0) return <NoItems />;

    return (
        <>
            <div className="rounded-[10px] bg-white lg:px-[24px] md:px-[16px] px-[8px] lg:py-[36px] md:py-[24px] py-[12px]">
                <div className="flex flex-col md:gap-[24px] gap-[12px]">
                    {/* header */}
                    <div className="flex md:flex-row flex-col md:items-center justify-between">
                        <div>
                            <span className="font-semibold lg:text-[20px] md:text-[18px]">{`${linksCount} Shareable Links`}</span>
                            {expiredCount > 0 && <span className="text-my-red text-[12px]">{` (${expiredCount} Expired)`}</span>}
                        </div>
                        <div className="flex items-center gap-[12px] justify-end">
                            <HelloSwitch
                                height={18}
                                width={30}
                                checked={showExpired}
                                onChange={(evt) => {
                                    setShowExpired(evt.target.checked);
                                }}
                            />
                            <span className="text-[12px] font-medium">{`Show Expired Links`}</span>
                            <Divider orientation="vertical" className="!h-[32px]" />

                            <HelloMuiButton variant="outlined" onClick={onDownloadCSVClicked}>
                                <div className="flex gap-[8px] items-center">
                                    <ExportIconSvg />
                                    <span>{`Export`}</span>
                                </div>
                            </HelloMuiButton>
                        </div>
                    </div>

                    <div className="lg:block hidden text-[12px]">
                        <LargeDevicesContent
                            items={filteredlinks}
                            onViewClicked={onViewClicked}
                            onRemoveClicked={onRemoveClicked}
                            onRefreshClicked={onRefreshClicked}
                        />
                    </div>

                    <div className="lg:hidden text-[12px]">
                        <MediumDevicesContent
                            items={filteredlinks}
                            onViewClicked={onViewClicked}
                            onRemoveClicked={onRemoveClicked}
                            onRefreshClicked={onRefreshClicked}
                        />
                    </div>
                </div>
            </div>

            <ViewPropertiesInLinkModal visible={viewPropertiesFlag} onClose={() => setViewPropertiesFlag(false)} linkId={viewLinkId} />
        </>
    );
};

export default SharableLinkSection;
