import { Menu } from '@mui/material';
import { AddPropertyToCollectionModal, CreateCollectionModal, SelectCompetitorModal } from 'components/Modals';
import CompetitorDetailsModal from 'components/Modals/Competitor/PreivewDetails';
import { DEFAULT_MODAL_Z_INDEX } from 'components/Modals/ModalLayout';
import { PropertyDetailModal, ShareLinkPropertyDetailModal } from 'components/Modals/PropertyDetail';
import AdditionalDetailForMobile from 'components/PropertyTable/AdditionalDetailsForMobile';
import { createContext, ReactNode, useContext, useState } from 'react';
import { Agent } from 'types/Agent';
import { PropertyDTO } from 'util/Types';

interface MainContextType {
    selectedProperty: PropertyDTO | null;
    isAdditionalDetailOpened: boolean;
    isCompetitorPreviewOpened: boolean;
    isSelectCompetitorOpened: boolean;
    isPropertyDetailOpened: boolean;
    closeCompetitorModal: () => void;
    showSelectCompetitorModal: () => void;
    showAddPropertyToCollectionModal: (propertyId: string) => void;
    showCreateCollectionModal: () => void;
    showPreviewCompetitorModal: (c: Agent) => void;
    showPropertyDetailModal: (p: PropertyDTO, showCompetitorPreview: boolean, z?: number) => void;
    showShareLinkPropertyDetailModal: (p: PropertyDTO) => void;
    showAddtionalPropertyDetail: (anchoreEl: HTMLElement, p: PropertyDTO) => void;
}

const DefaultValue: MainContextType = {
    selectedProperty: null,
    isAdditionalDetailOpened: false,
    isCompetitorPreviewOpened: false,
    isSelectCompetitorOpened: false,
    isPropertyDetailOpened: false,
    closeCompetitorModal: () => {},
    showSelectCompetitorModal: () => {},
    showAddPropertyToCollectionModal: () => {},

    showCreateCollectionModal: () => {},
    showPreviewCompetitorModal: (c: Agent) => {},
    showPropertyDetailModal: (p: PropertyDTO, showCompetitorPreview: boolean, z?: number) => {},
    showShareLinkPropertyDetailModal: (p: PropertyDTO) => {},
    showAddtionalPropertyDetail: (anchoreEl: HTMLElement, p: PropertyDTO) => {},
};

export const MainContext = createContext<MainContextType>(DefaultValue);

const MainContextProvider = ({ children }: { children: ReactNode }) => {
    const [showSelectCopmetitorModalFlag, setShowSelectCopmetitorModalFlag] = useState<boolean>(false);
    const showSelectCompetitorModal = () => setShowSelectCopmetitorModalFlag(true);
    const closeCompetitorModal = () => setShowSelectCopmetitorModalFlag(false);

    const [showAddPropertyToCollectionModalFlag, setShowAddPropertyToCollectionModalFlag] = useState<boolean>(false);
    const [propertyId, setPropertyId] = useState<string>('');
    const showAddPropertyToCollectionModal = (propertyId: string) => {
        setPropertyId(propertyId);
        setShowAddPropertyToCollectionModalFlag(true);
    };
    const closeAddPropertyToCollectionModal = () => setShowAddPropertyToCollectionModalFlag(false);

    const [showCreateCollectionModalFlag, setShowCreateCollectionModalFlag] = useState<boolean>(false);

    const showCreateCollectionModal = () => {
        setShowCreateCollectionModalFlag(true);
    };

    const [showPreviewCompetitorFlag, setShowPreviewCompetitorFlag] = useState<boolean>(false);
    const [agentForPreview, setAgentForPreview] = useState<Agent | null>(null);
    const showPreviewCompetitorModal = (c: Agent) => {
        setAgentForPreview(c);
        setShowPreviewCompetitorFlag(true);
    };
    const [showShareLinkPropertyDetailModalFlag, setShowShareLinkPropertyDetailModalFlag] = useState<boolean>(false);

    const showShareLinkPropertyDetailModal = (p: PropertyDTO) => {
        setPropertyForPreview(p);
        setShowShareLinkPropertyDetailModalFlag(true);
    };

    const [showPropertyDetailFlag, setShowPropertyDetailFlag] = useState<boolean>(false);
    const [propertyForPreview, setPropertyForPreview] = useState<PropertyDTO | null>(null);
    const [zIndexForProperty, setZIndexForProperty] = useState<number>(DEFAULT_MODAL_Z_INDEX);

    const showPropertyDetailModal = (p: PropertyDTO, showCompetitorPreview: boolean, z: number = DEFAULT_MODAL_Z_INDEX) => {
        setPropertyForPreview(p);
        setZIndexForProperty(z);
        setShowPropertyDetailFlag(true);
    };
    const [anchorElForMobilePropertyAdditionalDetail, setAnchorElForMobilePropertyAdditionalDetail] = useState<null | HTMLElement>(null);
    const expandAdditionalDetail: boolean = Boolean(anchorElForMobilePropertyAdditionalDetail);
    const showAddtionalPropertyDetail = (anchoreEl: HTMLElement, p: PropertyDTO) => {
        setAnchorElForMobilePropertyAdditionalDetail(anchoreEl);
        setPropertyForPreview(p);
    };

    return (
        <MainContext.Provider
            value={{
                selectedProperty: propertyForPreview,
                isCompetitorPreviewOpened: showPreviewCompetitorFlag,
                isAdditionalDetailOpened: expandAdditionalDetail,
                isSelectCompetitorOpened: showSelectCopmetitorModalFlag,
                isPropertyDetailOpened: showPropertyDetailFlag,
                showSelectCompetitorModal,
                closeCompetitorModal,
                showAddPropertyToCollectionModal,
                showCreateCollectionModal,
                showPreviewCompetitorModal,
                showPropertyDetailModal,
                showShareLinkPropertyDetailModal,
                showAddtionalPropertyDetail,
            }}
        >
            {children}

            <SelectCompetitorModal visible={showSelectCopmetitorModalFlag} onClose={closeCompetitorModal} />

            <AddPropertyToCollectionModal propertyId={propertyId} visible={showAddPropertyToCollectionModalFlag} onClose={closeAddPropertyToCollectionModal} />

            <CreateCollectionModal visible={showCreateCollectionModalFlag} onClose={() => setShowCreateCollectionModalFlag(false)} />

            <CompetitorDetailsModal visible={showPreviewCompetitorFlag} onClose={() => setShowPreviewCompetitorFlag(false)} competitor={agentForPreview} />

            <PropertyDetailModal
                visible={showPropertyDetailFlag}
                onClose={() => setShowPropertyDetailFlag(false)}
                property={propertyForPreview}
                zIndex={zIndexForProperty}
            />

            <ShareLinkPropertyDetailModal
                visible={showShareLinkPropertyDetailModalFlag}
                onClose={() => setShowShareLinkPropertyDetailModalFlag(false)}
                property={propertyForPreview}
            />

            <Menu
                anchorEl={anchorElForMobilePropertyAdditionalDetail}
                open={expandAdditionalDetail}
                onClose={() => setAnchorElForMobilePropertyAdditionalDetail(null)}
            >
                <AdditionalDetailForMobile
                    property={propertyForPreview}
                    onDetailClicked={() => {
                        if (propertyForPreview) {
                            showPropertyDetailModal(propertyForPreview, false);
                        }
                        setAnchorElForMobilePropertyAdditionalDetail(null);
                    }}
                />
            </Menu>
        </MainContext.Provider>
    );
};

export default MainContextProvider;

const useModals = () => useContext(MainContext);

export { useModals };
