import { Divider } from '@mui/material';
import FavAvatar from 'components/FavAvatar';
import { DuplicatePropertiesDto } from 'types/Property';
import { PropertyDTO } from 'util/Types';
import DuplicatedCompetitorsModal from '../DuplicatedCompetitors';
import { useState } from 'react';

interface DuplicatePaneProps {
    property?: PropertyDTO | null;
    duplicateProperties?: DuplicatePropertiesDto[];
}

const DuplicatePane = (props: DuplicatePaneProps) => {
    const { property, duplicateProperties } = props;
    const [visible, setVisible] = useState<boolean>(false);

    if (!property || !duplicateProperties) return null;

    const duplicatedCount = duplicateProperties?.length || 0;
    const firstAgent: DuplicatePropertiesDto = duplicateProperties[0];

    return (
        <div className="flex md:items-center gap-[8px] flex-wrap md:flex-row flex-col">
            <div className="flex items-center gap-[8px]">
                <FavAvatar
                    url={property.webPageUrl}
                    imageSize={16}
                    containerSize={28}
                />
                <div className="font-semibold truncate">
                    {property.companyName}
                </div>
            </div>

            {duplicatedCount > 0 && (
                <>
                    <Divider
                        orientation="vertical"
                        className="!h-[28px] md:block hidden"
                    />
                    <div className="flex items-center gap-[8px]">
                        <span className="font-semibold">{`Also Listed on: `}</span>

                        <span>{firstAgent.companyName}</span>

                        {duplicatedCount > 1 && (
                            <span
                                className="text-my-blue font-medium cursor-pointer"
                                onClick={() => setVisible(true)}
                            >
                                {`+ ${duplicatedCount - 1} more`}
                            </span>
                        )}
                    </div>
                </>
            )}

            <DuplicatedCompetitorsModal
                visible={visible}
                onClose={() => setVisible(false)}
                duplicatedList={duplicateProperties}
            />
        </div>
    );
};

export default DuplicatePane;
