import { Dialog } from '@mui/material';
import { ReactNode } from 'react';

export const DEFAULT_MODAL_Z_INDEX = 3;
interface ModalLayoutProps {
    children: ReactNode;
    visible: boolean;
    outclickoff?: boolean;
    showCloseIcon?: boolean;
    showShadow?: boolean;
    onClose: () => void;
    zIndex?: number;
}

const ModalLayout = ({ children, visible, onClose, showShadow = true, showCloseIcon = true, zIndex = DEFAULT_MODAL_Z_INDEX }: ModalLayoutProps) => {
    return (
        <Dialog
            onClose={onClose}
            open={visible}
            sx={{
                '& .MuiDialog-paper': {
                    maxWidth: 'fit-content',
                },
                '& .MuiPaper-root': {
                    backgroundColor: 'transparent', // Adjust the paper transparency
                    boxShadow: showShadow ? '' : 'none',
                },
                zIndex: zIndex,
            }}
        >
            <div className="relative">
                {showCloseIcon && (
                    <div className="absolute right-2 top-2 cursor-pointer text-[18px] leading-[20px]" onClick={onClose}>
                        {'✖'}
                    </div>
                )}
                {children}
            </div>
        </Dialog>
    );
};

export default ModalLayout;
