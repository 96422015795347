import { CircularProgress } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as RemoveCollectionSvg } from 'assets/svg/DeleteCollection.svg';
import { HelloMuiButton } from 'components/Mui';
import { useToast } from 'components/Providers/ToastProvider';
import useCollectionStore from 'store/useCollection';
import { ModalLayoutProps } from '..';
import ModalLayout from '../ModalLayout';
interface DeleteCollectionModalProps extends ModalLayoutProps {
    collectionId: string;
    onCallBack: () => void;
}
const DeleteCollectionModal = (props: DeleteCollectionModalProps) => {
    const { visible, onClose, collectionId, onCallBack } = props;
    const { removeCollection } = useCollectionStore();
    const { showMessage } = useToast();
    const { isPending, mutate: removeMutation } = useMutation({
        mutationFn: removeCollection,
        onSuccess: () => {
            onClose();
            onCallBack();
        },
        onError: (error) => showMessage(`Error`, error.message),
    });

    const onOKClicked = () => removeMutation(collectionId);

    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <div className="bg-white rounded-[10px] px-[12px] pt-[24px] pb-[12px] text-center max-w-[280px]">
                <div className="flex flex-col gap-[16px] items-center">
                    <RemoveCollectionSvg />

                    <div>
                        <div className="font-semibold text-[16px]">{'Delete Collection?'}</div>
                        <div className="text-[12px]">
                            Are you sure you want to delete the collection and it's associated <p className="font-bold">shareable links ?</p> It cannot be
                            undone.
                        </div>
                    </div>

                    <div className="flex flex-col w-full">
                        {isPending ? (
                            <CircularProgress className="mx-auto mb-2" size={24} />
                        ) : (
                            <>
                                <HelloMuiButton variant="contained" onClick={onOKClicked} className="!rounded-full">
                                    Yes, Delete
                                </HelloMuiButton>

                                <HelloMuiButton onClick={onClose}>Cancel</HelloMuiButton>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </ModalLayout>
    );
};

export default DeleteCollectionModal;
