import { ChangeEmailModal, DeleteAccountConfirmModal } from 'components/Modals';
import { useSendOtpMutation, useUpdateUserInfoMutation, useUploadUserProfileImage } from 'hooks';
import { Fragment, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import useUserStore from 'store/useUserStore';
import { AvatarUpload, DeleteAccountSection, EmailSection, FullNameSection, PhoneSection, SecuritySection } from './';

export interface AccountFormData {
    fullName: string;
    phone: string;
    profileImage: File | null;
}

const AccountTab = () => {
    const [openChangeEmail, setOpenChangeEmail] = useState(false);
    const [openDeleteAccount, setOpenDeleteAccount] = useState(false);

    const { userInfo: user } = useUserStore();

    const { register, control, handleSubmit, watch, reset, getValues } = useForm<AccountFormData>({
        defaultValues: {
            fullName: user?.fullName || '',
            phone: user?.phone || '',
            profileImage: null,
        },
    });

    const mutations = {
        updateUserInfo: useUpdateUserInfoMutation({}),

        updatePhone: useUpdateUserInfoMutation({
            successMessage: 'Successfully Changed  Phone Number.',
        }),

        uploadUserProfileImage: useUploadUserProfileImage({
            onSuccess: () => reset({ profileImage: null, fullName: user?.fullName }),
        }),

        sendOtp: useSendOtpMutation({
            successMessage: "To change email, we need to verify it's you. Check Your Email And Enter OTP ",
            onSuccess: () => setOpenChangeEmail(true),
        }),
    };

    const openChangeEmailModal = () => mutations.sendOtp.mutate(user?.email || '');

    const handleSaveFullNameAndPhotoClicked: SubmitHandler<AccountFormData> = async ({ fullName, profileImage }) => {
        const formData = profileImage
            ? (() => {
                  const data = new FormData();
                  data.append('file', profileImage);
                  return data;
              })()
            : undefined;

        await Promise.all([
            fullName !== user?.fullName ? mutations.updateUserInfo.mutate({ fullName }) : Promise.resolve(),
            formData ? mutations.uploadUserProfileImage.mutate(formData) : Promise.resolve(),
        ]);
    };

    const handleUpdatePhone = () => {
        const phone = getValues('phone');
        mutations.updatePhone.mutate({ phone });
    };

    const isSubmitting = mutations.updateUserInfo.isPending || mutations.uploadUserProfileImage.isPending;
    const isUpdatingPhone = mutations.updatePhone.isPending;

    const watchFullName = watch('fullName');
    const watchImage = watch('profileImage');
    const watchPhone = watch('phone');

    return (
        <Fragment>
            <div className="leading-[24px]">
                <div className="pb-[24px]">
                    <div className="font-semibold text-[18px] mb-[14px]" data-cy="account-profile-text">
                        Your profile
                    </div>
                    <AvatarUpload control={control} name="profileImage" imageUrl={user?.profileImageUrl || ''} />
                </div>
                <form onSubmit={handleSubmit(handleSaveFullNameAndPhotoClicked)}>
                    <FullNameSection register={register} isSubmitting={isSubmitting} watchFullName={watchFullName} watchImage={watchImage} />
                </form>
                <EmailSection openChangeEmailModal={openChangeEmailModal} isPending={mutations.sendOtp.isPending} />
                <PhoneSection register={register} handleUpdatePhone={handleUpdatePhone} isUpdatingPhone={isUpdatingPhone} watchPhone={watchPhone} />
                <SecuritySection />
                <DeleteAccountSection onDeleteAccount={() => setOpenDeleteAccount(true)} />
            </div>
            <ChangeEmailModal visible={openChangeEmail} onClose={() => setOpenChangeEmail(false)} />
            <DeleteAccountConfirmModal visible={openDeleteAccount} onClose={() => setOpenDeleteAccount(false)} />
        </Fragment>
    );
};

export default AccountTab;
