import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import { FormControl, MenuItem, Select } from '@mui/material';
import { useOnboardingStore } from 'store/useOnboardingStore';
import { RegisterAccount } from 'util/Types';
import { CustomOutlinedInput } from '../../auth';

const PersonalAccountSection = ({
    onChange,
}: {
    onChange: (ac: RegisterAccount) => void;
}) => {
    const { account, setAccount } = useOnboardingStore();

    const handleOnChange = (_key: keyof RegisterAccount, v: string) => {
        const newAcc = { ...account, [_key]: v };
        setAccount(newAcc);
        onChange(newAcc);
    };

    return (
        <div className="flex flex-col gap-[16px] py-[16px]">
            <div className="font-semibold text-[16px]">
                {'Your real estate website link (optional)'}
            </div>
            <div className="leading-[24px]">
                Sharing your real estate website link would help us suggest the
                best competitors that match your needs
            </div>
            <div>
                <CustomOutlinedInput
                    name="website"
                    startIcon={<LanguageOutlinedIcon />}
                    placeholder="https://www.example.com"
                    value={account.websiteUrl}
                    onChange={(e) =>
                        handleOnChange('websiteUrl', e.target.value)
                    }
                    className="max-w-[450px]"
                />
            </div>

            <div className="font-semibold text-[16px] leading-[21px]">
                Where did you hear about us?
            </div>
            <FormControl>
                <Select
                    value={account.referralSource}
                    onChange={(e) =>
                        handleOnChange('referralSource', e.target.value)
                    }
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    renderValue={(selected) => {
                        return selected;
                    }}
                    className="max-w-[250px] w-full h-[48px] rounded-[8px] border-[#DFE1E1] text-[14px] leading-6 px-1"
                    id="referral-source"
                >
                    <MenuItem value={'Google'} className="text-[14px]">
                        Google
                    </MenuItem>
                    <MenuItem value={'LinkedIn'} className="text-[14px]">
                        LinkedIn
                    </MenuItem>
                    <MenuItem value={'Events'} className="text-[14px]">
                        Events
                    </MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

export default PersonalAccountSection;
