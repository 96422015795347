import { useQuery } from '@tanstack/react-query';
import LoadingAnimation from 'components/Loading/LoadingAnimation';
import MapPropertyTable from 'components/PropertyTable/MapPropertyTable';
import useSharableLinkStore from 'store/sharableLinksStore';
import { ModalLayoutProps } from '..';
import ModalLayout from '../ModalLayout';

interface ViewPropertiesModalProps extends ModalLayoutProps {
    linkId: string;
}
const ViewPropertiesInLinkModal = (props: ViewPropertiesModalProps) => {
    const { visible, onClose, linkId } = props;
    const { loadFullLinkInfo } = useSharableLinkStore();

    const { isPending, data: linkobj } = useQuery({
        queryKey: ['loadSharableLinks', linkId],
        queryFn: () => loadFullLinkInfo(linkId),
        refetchOnWindowFocus: true,
    });

    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <div className="md:block hidden rounded-[10px] bg-white max-w-[950px] min-w-[600px]">
                <div className="p-[20px]">
                    {linkobj?.properties && <span className="font-semibold text-[16px]">{`${linkobj?.properties?.length} properties`}</span>}
                </div>
                <div className="relative h-[600px] overflow-auto">
                    {isPending ? <LoadingAnimation /> : <MapPropertyTable propertyList={linkobj?.properties || []} onSort={() => {}} />}
                </div>
            </div>

            <div className="md:hidden h-screen w-screen bg-white fixed left-0 top-0">
                <div className="absolute right-2 top-2 cursor-pointer text-[18px] leading-[20px]" onClick={onClose}>
                    {'✖'}
                </div>
                <div className="p-[20px]">
                    {linkobj?.properties && <span className="font-semibold text-[16px]">{`${linkobj?.properties?.length} properties`}</span>}
                </div>
                <div className="relative overflow-auto" style={{ height: 'calc(100vh - 65px)' }}>
                    {isPending ? <LoadingAnimation /> : <MapPropertyTable propertyList={linkobj?.properties || []} onSort={() => {}} />}
                </div>
            </div>
        </ModalLayout>
    );
};

export default ViewPropertiesInLinkModal;
