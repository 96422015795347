import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import { useIsMutating } from '@tanstack/react-query';
import { ReactComponent as RemoveSvg } from 'assets/svg/RemoveFilter.svg';
import { ReactComponent as CopySvg } from 'assets/svg/SharableLinkCopyAction.svg';
import { ReactComponent as RegenSvg } from 'assets/svg/SharableLinkPreviewAction.svg';
import { HelloMuiButton, RectMuiButton } from 'components';
import { useToast } from 'components/Providers/ToastProvider';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { ShareLinkDto } from 'types/SharableLink';
import { calculateDifferenceInDays, getDateString, isExpiredLink } from 'util/dateTimeUtils';
import styles from './shareableLinks.module.css';
import StatusItem from './StatusItem';

interface LargeDevicesContentProps {
    items: ShareLinkDto[];
    onViewClicked: (item: ShareLinkDto) => void;
    onRemoveClicked: (id: string) => void;
    onRefreshClicked: (id: string) => void;
}

const LargeDevicesContent = ({ items, onViewClicked, onRemoveClicked, onRefreshClicked }: LargeDevicesContentProps) => {
    const [id, setId] = useState<string>('');
    const { showMessage } = useToast();
    const onCopyClicked = (item: ShareLinkDto) => {
        navigator.clipboard.writeText(item.link);
        showMessage('Success', 'Copied to clipboard');
    };

    const onOpenClicked = (item: ShareLinkDto) => window.open(item.link);

    const isDeleting = useIsMutating({ mutationKey: ['removeSharableLinks'] });
    const isRefreshing = useIsMutating({ mutationKey: ['updateLink'] });

    return (
        <div className={`${styles['table']} rounded-t-[10px]`}>
            <div
                className={`grid grid-cols-8 gap-[4px] font-medium bg-my-gray bg-opacity-15 text-opacity-60 h-[48px] items-center px-[8px] border-b-0 border border-my-gray`}
            >
                <span className="col-span-2">LINK TITLE</span>
                <span>CREATED</span>
                <span>EXPIRES</span>
                <span>PROPERTIES</span>
                <span>PASSCODE</span>
                <span>ACTIONS</span>
                <span>STATUS</span>
            </div>

            <div style={{ height: 'calc(100vh - 320px)' }}>
                <div className="border-x border-b border-my-gray">
                    {items.map((item, index) => {
                        const isExp = isExpiredLink(item.expirationDate);

                        const diffDay = getDateString(item.expirationDate) + ' (' + calculateDifferenceInDays(item.expirationDate, item.createdAt) + ' days)';

                        return (
                            <div
                                className={`grid grid-cols-8 gap-[4px] font-medium h-[60px] items-center px-[8px] border-t border-my-gray ${styles['tr']}`}
                                key={index}
                            >
                                <div className="col-span-2 truncate">{item.title}</div>
                                <div className="truncate">{isExp ? '' : getDateString(item.createdAt)}</div>
                                <div className="truncate">{isExp ? '' : diffDay}</div>
                                <div>
                                    <span>{`${item.shareableLinkProperties.length} properties`}</span>
                                    <HelloMuiButton
                                        className="!rounded-full"
                                        onClick={() => onViewClicked(item)}
                                        sx={{
                                            height: '18px',
                                            paddingX: 0,
                                        }}
                                    >
                                        {`[View]`}
                                    </HelloMuiButton>
                                </div>
                                <div className="truncate">{isExp ? '' : item.password}</div>
                                <div className="flex items-center gap-[8px]">
                                    {isExp ? (
                                        <>
                                            {isRefreshing && id === item.id ? (
                                                <ClipLoader size={20} />
                                            ) : (
                                                <RectMuiButton
                                                    width={32}
                                                    onClick={() => {
                                                        setId(item.id);
                                                        onRefreshClicked(item.id);
                                                    }}
                                                >
                                                    <RefreshIcon className="!text-black" />
                                                </RectMuiButton>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <RectMuiButton width={32} onClick={() => onOpenClicked(item)}>
                                                <Tooltip title="Open Link" arrow>
                                                    <RegenSvg />
                                                </Tooltip>
                                            </RectMuiButton>

                                            <RectMuiButton width={32} onClick={() => onCopyClicked(item)}>
                                                <Tooltip title="Copy Link" arrow>
                                                    <CopySvg />
                                                </Tooltip>
                                            </RectMuiButton>
                                        </>
                                    )}

                                    {isDeleting && id === item.id ? (
                                        <ClipLoader size={20} />
                                    ) : (
                                        <RectMuiButton
                                            width={32}
                                            onClick={() => {
                                                setId(item.id);
                                                onRemoveClicked(item.id);
                                            }}
                                        >
                                            <Tooltip title="Remove Link" arrow>
                                                <RemoveSvg />
                                            </Tooltip>
                                        </RectMuiButton>
                                    )}
                                </div>
                                <div>
                                    <StatusItem status={isExp ? 'Expired' : 'Active'} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default LargeDevicesContent;
