import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { styled } from '@mui/system';
import MainLayout from '../layout/MainLayout';
import { useLocation } from 'react-router-dom';
import AccountTab from './account/Account';
import PaymentTab from './Payment';
import PreferenceTab from './Preference';

const SettingPage = () => {
    const location = useLocation();
    const tabId = new URLSearchParams(location.search).get('tab');
    const tabIndex: number = tabId ? ~~tabId : 0;

    return (
        <MainLayout>
            <div className="max-w-[1000px] mx-auto lg:p-0 p-[20px]">
                <div className="font-semibold text-[32px] pt-[40px]" data-cy="settings-title">
                    Settings
                </div>
                <Tabs defaultValue={tabIndex} className="">
                    <BaseTabsList className="border-b border-my-gray flex gap-[20px]">
                        <Tab value={0}>
                            <div className="font-semibold" data-cy="account-tab">
                                Account
                            </div>
                        </Tab>
                        <Tab value={1}>
                            <div className="font-semibold" data-cy="preference-tab">
                                Preference
                            </div>
                        </Tab>
                        <Tab value={2}>
                            <div className="font-semibold" data-cy="payment-tab">
                                Payment
                            </div>
                        </Tab>
                    </BaseTabsList>
                    <TabPanel value={0}>
                        <AccountTab />
                    </TabPanel>
                    <TabPanel value={1}>
                        <PreferenceTab />
                    </TabPanel>
                    <TabPanel value={2}>
                        <PaymentTab />
                    </TabPanel>
                </Tabs>
            </div>
        </MainLayout>
    );
};

const Tab = styled(BaseTab)`
    padding: 8px;
    color: rgba(18, 18, 18, 0.3);

    &.${tabClasses.selected} {
        color: #000;
        border-bottom: 2px solid;
    }
`;

const TabPanel = styled(BaseTabPanel)`
    margin-top: 20px;
    padding: 12px;
    overflow: hidden;
`;

export default SettingPage;
