import { CircularProgress } from '@mui/material';
import { useOnboardingStore } from 'store/useOnboardingStore';
import {
    FixedFeatures,
    MAX_LIMIT,
    PlanPeriods,
    SubscriptionPlans,
    TickPath,
} from 'util/Constants';
import { PlanTypes } from 'util/Types';
import IntroductionSection from './Introduction';
import { HelloMuiLoadingButton } from 'components';

const DISCOUNT_PERCENT = 0.2;

const PeriodSelector = () => {
    const { duration, setDuration } = useOnboardingStore();

    return (
        <div className="mx-auto w-fit ">
            <div className="rounded-[60px] h-[60px] border border-my-gray border-opacity-60 p-[8px] grid grid-cols-2 bg-white">
                {PlanPeriods.map((period, index) => (
                    <div
                        className={`cursor-pointer rounded-[60px] h-full ${duration === period.duration ? 'text-white bg-my-black' : ''}`}
                        key={index}
                        onClick={() => setDuration(period.duration)}
                    >
                        <div className="w-full h-full flex items-center justify-center px-[40px]">
                            <span>{period.description}</span>
                        </div>
                    </div>
                ))}
            </div>

            <div className="flex justify-end pt-[5px]">
                <img
                    alt={'Pricing Up '}
                    src="/image/OnBoarding/Onboarding-Pricing-ArrowUp.png"
                />
                <span className="text-my-blue font-semibold">{`Save ${DISCOUNT_PERCENT * 100}%`}</span>
            </div>
        </div>
    );
};

const PlanCard = ({
    selected,
    plan,
}: {
    selected: boolean;
    plan: PlanTypes;
}) => {
    const { duration } = useOnboardingStore();
    const features = [
        `Track up ${plan.maxTrackUpCount === MAX_LIMIT ? 'Unlimited' : plan.maxTrackUpCount} competitors`,
        `${plan.maxSwapCount === MAX_LIMIT ? 'Unlimited' : plan.maxSwapCount} competitor swap per month`,
        `${plan.maxPropertyData === MAX_LIMIT ? 'Unlimited' : `Max. ${plan.maxPropertyData}`} property data`,
        ...FixedFeatures,
    ];

    return (
        <div
            className={`cursor-pointer hover:shadow-hello rounded-[20px] bg-white bg-opacity-40 border ${selected ? 'shadow-hello-blue border-my-blue border-opacity-80' : 'border-my-gray border-opacity-60'} px-[20px] py-[24px] mx-[30px] md:mx-0`}
        >
            <img alt={`Plan Icon (${plan.title})`} src={plan.icon} />
            <div className="font-semibold text-[20px]">{plan.title}</div>
            <div className="text-[12px] truncate">{plan.description}</div>
            <div>
                <span className="text-[36px] font-semibold">{`$${Math.ceil(plan.pricePerMonth * duration * (duration === 1 ? 1 : 0.8))}`}</span>
                <span className="text-[12px]">{`${duration === 1 ? '/per month' : '/per year'}`}</span>
            </div>

            <div className="border border-b-my-gray border-opacity-40"></div>
            <div className="text-[12px] flex flex-col gap-[16px] py-[16px]">
                <div className="font-semibold text-[14px]">{'Features'}</div>
                {features.map((feature, index) => (
                    <div className="flex gap-[5px] items-center" key={index}>
                        <img alt={`Tick ${index}`} src={TickPath} />
                        <div className="truncate">{feature}</div>
                    </div>
                ))}
            </div>
            <HelloMuiLoadingButton
                onClick={() => {}}
                variant="outlined"
                className={`!rounded-[5px] h-[42px] w-full !border-my-black !border-opacity-10 ${selected ? '!bg-my-blue !text-white' : ''}`}
                // loading={loading}
                loadingIndicator={
                    <CircularProgress color="primary" size={24} />
                }
                sx={{
                    color: '#121212',
                }}
            >
                {selected ? 'Current Plan' : 'Choose Plan'}
            </HelloMuiLoadingButton>
        </div>
    );
};

const SelectPlanSection = () => {
    const { plan, setPlan } = useOnboardingStore();

    return (
        <div className="">
            <IntroductionSection />
            <PeriodSelector />

            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[20px] pt-[36px] lg:max-w-[1244px] mx-auto">
                {SubscriptionPlans.map((planobj: PlanTypes, index: number) => (
                    <div onClick={() => setPlan(planobj.title)} key={index}>
                        <PlanCard
                            plan={planobj}
                            selected={plan === planobj.title}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SelectPlanSection;
