import ModalLayout from 'components/Modals/ModalLayout';
import { useToast } from 'components/Providers/ToastProvider';
import SelectCompetitorPanel from 'components/SelectCompetitor';
import { useEffect } from 'react';
import useUserStore from 'store/useUserStore';

import { ModalLayoutProps } from '..';

const SelectCompetitorModal = ({ visible, onClose }: ModalLayoutProps) => {
    const { userInfo: user, isExceed } = useUserStore();
    const { showMessage } = useToast();
    const isExceedFlag = isExceed();

    useEffect(() => {
        if (isExceedFlag && visible && user?.savedCompetitors.length && user.savedCompetitors.length > 0)
            showMessage(`Alert`, `You're currently tracking ${user.savedCompetitors.length} competitors over your limit. Please adjust your selection.`);
    }, [visible]);

    return (
        <>
            <ModalLayout visible={visible} onClose={onClose}>
                <div className="h-screen w-screen bg-white fixed left-0 top-0 overflow-scroll pt-[30px]">
                    <div className="absolute right-2 top-2 cursor-pointer text-[18px] leading-[20px]" onClick={onClose}>
                        {'✖'}
                    </div>
                    <SelectCompetitorPanel />
                </div>
            </ModalLayout>
        </>
    );
};

export default SelectCompetitorModal;
