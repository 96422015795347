import { useQuery } from '@tanstack/react-query';
import useLandingStore from 'store/useLandingStore';
import NumberAnimation from './NumberAnimation';
import { useEffect, useRef, useState } from 'react';

const BlackInfo = () => {
    const { getStatistics } = useLandingStore();

    const { data } = useQuery({
        queryKey: ['getStatistics'],
        queryFn: getStatistics,
    });

    const elementRef = useRef<HTMLDivElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);

    const cCount = Math.floor((data?.totalAgents || 0) / 100) * 100;
    const pCount = Math.floor((data?.totalProperties || 0) / 1000);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Check if the element is intersecting (visible)
                setIsVisible(entry.isIntersecting);
            },
            {
                root: null, // Use the viewport as root
                threshold: 0.1, // Trigger when 10% of the element is visible
            },
        );

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current); // Cleanup on unmount
            }
        };
    }, []);

    return (
        <div className="bg-[#252525] text-white" ref={elementRef}>
            <div className="grid lg:flex md:grid-cols-2 grid-cols-1 justify-center items-center lg:gap-[120px] md:gap-[72px] gap-[30px] lg:px-[80px] md:px-[32px] px-[12px] py-[60px] lg:max-w-hello mx-auto font-medium text-[16px]">
                <div className="flex flex-col justify-center">
                    <div className="font-semibold md:text-[90px] text-[60px]">
                        <NumberAnimation
                            start={0}
                            end={cCount}
                            suffix="+"
                            startanimate={isVisible}
                        />
                    </div>
                    <div>{'REAL ESTATE COMPETITORS'}</div>
                </div>
                <div className="flex flex-col justify-center">
                    <div className="font-semibold md:text-[90px] text-[60px]">
                        <NumberAnimation
                            start={0}
                            end={pCount}
                            suffix="K+"
                            startanimate={isVisible}
                        />
                    </div>
                    <div>{'PROPERTY LISTINGS'}</div>
                </div>
                <div className="font-normal my-auto md:col-span-2 lg:col-span-1 leading-[36px] lg:max-w-[440px]">
                    {
                        'We add 2000+ listing per day and adding competitors from all over the world. It’s our goal to provide the best analysis data as possible allowing your business be the one step ahead and and uncover the most promising investment opportunities.'
                    }
                </div>
            </div>
        </div>
    );
};

export default BlackInfo;
