import * as yup from 'yup';

export const contactInfoSchema = yup
    .object({
        emailAddress: yup.string().email('Please Enter Valid Email!').required('Email is required'),
        phoneNumber: yup.string().required('Phone Number is required'),
        companyName: yup.string().optional(),
        officeAddress: yup.string().optional(),
    })
    .required();
