import { ModalLayoutProps } from '..';
import EditCollectionModal from './EditCollection';

const CreateCollectionModal = (props: ModalLayoutProps) => {
    const { visible, onClose } = props;
    return (
        <EditCollectionModal
            collection={null}
            visible={visible}
            onClose={onClose}
        />
    );
};

export default CreateCollectionModal;
