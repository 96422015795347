import { Button, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { ReactComponent as CollectionSvg } from 'assets/svg/CollectionOut.svg';
import { ReactComponent as DefaultPropertyImageSvg } from 'assets/svg/Default-Property-Image.svg';
import { ReactComponent as SeeDetailsMobileSvg } from 'assets/svg/Property-See-Details-Mobile.svg';
import { AiPercentage } from 'components/Labels';
import NewLabel from 'components/Labels/NewCompetitor';
import { OneLetterAvatar } from 'components/LetterAvatar';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useModals } from 'pages/MainProvider';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { convertDateIntoLocaleString, formatNumber, GetCountryCode, isNew } from 'util/Funcs';
import { PropertyDTO } from 'util/Types';
import styles from './collectionpropertytable.module.css';

import { HelloMuiButton } from 'components/Mui';
import useCollectionStore from 'store/useCollection';
import useUserStore from 'store/useUserStore';

interface PropertyTableProps {
    propertyList: PropertyDTO[];
    onCheckChanged: (selectedList: PropertyDTO[]) => void;
}

interface SortHeaderItemProps {
    title: string;
    className?: string;
    'data-cy'?: string;
}

const HeaderItem = ({ className = '', title, 'data-cy': dataCy = '' }: SortHeaderItemProps) => {
    return (
        <div className={`flex items-center gap-[2px] cursor-pointer ${className}`} data-cy={dataCy}>
            <span className={styles['headertitle']}>{title}</span>
        </div>
    );
};

const MIN_W = 400;
const MIN_H = 200;

const DateItem = ({ property }: { property: PropertyDTO }) => {
    const isNewProperty: boolean = isNew(property.createdAt);
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        if (!property.imageUrls) {
            setImage('');
            return;
        }

        const filterImages = async () => {
            for (const imgurl of property.imageUrls) {
                const img = new window.Image();
                img.src = imgurl;

                const isValid = await new Promise<boolean>((resolve) => {
                    img.onload = () => {
                        resolve(img.width >= MIN_W && img.height >= MIN_H);
                    };
                    img.onerror = () => resolve(false);
                });

                if (isValid) {
                    setImage(imgurl);
                    break;
                }
            }
        };

        filterImages();
    }, [property.imageUrls]);

    return (
        <div className="relative flex gap-[10px] overflow-hidden">
            {isNewProperty ? (
                <NewLabel />
            ) : (
                <div
                    className="min-w-[42px] w-[42px] h-[42px] border border-my-gray border-opacity-60 rounded-[5px] overflow-hidden flex items-center justify-center"
                    data-cy="property-image"
                >
                    {image ? <img src={image} width={42} height={42} /> : <DefaultPropertyImageSvg />}
                </div>
            )}

            <div className="flex flex-col justify-between">
                <div className="font-medium truncate">
                    <span data-cy="property-title">{property.title}</span>
                    <div className="z-[2] absolute right-0 top-[50%] h-[600%] w-[80%] bg-white blur-[50px] translate-x-[50%] translate-y-[-50%]"></div>
                </div>
                <div className="text-[12px]" data-cy="property-date">
                    {convertDateIntoLocaleString(property.createdAt)}
                </div>
            </div>
        </div>
    );
};

const CollectionPropertyTable = forwardRef(({ propertyList, onCheckChanged }: PropertyTableProps, ref) => {
    const {
        showSelectCompetitorModal,
        showAddPropertyToCollectionModal,
        showPropertyDetailModal,
        showAddtionalPropertyDetail,
        isAdditionalDetailOpened,
        selectedProperty,
    } = useModals();
    const { collectionList } = useCollectionStore();
    const { getUserPlan, isExceed } = useUserStore();
    const allPropertyIDList: string[] = [];
    collectionList.map((item) => {
        allPropertyIDList.push(...item?.properties?.map((p) => p.propertyId));
    });

    const [checkedProperties, setCheckedProperties] = useState<PropertyDTO[]>([]);

    const onClickShowDetail = (property: PropertyDTO) => {
        showPropertyDetailModal(property, true);
    };

    const openAdditionalPanelClicked = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, p: PropertyDTO) => {
        showAddtionalPropertyDetail(evt.currentTarget, p);
    };

    const onCollectionClicked = (propertyId: string) => {
        showAddPropertyToCollectionModal(propertyId);
    };

    const isExceedFlag = isExceed();
    const currentPlan = getUserPlan();

    useImperativeHandle(ref, () => ({
        getSelectedProperties(): PropertyDTO[] {
            return checkedProperties;
        },
    }));

    useEffect(() => {
        setCheckedProperties([]);
    }, [propertyList]);

    const onCheckAllChanged = (flag: boolean) => {
        let newCheckedList: PropertyDTO[] = [];
        if (flag) newCheckedList = [...propertyList];
        else newCheckedList = [];
        setCheckedProperties(newCheckedList);
        onCheckChanged(newCheckedList);
    };

    const onCheckPropertyChanged = (property: PropertyDTO, flag: boolean) => {
        let newCheckedList = [];

        if (flag) newCheckedList = [...checkedProperties, property];
        else newCheckedList = checkedProperties.filter((p) => p.propertyId !== property.propertyId);

        setCheckedProperties(newCheckedList);
        onCheckChanged(newCheckedList);
    };

    return (
        <>
            <div className={`w-full font-medium ${styles['propertytable']}`}>
                {/* header */}
                <div
                    className={`${styles['tr']} ${styles['thead']} grid grid-cols-9 items-center text-my-black text-opacity-60 border-y border-my-gray border-opacity-40 py-[15px] px-[10px] mb-[12px] bg-my-gray bg-opacity-20`}
                >
                    <div className="flex items-center gap-[4px] col-span-2">
                        <FormControlLabel
                            label=""
                            control={
                                <Checkbox checked={checkedProperties.length === propertyList.length} onChange={(e) => onCheckAllChanged(e.target.checked)} />
                            }
                        />
                        <HeaderItem title="Listing Date" data-cy="listing-date-header" />
                    </div>
                    <HeaderItem title="Competitor" data-cy="competitor-header" />
                    <HeaderItem title="Country" data-cy="country-header" />
                    <HeaderItem title="City" data-cy="city-header" />
                    <HeaderItem title="Property Type" data-cy="property-type-header" />
                    <HeaderItem title="Price" data-cy="price-header" />
                    <HeaderItem title="Constructed area" data-cy="construction-area-header" />
                    <HeaderItem title="Plot area" className="xl:flex hidden" data-cy="plot-area-header" />
                </div>

                {isExceedFlag ? (
                    <div className="bg-white py-[30px]">
                        <div className="mx-auto w-fit max-w-[550px] bg-white px-[44px] pt-[60px] pb-[40px] text-center leading-[24px] flex flex-col gap-[20px] rounded-[10px]">
                            <div>
                                <img alt={'Plan Limited'} src="/image/Plan-Limited.png" className="mx-auto" />
                                <div className="font-semibold text-[16px] pt-[20px]">Competitor need to be adjusted</div>
                                <div>
                                    Your current plan allows you to track up to
                                    <span className="font-bold">{currentPlan.maxTrackUpCount}</span>
                                    competitors. Please adjust your selection to continue accessing your competitor data.
                                </div>
                            </div>

                            <HelloMuiButton variant="outlined" className="!h-[42px]" onClick={showSelectCompetitorModal}>
                                Manage Competitors
                            </HelloMuiButton>
                        </div>
                    </div>
                ) : (
                    <div className={`flex flex-col gap-[4px] text-opacity-80 ${styles['body']}`} data-cy="property-list-container">
                        {propertyList.map((property: PropertyDTO, index: number) => {
                            const aiScore = Math.floor((property?.aiScore || -1) * 100);
                            const isInAnyCollection = allPropertyIDList.includes(property.propertyId);
                            return (
                                <div
                                    key={index}
                                    className={`${styles['tr']} grid grid-cols-9 items-center border-opacity-40 py-[8px] px-[10px]`}
                                    data-cy={`property-row-${index}`}
                                >
                                    <div className="col-span-2 pr-[12x] flex items-center gap-[4px]">
                                        <FormControlLabel
                                            label=""
                                            control={
                                                <Checkbox
                                                    checked={checkedProperties.filter((p) => p.propertyId === property.propertyId).length > 0}
                                                    onChange={(e) => onCheckPropertyChanged(property, e.target.checked)}
                                                    inputProps={{
                                                        'aria-label': '',
                                                    }}
                                                />
                                            }
                                        />
                                        <DateItem property={property} />
                                    </div>
                                    <div>
                                        <div className="flex items-center gap-[5px]">
                                            <OneLetterAvatar
                                                title={property.details.listingType || 'Sales'}
                                                colorClass={property.details.listingType === 'Rental' ? 'text-my-green' : 'text-my-blue'}
                                            />
                                            <span data-cy="property-company">{property.companyName || ''}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center gap-[5px]">
                                            {property.address.Country && (
                                                <ReactCountryFlag
                                                    svg
                                                    countryCode={GetCountryCode(property.address.Country || '') || ''}
                                                    style={{
                                                        width: '1.5em',
                                                        height: '1.5em',
                                                    }}
                                                />
                                            )}
                                            <span data-cy="property-country">{property.address.Country}</span>
                                        </div>
                                    </div>
                                    <div data-cy="property-city">{`${property.address.City || ''}`}</div>
                                    <div data-cy="property-type">{`${property.details.propertyType || ''}`}</div>
                                    <div data-cy="property-price">
                                        {property.details.price && property.details.price > 0
                                            ? `${formatNumber(property.details.price)} ${getSymbolFromCurrency(property.details.currency) || ''}`
                                            : 'On Request'}
                                    </div>
                                    <div data-cy="property-constructed-area">{`${formatNumber(property.details.sizeLand || 0)} sqm`}</div>
                                    <div className="flex xl:justify-between justify-end items-center">
                                        <span className="xl:inline-block hidden" data-cy="property-plot-area">
                                            {`${formatNumber(property.details.sizePlot || 0)} sqm`}
                                        </span>

                                        <div className="flex items-center text-my-blue gap-[10px] justify-end">
                                            {aiScore >= 0 && <AiPercentage percentage={aiScore} />}

                                            <IconButton onClick={() => onCollectionClicked(property.propertyId)}>
                                                <CollectionSvg className={isInAnyCollection ? 'text-red-500' : 'text-my-gray'} />
                                            </IconButton>

                                            <div className="lg:flex hidden gap-[12px] items-center">
                                                <Button variant="outlined" onClick={() => onClickShowDetail(property)} data-cy="property-detail-button">
                                                    Details
                                                </Button>
                                            </div>

                                            <div
                                                className={`lg:hidden w-[50px] h-[48px] flex items-center justify-center border ${isAdditionalDetailOpened && property.webPageId === selectedProperty?.webPageId ? 'bg-my-black text-white' : 'border-my-blue text-my-blue'} cursor-pointer`}
                                                onClick={(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => openAdditionalPanelClicked(ev, property)}
                                            >
                                                <SeeDetailsMobileSvg
                                                    className={
                                                        isAdditionalDetailOpened && property.webPageId === selectedProperty?.webPageId ? 'rotate-180' : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
});

export default CollectionPropertyTable;
