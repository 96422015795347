import styles from './LoadingAnimation.module.css';
import { ReactComponent as Loading1 } from 'assets/svg/loading-1.svg';
import { ReactComponent as Loading2 } from 'assets/svg/loading-2.svg';
import { useEffect, useState } from 'react';

const LoadingAnimation = () => {
    const [flag, setFlag] = useState<boolean>(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setFlag(!flag);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="absolute w-full h-full bg-white bg-opacity-80 z-[1] top-0 left-0 min-h-[100px]">
            <div className="min-h-[150px] flex items-center justify-center">
                <div className="relative h-16 w-16">
                    <Loading1 className={`absolute h-16 w-16 ${styles['icon']} ${styles['icon1']} ${flag ? styles['animate'] : ''}`} />
                    <Loading2 className={`absolute h-16 w-16 ${styles['icon']} ${styles['icon1']} ${!flag ? styles['animate'] : ''}`} />
                </div>
            </div>
        </div>
    );
};

export default LoadingAnimation;
