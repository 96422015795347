import { useQuery } from '@tanstack/react-query';
import { LoadingAnimation } from 'components';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useOnboardingStore } from 'store/useOnboardingStore';
import useRegionStore from 'store/useRegionStore';
import useUserStore from 'store/useUserStore';
import { ROUTES } from 'util/Constants';
import { getTokenFromLocalStorage } from 'util/localStorageHelper';

export const AppGuard = ({ children }: Readonly<{ children?: React.ReactNode }>) => {
    const userToken = getTokenFromLocalStorage();

    const { fetchUser, isOnboardingUser, userInfo } = useUserStore();
    const { currentStep } = useOnboardingStore();
    const { fetchRegions } = useRegionStore();

    const onboardingDidNotFinish = isOnboardingUser();

    const browserPath = window.location.pathname;

    const { isLoading } = useQuery({
        queryKey: ['fetchUser'],
        queryFn: fetchUser,
        enabled: !!userToken && userInfo === null,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
    });

    useQuery({
        queryKey: ['fetchRegions'],
        queryFn: fetchRegions,
        enabled: () => {
            return !!userToken;
        },
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
    });

    if (!userToken) return <Navigate to={ROUTES.SIGN_IN} />;

    if (isLoading)
        return (
            <div className="w-full h-screen flex items-center justify-center bg-white">
                <div className="relative h-16 w-16">
                    <LoadingAnimation />
                </div>
            </div>
        );

    if (onboardingDidNotFinish && browserPath !== ROUTES.ONBOARDING && currentStep < 3) return <Navigate to={ROUTES.ONBOARDING} />;

    return <>{children}</>;
};
