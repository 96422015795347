import AccountBalance from '@mui/icons-material/AccountBalance';
import { CircularProgress } from '@mui/material';
import { HelloMuiLoadingButton } from 'components';
import useUserStore from 'store/useUserStore';
import { FixedFeatures, MAX_LIMIT, StripeCustomerPortal, SubscriptionPlans, TickPath } from 'util/Constants';
import { PlanTypes } from 'util/Types';

const PaymentTab = () => {
    return (
        <div className="flex flex-col gap-[20px]">
            <div className="text-[18px] leading-[24px] font-semibold">{'Subscription & Billing'}</div>
            <div className="text-[16px] leading-[24px]">{'Active Subscription'}</div>
            <PlanCard />
        </div>
    );
};

const PlanCard = () => {
    const { userInfo: user } = useUserStore();

    const subscriptionDetail = user?.subscriptionDetails;
    const plan: PlanTypes = SubscriptionPlans.filter(
        (p) => (subscriptionDetail?.interval === 'month' ? p.priceId.month : p.priceId.year) === subscriptionDetail?.priceId,
    )[0];

    if (!plan) return null;

    const features = [
        `Track up ${plan.maxTrackUpCount === MAX_LIMIT ? 'Unlimited' : plan.maxTrackUpCount} competitors`,
        `${plan.maxSwapCount === MAX_LIMIT ? 'Unlimited' : plan.maxSwapCount} competitor swap per month`,
        `${plan.maxPropertyData === MAX_LIMIT ? 'Unlimited' : `Max. ${plan.maxPropertyData}`} property data`,
        ...FixedFeatures,
    ];

    const nextBillingDate = new Date(subscriptionDetail?.nextBillingDate || '');
    const onManageClicked = () => window.open(StripeCustomerPortal, '_blank');

    return (
        <div className="max-w-[350px] text-[12px]">
            <div
                className={`cursor-pointer hover:shadow-hello rounded-[20px] border border-my-gray border-opacity-40 px-[20px] py-[24px] flex flex-col gap-[20px]`}
            >
                <div className="flex justify-between items-center">
                    <div className="font-semibold text-[20px]">{plan.title}</div>

                    <div className="flex gap-[5px]">
                        <span className="capitalize px-[6px] py-[2px] bg-my-gray bg-opacity-60 rounded-[5px]">{`${subscriptionDetail?.interval}ly`}</span>
                        <span className="capitalize px-[6px] py-[2px] bg-[#00A67E] rounded-[5px] text-white">{subscriptionDetail?.status}</span>
                    </div>
                </div>

                <div className="truncate">{plan.description}</div>
                <div className="border border-b-my-gray border-opacity-40"></div>
                <div className="flex flex-col gap-[16px]">
                    <div className="font-semibold text-[14px]">{'Features'}</div>
                    {features.map((feature, index) => (
                        <div className="flex gap-[5px] items-center" key={index}>
                            <img alt={'Payment'} src={TickPath} />
                            <div className="truncate">{feature}</div>
                        </div>
                    ))}
                </div>

                <div className="font-medium">{`Next Billing date is ${nextBillingDate.toDateString()}`}</div>

                <HelloMuiLoadingButton
                    onClick={onManageClicked}
                    variant="outlined"
                    className={`!rounded-[5px] h-[36px] w-full`}
                    loadingIndicator={<CircularProgress color="primary" size={24} />}
                    sx={{
                        color: '#FFF',
                        backgroundColor: '#4EBBB8',
                        fontSize: '12px',
                        border: 'none',
                        '&:hover': {
                            color: 'black',
                            borderColor: '#4EBBB8',
                        },
                    }}
                >
                    <div className="flex gap-[5px]">
                        <AccountBalance fontSize="small" />
                        <span className="uppercase ">{'Manage subscription'}</span>
                    </div>
                </HelloMuiLoadingButton>
            </div>
        </div>
    );
};

export default PaymentTab;
