import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Divider } from '@mui/material';
import {
    InfiniteData,
    QueryClient,
    useInfiniteQuery,
    useMutation,
} from '@tanstack/react-query';
import { HelloMuiButton } from 'components';
import { useDeleteNotification } from 'hooks/useDeleteNotification';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { ClipLoader } from 'react-spinners';
import { queryClient } from 'routes';
import useNotificationStore from 'store/useNotificationStore';
import styled from 'styled-components';
import {
    NotificationCount,
    NotificationMessage,
    NotificationType,
} from 'types/Notification';
import { getDiffTimeSting } from 'util/Funcs';

const ContentContainer = styled.div`
    height: 320px;
    overflow: auto;

    @media (max-width: 743px) {
        height: calc(100vh - 290px);
    }
`;

const LimitedTextBox = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits the number of lines */
    -webkit-box-orient: vertical;
    overflow: hidden; /* Hides the overflow text */
    text-overflow: ellipsis; /* Adds ellipsis (...) at the end */
`;

const TabHeaderItem = ({
    title,
    active,
    count,
    onClick,
}: {
    title: string;
    active: boolean;
    count: number;
    onClick: () => void;
}) => {
    return (
        <div
            className={`md:text-[12px] cursor-pointer h-[48px] flex gap-[8px] items-center justify-center md:px-[22px] px-[12px] ${active ? 'text-black bg-my-gray bg-opacity-10 border-b-[2px] border-black' : 'bg-white'}`}
            onClick={onClick}
        >
            <span className={`font-semibold ${active ? '' : 'opacity-40'}`}>
                {title}
            </span>
            <span
                className={`rounded-[5px] px-[8px] font-medium ${active ? 'text-white bg-my-green' : 'text-black bg-my-gray bg-opacity-40'}`}
            >
                {count}
            </span>
        </div>
    );
};

const EmptyNotificationPanel = () => {
    return (
        <div className="flex flex-col items-center gap-[24px] max-w-[440px]">
            <img alt="NoNotification" src="/image/No-Notification.png" />
            <div className="text-center">
                <div className="font-semibold">No notification yet</div>
                <div>
                    You'll see notifications here as soon as they become
                    available
                </div>
            </div>
        </div>
    );
};

const NotificationItem = ({
    item,
    onClick,
}: {
    item: NotificationMessage;
    onClick: () => void;
}) => {
    const isRead = item.readAt !== null;
    const { mutate: deleteNotification } = useDeleteNotification(item.type!);
    return (
        <div
            className={`py-[2px] bg-my-gray bg-opacity-40 ${isRead ? '' : 'cursor-pointer'}`}
            onClick={onClick}
        >
            <div
                className={`${!isRead ? 'border-l-[3px] border-my-blue' : ''} flex justify-between h-[72px] gap-[8px] px-[16px]`}
            >
                <div className="flex items-center gap-[8px]">
                    <div className="rounded-full border border-my-blue bg-my-green bg-opacity-20 w-[48px] h-[48px] min-w-[48px] min-h-[48px]"></div>

                    <LimitedTextBox className="text-black leading-[24px]">
                        {item.message}
                    </LimitedTextBox>
                </div>

                <div className="flex items-center gap-[8px]">
                    <div className="truncate text-my-black text-opacity-60 min-w-[60px] max-w-[70px] text-[12px]">
                        {getDiffTimeSting(item.createdAt!)}
                    </div>
                    <DeleteOutlineIcon
                        className="cursor-pointer"
                        onClick={() => deleteNotification(item.id!)}
                    />
                </div>
            </div>
        </div>
    );
};

export const NotificationContent = () => {
    const [activeTab, setActiveTab] =
        useState<NotificationType>('listingUpdate');

    const { ref, inView } = useInView();

    const {
        markAsReadAllNotification,
        readOneNotification,
        loadNotifications,
    } = useNotificationStore();

    const { fetchNextPage, hasNextPage, data, status, isFetchingNextPage } =
        useInfiniteQuery({
            queryKey: ['loadNotifications', activeTab],
            queryFn: async ({ pageParam }) =>
                loadNotifications({
                    pageParam,
                    type: activeTab as NotificationType,
                }),
            initialPageParam: 1,
            getNextPageParam: (lastPage, _, lastPageParam) => {
                if (lastPage.length === 0) return undefined;
                return lastPageParam + 1;
            },
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
        });

    const optimisticallyUpdateNotifications = (
        queryClient: QueryClient,
        updateFn: (notification: NotificationMessage) => NotificationMessage,
    ) => {
        queryClient.setQueryData(
            ['loadNotifications', activeTab],
            (oldData: InfiniteData<any, any>) => {
                const newData = oldData.pages.map(
                    (page: NotificationMessage[]) => page.map(updateFn),
                );
                return { ...oldData, pages: newData };
            },
        );
    };

    const readUnreadNotificationCountUpdate = (
        oldCount: NotificationCount,
        newUnReadCount: number,
    ) => {
        return {
            ...oldCount,
            unReadCount: newUnReadCount,
        };
    };

    const { mutate: markAsReadAllMutate } = useMutation({
        mutationFn: markAsReadAllNotification,
        onMutate: () => {
            optimisticallyUpdateNotifications(queryClient, (n) => ({
                ...n,
                readAt: new Date().toISOString(),
            }));
            queryClient.setQueryData(
                ['readUnreadNotificationCount'],
                (oldCount: NotificationCount) =>
                    readUnreadNotificationCountUpdate(oldCount, 0),
            );
        },
    });

    const { mutate: markAsReadMutate } = useMutation({
        mutationFn: readOneNotification,
        onMutate: (id) => {
            optimisticallyUpdateNotifications(queryClient, (notification) =>
                notification.id === id
                    ? { ...notification, readAt: new Date().toISOString() }
                    : notification,
            );
            queryClient.setQueryData(
                ['readUnreadNotificationCount'],
                (oldCount: NotificationCount) =>
                    readUnreadNotificationCountUpdate(
                        oldCount,
                        Math.max(0, oldCount.unReadCount - 1),
                    ),
            );
        },
    });

    const onRead = async (item: NotificationMessage) => {
        if (item.readAt) return;
        markAsReadMutate(item.id!);
    };

    const allNotifications = data?.pages.flat()!;

    const isAllRead = allNotifications?.every((n) => n.readAt !== null);
    const notificationsCount = queryClient.getQueryData([
        'readUnreadNotificationCount',
    ]) as NotificationCount;

    useEffect(() => {
        if (inView && hasNextPage) fetchNextPage();
    }, [inView, hasNextPage, fetchNextPage]);

    return (
        <div className={`md:w-[500px] bg-my-gray bg-opacity-10 flex flex-col`}>
            {/* header */}
            <div className="h-[60px] flex items-center justify-between px-[15px] cursor-pointer">
                <span className="text-[18px] font-semibold">Notifications</span>
                <HelloMuiButton
                    sx={{ gap: '8px' }}
                    onClick={() => markAsReadAllMutate()}
                    disabled={
                        notificationsCount?.unReadCount === 0 || isAllRead
                    }
                >
                    <DoneAllIcon />
                    <span>Mark all as read</span>
                </HelloMuiButton>
            </div>
            {/* tab title */}
            <div className="bg-white">
                <Divider />
                <div className="px-[16px] flex items-center">
                    <TabHeaderItem
                        title="Listing Update"
                        active={activeTab === 'listingUpdate'}
                        count={notificationsCount?.listingUpdateCount}
                        onClick={() => setActiveTab('listingUpdate')}
                    />

                    <TabHeaderItem
                        title="Report"
                        active={activeTab === 'report'}
                        count={notificationsCount?.reportCount}
                        onClick={() => setActiveTab('report')}
                    />
                </div>
                <Divider />
            </div>

            {/* content */}

            {status === 'pending' ? (
                <div className="flex items-center justify-center my-3">
                    <ClipLoader />
                </div>
            ) : (
                <div>
                    {allNotifications?.length > 0 && (
                        <div className="font-medium text-[16px] h-[45px] flex items-center px-[16px]">
                            Today
                        </div>
                    )}

                    <ContentContainer className="flex-grow">
                        {allNotifications?.length === 0 ? (
                            <div className="w-full h-full flex items-center justify-center">
                                <EmptyNotificationPanel />
                            </div>
                        ) : (
                            allNotifications?.map((notification, index) => {
                                return (
                                    <div key={index} ref={ref}>
                                        <Divider />
                                        <NotificationItem
                                            item={notification}
                                            onClick={() => onRead(notification)}
                                        />
                                    </div>
                                );
                            })
                        )}
                        <Divider />
                        {isFetchingNextPage && (
                            <div className="flex items-center justify-center my-3">
                                <ClipLoader />
                            </div>
                        )}
                    </ContentContainer>
                </div>
            )}
        </div>
    );
};

export default NotificationContent;
