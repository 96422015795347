import * as yup from 'yup';

export const otpSchema = yup
    .object({
        otp: yup
            .string()
            .length(6, 'OTP must be exactly 6 characters long!')
            .required('OTP is required!'),
    })
    .required();
