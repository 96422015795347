import LimitedModal from 'components/Modals/Limited';
import { useLoadShareableLinks } from 'hooks/useShareableLinks';
import MainLayout from 'pages/layout/MainLayout';
import { useEffect, useState } from 'react';
import useUserStore from 'store/useUserStore';
import { useUtilsStore } from 'store/useUtilsStore';
import CompetitorsTab from './competitors';
import OverViewTab from './overview';
import SaveListingPage from './savelisting';

const MainPage = () => {
    const { topTabIndex } = useUtilsStore();
    const [showLimitedModalFlag, setShowLimitedModalFlag] = useState<boolean>(false);
    const { isExceed } = useUserStore();

    useLoadShareableLinks();

    useEffect(() => {
        const isExceedFlag = isExceed();
        setShowLimitedModalFlag(isExceedFlag && topTabIndex === 1);
    }, [topTabIndex]);

    return (
        <MainLayout>
            <div className="lg:max-w-hello mx-auto">
                <div className={topTabIndex === 0 ? '' : 'hidden'}>
                    <OverViewTab />
                </div>

                <div className={topTabIndex === 1 ? '' : 'hidden'}>
                    <CompetitorsTab />
                </div>
            </div>

            <div className={topTabIndex === 2 ? '' : 'hidden'}>
                <SaveListingPage />
            </div>

            <LimitedModal visible={showLimitedModalFlag} onClose={() => setShowLimitedModalFlag(false)} />
        </MainLayout>
    );
};

export default MainPage;
