import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import { useIsMutating } from '@tanstack/react-query';
import { ReactComponent as RemoveSvg } from 'assets/svg/RemoveFilter.svg';
import { ReactComponent as CopySvg } from 'assets/svg/SharableLinkCopyAction.svg';
import { ReactComponent as RegenSvg } from 'assets/svg/SharableLinkPreviewAction.svg';
import { HelloMuiButton, RectMuiButton } from 'components';
import { useToast } from 'components/Providers/ToastProvider';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import { ShareLinkDto } from 'types/SharableLink';
import { calculateDifferenceInDays, getDateString, isExpiredLink } from 'util/dateTimeUtils';
import styles from './shareableLinks.module.css';
import StatusItem from './StatusItem';

const GrayGridItem = styled('div')({
    padding: '8px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    backgroundColor: 'rgba(218, 218, 218, 0.1)',
    borderColor: '#DADADA',
});

const WhiteGridItem = styled('div')({
    padding: '8px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    backgroundColor: 'white',
    borderColor: '#DADADA',
});

interface MobileLinkItemProps {
    item: ShareLinkDto;
    onViewClicked: (item: ShareLinkDto) => void;
    onRemoveClicked: (id: string) => void;
    onRefreshClicked: (id: string) => void;
}

const MobileLinkItem = ({ item, onViewClicked, onRemoveClicked, onRefreshClicked }: MobileLinkItemProps) => {
    const [id, setId] = useState<string>('');
    const { showMessage } = useToast();
    const onCopyClicked = () => {
        navigator.clipboard.writeText(item.link);
        showMessage('Success', 'Copied to clipboard');
    };
    const onOpenClicked = () => window.open(item.link);

    const isExpired: boolean = isExpiredLink(item.expirationDate);
    const diffDay = getDateString(item.expirationDate) + ' (' + calculateDifferenceInDays(item.expirationDate, item.createdAt) + ' days)';

    const isDeleting = useIsMutating({ mutationKey: ['removeSharableLinks'] });
    const isRefreshing = useIsMutating({ mutationKey: ['updateLink'] });

    return (
        <div className="border border-my-gray">
            <div className="md:block hidden">
                <div className="grid grid-cols-6">
                    <GrayGridItem className="col-span-1 border-r border-b">{'LINK TITLE'}</GrayGridItem>
                    <WhiteGridItem className="col-span-5 border-b">{item.title}</WhiteGridItem>
                </div>

                <div className="grid grid-cols-6">
                    <GrayGridItem className="col-span-1 border-r border-b">{'CREATED'}</GrayGridItem>
                    <WhiteGridItem className="col-span-2 border-r border-b">{getDateString(item.createdAt)}</WhiteGridItem>
                    <GrayGridItem className="col-span-1 border-r border-b">{'EXPIRES'}</GrayGridItem>
                    <WhiteGridItem className="col-span-2 border-b">{diffDay}</WhiteGridItem>
                </div>

                <div className="grid grid-cols-6">
                    <GrayGridItem className="col-span-1 border-r border-b">{'PROPERTIES'}</GrayGridItem>
                    <WhiteGridItem className="col-span-2 border-r border-b">
                        <span>{`${item.shareableLinkProperties.length} properties`}</span>
                        <HelloMuiButton
                            className="!rounded-full"
                            onClick={() => onViewClicked(item)}
                            sx={{
                                height: '18px',
                                paddingX: 0,
                            }}
                        >
                            {`[View]`}
                        </HelloMuiButton>
                    </WhiteGridItem>
                    <GrayGridItem className="col-span-1 border-r border-b">{'PASSCODE'}</GrayGridItem>
                    <WhiteGridItem className="col-span-2 border-b">{item.password}</WhiteGridItem>
                </div>

                <div className="flex justify-between items-center px-[12px] py-[4px]">
                    <StatusItem status={isExpired ? 'Expired' : 'Active'} />

                    <div className="flex items-center gap-[8px]">
                        {isExpired ? (
                            <>
                                {isRefreshing && id === item.id ? (
                                    <ClipLoader size={20} />
                                ) : (
                                    <RectMuiButton
                                        width={32}
                                        onClick={() => {
                                            setId(item.id);
                                            onRefreshClicked(item.id);
                                        }}
                                    >
                                        <RefreshIcon className="!text-black" />
                                    </RectMuiButton>
                                )}
                            </>
                        ) : (
                            <>
                                <RectMuiButton width={32} onClick={onOpenClicked}>
                                    <Tooltip title="Open Link" arrow>
                                        <RegenSvg />
                                    </Tooltip>
                                </RectMuiButton>

                                <RectMuiButton width={32} onClick={onCopyClicked}>
                                    <Tooltip title="Copy Link" arrow>
                                        <CopySvg />
                                    </Tooltip>
                                </RectMuiButton>
                            </>
                        )}

                        {isDeleting && id === item.id ? (
                            <ClipLoader size={20} />
                        ) : (
                            <RectMuiButton
                                width={32}
                                onClick={() => {
                                    setId(item.id);
                                    onRemoveClicked(item.id);
                                }}
                            >
                                <Tooltip title="Remove Link" arrow>
                                    <RemoveSvg />
                                </Tooltip>
                            </RectMuiButton>
                        )}
                    </div>
                </div>
            </div>

            <div className="md:hidden">
                <div className="grid grid-cols-3">
                    <GrayGridItem className="col-span-1 border-r border-b">{'LINK TITLE'}</GrayGridItem>
                    <WhiteGridItem className="col-span-2 border-b">{item.title}</WhiteGridItem>
                </div>
                <div className="grid grid-cols-3">
                    <GrayGridItem className="col-span-1 border-r border-b">{'CREATED'}</GrayGridItem>
                    <WhiteGridItem className="col-span-2 border-b">{getDateString(item.createdAt)}</WhiteGridItem>
                </div>
                <div className="grid grid-cols-3">
                    <GrayGridItem className="col-span-1 border-r border-b">{'EXPIRES'}</GrayGridItem>
                    <WhiteGridItem className="col-span-2 border-b">{diffDay}</WhiteGridItem>
                </div>
                <div className="grid grid-cols-3">
                    <GrayGridItem className="col-span-1 border-r border-b">{'PROPERTIES'}</GrayGridItem>
                    <WhiteGridItem className="col-span-2 border-b">
                        <span>{`${item.shareableLinkProperties.length} properties`}</span>
                        <HelloMuiButton
                            className="!rounded-full"
                            onClick={() => onViewClicked(item)}
                            sx={{
                                height: '18px',
                                paddingX: 0,
                            }}
                        >
                            {`[View]`}
                        </HelloMuiButton>
                    </WhiteGridItem>
                </div>
                <div className="grid grid-cols-3">
                    <GrayGridItem className="col-span-1 border-r border-b">{'PASSCODE'}</GrayGridItem>
                    <WhiteGridItem className="col-span-2 border-b">{item.password}</WhiteGridItem>
                </div>
                <div className="flex justify-between items-center px-[12px] py-[4px]">
                    <StatusItem status={isExpiredLink(item.expirationDate) ? 'Expired' : 'Active'} />

                    <div className="flex items-center gap-[8px]">
                        {isExpired ? (
                            <>
                                {isRefreshing && id === item.id ? (
                                    <ClipLoader size={20} />
                                ) : (
                                    <RectMuiButton
                                        width={32}
                                        onClick={() => {
                                            setId(item.id);
                                            onRefreshClicked(item.id);
                                        }}
                                    >
                                        <RefreshIcon className="!text-black" />
                                    </RectMuiButton>
                                )}
                            </>
                        ) : (
                            <>
                                <RectMuiButton width={32} onClick={onOpenClicked}>
                                    <Tooltip title="Open Link" arrow>
                                        <RegenSvg />
                                    </Tooltip>
                                </RectMuiButton>

                                <RectMuiButton width={32} onClick={onCopyClicked}>
                                    <Tooltip title="Copy Link" arrow>
                                        <CopySvg />
                                    </Tooltip>
                                </RectMuiButton>
                            </>
                        )}

                        {isDeleting && id === item.id ? (
                            <ClipLoader size={20} />
                        ) : (
                            <RectMuiButton
                                width={32}
                                onClick={() => {
                                    setId(item.id);
                                    onRemoveClicked(item.id);
                                }}
                            >
                                <Tooltip title="Remove Link" arrow>
                                    <RemoveSvg />
                                </Tooltip>
                            </RectMuiButton>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface MediumDevicesContentProps {
    items: ShareLinkDto[];
    onViewClicked: (item: ShareLinkDto) => void;
    onRemoveClicked: (id: string) => void;
    onRefreshClicked: (id: string) => void;
}

const MediumDevicesContent = ({ items, onViewClicked, onRemoveClicked, onRefreshClicked }: MediumDevicesContentProps) => {
    return (
        <div className={`overflow-auto p-[8px] pl-0 ${styles['mobilecontent']}`}>
            <div className="flex flex-col gap-[12px]">
                {items.map((item, index) => (
                    <MobileLinkItem
                        item={item}
                        key={index}
                        onViewClicked={onViewClicked}
                        onRemoveClicked={onRemoveClicked}
                        onRefreshClicked={onRefreshClicked}
                    />
                ))}
            </div>
        </div>
    );
};

export default MediumDevicesContent;
