export const getDateString = (date: string) => {
    return date.split('T')[0].replaceAll('-', '.');
};

export const calculateDifferenceInDays = (expiry: string, createdAt: string) => {
    const dateExpiry = new Date(expiry);
    const dateCreatedAt = new Date(createdAt);

    return Math.round((dateExpiry.getTime() - dateCreatedAt.getTime()) / (1000 * 60 * 60 * 24));
};

export const isExpiredLink = (expDate: string) => {
    const dateExpiry = new Date(expDate);
    const now = new Date();
    return dateExpiry.getTime() - now.getTime() < 0;
};
