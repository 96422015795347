import { ReactComponent as CountryLockSvg } from 'assets/svg/Country-Lock.svg';

interface Props {
    item: string;
    onShowModal: () => void;
}

const LockedItem = ({ item, onShowModal }: Props) => {
    return (
        <div className="px-[10px] py-[6px] cursor-pointer" onClick={onShowModal}>
            <div className="flex gap-[10px] items-center justify-between opacity-40">
                <span>{item}</span>
                <CountryLockSvg />
            </div>
        </div>
    );
};

export default LockedItem;
