import { Badge, IconButton, Menu } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactComponent as NotificationSvg } from 'assets/svg/Notification.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useNotificationStore from 'store/useNotificationStore';
import { ROUTES } from 'util/Constants';
import NotificationContent from './NotificationContent';

const NotificationArea = () => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const expanded = Boolean(anchorEl);

    const { getReadUnreadNotificationCount } = useNotificationStore();

    const handleShowNotificationClick = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        setAnchorEl(expanded ? null : event.currentTarget);
    };

    const navigateToNotificationPage = () => {
        navigate(ROUTES.NOTIFICATIONS);
    };

    const onCloseNotification = () => {
        setAnchorEl(null);
    };

    const { data } = useQuery({
        queryKey: ['readUnreadNotificationCount'],
        queryFn: getReadUnreadNotificationCount,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
    });

    return (
        <>
            <IconButton
                onClick={handleShowNotificationClick}
                className="md:!inline-flex !hidden"
            >
                <Badge badgeContent={data?.unReadCount} color="error">
                    <NotificationSvg />
                </Badge>
            </IconButton>

            <IconButton
                onClick={navigateToNotificationPage}
                className="md:!hidden"
            >
                <Badge badgeContent={data?.unReadCount} color="error">
                    <NotificationSvg />
                </Badge>
            </IconButton>

            <Menu
                anchorEl={anchorEl}
                open={expanded}
                onClose={onCloseNotification}
                sx={{
                    marginTop: '30px',
                    '& .MuiList-root': {
                        padding: '0px',
                    },
                }}
                className="hi"
            >
                <NotificationContent />
            </Menu>
        </>
    );
};

export default NotificationArea;
