import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LockedItem from 'components/LockedItem';
import { UpgradePlanModal } from 'components/Modals';
import UnLockedItem from 'components/UnLockedItem';
import { useCallback, useRef, useState } from 'react';
import useRegionStore from 'store/useRegionStore';
import useUserStore from 'store/useUserStore';
import { DEFAULT_REGION, Region } from 'types/Region';
import { containsWithoutSpecialChars } from 'util/containsWithoutSpecialChars';
import DefaultDropDown from './DefaultDropDown';

interface StateFilterProps {
    lock?: boolean;
    placeholder?: string;
    region: Region;
    selectedState?: string;
    onChanged?: (state: string) => void;
}

const StateFilter = ({ lock = false, selectedState = '', onChanged = () => {}, region = DEFAULT_REGION, placeholder = 'State' }: StateFilterProps) => {
    const [showUpgradePlanModal, setShowUpgradePlanModal] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const childRef = useRef<{ closePopup: () => void }>(null);
    const { getStates, getNonStates, getFilteredStates } = useRegionStore();
    const { isPremiumUser } = useUserStore();

    const itemSelected = useCallback(
        (item: string) => {
            onChanged(item);
            if (childRef.current) {
                childRef.current.closePopup();
            }
        },
        [onChanged],
    );

    const onSearchTermChanged = (text: string) => setSearchTerm(text);

    const onClearClicked = () => onChanged('');

    const onShowModal = () => {
        if (childRef.current) {
            childRef.current.closePopup();
        }
        if (!isPremiumUser()) setShowUpgradePlanModal(true);
    };

    const filteredStates = getFilteredStates(searchTerm);

    const states = getStates(lock, region);

    const nonStates = getNonStates(region);

    return (
        <>
            <DefaultDropDown
                ref={childRef}
                Title={<div className="truncate">{selectedState || placeholder}</div>}
                IsEmpty={!selectedState}
                onClearClicked={onClearClicked}
            >
                {/* searching */}
                <div className="px-[10px] py-[5px]">
                    <div className="rounded-[5px] border border-my-gray border-opacity-60 flex items-center justify-between gap-[5px] px-[10px] py-[5px]">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => onSearchTermChanged(e.target.value)}
                            className="flex-grow focus:outline-none w-full"
                        />
                        <SearchOutlinedIcon />
                    </div>
                </div>
                <div className="max-h-[300px] overflow-y-scroll">
                    {region.name === '' &&
                        filteredStates.map((state, index) =>
                            lock && state.lock ? (
                                <LockedItem key={index} item={state.title} onShowModal={onShowModal} />
                            ) : (
                                <UnLockedItem key={index} item={state.title} itemSelected={itemSelected} />
                            ),
                        )}
                    {states
                        .filter((state) => containsWithoutSpecialChars(state.State, searchTerm))
                        .map((state, index) => (
                            <UnLockedItem key={index} item={state.State} itemSelected={itemSelected} />
                        ))}

                    {lock &&
                        nonStates
                            .filter((state) => containsWithoutSpecialChars(state.State, searchTerm))
                            .map((state, index) => <LockedItem key={index} item={state.State} onShowModal={onShowModal} />)}
                </div>
            </DefaultDropDown>

            <UpgradePlanModal visible={showUpgradePlanModal} onClose={() => setShowUpgradePlanModal(false)} />
        </>
    );
};

export default StateFilter;
