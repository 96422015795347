export const containsWithoutSpecialChars = (sourceText: string, searchTerm: string): boolean => {
    if (!sourceText) return false;
    if (!searchTerm) return true;

    const normalizedSearchTerm = searchTerm
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

    return sourceText
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(normalizedSearchTerm);
};
