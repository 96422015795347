import HeaderSection from './Header';
import IntroductionSection from './Introduction';
import OverviewSection from './OverView';
import BlackInfo from './BlackInfo';
import FeaturesSection from './Features';
import AIPowerSearchSection from './AIPowerSearch';
import HelloDataWorkFlowSection from './HelloDataWorkFlow';
import PricingSection from './Pricing';
import PartnersSection from './Partners';
import FAQSection from './FAQ';
import ContactSection from './Contact';
import FooterSection from './Footer';

const LandingPage = () => {
    return (
        <div className="w-screen h-screen overflow-auto">
            <div className="flex flex-col lg:gap-[90px] md:gap-[60px] gap-[30px]">
                <HeaderSection />
                <IntroductionSection />
                <OverviewSection />
                <BlackInfo />
                <FeaturesSection />
                <AIPowerSearchSection />
                <HelloDataWorkFlowSection />
                <PricingSection />
                <PartnersSection />
                <FAQSection />
                <ContactSection />
                <FooterSection />
            </div>
        </div>
    );
};
export default LandingPage;
