import { CircularProgress } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import useUserStore from 'store/useUserStore';
import { logoutUserAndClearLocalStorage } from 'util/localStorageHelper';
import ModalLayout from './ModalLayout';
import { ModalLayoutProps } from '.';
import { HelloMuiLoadingButton } from 'components/Mui';

const DeleteAccountConfirmModal = ({ visible, onClose }: ModalLayoutProps) => {
    const { showMessage } = useToast();
    const { deleteUserAccount } = useUserStore();
    const { isPending, mutate } = useMutation({
        mutationFn: deleteUserAccount,
        mutationKey: ['deleteUserAccount'],
        onSuccess: (status) => {
            if (status === 200) {
                logoutUserAndClearLocalStorage();
                localStorage.removeItem('onboardingStep');
            }
        },
        onError: (error) => {
            showMessage('Error', error.message);
        },
    });
    const onComfirmClicked = () => mutate();

    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <div className="md:w-[620px] bg-white px-[36px] py-[42px] text-center leading-[24px] flex flex-col gap-[20px] rounded-[20px]">
                <div className="text-[20px] font-semibold">
                    {'You’re about to delete your account '}
                </div>
                <div className="">
                    You are about to delete your account. This will delete all
                    of your data from Hello:data, including competitor analysis
                    and all that you have added.
                </div>
                <div className="pt-[10px]">
                    <HelloMuiLoadingButton
                        onClick={onComfirmClicked}
                        variant="outlined"
                        className={`!rounded-[8px] h-[50px] !border-my-gray !w-full`}
                        loading={isPending}
                        loadingIndicator={
                            <CircularProgress color="primary" size={24} />
                        }
                        sx={{
                            color: 'white',
                            backgroundColor: '#AD1619',
                            '&:hover': {
                                color: 'black',
                            },
                        }}
                    >
                        <div>Confirm</div>
                    </HelloMuiLoadingButton>

                    <div
                        className="h-[50px] leading-[50px] cursor-pointer"
                        onClick={onClose}
                    >
                        Cancel
                    </div>
                </div>
            </div>
        </ModalLayout>
    );
};

export default DeleteAccountConfirmModal;
