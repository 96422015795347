import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import App from 'App';
import { BackgroundLayer } from 'components';
import ToastProvider from 'components/Providers/ToastProvider';
import { TooltipProvider } from 'components/Providers/ToolTipProvider';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import 'react-loading-skeleton/dist/skeleton.css';
import reportWebVitals from 'reportWebVitals';
import { LoadInitialData } from 'util/AppApi';
import DefaultMuiTheme from 'util/MuiTheme';
import './index.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

LoadInitialData();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <MUIThemeProvider theme={DefaultMuiTheme}>
        <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={2000}>
            <TooltipProvider>
                <ToastProvider>
                    <CssBaseline />
                    <BackgroundLayer />
                    <App />
                </ToastProvider>
            </TooltipProvider>
        </SnackbarProvider>
    </MUIThemeProvider>,
);

reportWebVitals();
