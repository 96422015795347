import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button } from '@mui/material';
import { ReactComponent as DefaultPropertyImageSvg } from 'assets/svg/Default-Property-Image.svg';
import { AiPercentage } from 'components/Labels';
import NewLabel from 'components/Labels/NewCompetitor';
import { HelloMuiButton } from 'components/Mui';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useModals } from 'pages/MainProvider';
import { useEffect, useState } from 'react';
import useUserStore from 'store/useUserStore';
import { DEFAULT_SORT } from 'util/Constants';
import { convertDateIntoLocaleString, formatNumber, isNew } from 'util/Funcs';
import { ORDER_TYPE, PropertyDTO, SortDTO } from 'util/Types';
import styles from './mappropertytable.module.css';

interface PropertyTableProps {
    propertyList: PropertyDTO[];
    onSort: (_s: SortDTO) => void;
}

interface SortHeaderItemProps {
    title: string;
    currentSort: SortDTO;
    className?: string;
    sortField?: string;
    onSort?: (s: SortDTO) => void;
}

const SORT_TYPES: ORDER_TYPE[] = ['default', 'ASC', 'DESC'];
const SORT_ICONS = [<ArrowRightIcon />, <ArrowDropDownIcon />, <ArrowDropUpIcon />];

const HeaderItem = ({ className = '', title, currentSort, sortField = '', onSort = () => {} }: SortHeaderItemProps) => {
    const [sortStatus, setSortStatus] = useState<number>(0);

    useEffect(() => {
        if (currentSort.field !== sortField) setSortStatus(0);
        else {
            setSortStatus(SORT_TYPES.indexOf(currentSort.order));
        }
    }, [currentSort]);

    const onSortClicked = () => {
        const newSortIndex = (sortStatus + 1) % 3;
        setSortStatus(newSortIndex);
        if (sortField) {
            onSort({
                field: sortField,
                order: SORT_TYPES[newSortIndex],
            });
        }
    };

    return (
        <div className={`flex items-center gap-[2px] cursor-pointer ${className}`} onClick={onSortClicked}>
            <span>{title}</span>
            {sortField && <div>{SORT_ICONS[sortStatus]}</div>}
        </div>
    );
};

const MIN_W = 400;
const MIN_H = 200;

const DateItem = ({ property }: { property: PropertyDTO }) => {
    const isNewProperty: boolean = isNew(property.createdAt);
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        if (!property.imageUrls) {
            setImage('');
            return;
        }

        const filterImages = async () => {
            const validImages = [];

            for (const imgurl of property.imageUrls) {
                const img = new window.Image();
                img.src = imgurl;

                const isValid = await new Promise<boolean>((resolve) => {
                    img.onload = () => {
                        resolve(img.width >= MIN_W && img.height >= MIN_H);
                    };
                    img.onerror = () => resolve(false);
                });

                if (isValid) {
                    validImages.push(imgurl);
                }

                if (validImages.length > 0) break;
            }

            setImage(validImages.length > 0 ? validImages[0] : '');
        };

        filterImages();
    }, [property.imageUrls]);

    return (
        <div className="relative flex gap-[10px] overflow-hidden">
            {isNewProperty ? (
                <NewLabel />
            ) : (
                <div className="min-w-[42px] w-[42px] h-[42px] border border-my-gray border-opacity-60 rounded-[5px] overflow-hidden flex items-center justify-center">
                    {image ? <img src={image} width={42} height={42} /> : <DefaultPropertyImageSvg />}
                </div>
            )}

            <div className="flex flex-col justify-between">
                <div className="font-medium truncate">
                    <span>{property.title}</span>
                    <div className="z-[2] absolute right-0 top-[50%] h-[600%] w-[80%] bg-white blur-[50px] translate-x-[50%] translate-y-[-50%]"></div>
                </div>
                <div className="text-[12px]">{convertDateIntoLocaleString(property.createdAt)}</div>
            </div>
        </div>
    );
};

const MapPropertyTable = ({ propertyList, onSort }: PropertyTableProps) => {
    const { showSelectCompetitorModal, showPropertyDetailModal } = useModals();
    const { getUserPlan, isExceed } = useUserStore();

    const [currentSort, setCurrentSort] = useState<SortDTO>(DEFAULT_SORT);

    const onClickShowDetail = (_p: PropertyDTO) => {
        showPropertyDetailModal(_p, true);
    };

    const onSortClicked = (_s: SortDTO) => {
        setCurrentSort(_s);
        onSort(_s);
    };

    const isExceedFlag = isExceed();
    const currentPlan = getUserPlan();

    return (
        <>
            <div className={`w-full font-medium ${styles['propertytable']}`}>
                {/* header */}
                <div
                    className={`${styles['tr']} ${styles['thead']} grid grid-cols-5 items-center text-my-black text-opacity-60 border-y border-my-gray border-opacity-40 py-[15px] px-[10px] mb-[12px] bg-my-gray bg-opacity-20`}
                >
                    <HeaderItem title="Listing Date" sortField="PropertyCreatedAt" currentSort={currentSort} onSort={onSortClicked} className="col-span-2" />
                    <HeaderItem title="Price" sortField="Price" currentSort={currentSort} onSort={onSortClicked} />
                    <HeaderItem title="Constructed area" sortField="SizeLand" currentSort={currentSort} onSort={onSortClicked} />
                    <HeaderItem title="Plot area" sortField="SizePlot" currentSort={currentSort} onSort={onSortClicked} className="md:flex hidden" />
                </div>

                {isExceedFlag ? (
                    <div className="bg-white py-[30px]">
                        <div className="mx-auto w-fit max-w-[550px] bg-white px-[44px] pt-[60px] pb-[40px] text-center leading-[24px] flex flex-col gap-[20px] rounded-[10px]">
                            <div>
                                <img alt={'Plan Limited'} src="/image/Plan-Limited.png" className="mx-auto" />
                                <div className="font-semibold text-[16px] pt-[20px]">{`Competitor need to be adjusted`}</div>
                                <div>
                                    {`Your current plan allows you to track up to `}
                                    <span className="font-bold">{currentPlan.maxTrackUpCount}</span>
                                    {` competitors. Please adjust your selection to continue accessing your competitor data.`}
                                </div>
                            </div>

                            <HelloMuiButton variant="outlined" className="!h-[42px]" onClick={showSelectCompetitorModal}>
                                {`Manage Competitors`}
                            </HelloMuiButton>
                        </div>
                    </div>
                ) : (
                    <div className={`flex flex-col gap-[4px] text-opacity-80 ${styles['body']}`}>
                        {propertyList.map((property: PropertyDTO, index: number) => {
                            const aiScore = Math.floor((property?.aiScore || -1) * 100);

                            return (
                                <div key={index} className={`${styles['tr']} grid grid-cols-5 items-center border-opacity-40 py-[8px] px-[10px]`}>
                                    <div className="col-span-2 pr-[12x]">
                                        <DateItem property={property} />
                                    </div>
                                    <div>
                                        {property.details.price && property.details.price > 0
                                            ? `${formatNumber(property.details.price)} ${getSymbolFromCurrency(property.details.currency) || ''}`
                                            : 'On Request'}
                                    </div>
                                    <div>{`${formatNumber(property.details.sizeLand || 0)} sqm`}</div>
                                    <div className="flex justify-between items-center">
                                        <span className="md:inline-block hidden">{`${formatNumber(property.details.sizePlot || 0)} sqm`}</span>
                                        <div></div>
                                        <div className="flex items-center text-my-blue gap-[10px] justify-end">
                                            {aiScore >= 0 && <AiPercentage percentage={aiScore} />}
                                            <Button variant="outlined" onClick={() => onClickShowDetail(property)}>
                                                {'Details'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
};

export default MapPropertyTable;
