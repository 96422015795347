import { Avatar } from '@mui/material';
import clsx from 'clsx';
import { memo } from 'react';
import { GetTwoLetterName } from 'util/Funcs';

const shallowEqual = (obj1: Record<string, any>, obj2: Record<string, any>) => {
    return (
        Object.keys(obj1).length === Object.keys(obj2).length &&
        Object.keys(obj1).every((key) => obj1[key] === obj2[key])
    );
};

const areEqual = (
    prevProps: { title: string },
    nextProps: { title: string },
) => {
    return shallowEqual(prevProps, nextProps);
};

interface OneLetterAvatarProps {
    title: string;
    colorClass?: string;
}

export const OneLetterAvatar = memo((props: OneLetterAvatarProps) => {
    const { title = '?', colorClass = '' } = props;

    const oneLetterName = title[0];

    return (
        <div
            className={clsx(
                'rounded-full min-w-[28px] w-[28px] h-[28px] border border-my-gray border-opacity-60 p-[3px] flex items-center justify-center bg-opacity-20',
                colorClass,
            )}
        >
            {oneLetterName}
        </div>
    );
}, areEqual);

export const TwoLetterAvatar = memo(
    ({ title, profileUrl }: { title: string; profileUrl: string }) => {
        const twoLetterName = GetTwoLetterName(title);

        return (
            <div className="rounded-full w-[48px] h-[48px] border border-my-gray border-opacity-60 p-[3px] flex items-center justify-center">
                <Avatar
                    alt="Avatar"
                    src={profileUrl || ''}
                    sx={{
                        width: '40px',
                        height: '40px',
                        backgroundColor: profileUrl ? 'transparent' : '#FFC034',
                    }}
                >
                    <span className="font-poppins font-medium text-[16px] -tracking-[1px] uppercase text-black">
                        {twoLetterName}
                    </span>
                </Avatar>
            </div>
        );
    },
    areEqual,
);
