import { yupResolver } from '@hookform/resolvers/yup';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as PlusSvg } from 'assets/svg/Plus.svg';
import ErrorMessage from 'components/ErrorMessage';
import { useToast } from 'components/Providers/ToastProvider';
import { useTooltip } from 'components/Providers/ToolTipProvider';
import { AddCompetitorTooltip } from 'components/ToolTips';
import LocationInput from 'pages/auth/sign-up/LocationInput';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryClient } from 'routes';
import useCompetitorsStore from 'store/useCompetitorsStore';
import { LocalAgent } from 'types/Agent';
import { AzureLocation } from 'types/Azure';
import { GetFlag } from 'util/AppApi';
import * as yup from 'yup';
import ModalLayout from '../ModalLayout';

interface AddedCompetitorProps {
    competitor: LocalAgent;
    index: number;
    onRemoveClicked: () => void;
}
import { HelloMuiLoadingButton } from 'components/Mui';

const AddedCompetitor = ({ competitor, index, onRemoveClicked }: AddedCompetitorProps) => {
    return (
        <div className="px-[18px] py-[14px] flex gap-[10px] items-center justify-between">
            <div className="flex gap-[10px] items-center">
                <div className="border border-my-gray rounded-full w-[32px] h-[32px] flex items-center justify-center">{index + 1}</div>

                <div>
                    <div className="truncate">
                        <span className="text-[16px] font-medium">{competitor.c_name}</span>
                        <span>{` (${competitor.website_url})`}</span>
                    </div>
                    <div className="text-my-black text-opacity-80 text-[12px]">{`${competitor.country} ${competitor.city}`}</div>
                </div>
            </div>

            <div className="cursor-pointer" onClick={onRemoveClicked}>
                <DeleteOutlinedIcon />
            </div>
        </div>
    );
};

interface AddManualCompetitorModalProps {
    visible: boolean;
    onClose: () => void;
}
interface FormData {
    c_name: string;
    website_url: string;
    c_property_filter?: string;
    email?: string;
    phone?: string;
    city: string;
    country: string;
    address: string;
}

const AddManualCompetitorModal = ({ visible, onClose }: AddManualCompetitorModalProps) => {
    const [competitorList, setCompetitorList] = useState<LocalAgent[]>([]);
    const [location, setLocation] = useState<AzureLocation | null>(null);
    const { showTooltip } = useTooltip();
    const { showMessage } = useToast();
    const { saveAgentsToBackend } = useCompetitorsStore();

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
        watch,
    } = useForm<FormData>({
        resolver: yupResolver(
            yup.object().shape({
                c_name: yup.string().required('Name is Required'),
                website_url: yup.string().required('Website URL is Required').url('Invalid URL'),
                c_property_filter: yup.string().optional(),
                email: yup.string().email().optional(),
                phone: yup.string().optional(),
                city: yup.string().required(),
                country: yup.string().required(),
                address: yup.string().required('Address is Required'),
            }),
        ),
    });

    const onLocationClicked = (location: AzureLocation) => {
        setValue('country', location.country);
        setValue('city', location.city);
        setValue('address', location.title);
        setLocation(location);
    };

    const onAddClicked = handleSubmit((data) => {
        const newCompetitor = {
            c_name: data.c_name,
            website_url: data.website_url,
            country: data.country,
            city: data.city,
            address: data.address,
            c_property_filter: '',
            c_property_url: '',
        };
        setCompetitorList([...competitorList, newCompetitor]);
        setLocation(null);
        reset();
    });

    const onRemoveClicked = (index: number) => {
        const newList = competitorList.filter((_, i) => i !== index);
        setCompetitorList(newList);
    };

    const { mutate: saveAgentsMutate, isPending } = useMutation({
        mutationFn: saveAgentsToBackend,
        mutationKey: ['saveAgentsToBackend'],
        onSuccess: () => {
            showMessage('Success', `Added ${competitorList.length} competitors!`);
            queryClient.invalidateQueries({
                queryKey: ['fetchAllCompetitors'],
            });
        },
        onError: (error) => {
            showMessage('Error', error.message);
        },
        onSettled: () => {
            setCompetitorList([]);
        },
    });

    const onSaveClicked = () => saveAgentsMutate(competitorList);

    useEffect(() => {
        if (!visible || GetFlag('addCompetitorTooltip')) return;
        showTooltip(<AddCompetitorTooltip />);
    }, [visible]);

    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <form className="bg-white rounded-[10px] pb-[24px] pt-[50px] px-[20px] flex flex-col gap-[16px] text-[12px]">
                <div>
                    <div className="font-semibold text-[22px]" data-cy="modal-title-for-manual-competitor">
                        Add Competitors
                    </div>
                    <div className="text-[12px]" data-cy="modal-description-for-manual-competitor">
                        Simply provide us with the relevant information, and our AI-powered scraper will handle the rest.
                    </div>
                </div>

                <div className="grid md:grid-cols-2 grid-cols-1 gap-[16px]">
                    <div>
                        <div className="text-[12px] font-medium pb-[5px]">Competitor's Name</div>
                        <input
                            type="text"
                            className="w-full h-[42px] border border-my-gray rounded-[8px] px-[10px] bg-my-gray bg-opacity-20"
                            {...register('c_name')}
                            data-cy="modal-input-c_name"
                        />
                        {errors.c_name && <ErrorMessage msg={errors.c_name.message} data-cy="name-error-message" />}
                    </div>

                    <div>
                        <div className="text-[12px] font-medium pb-[5px]">Website URL</div>
                        <input
                            type="text"
                            className="w-full h-[42px] border border-my-gray rounded-[8px] px-[10px] bg-my-gray bg-opacity-20"
                            {...register('website_url')}
                            placeholder="e.g. https://www.hellohere.com"
                            data-cy="modal-input-website_url"
                        />
                        {errors.website_url && <ErrorMessage msg={errors.website_url.message} data-cy="website-url-error-message" />}
                    </div>
                </div>

                <div>
                    <div className="text-[12px] font-medium pb-[5px]">Address</div>
                    <div className={`h-[42px] text-[12px] ${errors?.address && !location ? 'mb-3' : ''}`}>
                        <LocationInput value={location?.title || ''} onLocationClicked={onLocationClicked} showIcon={false} data-cy="modal-input-address" />
                        {!location && errors.address && <ErrorMessage msg={errors!.address!.message} />}
                    </div>
                </div>

                <HelloMuiLoadingButton
                    onClick={onAddClicked}
                    variant="outlined"
                    className={`!rounded-[5px] h-[42px] w-full !px-[25px]`}
                    sx={{
                        color: '#FFF',
                        backgroundColor: '#000',
                        fontSize: '12px',
                        '&:hover': {
                            color: 'black',
                        },
                    }}
                >
                    <div className="flex gap-[5px] items-center" data-cy="add-button-for-competitor">
                        <PlusSvg />
                        <span>Add</span>
                    </div>
                </HelloMuiLoadingButton>

                {competitorList.length > 0 && (
                    <>
                        <div className="w-full h-1 border-b border-my-gray"></div>
                        <div className="font-semibold text-[16px]" data-cy="added-competitors-list-title">
                            Added Competitors
                        </div>
                        <div className=" rounded-[5px] border border-my-gray max-h-[300px] overflow-y-scroll" data-cy="added-competitors-list">
                            {competitorList.map((item, index) => {
                                return <AddedCompetitor competitor={item} index={index} key={index} onRemoveClicked={() => onRemoveClicked(index)} />;
                            })}
                        </div>

                        <div data-cy="save-competitor-btn">
                            <HelloMuiLoadingButton
                                onClick={onSaveClicked}
                                variant="outlined"
                                className={`!rounded-[5px] h-full w-full !px-[25px]`}
                                disabled={isPending}
                                sx={{
                                    color: '#FFF',
                                    backgroundColor: '#3176BC',
                                    fontSize: '12px',
                                    '&:hover': {
                                        color: 'black',
                                    },
                                }}
                            >
                                {'Save'}
                            </HelloMuiLoadingButton>
                        </div>
                    </>
                )}
            </form>
        </ModalLayout>
    );
};

export default AddManualCompetitorModal;
