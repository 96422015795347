import { useEffect, useState } from 'react';

const DOT_COUNT = 4;

const AnalyzingPanel = () => {
    const [activeIndex, setActiveIndex] = useState<number>(0);

    useEffect(() => {
        let id = 0;
        const timer = setInterval(() => {
            setActiveIndex(id++ % 4);
        }, 300);
        return () => clearInterval(timer);
    }, []);

    return (
        <div className="absolute top-0 left-0 bg-white bg-opacity-90 w-full h-full flex flex-col items-center justify-center gap-[12px]">
            <div className="flex items-center justify-center">
                <img src={`/image/HelloHereIcon.png`} />
            </div>
            <div className="flex items-center gap-[4px]">
                <span> {`Analyzing...`}</span>
                <div className="grid grid-cols-4 gap-[4px]">
                    {Array.from({ length: DOT_COUNT }, (_, index) => {
                        return (
                            <div
                                className={`w-[4px] h-[8px] bg-my-blue`}
                                style={{
                                    opacity:
                                        index >= activeIndex
                                            ? 1 - (index - activeIndex) * 0.2
                                            : 0.2 + index * 0.2,
                                }}
                                key={index}
                            ></div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default AnalyzingPanel;
