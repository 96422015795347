import { useQuery } from '@tanstack/react-query';
import useSharableLinkStore from 'store/sharableLinksStore';

export const useLoadShareableLinks = () => {
    const { loadShareableLinks } = useSharableLinkStore();
    return useQuery({
        queryKey: ['loadSharableLinks'],
        queryFn: loadShareableLinks,
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000 * 5,
    });
};
