import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTooltip } from 'components/Providers/ToolTipProvider';
import { SetFlag } from 'util/AppApi';
import styles from './ToolTip.module.css';

interface TooltipItemProps {
    icon: string;
    title: string;
    description: string;
}

const TooltipItem = ({ icon, title, description }: TooltipItemProps) => (
    <div className="flex gap-[5px]">
        <div className="min-w-[16px]">
            <img
                alt={title}
                src={`/image/Tooltip/${icon}.png`}
                className="min-w-max"
            />
        </div>
        <div>
            <span>{title}</span>
            <span>{description}</span>
        </div>
    </div>
);

const AddCompetitorTooltip = () => {
    const { hideTooltip } = useTooltip();

    const onCloseClick = () => {
        SetFlag('addCompetitorTooltip', true);
        hideTooltip();
    };

    const tooltipItems = [
        {
            icon: 'World',
            title: 'Website:',
            description: ' Enter the main website of the competitor.',
        },
        {
            icon: 'Time',
            title: 'Time:',
            description:
                " Our competitor analysis typically takes 2-3 business hours to complete. We'll notify you by email as soon as your report is ready.",
        },
    ];

    return (
        <div className="absolute lg:left-[50%] left-[100px] top-[50%] translate-y-[-50%]">
            <div
                className={`relative bg-black rounded-[10px] px-[12px] py-[18px] max-w-[180px] text-white lg:ml-[-500px] ${styles['tooltip']}`}
                data-cy="add-competitor-tooltip"
            >
                <div
                    onClick={onCloseClick}
                    className="absolute w-[28px] h-[28px] cursor-pointer right-[10px] top-[10px] rounded-full flex items-center justify-center bg-my-gray bg-opacity-30"
                    data-cy="add-competitor-tooltip-close"
                >
                    <CloseOutlinedIcon className="!text-[14px] text-white" />
                </div>
                <div className="text-[12px] flex flex-col gap-[10px]">
                    <div>
                        <img alt="lamp" src="/image/Tooltip/Lamp.png" />
                        <div
                            className="font-semibold"
                            data-cy="add-competitor-tooltip-heading"
                        >
                            To create a competitor, you are required to provide
                            the following:
                        </div>
                    </div>
                    {tooltipItems.map((item, index) => (
                        <TooltipItem key={index} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AddCompetitorTooltip;
