import { HelloMuiButton } from 'components/Mui';
import { useModals } from 'pages/MainProvider';

interface Props {
    headerText: string;

    children: React.ReactNode;
}
const CompetitorAdjusted = ({ headerText, children }: Props) => {
    const { showSelectCompetitorModal } = useModals();
    return (
        <div className="bg-white py-[30px]">
            <div className="mx-auto w-fit max-w-[550px] bg-white px-[44px] pt-[60px] pb-[40px] text-center leading-[24px] flex flex-col gap-[20px] rounded-[10px]">
                <div>
                    <img alt={'Plan Limited'} src="/image/Plan-Limited.png" className="mx-auto" />
                    <div className="font-semibold text-[16px] pt-[20px]">{headerText}</div>
                    {children}
                </div>

                <HelloMuiButton variant="outlined" className="!h-[42px]" onClick={showSelectCompetitorModal}>
                    Manage Competitors
                </HelloMuiButton>
            </div>
        </div>
    );
};

interface IsExceedCompetitorProps {
    boldText: number;
}
export const IsExceedCompetitor = ({ boldText }: IsExceedCompetitorProps) => {
    return (
        <CompetitorAdjusted headerText="Competitor need to be adjusted">
            Your current plan allows you to track up to
            <span className="font-bold"> {boldText} </span>
            competitors. Please adjust your selection to continue accessing your competitor data.
        </CompetitorAdjusted>
    );
};

export const NoCompetitor = () => {
    return (
        <CompetitorAdjusted headerText="No competitors selected.">
            <div>To view competitor data, please select at least one competitor.</div>
        </CompetitorAdjusted>
    );
};
