import { styled, Switch, SwitchProps } from '@mui/material';

interface HelloSwitchProps extends SwitchProps {
    width?: number;
    height?: number;
}

const HelloSwitch = (pprops: HelloSwitchProps) => {
    const { width = 42, height = 26 } = pprops;
    const thumbw = height - 4;
    const translateX = width - 4 - thumbw;

    const OriginalSwitch = styled((props: SwitchProps) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    ))(({ theme }) => ({
        width: width,
        height: height,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: `translateX(${translateX}px)`,
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#3176BC',
                    opacity: 1,
                    border: 0,
                    ...theme.applyStyles('dark', {
                        backgroundColor: '#2ECA45',
                    }),
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[100],
                ...theme.applyStyles('dark', {
                    color: theme.palette.grey[600],
                }),
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
                ...theme.applyStyles('dark', {
                    opacity: 0.3,
                }),
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: thumbw,
            height: thumbw,
        },
        '& .MuiSwitch-track': {
            borderRadius: height / 2,
            backgroundColor: '#E9E9EA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
            ...theme.applyStyles('dark', {
                backgroundColor: '#39393D',
            }),
        },
    }));

    return <OriginalSwitch {...pprops} />;
};
export default HelloSwitch;
