import { ReactComponent as FacebookSvg } from 'assets/svg/Social-Facebook.svg';
import { ReactComponent as InstagramSvg } from 'assets/svg/Social-Instagram.svg';
import { ReactComponent as LinkedInSvg } from 'assets/svg/Social-LinkedIn.svg';
import { ReactComponent as PSvg } from 'assets/svg/Social-P.svg';
import { ReactComponent as TwitterSvg } from 'assets/svg/Social-X.svg';
import { ReactComponent as TiktokSvg } from 'assets/svg/Social-J.svg';
import { ReactComponent as YoutubSvg } from 'assets/svg/Social-Youtube.svg';
import { ReactComponent as MainLogoSvg } from 'assets/svg/Main-Logo.svg';

import { ROUTES } from 'util/Constants';

const FooterSection = () => {
    return (
        <div className="lg:px-[100px] md:px-[32px] px-[12px]">
            <div className="lg:flex gap-[98px] pb-[48px] lg:max-w-hello mx-auto ">
                <div className="lg:max-w-[385px] pb-[40px] flex flex-col gap-[24px]">
                    <div className="flex gap-[5px] items-center text-[18px]">
                        <MainLogoSvg />
                    </div>
                    <div className="leading-[24px]">
                        {
                            "Hello:data empowers you to win in real estate by providing competitor analysis tools. Gain insights into competitor activity and understand your clients' needs to make data-driven decisions."
                        }
                    </div>
                    <div className="flex gap-[10px] items-center">
                        <a href="https://www.facebook.com/mallorca.is.magic" target="_blank">
                            <FacebookSvg />
                        </a>
                        <a href="https://www.instagram.com/mallorcamagic/" target="_blank">
                            <InstagramSvg />
                        </a>
                        <a href="https://www.linkedin.com/company/hello-here/posts/?feedView=all">
                            <LinkedInSvg />
                        </a>
                        <a href="https://www.pinterest.de/mallorca_magic/" target="_blank">
                            <PSvg />
                        </a>
                        <a href="https://x.com/mallorca_magic">
                            <TwitterSvg />
                        </a>
                        <a href="https://www.tiktok.com/@mallorca.magic" target="_blank">
                            <TiktokSvg />
                        </a>
                        <a href="https://www.youtube.com/channel/UCqYf68oGZFdpx8dwkdtZNiA">
                            <YoutubSvg />
                        </a>
                    </div>
                </div>

                <div className="grid lg:grid-cols-4 grid-cols-2 gap-[85px]">
                    <div>
                        <div className="text-16[px] font-semibold leading-24px pb-[36px]">{'Quick Links'}</div>
                        <div className="flex flex-col gap-[16px]">
                            <a href="#featured">{'Feature'}</a>
                            <a href="#pricing">{'Pricing'}</a>
                            <a href="#faq">{'FAQ'}</a>
                            <a href={ROUTES.SIGN_IN}>{'Sign in'}</a>
                        </div>
                    </div>

                    <div>
                        <div className="text-16[px] font-semibold leading-24px pb-[36px]">{'Company'}</div>
                        <div className="flex flex-col gap-[16px]">
                            <a href="https://hellohere.to/" target="_blank">
                                {'Hello:here'}
                            </a>
                            <a href="https://roadmap.hellohere.es/" target="_blank">
                                {'Road map'}
                            </a>
                            <a href="mailto:info@hellohere.com">{'Contact us'}</a>
                        </div>
                    </div>

                    <div>
                        <div className="text-16[px] font-semibold leading-24px pb-[36px]">{'Resources'}</div>
                        <div className="flex flex-col gap-[16px]">
                            <a href="https://legal.hellohere.es/Terms-of-use" target="_blank">
                                {'Terms of use'}
                            </a>
                            <a href="https://legal.hellohere.es/Privacypolicy" target="_blank">
                                {'Privacy policy'}
                            </a>
                            <a href="https://legal.hellohere.es/General-Terms-and-Conditions" target="_blank">
                                {'Terms & Condition'}
                            </a>
                        </div>
                    </div>

                    <div>
                        <div className="text-16[px] font-semibold leading-24px pb-[36px]">{'Other products'}</div>
                        <div className="flex flex-col gap-[16px]">
                            <a href="https://www.mallorca-magic.com/" target="_blank">
                                {'Mallorcamagic'}
                            </a>
                            <a href="#">{'AI property matching app'}</a>
                            <a href="https://hellohere.es/hello:magic" target="_blank">
                                {'Hello:magic'}
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="leading-[24px] md:flex justify-between lg:max-w-hello m-auto pb-[72px]">
                <div>{'© 2024 - Hello:data All rights reserved.'}</div>
                <div>
                    <span>{'A product of '}</span>
                    <span className="text-my-blue">
                        <a href="https://hellohere.to/" target="_blank">
                            {'Hello:here 💙'}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default FooterSection;
