import { Divider } from '@mui/material';
import CollectionItem from 'components/Modals/Collection/CollectionItem';
import useCollectionStore from 'store/useCollection';
import styled from 'styled-components';
import { CollectionDto } from 'types/Collection';

const Containter = styled.div`
    position: relative;
    height: calc(100vh - 210px);
    overflow: auto;
    @media (max-width: 744px) {
        height: calc(100vh - 265px);
    }
`;

const CollectionListSection = ({ onSelected }: { onSelected: (t: CollectionDto) => void }) => {
    const { collectionList } = useCollectionStore();

    return (
        <div className="bg-white rounded-[10px]">
            <div className="font-semibold text-[18px] px-[18px] py-[20px]">{`${collectionList.length} Collection`}</div>

            <Divider />

            <Containter className="px-[18px] py-[20px]">
                <div className="grid md:grid-cols-3 md:gap-[20px] gap-[8px]">
                    {collectionList.map((item, index) => (
                        <CollectionItem item={item} isActive={false} onClick={() => onSelected(item)} key={index} />
                    ))}
                </div>
            </Containter>
        </div>
    );
};

export default CollectionListSection;
