import { Divider } from '@mui/material';
import { ReactComponent as MapSearchPropertyIcon } from 'assets/svg/MapSearch-Property-Icon.svg';
import { PropertyPagination } from 'components/Pagination';
import useMapPropertyStore, { COUNT_PER_PAGE } from 'store/useMapPropertyStore';
import MapPropertyTable from './PropertyTable/MapPropertyTable';

const MapPropertyPanel = () => {
    const { currentPage, setCurrentPage, totalCount, propertiesInPage } =
        useMapPropertyStore();
    const totalPageCount = Math.ceil(totalCount / COUNT_PER_PAGE);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className="px-[12px] flex flex-col gap-[12px] pt-[8px] h-full">
            {totalCount == 0 ? (
                <div className="w-full h-full flex items-center justify-center">
                    <div className="flex flex-col gap-[16px] max-w-[350px] text-center">
                        <MapSearchPropertyIcon className="mx-auto" />
                        <div>{`Click and hold on the text box and drag it to the desired location on the map.`}</div>
                    </div>
                </div>
            ) : (
                <>
                    {/* export panel */}
                    <div className="flex justify-between items-center gap-[10px]">
                        <div className="text-my-blue font-semibold text-[16px]">{`${totalCount} property result found`}</div>
                    </div>
                    {/* data */}
                    <div className="flex-grow overflow-auto">
                        <MapPropertyTable
                            propertyList={propertiesInPage}
                            onSort={() => {}}
                        />
                    </div>
                    {/* pagination */}
                    {totalPageCount > 1 && (
                        <div className="px-[12px] left-0 bg-white">
                            <Divider />
                            <div className="mx-auto w-fit md:block hidden">
                                <PropertyPagination
                                    totalPageCount={totalPageCount}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                    half={true}
                                />
                            </div>
                            <div className="mx-auto w-fit md:hidden">
                                <PropertyPagination
                                    totalPageCount={totalPageCount}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default MapPropertyPanel;
