import { FormControl, MenuItem, Select } from '@mui/material';
import ClearFilterButton from './ClearFilterButton';

interface SimpleFilterProps {
    stringlist?: string[];
    placeholder: string;
    value: string;
    onChanged: (item: string) => void;
    showClearButton?: boolean;
    displayClassName?: string;
    'data-cy'?: string;
}

const SimpleFilter = ({
    value,
    onChanged,
    stringlist = [],
    placeholder,
    showClearButton = true,
    displayClassName = '',
    'data-cy': dataCy,
}: SimpleFilterProps) => {
    const clearFilterClicked = () => {
        onChanged('');
    };

    return (
        <div className="relative w-full h-full">
            {!value && (
                <div className="absolute top-0 left-0 w-full h-full flex items-center px-[10px] truncate">
                    {placeholder}
                </div>
            )}

            <FormControl fullWidth className="!h-full">
                <Select
                    value={value}
                    onChange={(e) => onChanged(e.target.value)}
                    displayEmpty
                    renderValue={(selected) => {
                        return selected;
                    }}
                    className={`!w-full !h-full rounded-[8px] border-[#DFE1E1] leading-6 px-1 ${displayClassName}`}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 300,
                            },
                        },
                    }}
                >
                    {stringlist.map((item: string, idx: number) => (
                        <MenuItem
                            value={item}
                            key={idx}
                            sx={{ height: '32px' }}
                            data-cy={dataCy}
                        >
                            {item}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* clear button */}
            {value && showClearButton && (
                <ClearFilterButton onClicked={clearFilterClicked} />
            )}
        </div>
    );
};

export default SimpleFilter;
