import { yupResolver } from '@hookform/resolvers/yup';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ErrorMessage from 'components/ErrorMessage';
import { useToast } from 'components/Providers/ToastProvider';
import { useSendOptForUpdateEmailMutation } from 'hooks';
import { BlackButton, CustomOutlinedInput } from 'pages/auth';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { emailSchema } from 'schemas/emailValidationSchema';
import useOtpStore from 'store/useOtpStore';
import useUserStore from 'store/useUserStore';

interface FormData {
    email: string;
}

const EnterEmailSection = () => {
    const { userInfo } = useUserStore();
    const userEmail = userInfo?.email;
    const { handleSubmit, control, formState, getValues } = useForm<FormData>({
        resolver: yupResolver<FormData>(emailSchema),
    });
    const { showMessage } = useToast();
    const { setUserNewEmail } = useOtpStore();

    const { mutate, isPending } = useSendOptForUpdateEmailMutation({
        successMessage: 'Enter Otp Code That  You Receive On  New Email!',
        successType: 'Success',
        onSuccess: () => {
            setUserNewEmail(getValues('email'));
        },
    });

    const onSendVerifyClicked: SubmitHandler<FormData> = ({ email }) => {
        if (email === userEmail) {
            showMessage('Warning', "Don't Enter Your Current Email");
            return;
        }
        mutate(email);
    };

    return (
        <form onSubmit={handleSubmit(onSendVerifyClicked)}>
            <div className={'text-black text-[20px] font-semibold leading-[21px]'}>{'Change Email'}</div>
            <div className="text-my-black">Enter the email address you want to add, and we will send a verification code to confirm it.</div>
            <Controller
                name="email"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <CustomOutlinedInput
                        name="email"
                        value={value}
                        onChange={onChange}
                        autoFocus={true}
                        placeholder="Email Address"
                        startIcon={<EmailOutlinedIcon />}
                    />
                )}
            />
            {formState.errors.email && <ErrorMessage msg={`${formState.errors.email.message}`} />}

            <BlackButton className="w-full !mt-2" loading={isPending} type="submit">
                Send Otp Code to New Email
            </BlackButton>
        </form>
    );
};

export default EnterEmailSection;
