import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';
import { queryClient } from 'routes';
import useCompetitorsStore from 'store/useCompetitorsStore';
import ModalLayout from '../ModalLayout';
import { ModalLayoutProps } from '..';
import { HelloMuiButton } from 'components/Mui';

const RemoveCompetitorModal = ({ visible, onClose }: ModalLayoutProps) => {
    const { removeCompetitor, selectedSavedCompetitor } = useCompetitorsStore();
    const { showMessage } = useToast();

    const invalidateQueries = () => {
        const queriesToInvalidate = [
            { queryKey: ['fetchPropertiesStatistics'], exact: true },
            { queryKey: ['fetchCompetitorAllProperties'] },
        ];

        queriesToInvalidate.forEach((query) => {
            queryClient.invalidateQueries(query);
        });
    };

    const { mutate, isPending } = useMutation({
        mutationFn: removeCompetitor,
        mutationKey: ['removeCompetitor'],
        onSuccess: () => {
            onClose();
            showMessage('Success', 'Competitor removed successfully');
            invalidateQueries();
        },
        onError: (error) => {
            showMessage('Error', error.message);
        },
    });

    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <div className="w-[300px] bg-white rounded-[10px]">
                <div className="p-[22px] text-center">
                    <img
                        alt="REMOVE COMPETITOR"
                        src="/image/RemoveCompetitor.png"
                        className="mx-auto"
                    />

                    <div className="text-[16px] font-semibold py-[10px]">
                        Remove Competitor?
                    </div>
                    <div className="text-[12px]">
                        <span>Are you sure you want to remove </span>
                        <span className="font-semibold">
                            {selectedSavedCompetitor?.name || ''}
                        </span>
                        <span>? competitors will be removed from the list</span>
                    </div>
                    <div className="h-[10px]"></div>
                    <div>
                        <HelloMuiButton
                            variant="contained"
                            onClick={() =>
                                mutate(selectedSavedCompetitor?.companyId || '')
                            }
                            disabled={isPending}
                            sx={{
                                borderRadius: '20px',
                                height: '42px',
                                width: '100%',
                            }}
                        >
                            {isPending ? 'Removing...' : 'Remove'}
                        </HelloMuiButton>
                    </div>

                    <div>
                        <HelloMuiButton
                            onClick={onClose}
                            sx={{
                                borderRadius: '20px',
                                height: '42px',
                                width: '100%',
                            }}
                        >
                            Cancel
                        </HelloMuiButton>
                    </div>
                </div>
            </div>
        </ModalLayout>
    );
};

export default RemoveCompetitorModal;
