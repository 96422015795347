import { PropertyDTO, SortDTO } from 'util/Types';
import PropertyTable from './OriginalTable';

interface PropertyTableProps {
    propertyList: PropertyDTO[];
    onSort: (sort: SortDTO) => void;
}

const OverviewPropertyTable = ({ propertyList, onSort }: PropertyTableProps) => {
    return <PropertyTable propertyList={propertyList} onSort={onSort} isForCompetitor={false} shouldShowDetail={true} />;
};

export default OverviewPropertyTable;
