import { useState, useRef, useCallback } from 'react';
import _ from 'lodash';
import { AzureLocation } from 'types/Azure';
import { Menu, MenuItem } from '@mui/material';
import { getAzureLocations } from 'sevices/azureLocationService';
import { useToast } from 'components/Providers/ToastProvider';

interface AddressSearchFieldProps {
    locationClicked: (v: any) => void;
}

export const AddressSearchField = (props: AddressSearchFieldProps) => {
    const { showMessage } = useToast();
    const { locationClicked } = props;

    const ref = useRef<HTMLDivElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const expanded = Boolean(anchorEl);

    const [searchText, setSearchText] = useState('');
    const [addrList, setAddrList] = useState<AzureLocation[]>([]);

    const searchTextChanged = (_txt: string) => {
        setSearchText(_txt);
        debounceFn(_txt);
    };

    const loadAddressList = async (_txt: string) => {
        if (_txt.length < 3) return;

        const results = await getAzureLocations(_txt);

        if (results.error) {
            showMessage('Error', results.error);
            return;
        }

        setAddrList(results.locations);
        setAnchorEl(results.locations.length > 0 ? ref.current : null);
    };

    const addrClicked = (addrItem: AzureLocation /** title, position */) => {
        setAnchorEl(null);

        setSearchText(addrItem.title || '');
        if (locationClicked) locationClicked(addrItem.position);
    };

    const debounceFn = useCallback(_.debounce(loadAddressList, 500), []);

    return (
        <>
            <div
                className="flex gap-[5px] border border-[#121212] border-opacity-60 rounded-[5px] p-[5px] bg-white items-center relative text-[#121212]"
                ref={ref}
            >
                <input
                    type="text"
                    required
                    onChange={(e) => searchTextChanged(e.target.value)}
                    value={searchText}
                    className="w-full text-[14px] p-[5px] rounded-[5px] font-normal"
                />
            </div>

            {/* Popups */}
            <Menu
                anchorEl={anchorEl}
                open={expanded}
                onClose={() => setAnchorEl(null)}
                sx={{ marginTop: '5px', padding: 0 }}
            >
                {addrList.map((addr: AzureLocation, index) => (
                    <MenuItem key={index} onClick={() => addrClicked(addr)}>
                        {addr.title}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
