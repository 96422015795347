import { PropertyDTO, SortDTO } from 'util/Types';
import PropertyTable from './OriginalTable';

interface PropertyTableProps {
    propertyList: PropertyDTO[];
    onSort: (sort: SortDTO) => void;
    shouldShowDetail?: boolean;
}

const CompetitorPropertyTable = ({ propertyList, onSort, shouldShowDetail = true }: PropertyTableProps) => {
    return <PropertyTable propertyList={propertyList} onSort={onSort} isForCompetitor={true} shouldShowDetail={shouldShowDetail} />;
};

export default CompetitorPropertyTable;
