import { CircularProgress } from '@mui/material';
import { HelloMuiLoadingButton } from 'components';
import { ReactNode } from 'react';

interface BlackButtonProps {
    children: ReactNode;
    loading?: boolean;
    className?: string;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    'data-cy'?: string;
}

const BlackButton = ({
    children,
    loading = false,
    onClick = () => {},
    className = '',
    type = 'button',
    'data-cy': dataCy,
}: BlackButtonProps) => {
    return (
        <HelloMuiLoadingButton
            onClick={onClick}
            variant="outlined"
            className={`!rounded-[8px] h-[50px] !border-my-gray ${className}`}
            loading={loading}
            loadingIndicator={<CircularProgress color="primary" size={24} />}
            sx={{
                color: 'white',
                backgroundColor: '#252525',
                '&:hover': {
                    color: 'black',
                },
            }}
            type={type}
            data-cy={dataCy}
        >
            {children}
        </HelloMuiLoadingButton>
    );
};

export default BlackButton;
