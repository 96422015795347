import { isEqual } from 'lodash';
import { useFiltersStore } from 'store/useFiltersStore';
import { SavedFilter } from 'types/PropertyFilters';

export const useFilterLogic = (childRef?: React.RefObject<{ closePopup: () => void }>) => {
    const { currentFilter, savedFilterList, setCurrentFilter } = useFiltersStore();

    const currentIndex = savedFilterList.findIndex((filter) => isEqual(filter.filter, currentFilter));

    const onFilterClicked = (filter: SavedFilter) => {
        setCurrentFilter(filter.filter);
        if (childRef?.current) childRef.current.closePopup();
    };

    return {
        currentFilter,
        savedFilterList,
        currentIndex,
        onFilterClicked,
        setCurrentFilter,
    };
};
