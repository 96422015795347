// src/components/AzureMap.js
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import * as atlas from 'azure-maps-control';
import 'azure-maps-control/dist/atlas.min.css'; // Import the CSS file
import { AddressSearchField } from './LocationSearcher';
import { PropertyDTO } from 'util/Types';
import PropertyMarker from './PropertyMarker';
import BeginPointMaker from './BeginPointMarker';
import { getCurrentLocation } from './util';
import CancelButton from './CancelButton';
import DrawAreaButton from './DrawAreaButton';
import PropertyDetails from './PropertyDetails';
import { PropertyDetailModal } from 'components/Modals/PropertyDetail/index';
import { DEFAULT_MODAL_Z_INDEX } from 'components/Modals/ModalLayout';
import { LocationType } from 'types/Azure';
import useMapPropertyStore from 'store/useMapPropertyStore';
import MapPropertyTableModal from 'components/Modals/MapPropertyTable';

const DEFAULT_ZOOM_LEVEL = 10;
// const MAX_RENDER_COUNT = 100;
interface CustomAzureMapProps {
    onSearch: (plist: LocationType[]) => void;
    isPending: boolean;
}
const CustomAzureMap = (props: CustomAzureMapProps) => {
    const { onSearch, isPending } = props;
    const { init, totalProperties: propertyResult, totalCount, drawingFlag, setDrawingFlag } = useMapPropertyStore();

    const polygonCoordinates = useRef<[number, number][]>([]);

    const mapRef = useRef<HTMLDivElement | null>(null);

    const [browseProperties, setBrowseProperties] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [map, setMap] = useState<atlas.Map | null>(null);

    const [dataSourcePolygon, setDataSourcePolygon] = useState<atlas.source.DataSource | null>(null);
    const [dataSourceLine, setDataSourceLine] = useState<atlas.source.DataSource | null>(null);

    const [currentLocation, setCurrentLocation] = useState<LocationType | null>(null);

    const [selectedProperty, setSelectedProperty] = useState<PropertyDTO | null>(null);

    const initMap = () => {
        // Initialize the map
        const mapInstance = new atlas.Map(mapRef.current!, {
            zoom: DEFAULT_ZOOM_LEVEL,
            authOptions: {
                authType: atlas.AuthenticationType.subscriptionKey,
                subscriptionKey: process.env.REACT_APP_AZURE_MAPS_KEY,
            },
        });
        // Add a data source
        const sourcePolygon = new atlas.source.DataSource();
        const sourceLine = new atlas.source.DataSource();

        const polygonLayer = new atlas.layer.PolygonLayer(sourcePolygon, 'polygon', {
            fillColor: 'rgba(78, 187, 184, 0.5)',
            strokeColor: 'red',
            strokeWidth: 2,
        });

        // Add a line layer to the map
        const lineLayer = new atlas.layer.LineLayer(sourceLine, 'lines', {
            strokeColor: '#3176BC',
            strokeWidth: 2,
        });

        mapInstance.events.add('ready', function () {
            mapInstance.sources.add(sourcePolygon);
            mapInstance.sources.add(sourceLine);

            mapInstance.layers.add(polygonLayer);
            mapInstance.layers.add(lineLayer);

            setMap(mapInstance);

            setDataSourcePolygon(sourcePolygon);
            setDataSourceLine(sourceLine);

            mapInstance.controls.add(new atlas.control.ZoomControl(), {
                position: atlas.ControlPosition.BottomRight,
            });
        });

        return mapInstance;
    };

    //initing map
    useEffect(() => {
        const mapInstance = initMap();
        getCurrentLocation(setCurrentLocation, () => {
            // showMessage('Error', msg),
        });
        // Cleanup on unmount
        return () => {
            mapInstance.dispose();
        };
    }, [mapRef]);

    //moving camera to current browser location
    useEffect(() => {
        if (map && currentLocation) {
            map.setCamera({
                center: [currentLocation.lng || 0, currentLocation.lat || 0],
                zoom: DEFAULT_ZOOM_LEVEL,
            });
        }
    }, [currentLocation, map]);

    //adding event
    useEffect(() => {
        if (map) {
            map.events.add('click', handleMapClick);
        }

        return () => {
            if (map) {
                map.events.remove('click', handleMapClick);
            }
        };
    }, [map, drawingFlag, propertyResult]);

    const AddBeginMarker = (isStart: boolean, position: [number, number]) => {
        const element = document.createElement('div');
        ReactDOM.createRoot(element).render(<BeginPointMaker isDrawing={isStart} onClick={isStart ? handleSearch : onCloseRegion} />);

        const beginMarker = new atlas.HtmlMarker({
            position: position,
            htmlContent: element,
        });

        map?.markers.add(beginMarker);
    };

    //rendering property result on map
    useEffect(() => {
        const pointsForBubble = propertyResult
            // .slice(0, MAX_RENDER_COUNT)
            .map((item: PropertyDTO) => {
                const element = document.createElement('div');
                ReactDOM.createRoot(element).render(<PropertyMarker property={item} onClick={() => onPropertyMarkerClicked(item, element)} />);
                return new atlas.HtmlMarker({
                    position: [item.longitude, item.latitude],
                    htmlContent: element,
                });
            });

        map?.markers.clear();
        map?.markers.add(pointsForBubble);

        if (pointsForBubble.length > 0) AddBeginMarker(false, polygonCoordinates.current[0]);
    }, [propertyResult]);

    //close current region and remove all points and properties
    const onCloseRegion = () => {
        map?.markers.clear();

        dataSourcePolygon?.clear();
        dataSourceLine?.clear();
        polygonCoordinates.current = [];
        init();
        setDrawingFlag(false);
    };

    const onPropertyMarkerClicked = (property: PropertyDTO, element: HTMLDivElement) => {
        setSelectedProperty(property);
        setAnchorEl(element);
    };

    const handleSearch = async () => {
        if (polygonCoordinates.current.length < 3 || isPending) return;

        const params: LocationType[] = polygonCoordinates.current.map((item: [number, number]) => {
            return { lat: item[1], lng: item[0] };
        });

        setTimeout(() => onSearch(params), 500);
    };

    const handleMapClick = (e: any) => {
        if (!drawingFlag || isPending) return;

        const newPosition: number[] = e.position;
        const newCoordinates: [number, number][] = [...polygonCoordinates.current, [newPosition[0], newPosition[1]]];
        //drawing polygons
        dataSourcePolygon?.clear();
        dataSourcePolygon?.add(new atlas.data.Feature(new atlas.data.Polygon([newCoordinates])));

        //drawing first bubble
        if (newCoordinates.length === 1) {
            AddBeginMarker(true, newCoordinates[0]);
        } else if (newCoordinates.length > 1) {
            //drawing line
            dataSourceLine?.clear();
            dataSourceLine?.add(new atlas.data.Feature(new atlas.data.LineString(newCoordinates)));
        }

        polygonCoordinates.current = newCoordinates;
    };

    //moving camera to there
    const locationSearchClicked = (_location: LocationType) => {
        if (map) {
            map.setCamera({
                center: [_location.lng || 0, _location.lat || 0],
            });
        }
    };

    const BrowsePropertiesClicked = () => {
        setBrowseProperties(true);
    };

    //remove all property result and set as drawing now
    const DrawAreaClicked = () => {
        onCloseRegion();
        setDrawingFlag(true);
    };

    //
    const onCancelClicked = () => {
        if (isPending) return;
        onCloseRegion();
    };

    return (
        <div className="h-full w-full relative bg-white">
            <div ref={mapRef} className="h-full w-full" id="myMap"></div>

            <div className={'absolute bottom-5 w-full flex justify-center'}>
                {drawingFlag && <CancelButton loading={isPending} onClick={onCancelClicked} />}
                <div className="lg:hidden block">
                    {!drawingFlag && propertyResult.length > 0 && (
                        <DrawAreaButton title={`Browse all ${totalCount} listings`} onClick={BrowsePropertiesClicked} />
                    )}
                </div>

                {!drawingFlag && propertyResult.length === 0 && <DrawAreaButton title={`Draw your area`} onClick={DrawAreaClicked} />}
            </div>

            <div className={'absolute top-5 left-5 lg:w-[300px] w-[200px]'}>
                <AddressSearchField locationClicked={locationSearchClicked} />
            </div>

            <PropertyDetails anchorEl={anchorEl} onClose={() => setAnchorEl(null)} property={selectedProperty} onDetailClicked={() => setExpanded(true)} />
            <PropertyDetailModal property={selectedProperty} visible={expanded} onClose={() => setExpanded(false)} zIndex={DEFAULT_MODAL_Z_INDEX + 2} />
            <MapPropertyTableModal visible={browseProperties} onClose={() => setBrowseProperties(false)} />
        </div>
    );
};

export default CustomAzureMap;
