import { Divider } from '@mui/material';
import { ReactComponent as DrawMapSearchSvg } from 'assets/svg/Main/Main-DrawMapSearch.svg';
import { ReactComponent as PlusSvg } from 'assets/svg/Plus.svg';
import { HelloMuiLoadingButton } from 'components';
import { AzureMapModal } from 'components/Modals';
import { useModals } from 'pages/MainProvider';
import { useState } from 'react';

const DrawMapPanel = () => {
    const { showSelectCompetitorModal } = useModals();
    const [showMapModal, setShowMapModal] = useState<boolean>(false);

    const onDrawMapButtonClicked = () => {
        setShowMapModal(true);
    };

    return (
        <div className="flex md:flex-row flex-col px-[16px] py-[10px] gap-[5px] rounded-[10px] bg-white font-medium text-[12px] border border-my-gray border-opacity-60 md:h-[66px] ">
            <div className="flex-grow flex h-[44px]">
                <div className="md:w-fit w-full">
                    <HelloMuiLoadingButton
                        onClick={onDrawMapButtonClicked}
                        variant="outlined"
                        className={`!rounded-[5px] !h-full !px-[25px] !w-full`}
                        sx={{
                            color: '#3176BC',
                            backgroundColor: '#FFF',
                            fontSize: '12px',
                            gap: '10px',
                            '&:hover': { color: 'black' },
                        }}
                    >
                        <DrawMapSearchSvg />
                        <span data-cy="draw-map-search">
                            Draw Map to Search
                        </span>
                    </HelloMuiLoadingButton>
                </div>
            </div>

            <Divider orientation="vertical" />

            <div className="md:pl-[20px] h-[44px] md:w-fit w-full">
                <HelloMuiLoadingButton
                    onClick={showSelectCompetitorModal}
                    variant="outlined"
                    className={`!rounded-[5px] !h-full !px-[25px] !w-full`}
                    sx={{
                        color: '#FFF',
                        backgroundColor: '#3176BC',
                        fontSize: '12px',
                        gap: '10px',
                        '&:hover': { color: 'black' },
                    }}
                >
                    <PlusSvg />
                    <span data-cy="add-competitor">Add Competitor</span>
                </HelloMuiLoadingButton>
            </div>

            <AzureMapModal
                visible={showMapModal}
                onClose={() => setShowMapModal(false)}
            />
        </div>
    );
};

export default DrawMapPanel;
