import { Divider } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ReactComponent as ExportDataSvg } from 'assets/svg/ExportData.svg';
import { ReactComponent as FollowersSvg } from 'assets/svg/Followers.svg';
import { ReactComponent as LocationOnSvg } from 'assets/svg/LocationOn.svg';
import { ReactComponent as RemoveCompetitorSvg } from 'assets/svg/RemoveFilter.svg';
import axios from 'axios';
import { HelloMuiButton, LoadingAnimation } from 'components';
import LoadingOverlay from 'components/Loading/LoadingOverlay';
import RemoveCompetitorModal from 'components/Modals/Competitor/RemoveCompetitor';
import { CompetitorPropertyPagination } from 'components/Pagination';
import { CompetitorPropertyTable } from 'components/PropertyTable';
import { useToast } from 'components/Providers/ToastProvider';
import { useState } from 'react';
import { queryClient } from 'routes';
import useCompetitorsStore from 'store/useCompetitorsStore';
import usePropertiesStore from 'store/usePropertiesStore';
import { PropertySort } from 'types/PropertyFilters';
import FavAvatar from '../../../components/FavAvatar';
import CompetitorOverviewGrid from './CompetitorOverviewGrid';
import styles from './competitors.module.css';
import { DEFAULT_PAGE } from 'util/Constants';

const BackgrodunEffect = () => {
    return <div className="absolute w-[500px] h-[600px] top-[50px] left-[-100px] bg-my-green bg-opacity-20 blur-[175px] lg:block hidden z-[0]"></div>;
};

const RightPanel = () => {
    const [openRemoveModal, setOpenRemoveModal] = useState<boolean>(false);
    const { selectedSavedCompetitor } = useCompetitorsStore();
    const { showMessage } = useToast();

    const {
        fetchPropertiesForCompetitor,
        downloadCSVProperties,
        allPropertiesForCompetitor,
        setDefaultSort,
        fetchPropertiesForCompetitorCountPerPage,
        fetchPropertiesForCompetitorSetCurrentPage,
        fetchPropertiesForCompetitorCurrentPage,
        fetchPropertiesForCompetitorSetCountPerPage,
    } = usePropertiesStore();

    const totalCount = selectedSavedCompetitor?.propertyCount || 0;
    const totalPages = Math.ceil(totalCount / fetchPropertiesForCompetitorCountPerPage);

    const resetQueries = () => {
        queryClient.resetQueries({
            queryKey: ['fetchPropertiesForCompetitor', selectedSavedCompetitor?.companyId],
        });
    };

    const setCurrentPage = (p: number) => {
        fetchPropertiesForCompetitorSetCurrentPage(p);
        resetQueries();
    };

    const onCountPerPageChanged = (c: number) => {
        fetchPropertiesForCompetitorSetCurrentPage(DEFAULT_PAGE);
        fetchPropertiesForCompetitorSetCountPerPage(c);
        resetQueries();
    };

    const { isLoading } = useQuery({
        queryKey: ['fetchPropertiesForCompetitor', selectedSavedCompetitor?.companyId],
        queryFn: () => fetchPropertiesForCompetitor(selectedSavedCompetitor?.companyId || ''),
        enabled: !!selectedSavedCompetitor?.companyId,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    });

    const { mutate, isPending } = useMutation({
        mutationFn: downloadCSVProperties,
        mutationKey: ['downloadCSVProperties'],
        onError: (error) => {
            showMessage('Error', error.message);
        },
        onSuccess: async (url) => {
            try {
                const response = await axios.get(url, {
                    responseType: 'blob',
                    // Remove 'withCredentials' unless you need to send cookies or authentication headers
                    // withCredentials: true,
                });

                const blob = response.data;
                const objectUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = objectUrl;
                link.download = 'data.csv';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(objectUrl);
            } catch (error) {
                showMessage('Error', 'There is some error in downloading the file. Please try again later.');
            }
        },
    });

    const onExportDataClicked = () => {
        mutate({
            companyId: selectedSavedCompetitor!.companyId,
        });
    };

    const onExploreClicked = () => {
        if (selectedSavedCompetitor!.websiteUrl) {
            const url = selectedSavedCompetitor!.websiteUrl ? new URL(selectedSavedCompetitor!.websiteUrl) : null;
            window.open(url?.origin || '');
        }
    };

    const handleSort = (sort: PropertySort) => {
        setDefaultSort(sort);
        queryClient.resetQueries({
            queryKey: ['fetchPropertiesForCompetitor', selectedSavedCompetitor?.companyId],
        });
    };

    if (!selectedSavedCompetitor) return null;

    return (
        <>
            <div className="lg:h-full relative bg-white" data-cy="competitor-overview">
                <BackgrodunEffect />
                <div
                    className="h-[140px] min-h-[140px] bg-cover"
                    style={{
                        backgroundImage: "url('/image/competitor-background.png')",
                    }}
                ></div>
                <div className="relative bg-white">
                    <div className="h-[70px] relative p-[13px] md:block hidden">
                        <div className="absolute bottom-0 left-0 p-[13px]">
                            <FavAvatar url={selectedSavedCompetitor.websiteUrl!} imageSize={40} containerSize={95} />
                        </div>
                        <div className="pl-[100px] flex gap-[20px] justify-between items-center">
                            <div className="md:max-w-[350px] lg:max-w-[500px]">
                                <div className="font-semibold text-[16px] truncate" data-cy="right-panel-competitor-name">
                                    {selectedSavedCompetitor?.name}
                                </div>
                                <div className="flex flex-row gap-4">
                                    <div className="text-opacity-60 text-black flex items-center gap-1">
                                        <LocationOnSvg />
                                        <div
                                            className="text-[12px] truncate font-medium"
                                            data-cy="right-panel-competitor-location"
                                        >{`${selectedSavedCompetitor.city ? `${selectedSavedCompetitor.city},` : 'Unknown'} ${selectedSavedCompetitor.country || ''}`}</div>
                                    </div>
                                    <div className="text-opacity-60 text-black flex items-center gap-1">
                                        <FollowersSvg />
                                        <div className="text-[12px] truncate font-medium" data-cy="right-panel-competitor-followers">
                                            {`${selectedSavedCompetitor.followers ?? 0} `}
                                            Followers
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-[10px] items-center">
                                <HelloMuiButton
                                    variant="contained"
                                    sx={{
                                        gap: '5px',
                                        backgroundColor: '#4EBBB8',
                                        '&:hover': {
                                            backgroundColor: '#FFF',
                                            color: 'black',
                                        },
                                    }}
                                    disabled={isPending}
                                    onClick={onExportDataClicked}
                                >
                                    <ExportDataSvg />
                                    <span data-cy="right-panel-export-text">
                                        {isPending ? 'Exporting...' : 'Export'}
                                        data
                                    </span>
                                </HelloMuiButton>
                                <HelloMuiButton
                                    variant="outlined"
                                    sx={{
                                        background: 'black',
                                        color: 'white',
                                        '&:hover': { color: 'black' },
                                    }}
                                    onClick={onExploreClicked}
                                    data-cy="right-panel-explore-competitor-button"
                                >
                                    Explore Competitor
                                </HelloMuiButton>
                                <div
                                    className="cursor-pointer border border-my-gray rounded-[5px] p-[5px]"
                                    onClick={() => setOpenRemoveModal(true)}
                                    data-cy="right-panel-remove-competitor-button"
                                >
                                    <RemoveCompetitorSvg />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* for mobile */}
                    <div className="md:hidden flex flex-col pb-[10px]">
                        <div className="relative h-[70px] flex justify-end">
                            <div className="absolute bottom-0 left-0 p-[13px] w-full flex justify-center">
                                <FavAvatar url={selectedSavedCompetitor.websiteUrl!} imageSize={40} containerSize={95} />
                            </div>

                            <div className="p-[10px] z-[2]">
                                <div className="cursor-pointer border border-my-gray rounded-[5px] p-[5px]" onClick={() => setOpenRemoveModal(true)}>
                                    <RemoveCompetitorSvg />
                                </div>
                            </div>
                        </div>

                        <div className="text-center flex flex-col gap-[5px] px-[10px]">
                            <div className="font-semibold text-[16px] truncate">{selectedSavedCompetitor?.name}</div>
                            <div className="text-opacity-60 text-black flex items-center justify-center">
                                <LocationOnSvg />
                                <div className="text-[12px] truncate font-medium">{`${selectedSavedCompetitor.city ? `${selectedSavedCompetitor.city},` : 'Unknown'} ${selectedSavedCompetitor.country || ''}`}</div>
                            </div>
                            <div className="flex flex-col gap-4 items-center">
                                <HelloMuiButton
                                    variant="contained"
                                    sx={{
                                        gap: '5px',
                                        height: '36px',
                                        width: '160px',
                                        backgroundColor: '#4EBBB8',
                                        '&:hover': {
                                            backgroundColor: '#FFF',
                                            color: 'black',
                                        },
                                    }}
                                    disabled={isPending}
                                    onClick={onExportDataClicked}
                                >
                                    <ExportDataSvg />
                                    <span>
                                        {isPending ? 'Exporting...' : 'Export'}
                                        data
                                    </span>
                                </HelloMuiButton>
                                <HelloMuiButton
                                    variant="outlined"
                                    sx={{
                                        height: '36px',
                                        width: '160px',
                                        background: 'black',
                                        color: 'white',
                                        '&:hover': { color: 'black' },
                                    }}
                                >
                                    {'Explore Competitor'}
                                </HelloMuiButton>
                            </div>
                        </div>
                    </div>
                </div>

                <Divider className="w-full" />

                <div className="px-[10px] pt-[20px]">
                    <CompetitorOverviewGrid />
                    <div className="py-[10px]"></div>

                    <div className={`relative overflow-auto bg-white ${styles['rightcontent']}`}>
                        {isLoading && <LoadingAnimation />}
                        <div className="text-[12px]">
                            <CompetitorPropertyTable propertyList={allPropertiesForCompetitor} onSort={handleSort} />
                        </div>
                    </div>

                    <div className="flex justify-end py-[5px] relative bg-white" data-cy="right-panel-property-pagination">
                        <CompetitorPropertyPagination
                            currentPage={fetchPropertiesForCompetitorCurrentPage}
                            setCurrentPage={setCurrentPage}
                            countPerPage={fetchPropertiesForCompetitorCountPerPage}
                            onCountPerPageChanged={onCountPerPageChanged}
                            totalCount={totalCount}
                            totalPages={totalPages}
                        />
                        {isLoading && <LoadingOverlay />}
                    </div>
                </div>
            </div>

            <RemoveCompetitorModal visible={openRemoveModal} onClose={() => setOpenRemoveModal(false)} />
        </>
    );
};

export default RightPanel;
