import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import LockedItem from 'components/LockedItem';
import { UpgradePlanModal } from 'components/Modals';
import UnLockedItem from 'components/UnLockedItem';
import { useCallback, useRef, useState } from 'react';
import useRegionStore from 'store/useRegionStore';
import useUserStore from 'store/useUserStore';
import { DEFAULT_REGION, Region } from 'types/Region';
import { containsWithoutSpecialChars } from 'util/containsWithoutSpecialChars';
import DefaultDropDown from './DefaultDropDown';

interface CityFilterProps {
    lock?: boolean;
    placeholder?: string;
    region: Region;
    selectedCity?: any;
    onChanged?: (city: string) => void;
    selectedState?: string;
}

const CityFilter = ({
    lock = false,
    selectedCity = '',
    onChanged = () => {},
    region = DEFAULT_REGION,
    placeholder = 'Area',
    selectedState,
}: CityFilterProps) => {
    const [showUpgradePlanModal, setShowUpgradePlanModal] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const childRef = useRef<{ closePopup: () => void }>(null);
    const { getFilteredCities, getCities, getNonCities } = useRegionStore();
    const { isPremiumUser } = useUserStore();

    const itemSelected = useCallback(
        (item: string) => {
            onChanged(item);
            if (childRef.current) {
                childRef.current.closePopup();
            }
        },
        [onChanged],
    );

    const onSearchTermChanged = (text: string) => setSearchTerm(text);

    const onClearClicked = () => onChanged('');

    const onShowModal = () => {
        if (childRef.current) {
            childRef.current.closePopup();
        }
        if (!isPremiumUser()) setShowUpgradePlanModal(true);
    };

    const filteredCities = getFilteredCities(searchTerm);

    const cities = getCities(selectedState!, region);

    const nonCities = getNonCities(selectedState!, region);

    return (
        <>
            <DefaultDropDown
                ref={childRef}
                Title={<div className="truncate">{selectedCity || placeholder}</div>}
                IsEmpty={!selectedCity}
                onClearClicked={onClearClicked}
            >
                {/* searching */}
                <div className="px-[10px] py-[5px]">
                    <div className="rounded-[5px] border border-my-gray border-opacity-60 flex items-center justify-between gap-[5px] px-[10px] py-[5px]">
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => onSearchTermChanged(e.target.value)}
                            className="flex-grow focus:outline-none w-full"
                        />
                        <SearchOutlinedIcon />
                    </div>
                </div>
                <div className="max-h-[300px] overflow-y-scroll">
                    {!region.name &&
                        !selectedState &&
                        filteredCities.map((city, index) =>
                            lock && city.lock ? (
                                <LockedItem key={index} item={city.title} onShowModal={onShowModal} />
                            ) : (
                                <UnLockedItem key={index} item={city.title} itemSelected={itemSelected} />
                            ),
                        )}
                    {(lock ? cities : [...cities, ...nonCities])
                        .filter((city) => containsWithoutSpecialChars(city!, searchTerm))
                        .sort((a, b) => a!.localeCompare(b!))
                        .map((city, index) => (
                            <UnLockedItem key={index} item={city!} itemSelected={itemSelected} />
                        ))}

                    {lock &&
                        nonCities
                            .filter((city) => containsWithoutSpecialChars(city!, searchTerm))
                            .sort((a, b) => a!.localeCompare(b!))
                            .map((city, index) => <LockedItem key={index} item={city!} onShowModal={onShowModal} />)}
                </div>
            </DefaultDropDown>

            <UpgradePlanModal visible={showUpgradePlanModal} onClose={() => setShowUpgradePlanModal(false)} />
        </>
    );
};

export default CityFilter;
