import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Button, IconButton } from '@mui/material';
import { ReactComponent as CollectionSvg } from 'assets/svg/CollectionOut.svg';
import { ReactComponent as DefaultPropertyImageSvg } from 'assets/svg/Default-Property-Image.svg';
import { ReactComponent as SeeDetailsMobileSvg } from 'assets/svg/Property-See-Details-Mobile.svg';
import { IsExceedCompetitor, NoCompetitor } from 'components/CompetitorAdjusted';
import { AiPercentage } from 'components/Labels';
import NewLabel from 'components/Labels/NewCompetitor';
import { OneLetterAvatar } from 'components/LetterAvatar';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useModals } from 'pages/MainProvider';
import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import useCollectionStore from 'store/useCollection';
import useUserStore from 'store/useUserStore';
import { DEFAULT_SORT } from 'util/Constants';
import { convertDateIntoLocaleString, formatNumber, GetCountryCode, isNew } from 'util/Funcs';
import { ORDER_TYPE, PropertyDTO, SortDTO } from 'util/Types';
import styles from './propertytable.module.css';

interface PropertyTableProps {
    propertyList: PropertyDTO[];
    onSort: (sort: SortDTO) => void;
    isForCompetitor: boolean;
    shouldShowDetail: boolean;
}

interface SortHeaderItemProps {
    title: string;
    currentSort: SortDTO;
    className?: string;
    sortField?: string;
    onSort?: (sort: SortDTO) => void;
    'data-cy'?: string;
}

const SORT_TYPES: ORDER_TYPE[] = ['default', 'ASC', 'DESC'];
const SORT_ICONS = [<ArrowRightIcon />, <ArrowDropDownIcon />, <ArrowDropUpIcon />];

const HeaderItem = ({ className = '', title, currentSort, sortField = '', onSort = () => {}, 'data-cy': dataCy = '' }: SortHeaderItemProps) => {
    const [sortStatus, setSortStatus] = useState<number>(0);

    useEffect(() => {
        if (currentSort.field !== sortField) setSortStatus(0);
        else {
            setSortStatus(SORT_TYPES.indexOf(currentSort.order));
        }
    }, [currentSort]);

    const onSortClicked = () => {
        const newSortIndex = (sortStatus + 1) % 3;
        setSortStatus(newSortIndex);
        if (sortField) {
            onSort({
                field: sortField,
                order: SORT_TYPES[newSortIndex],
            });
        }
    };

    return (
        <div className={`flex items-center gap-[2px] cursor-pointer ${className}`} onClick={onSortClicked} data-cy={dataCy}>
            <span className={styles['headertitle']}>{title}</span>
            {sortField && <div>{SORT_ICONS[sortStatus]}</div>}
        </div>
    );
};

const MIN_W = 400;
const MIN_H = 200;

const DateItem = ({ property }: { property: PropertyDTO }) => {
    const isNewProperty: boolean = isNew(property.createdAt);
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        if (!property.imageUrls) {
            setImage('');
            return;
        }

        const filterImages = async () => {
            for (const imgurl of property.imageUrls) {
                const img = new window.Image();
                img.src = imgurl;

                const isValid = await new Promise<boolean>((resolve) => {
                    img.onload = () => {
                        resolve(img.width >= MIN_W && img.height >= MIN_H);
                    };
                    img.onerror = () => resolve(false);
                });

                if (isValid) {
                    setImage(imgurl);
                    break;
                }
            }
        };

        filterImages();
    }, [property.imageUrls]);

    return (
        <div className="relative flex gap-[10px] overflow-hidden">
            {isNewProperty ? (
                <NewLabel />
            ) : (
                <div
                    className="min-w-[42px] w-[42px] h-[42px] border border-my-gray border-opacity-60 rounded-[5px] overflow-hidden flex items-center justify-center"
                    data-cy="property-image"
                >
                    {image ? <img src={image} width={42} height={42} /> : <DefaultPropertyImageSvg />}
                </div>
            )}

            <div className="flex flex-col justify-between">
                <div className="font-medium truncate">
                    <span data-cy="property-title">{property.title}</span>
                    <div className="z-[2] absolute right-0 top-[50%] h-[600%] w-[80%] bg-white blur-[50px] translate-x-[50%] translate-y-[-50%]"></div>
                </div>
                <div className="text-[12px]" data-cy="property-date">
                    {convertDateIntoLocaleString(property.createdAt)}
                </div>
            </div>
        </div>
    );
};

const PropertyTable = ({ propertyList, onSort, isForCompetitor, shouldShowDetail }: PropertyTableProps) => {
    const { showAddPropertyToCollectionModal, showPropertyDetailModal, showAddtionalPropertyDetail, isAdditionalDetailOpened, selectedProperty } = useModals();
    const { collectionList } = useCollectionStore();
    const { getUserPlan, isExceed, userInfo } = useUserStore();
    const [currentSort, setCurrentSort] = useState<SortDTO>(DEFAULT_SORT);

    const onClickShowDetail = (property: PropertyDTO) => {
        showPropertyDetailModal(property, true);
    };

    const onSortClicked = (sort: SortDTO) => {
        setCurrentSort(sort);
        if (onSort) onSort(sort);
    };

    const openAdditionalPanelClicked = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>, p: PropertyDTO) => {
        showAddtionalPropertyDetail(evt.currentTarget, p);
    };

    const onCollectionClicked = (propertyId: string) => {
        showAddPropertyToCollectionModal(propertyId);
    };

    const isExceedFlag = isExceed();
    const currentPlan = getUserPlan();

    return (
        <>
            <div className={`w-full font-medium ${styles['propertytable']}`}>
                {/* header */}
                <div
                    className={`${styles['tr']} ${styles['thead']} grid ${isForCompetitor ? 'xl:grid-cols-10 grid-cols-9' : 'grid-cols-9'} items-center text-my-black text-opacity-60 border-y border-my-gray border-opacity-40 py-[15px] px-[10px] mb-[12px] bg-my-gray bg-opacity-20`}
                >
                    <HeaderItem
                        title="Listing Date"
                        sortField="propertyCreatedAt"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                        className="col-span-2"
                        data-cy="listing-date-header"
                    />
                    <HeaderItem title="Competitor" sortField="CompanyName" currentSort={currentSort} onSort={onSortClicked} data-cy="competitor-header" />
                    <HeaderItem title="Country" sortField="AddressCountry" currentSort={currentSort} onSort={onSortClicked} data-cy="country-header" />
                    <HeaderItem title="City" sortField="AddressCity" currentSort={currentSort} onSort={onSortClicked} data-cy="city-header" />
                    <HeaderItem
                        title="Property Type"
                        sortField="PropertyType"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                        data-cy="property-type-header"
                    />
                    <HeaderItem title="Price" sortField="Price" currentSort={currentSort} onSort={onSortClicked} data-cy="price-header" />
                    <HeaderItem
                        title="Constructed area"
                        sortField="SizeLand"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                        data-cy="construction-area-header"
                    />
                    <HeaderItem
                        title="Plot area"
                        sortField="SizePlot"
                        currentSort={currentSort}
                        onSort={onSortClicked}
                        className={`xl:flex hidden ${isForCompetitor ? 'xl:col-span-2' : 'col-span-1'}`}
                        data-cy="plot-area-header"
                    />
                </div>

                {isExceedFlag ? (
                    <IsExceedCompetitor boldText={currentPlan.maxTrackUpCount} />
                ) : userInfo?.savedCompetitors.length === 0 ? (
                    <NoCompetitor />
                ) : (
                    <div className={`flex flex-col gap-[4px] text-opacity-80 ${styles['body']}`} data-cy="property-list-container">
                        {propertyList.map((property: PropertyDTO, index: number) => {
                            const aiScore = Math.floor((property?.aiScore || -1) * 100);
                            const isInAnyCollection = collectionList?.some((collection) =>
                                collection?.properties?.some((p) => p.propertyId === property.propertyId),
                            );

                            return (
                                <div
                                    key={index}
                                    className={`${styles['tr']} grid ${isForCompetitor ? 'xl:grid-cols-10 grid-cols-9' : 'grid-cols-9'} items-center border-opacity-40 py-[8px] px-[10px]`}
                                    data-cy={`property-row-${index}`}
                                >
                                    <div className="col-span-2 pr-[12x]">
                                        <DateItem property={property} />
                                    </div>
                                    <div>
                                        <div className="flex items-center gap-[5px]">
                                            <OneLetterAvatar
                                                title={property.details.listingType || 'Sales'}
                                                colorClass={property.details.listingType === 'Rental' ? 'text-my-green' : 'text-my-blue'}
                                            />
                                            <span data-cy="property-company">{property.companyName || ''}</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex items-center gap-[5px]">
                                            {property.address.Country && (
                                                <ReactCountryFlag
                                                    svg
                                                    countryCode={GetCountryCode(property.address.Country || '') || ''}
                                                    style={{
                                                        width: '1.5em',
                                                        height: '1.5em',
                                                    }}
                                                />
                                            )}
                                            <span data-cy="property-country">{property.address.Country}</span>
                                        </div>
                                    </div>
                                    <div data-cy="property-city">{`${property.address.City || ''}`}</div>
                                    <div data-cy="property-type">{`${property.details.propertyType || ''}`}</div>
                                    <div data-cy="property-price">
                                        {property.details.price && property.details.price > 0
                                            ? `${formatNumber(property.details.price)} ${getSymbolFromCurrency(property.details.currency) || ''}`
                                            : 'On Request'}
                                    </div>
                                    <div data-cy="property-constructed-area">{`${formatNumber(property.details.sizeLand || 0)} sqm`}</div>
                                    <div className={`flex xl:justify-between justify-end items-center ${isForCompetitor ? 'xl:col-span-2' : 'col-span-1'}`}>
                                        <div className="xl:inline-block hidden" data-cy="property-plot-area">
                                            {`${formatNumber(property.details.sizePlot || 0)} sqm`}
                                        </div>

                                        <div className="flex items-center text-my-blue justify-end">
                                            {shouldShowDetail && (
                                                <>
                                                    {aiScore >= 0 && <AiPercentage percentage={aiScore} />}

                                                    <IconButton onClick={() => onCollectionClicked(property.propertyId)}>
                                                        <CollectionSvg className={isInAnyCollection ? 'text-red-500 ' : 'text-my-gray'} />
                                                    </IconButton>

                                                    <div className="lg:flex hidden gap-[12px] items-center">
                                                        <Button variant="outlined" onClick={() => onClickShowDetail(property)} data-cy="property-detail-button">
                                                            Details
                                                        </Button>
                                                    </div>

                                                    <div
                                                        className={`lg:hidden w-[50px] h-[48px] flex items-center justify-center border ${isAdditionalDetailOpened && property.webPageId === selectedProperty?.webPageId ? 'bg-my-black text-white' : 'border-my-blue text-my-blue'} cursor-pointer`}
                                                        onClick={(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => openAdditionalPanelClicked(ev, property)}
                                                    >
                                                        <SeeDetailsMobileSvg
                                                            className={
                                                                isAdditionalDetailOpened && property.webPageId === selectedProperty?.webPageId
                                                                    ? 'rotate-180'
                                                                    : ''
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
};

export default PropertyTable;
