import { useQueryClient } from '@tanstack/react-query';
import { useFiltersStore } from 'store/useFiltersStore';
import usePropertiesStore from 'store/usePropertiesStore';
import { formatNumber } from 'util/Funcs';

const ExportPanel = () => {
    const queryClient = useQueryClient();
    const { currentFilter } = useFiltersStore();
    const { currentPage, fetchCompetitorAllPropertiesSort } = usePropertiesStore();

    const data = queryClient.getQueryData(['fetchCompetitorAllProperties', currentPage, currentFilter, fetchCompetitorAllPropertiesSort]) as {
        findAllPropertiesByCompanyIds: { total: number };
    };
    const total = data?.findAllPropertiesByCompanyIds.total ?? 0;
    return (
        <div className="flex justify-between items-center gap-[10px]">
            <div className="text-my-blue font-semibold text-[16px]" data-cy="total-properties-count">{`${formatNumber(total)} property result found`}</div>
            <div className="flex gap-[10px] items-center justify-between"></div>
        </div>
    );
};

export default ExportPanel;
