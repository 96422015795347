import { ReactComponent as AddedByYouSvg } from 'assets/svg/AddedByYou.svg';

const AddedByYouLabel = () => {
    return (
        <div className="flex items-center gap-[4px] text-my-blue font-medium text-[8px]">
            <AddedByYouSvg />
            <span>{'Added by you'}</span>
        </div>
    );
};

export default AddedByYouLabel;
