import { Button, Divider } from '@mui/material';
import { FilterSetPane } from 'components/Filter';
import { useToast } from 'components/Providers/ToastProvider';
import { useEffect, useRef, useState } from 'react';
import { DEFAULT_FILTER } from 'util/Constants';
import { getFilterCount } from 'util/Funcs';
import { FilterType, LocalSavedFilter } from 'util/Types';
import ModalLayout from './ModalLayout';

interface SaveFilterModalProps {
    filter: LocalSavedFilter;
    visible: boolean;
    onSave: (filter: LocalSavedFilter) => void;
    onClose: () => void;
}

const SaveFilterModal = ({
    filter,
    visible,
    onClose,
    onSave,
}: SaveFilterModalProps) => {
    const [filterName, setFilterName] = useState<string>('');
    const [currentFilter, setCurrentFilter] = useState<FilterType>(
        filter.filter,
    );
    const childRef = useRef<{
        getFilter: () => FilterType;
        clearFilter: () => void;
    }>(null);
    const { showMessage } = useToast();

    useEffect(() => {
        setFilterName(filter.title);
        setCurrentFilter(filter.filter);
    }, [filter.id]);

    const onSaveClicked = () => {
        if (!childRef.current) return;
        if (!filterName) {
            showMessage('Alert', "Please fill filter's Name");
            return;
        }
        if (getFilterCount(currentFilter) < 1) {
            showMessage('Alert', 'Please select at least 1 filter!');
            return;
        }

        onSave({
            id: filter.id,
            title: filterName,
            filter: currentFilter,
        });
    };

    const onClearClicked = () => {
        setFilterName('');
        setCurrentFilter(DEFAULT_FILTER);
        childRef.current?.clearFilter();
        setTimeout(() => childRef.current?.clearFilter());
    };

    const handleFilterChanged = (filter: FilterType) =>
        setCurrentFilter(filter);

    return (
        <>
            <ModalLayout visible={visible} onClose={onClose}>
                <div className="md:block hidden" data-cy="filter-modal">
                    <div className="rounded-[10px] md:max-w-[748px] bg-white overflow-hidden">
                        <div className="px-[23px] h-[60px] bg-my-gray bg-opacity-40 flex flex-col justify-center">
                            Save Filter Preference
                        </div>

                        <div className="p-[23px] flex flex-col gap-[20px]">
                            <div>Filter name</div>
                            <div className="rounded-[5px] border border-my-gray border-opacity-60 h-[48px] px-[16px] flex justify-between items-center">
                                <input
                                    type="text"
                                    className="w-full"
                                    placeholder="e.g. urban property"
                                    value={filterName}
                                    onChange={(e) =>
                                        setFilterName(e.target.value)
                                    }
                                    data-cy="filter-name-input"
                                />

                                <span>{`${filterName.length}/30`}</span>
                            </div>
                            <Divider className="w-full" />

                            <div
                                className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[10px]"
                                data-cy="filters-modal"
                            >
                                <FilterSetPane
                                    key="filter-set-plane"
                                    ref={childRef}
                                    value={currentFilter}
                                    handleChanged={handleFilterChanged}
                                />
                            </div>
                        </div>

                        <Divider className="w-full" />

                        <div className="px-[23px] h-[60px] flex justify-end">
                            {/* back and save */}
                            <div className="flex items-center gap-[20px]">
                                <Button
                                    onClick={onClearClicked}
                                    className="w-[160px]"
                                    data-cy="clear-filter"
                                >
                                    Clear
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onSaveClicked}
                                    className="w-[160px]"
                                    data-cy="save-filter-to-storage"
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="md:hidden">
                    <div className="h-screen w-screen bg-white fixed left-0 top-0 z-[3]">
                        <div className="relative h-full w-full">
                            <div className="px-[23px] h-[60px] bg-my-gray bg-opacity-40 flex flex-col justify-center">
                                Save Filter Preference
                            </div>

                            <div className="px-[23px] py-[20px]">
                                <div className="pb-[10px]">Filter name</div>
                                <div className="rounded-[5px] border border-my-gray border-opacity-60 h-[48px] px-[16px] flex justify-between items-center">
                                    <input
                                        type="text"
                                        className="w-full"
                                        placeholder="e.g. urban property"
                                        value={filterName}
                                        onChange={(e) =>
                                            setFilterName(e.target.value)
                                        }
                                    />

                                    <span>{`${filterName.length}/30`}</span>
                                </div>
                            </div>

                            <Divider className="w-full" />

                            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[10px] px-[23px] py-[15px]">
                                <FilterSetPane
                                    ref={childRef}
                                    value={currentFilter}
                                    handleChanged={handleFilterChanged}
                                />
                            </div>

                            <div className="absolute bottom-0 left-0 w-full">
                                <Divider className="w-full" />

                                <div className="px-[23px] h-[60px] flex justify-end">
                                    {/* back and save */}
                                    <div className="flex items-center gap-[20px]">
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            className="w-[120px]"
                                            onClick={onClose}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={onSaveClicked}
                                            className="w-[120px]"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalLayout>
        </>
    );
};

export default SaveFilterModal;
