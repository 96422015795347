import { useEffect, useState } from 'react';
import { AgentExist, LocalAgent } from 'types/Agent';
import { GetFileSizeString } from 'util/Funcs';
import { ReactComponent as CSVFileSvg } from 'assets/svg/CSV-File.svg';
import { ReactComponent as EditLocalCompetitorSvg } from 'assets/svg/Edit-Local-Competitor.svg';
import { ReactComponent as RemoveLocalCompetitorSvg } from 'assets/svg/Remove-Local-Competitor.svg';
import { ReactComponent as CompleteEditCompetitorSvg } from 'assets/svg/Complete-Edit-Competitor.svg';
import { ReactComponent as CancelEditCompetitorSvg } from 'assets/svg/Cancel-Edit-Competitor.svg';

import CloseIcon from '@mui/icons-material/Close';
import { Divider, IconButton } from '@mui/material';
import useCompetitorsStore from 'store/useCompetitorsStore';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { useToast } from 'components/Providers/ToastProvider';

import Papa, { ParseResult } from 'papaparse';
import { queryClient } from 'routes';
import { HelloMuiButton } from 'components/Mui';

interface CSVLocalAgentsProps {
    file: File | null;
    onCancel: () => void;
    onClose: () => void;
}

const isValidRecord = (rec: LocalAgent): boolean => {
    return !(!rec.c_name || !rec.address || !rec.website_url);
};

export const SelectedFileItem = ({ filename, filesize }: { filename: string; filesize: number }) => {
    return (
        <div className="flex gap-[4px] items-center max-w-[350px]">
            <CSVFileSvg />
            <div className="text-left">
                <div className="font-medium truncate">{filename}</div>
                <div className="text-my-gray text-[12px]">{GetFileSizeString(filesize)}</div>
            </div>
        </div>
    );
};

const TableHeader = () => (
    <div className="xl:block hidden border-b border-my-gray p-[12px]">
        <div className="grid grid-cols-4">
            <span>{`Name`}</span>
            <span>{`URL`}</span>
            <span>{`Location`}</span>
        </div>
    </div>
);

const ErrorIcon = () => <span className="text-my-red font-medium text-[10px] px-[4px] py-[1px] bg-my-red bg-opacity-20 rounded-[4px]">{`Error`}</span>;
const ExistIcon = () => <span className="text-my-green font-medium text-[10px] px-[4px] py-[1px] bg-my-green bg-opacity-20 rounded-[4px]">{`Existing`}</span>;

// px-[4px] py-[8px] border-my-gray truncate
const GridItem = styled('div')({
    padding: '8px 4px',
    // truncate
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    borderColor: '#DADADA',
});

const InputTextItem = styled('input')({
    backgroundColor: 'rgba(218, 218, 218, 0.3)',
    padding: '4px 8px',
    width: '100%',
});

const LocalAgentItem = ({ item, onRemoveClicked, onChanged }: { item: LocalAgent; onRemoveClicked: () => void; onChanged: (agent: LocalAgent) => void }) => {
    const [temp, setTemp] = useState<LocalAgent | null>(null);
    const [editMode, setEditMode] = useState<boolean>(false);

    const { getExistAgents } = useCompetitorsStore();
    useMutation({
        mutationFn: getExistAgents,
        mutationKey: ['getExistAgents'],
        onSuccess: () => {},
        onError: () => {},
    });

    useEffect(() => {
        setTemp(item);
    }, [item]);

    const isValid = temp ? isValidRecord(temp) : false;
    const onCancelClicked = () => {
        setTemp(item);
        setEditMode(false);
    };

    const onCompleteClicked = async () => {
        if (temp) {
            if (item.website_url === temp.website_url) onChanged(temp);
            else {
                const result: AgentExist[] = await getExistAgents([temp]);
                if (result && result.length > 0) onChanged({ ...temp, ['exist']: result[0].exists });
                else onChanged(temp);
            }
        }
        setEditMode(false);
    };

    const onChange = (key: keyof LocalAgent, value: string) => {
        setTemp({
            ...temp,
            [key]: value,
        });
    };

    if (!temp) return null;
    return (
        <div className={`p-[12px] xl:border-y border-my-gray border-opacity-50 ${isValid ? '' : 'bg-my-red bg-opacity-10'}`}>
            <div className="xl:grid hidden grid-cols-4 items-center gap-[4px] ">
                {!editMode ? (
                    <>
                        <div className="truncate">{temp.c_name}</div>
                        <div className="truncate">{temp.website_url}</div>
                        <div className="truncate">{temp.address}</div>

                        <div className="flex items-center justify-end">
                            {!isValid && <ErrorIcon />}

                            {isValid && temp.exist && <ExistIcon />}

                            <IconButton onClick={() => setEditMode(true)}>
                                <EditLocalCompetitorSvg />
                            </IconButton>
                            <IconButton onClick={onRemoveClicked}>
                                <RemoveLocalCompetitorSvg />
                            </IconButton>
                        </div>
                    </>
                ) : (
                    <>
                        <InputTextItem autoFocus value={temp.c_name} onChange={(ev) => onChange('c_name', ev.target.value)} />
                        <InputTextItem value={temp.website_url} onChange={(ev) => onChange('website_url', ev.target.value)} />
                        <InputTextItem value={temp.address} onChange={(ev) => onChange('address', ev.target.value)} />
                        <div className="flex items-center justify-end">
                            <IconButton onClick={onCompleteClicked}>
                                <CompleteEditCompetitorSvg />
                            </IconButton>
                            <IconButton onClick={onCancelClicked}>
                                <CancelEditCompetitorSvg />
                            </IconButton>
                        </div>
                    </>
                )}
            </div>

            <div className="xl:hidden">
                <div className="md:block hidden border border-my-gray">
                    {!editMode ? (
                        <>
                            <div className="grid grid-cols-3">
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`Name`}</GridItem>
                                <GridItem className="border-r border-b col-span-2 truncate">{temp.c_name}</GridItem>
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`URL`}</GridItem>
                                <GridItem className="border-b col-span-2 truncate">{temp.website_url}</GridItem>
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`Location`}</GridItem>
                                <GridItem className="border-b col-span-2 truncate">{temp.address}</GridItem>
                            </div>

                            <div className="flex items-center justify-end">
                                {!isValid && <ErrorIcon />}

                                {isValid && temp.exist && <ExistIcon />}

                                <IconButton onClick={() => setEditMode(true)}>
                                    <EditLocalCompetitorSvg />
                                </IconButton>
                                <IconButton onClick={onRemoveClicked}>
                                    <RemoveLocalCompetitorSvg />
                                </IconButton>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="grid grid-cols-3">
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`Name`}</GridItem>
                                <GridItem className="border-r border-b col-span-2">
                                    <InputTextItem autoFocus value={temp.c_name} onChange={(ev) => onChange('c_name', ev.target.value)} />
                                </GridItem>
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`URL`}</GridItem>
                                <GridItem className="border-r border-b col-span-2">
                                    <InputTextItem value={temp.website_url} onChange={(ev) => onChange('website_url', ev.target.value)} />
                                </GridItem>
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`Location`}</GridItem>
                                <GridItem className="border-r border-b col-span-2">
                                    <InputTextItem value={temp.address} onChange={(ev) => onChange('address', ev.target.value)} />
                                </GridItem>
                            </div>

                            <div className="flex items-center justify-end">
                                <IconButton onClick={onCompleteClicked}>
                                    <CompleteEditCompetitorSvg />
                                </IconButton>
                                <IconButton onClick={onCancelClicked}>
                                    <CancelEditCompetitorSvg />
                                </IconButton>
                            </div>
                        </>
                    )}
                </div>

                <div className="md:hidden border border-my-gray">
                    {!editMode ? (
                        <>
                            <div className="grid grid-cols-3">
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`Name`}</GridItem>
                                <GridItem className="border-b col-span-2 truncate">{temp.c_name}</GridItem>
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`URL`}</GridItem>
                                <GridItem className="border-b col-span-2 truncate">{temp.website_url}</GridItem>
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`Location`}</GridItem>
                                <GridItem className="border-b col-span-2 truncate">{temp.address}</GridItem>
                            </div>

                            <div className="flex items-center justify-end">
                                {!isValid && <ErrorIcon />}

                                {isValid && temp.exist && <ExistIcon />}

                                <IconButton onClick={() => setEditMode(true)}>
                                    <EditLocalCompetitorSvg />
                                </IconButton>
                                <IconButton onClick={onRemoveClicked}>
                                    <RemoveLocalCompetitorSvg />
                                </IconButton>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="grid grid-cols-3">
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`Name`}</GridItem>
                                <GridItem className="border-b col-span-2">
                                    <InputTextItem autoFocus value={temp.c_name} onChange={(ev) => onChange('c_name', ev.target.value)} />
                                </GridItem>
                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`URL`}</GridItem>
                                <GridItem className="border-b col-span-2">
                                    <InputTextItem value={temp.website_url} onChange={(ev) => onChange('website_url', ev.target.value)} />
                                </GridItem>

                                <GridItem className="border-r border-b bg-my-gray bg-opacity-20">{`Location`}</GridItem>
                                <GridItem className="border-b col-span-2">
                                    <InputTextItem value={temp.address} onChange={(ev) => onChange('address', ev.target.value)} />
                                </GridItem>
                            </div>

                            <div className="flex items-center justify-end">
                                <IconButton onClick={onCompleteClicked}>
                                    <CompleteEditCompetitorSvg />
                                </IconButton>
                                <IconButton onClick={onCancelClicked}>
                                    <CancelEditCompetitorSvg />
                                </IconButton>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const ValidFields = ['Competitor Name', 'URL', 'Location'];

const CSVLocalAgents = ({ file, onCancel, onClose }: CSVLocalAgentsProps) => {
    const [competitors, setCompetitors] = useState<LocalAgent[]>([]);
    const [hasError, setHasError] = useState<boolean>(false);
    const { getExistAgents, saveAgentsToBackend } = useCompetitorsStore();
    const { showMessage } = useToast();

    const errorCount = competitors.filter((item) => !isValidRecord(item)).length;

    const { mutate: getExistAgentsMutation } = useMutation({
        mutationFn: getExistAgents,
        mutationKey: ['getExistAgents'],
        onSuccess: (existingUrls: AgentExist[], variables: LocalAgent[]) => {
            const newCompetitors = variables;
            newCompetitors.map((item) => {
                const eitem = existingUrls.filter((c) => c.url === item.website_url);
                item.exist = eitem && eitem.length > 0 ? eitem[0].exists : false;
            });
            setCompetitors(newCompetitors);
        },
        onError: () => {
            showMessage('Error', 'Can not load existing competitors!');
        },
    });

    const { mutate: saveAgentsMutate } = useMutation({
        mutationFn: saveAgentsToBackend,
        mutationKey: ['saveAgentsToBackend'],
        onSuccess: (data, variables: LocalAgent[]) => {
            showMessage('Success', `Uploaded ${variables.length} competitors!`);
            queryClient.invalidateQueries({
                queryKey: ['fetchSavedCompetitors'],
            });
            onClose();
        },
        onError: () => {
            showMessage('Error', 'Can not upload competitors!');
        },
    });

    useEffect(() => {
        if (file) {
            setCompetitors([]);

            Papa.parse(file, {
                complete: (result: ParseResult<any>) => {
                    const resultfieldstr = result.meta.fields?.map((field: string) => field.trim()).join('');
                    const validstr = ValidFields.map((field: string) => field.trim()).join('');
                    const isError = result.errors.length > 0 || !resultfieldstr?.includes(validstr);
                    setHasError(isError);
                    if (isError) return;

                    const newCompetitors: LocalAgent[] = result.data.map((c: any) => {
                        return result.meta.fields
                            ? {
                                  c_name: c[result.meta.fields[0]].trim(),
                                  website_url: c[result.meta.fields[1]].trim(),
                                  address: c[result.meta.fields[2]].trim(),
                                  c_property_filter: '',
                                  c_property_url: '',
                                  country: '',
                                  city: '',
                              }
                            : {};
                    });
                    getExistAgentsMutation(newCompetitors);
                },
                header: true, // Set to true if your CSV contains headers
            });
        }
    }, [file]);

    if (!file) return null;

    const filename = file.name,
        filesize = file.size;

    const onUploadClicked = () => {
        const validCompetitors = competitors.filter((item) => !item.exist && isValidRecord(item));
        saveAgentsMutate(validCompetitors);
    };

    const onRemoveClicked = (index: number) => {
        const tempcompetitors = [...competitors];
        tempcompetitors.splice(index, 1);
        setCompetitors(tempcompetitors);
    };

    const onChanged = (index: number, agent: LocalAgent) => {
        const temCompetitors = [...competitors];
        temCompetitors[index] = agent;
        setCompetitors(temCompetitors);
    };

    return (
        <div className="w-full">
            <div className="p-[16px] ">
                <div
                    className={`rounded-[10px] px-[12px] py-[15px] ${hasError ? 'border border-my-red' : ''}`}
                    style={{
                        boxShadow: '0px 4px 16px 0px rgba(109, 141, 173, 0.1)',
                    }}
                >
                    <div className="flex items-center justify-between">
                        <SelectedFileItem filename={filename} filesize={filesize} />
                        <div className="flex gap-[4px] items-center">
                            {hasError && <span className="font-medium text-my-red">{`Error`}</span>}
                            <IconButton onClick={onCancel}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>

            {/* content */}
            {competitors.length > 0 && (
                <div className="md:p-0 pb-[70px]">
                    <Divider />

                    <div>
                        <div className="p-[16px]">
                            <span className="font-semibold">{`${competitors.length} competitor found`}</span>
                            {errorCount > 0 && <span className="text-my-red text-[10px]">{` (${errorCount} errors)`}</span>}
                        </div>
                        <Divider />

                        <div className="text-[12px] flex flex-col gap-[4px] md:max-h-[300px] overflow-auto xl:p-0 p-[8px]">
                            {/* header */}
                            <TableHeader />
                            {competitors.map((c, index) => (
                                <LocalAgentItem
                                    item={c}
                                    key={index}
                                    onRemoveClicked={() => onRemoveClicked(index)}
                                    onChanged={(agent: LocalAgent) => {
                                        onChanged(index, agent);
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {/* buttons */}
            <div className="md:relative fixed bottom-0 left-0 md:w-auto bg-white w-screen flex items-center gap-[4px] p-[16px]">
                <div className="flex-grow"></div>
                <HelloMuiButton variant="outlined" className="!mx-auto !text-black !border-my-gray" onClick={onCancel}>
                    {`Cancel`}
                </HelloMuiButton>
                <HelloMuiButton variant="contained" onClick={onUploadClicked} disabled={hasError || competitors.length === 0}>
                    {`Upload`}
                </HelloMuiButton>
            </div>
        </div>
    );
};

export default CSVLocalAgents;
