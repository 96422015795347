import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import {
    NotificationCount,
    NotificationMessage,
    NotificationType,
} from 'types/Notification';
import { create } from 'zustand';

interface NotificationStore {
    loadNotifications: ({
        pageParam,
        type,
    }: {
        pageParam: number;
        type?: NotificationType;
    }) => Promise<NotificationMessage[]>;
    readOneNotification: (id: string) => Promise<NotificationMessage>;
    markAsReadAllNotification: () => Promise<boolean>;
    getReadUnreadNotificationCount: () => Promise<NotificationCount>;

    deleteNotification: (id: string) => Promise<boolean>;
}

const useNotificationStore = create<NotificationStore>((set) => ({
    loadNotifications: async ({
        pageParam,
        type,
    }: {
        pageParam: number;
        type?: NotificationType;
    }) => {
        try {
            const { data } = await axiosInstance(
                `/notifications?page=${pageParam ?? 1}&type=${type}`,
            );
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    readOneNotification: async (id: string) => {
        try {
            const { data } = await axiosInstance.patch(
                `/notifications/${id}/read`,
            );
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    markAsReadAllNotification: async () => {
        try {
            const { data } = await axiosInstance.patch(
                `/notifications/mark-all-read`,
            );
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    getReadUnreadNotificationCount: async () => {
        try {
            const { data } = await axiosInstance(
                `/notifications/read-unread-count`,
            );
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    deleteNotification: async (id: string) => {
        try {
            const { data } = await axiosInstance.delete(`/notifications/${id}`);
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error))
                throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
}));

export default useNotificationStore;
