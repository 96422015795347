interface ErrorMessageProps {
    msg?: string;
    'data-cy'?: string;
}

const ErrorMessage = ({ msg, 'data-cy': dataCy }: ErrorMessageProps) => {
    return (
        <div
            className="text-[12px] text-red-500 italic py-[5px] font-medium"
            data-cy={dataCy}
        >
            {msg}
        </div>
    );
};

export default ErrorMessage;
