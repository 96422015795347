import { GradientButton } from 'components';
import AccurateData from './AccurateData';
import AdvancedFilter from './AdvancedFilter';
import EnrichedProspectData from './EnrichedProspectData';
import ExportDoc from './ExportDoc';
import GivingControl from './GivingControl';

const FeaturesSection = () => {
    return (
        <div
            className="lg:px-[100px] md:px-[32px] px-[12px] xl:lg:max-w-hello mx-auto"
            id="featured"
        >
            {/* mark */}
            <div className="flex">
                <GradientButton>
                    <div className="flex items-center justify-between rounded-[5px] h-[30px] px-[15px] gap-[10px]">
                        <img
                            alt={'Gradient Button '}
                            src="/image/Landing/Landing-Feature-Icon.png"
                            className="w-[14px] h-[14px]"
                        />
                        <span>{`Features`}</span>
                    </div>
                </GradientButton>
            </div>
            {/* tite */}
            <div className="pt-[20px] pb-[40px] grid lg:grid-cols-2 sm:grid-cols-1 text-[14px]">
                <div>
                    <div className="font-nunito md:text-[36px] text-[24px] font-extrabold">
                        {'The New Standard in Real Estate Competitor Analysis'}
                    </div>
                    <div className="text-my-black leading-[28px]">
                        {
                            'All the features you need to easily understand what your competitors are offering to build a strong marketing plan and ultimately build a better business.'
                        }
                    </div>
                </div>
                <div></div>
            </div>
            {/* Graphs */}
            <div className="flex flex-col gap-[5px]">
                <div className="grid lg:grid-cols-5 sm:grid-cols-1 gap-[5px] lg:max-w-max md:max-w-[680px] max-w-full mx-auto">
                    <div className="col-span-2">
                        <GivingControl />
                    </div>
                    <div className="col-span-3">
                        <ExportDoc />
                    </div>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-1 lg:max-w-max md:max-w-[680px] mx-auto gap-[5px]">
                    <AccurateData />
                    <AdvancedFilter />
                    <EnrichedProspectData />
                </div>
            </div>

            <div></div>
        </div>
    );
};

export default FeaturesSection;
