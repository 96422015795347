import { InputAdornment, OutlinedInput } from '@mui/material';
import { ReactNode } from 'react';

interface CustomOutlinedInputProps {
    name: string;
    value?: string;
    onChange?: (e: any) => void;
    onFocus?: () => void;
    placeholder?: string;
    className?: string;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    autoFocus?: boolean;
    'data-cy'?: string;
}

const CustomOutlinedInput = ({
    name,
    startIcon,
    endIcon,
    value = '',
    placeholder = '',
    className = '',
    onChange = () => {},
    autoFocus = false,
    onFocus = () => {},
    'data-cy': dataCy,
}: CustomOutlinedInputProps) => {
    return (
        <div className="h-full w-full">
            <OutlinedInput
                data-cy={dataCy}
                onChange={(e) => onChange(e)}
                onFocus={onFocus}
                name={name}
                value={value}
                autoFocus={autoFocus}
                fullWidth
                placeholder={placeholder}
                className={`!rounded-[8px] h-[50px] bg-white bg-opacity-40 ${className}`}
                startAdornment={
                    <InputAdornment position="start" className="text-black">
                        {startIcon || <></>}
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="start" className="text-black">
                        {endIcon || <></>}
                    </InputAdornment>
                }
                sx={{
                    '& .MuiOutlinedInput-input': { p: '0px' },
                    fontSize: 'inherit',
                }}
            />
        </div>
    );
};

export default CustomOutlinedInput;
