import useOtpStore from 'store/useOtpStore';
import ModalLayout from '../ModalLayout';
import EnterEmailSection from './EnterEmail';
import EnterOtpSection from './EnterOtp';
import { ModalLayoutProps } from '..';

const ChangeEmailModal = ({ visible, onClose }: ModalLayoutProps) => {
    const { isOldOtpEntered, userNewEmail } = useOtpStore();

    const renderContent = () => {
        if (!isOldOtpEntered || userNewEmail) {
            return <EnterOtpSection />;
        }
        return <EnterEmailSection />;
    };

    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <div className="otpDialog rounded-[20px] px-[32px] py-[48px] flex flex-col gap-[20px] bg-white md:max-w-[620px]">
                {renderContent()}
            </div>
        </ModalLayout>
    );
};

export default ChangeEmailModal;
