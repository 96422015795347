import { Button, IconButton, Menu } from '@mui/material';
import { useState } from 'react';
import usePropertiesStore from 'store/usePropertiesStore';
import {
    DuplicatePropertiesDto,
    PropertyAdditionalDetail,
} from 'types/Property';
import { PropertyStatisticsActionEnum } from 'types/PropertyStatistics';
import { PropertyDTO } from 'util/Types';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { HelloMuiButton } from 'components/Mui';

interface ExplorerListingButtonGroupProps {
    property: PropertyDTO | null;
    additionalDetails?: PropertyAdditionalDetail | null;
}

const ListingDropDown = ({
    duplicatedItems,
    onExplorerListingClicked,
}: {
    duplicatedItems: DuplicatePropertiesDto[];
    onExplorerListingClicked: (wpid: string, wurl: string) => void;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const expanded = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onClose = () => {
        setAnchorEl(null);
    };

    const onClick = (item: DuplicatePropertiesDto) => {
        onExplorerListingClicked(item.webPageId, item.url || item.websiteUrl);
        onClose();
    };

    const MiniItem = ({ item }: { item: DuplicatePropertiesDto }) => {
        return (
            <div className="flex items-center justify-between gap-[12px] px-[12px] h-[42px]">
                <div className="text-black truncate text-[12px]">
                    {item.companyName}
                </div>
                <IconButton onClick={() => onClick(item)}>
                    <ArrowRightAltIcon />
                </IconButton>
            </div>
        );
    };

    return (
        <div>
            <div
                className="h-[32px] rounded-[5px] bg-my-green text-white flex items-center px-[8px] cursor-pointer"
                onClick={handleClick}
            >
                <span className="text-[12px]"> {'Explore Listing'} </span>
                {expanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </div>

            <Menu
                anchorEl={anchorEl}
                open={expanded}
                onClose={onClose}
                sx={{ marginTop: '10px' }}
            >
                <div className="max-h-[350px] min-w-[250px] md:max-w-max max-w-[300px] overflow-auto">
                    {duplicatedItems.map((item, index) => (
                        <MiniItem item={item} key={index} />
                    ))}
                </div>
            </Menu>
        </div>
    );
};

const ExplorerListingButtonGroup = (props: ExplorerListingButtonGroupProps) => {
    const { property, additionalDetails } = props;
    const { savePropertyStatistics } = usePropertiesStore();

    if (!property) return null;

    const duplicateProperties = additionalDetails?.duplicateProperties;
    const duplicatedCount = duplicateProperties?.length || 0;

    const onExplorerListingClicked = (
        webPageId: string,
        webPageUrl: string,
    ) => {
        savePropertyStatistics({
            webPageId: webPageId,
            action: PropertyStatisticsActionEnum.CLICK,
        });
        window.open(webPageUrl);
    };

    const onSelfExplorerListingClicked = () => {
        onExplorerListingClicked(property.webPageId, property.webPageUrl);
    };

    if (duplicatedCount == 0) {
        return (
            <HelloMuiButton
                variant="contained"
                onClick={onSelfExplorerListingClicked}
                sx={{
                    borderRadius: '5px',
                    height: '32px',
                    backgroundColor: '#4EBBBB',
                    '&:hover': {
                        backgroundColor: 'white',
                        color: '#4EBBBB',
                    },
                }}
                className="truncate"
            >
                {'Explore Listing'}
            </HelloMuiButton>
        );
    } else {
        return (
            <ListingDropDown
                duplicatedItems={[
                    {
                        webPageId: property.webPageId,
                        companyId: property.companyId,
                        url: property.imageUrl || '',
                        companyName: property.companyName,
                        websiteUrl: property.webPageUrl,
                    },
                    ...(duplicateProperties || []),
                ]}
                onExplorerListingClicked={onExplorerListingClicked}
            />
        );
    }
};

export default ExplorerListingButtonGroup;
