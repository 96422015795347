import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Divider } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ReactComponent as CreateShareLinkSvg } from 'assets/svg/Create-Shareable-Link.svg';
import { ReactComponent as ExportDataSvg } from 'assets/svg/DownloadCSVCollection.svg';
import { ReactComponent as EditSvg } from 'assets/svg/EditFilter.svg';
import { ReactComponent as RemoveSvg } from 'assets/svg/RemoveFilter.svg';
import { HelloMuiButton } from 'components';
import CollectionItem from 'components/Modals/Collection/CollectionItem';
import DeleteCollectionModal from 'components/Modals/Collection/DeleteCollection';
import EditCollectionModal from 'components/Modals/Collection/EditCollection';
import CreateSharableLinkMoal from 'components/Modals/SharableLink/Create';
import ShareableLinkCreatedModal from 'components/Modals/SharableLink/Success';
import CollectionPropertyTable from 'components/PropertyTable/CollectionPropertyTable';
import { useToast } from 'components/Providers/ToastProvider';
import { Fragment, useState } from 'react';
import useSharableLinkStore from 'store/sharableLinksStore';
import useCollectionStore from 'store/useCollection';
import { CollectionDto } from 'types/Collection';
import { LocalShareLinkDto } from 'types/SharableLink';
import { PropertyDTO } from 'util/Types';

interface HeaderProps {
    item: CollectionDto;
    onClose: () => void;
    selectedProperties: PropertyDTO[];
}

const Header = ({ item, onClose, selectedProperties }: HeaderProps) => {
    const [link, setLink] = useState<string>('');
    const [showEditCollectionModalFlag, setShowEditCollectionModalFlag] = useState<boolean>(false);
    const [showDeleteModalFlag, setShowDeleteModalFlag] = useState<boolean>(false);
    const [showCreateSharbleLinkModal, setShowCreateSharbleLinkModal] = useState<boolean>(false);
    const [showSuccessModalFlag, setShowSuccessModalFlag] = useState<boolean>(false);

    const { createShareableLinks } = useSharableLinkStore();
    const { downloadCSV } = useCollectionStore();
    const { showMessage } = useToast();

    const { isPending, mutate: createMutation } = useMutation({
        mutationFn: createShareableLinks,
        onSuccess: (link: string) => {
            setLink(link);
            showMessage('Success!', 'Link is Created!');
            setShowCreateSharbleLinkModal(false);
            setShowSuccessModalFlag(true);
        },
        onError: (error) => {
            showMessage('Error!', error.message);
        },
    });

    const { mutate: downloadCollection } = useMutation({
        mutationFn: downloadCSV,
        onSuccess: (link: string) => {
            window.open(link);
        },
        onError: (error) => {
            showMessage('Error!', error.message);
        },
    });

    const selectedPropertyCount: number = selectedProperties.length;
    const disabled: boolean = selectedPropertyCount === 0;

    const onCreateClicked = () => setShowCreateSharbleLinkModal(true);

    const createShareLink = (link: LocalShareLinkDto) => {
        const propertyIds = selectedProperties.map((p, _) => p.propertyId);
        link.propertyIds = propertyIds;
        link.collectionId = item.id;
        const now: Date = new Date();
        now.setDate(new Date().getDate() + +link.expirationDate);

        createMutation({
            ...link,
            expirationDate: now.toISOString(),
            password: link.password.replaceAll('-', ''),
        });
    };

    const onDownloadClicked = () => downloadCollection(item.id);

    return (
        <Fragment>
            <div className="md:block hidden">
                <div className="flex items-center justify-between text-[12px]">
                    <CollectionItem item={item} isActive={false} border={false} />

                    <div className="flex gap-[12px] items-center">
                        <div className="lg:flex hidden items-center gap-[12px]">
                            {!disabled && <span className="text-my-blue font-medium">{`${selectedPropertyCount} property selected`}</span>}
                            <HelloMuiButton
                                variant="contained"
                                className={`!bg-my-black !text-[12px] !text-white ${disabled ? 'opacity-30' : ''}`}
                                disabled={disabled}
                                onClick={onCreateClicked}
                            >
                                <div className="flex items-center gap-[4px]">
                                    <CreateShareLinkSvg />
                                    <span>Create Shareable Link</span>
                                </div>
                            </HelloMuiButton>
                            <HelloMuiButton variant="outlined" className={`!border-my-green !text-my-green !text-[12px]`} onClick={onDownloadClicked}>
                                <div className="flex items-center gap-[4px]">
                                    <ExportDataSvg />
                                    <span className="text-my-green">Download .csv file</span>
                                </div>
                            </HelloMuiButton>
                        </div>

                        <HelloMuiButton
                            variant="outlined"
                            className="!w-[36px] !px-0 !border-my-gray border-opacity-60 !min-w-max"
                            onClick={() => setShowEditCollectionModalFlag(true)}
                        >
                            <EditSvg />
                        </HelloMuiButton>

                        <HelloMuiButton
                            variant="outlined"
                            className="!w-[36px] !px-0 border !border-my-gray border-opacity-60 !min-w-max"
                            onClick={() => setShowDeleteModalFlag(true)}
                        >
                            <RemoveSvg />
                        </HelloMuiButton>

                        <Divider orientation="vertical" className="!h-[32px]" />

                        <HelloMuiButton
                            variant="contained"
                            className="!bg-my-black !w-[36px] !px-0 border !border-my-gray border-opacity-60 !min-w-max"
                            onClick={onClose}
                        >
                            <CloseOutlinedIcon />
                        </HelloMuiButton>
                    </div>
                </div>
                <div className="lg:hidden flex items-center gap-[12px] text-[12px]">
                    <HelloMuiButton
                        variant="contained"
                        className={`!bg-my-black !text-[12px] !text-white ${disabled ? 'opacity-30' : ''}`}
                        disabled={disabled}
                        onClick={onCreateClicked}
                    >
                        <div className="flex items-center gap-[4px]">
                            <CreateShareLinkSvg />
                            <span>Create Shareable Link</span>
                        </div>
                    </HelloMuiButton>
                    <HelloMuiButton
                        variant="outlined"
                        className={`!border-my-green !text-my-green !text-[12px] ${disabled ? 'opacity-30' : ''}`}
                        disabled={disabled}
                        onClick={() => {}}
                    >
                        <div className="flex items-center gap-[4px]">
                            <ExportDataSvg />
                            <span className="text-my-green">Download .csv file</span>
                        </div>
                    </HelloMuiButton>
                    {!disabled && <span className="text-my-blue font-medium">{`${selectedPropertyCount} property selected`}</span>}
                </div>
            </div>

            <div className="md:hidden pb-[12px]">
                <div className="flex items-center justify-between gap-[12px] text-[12px]">
                    <span className="text-my-blue font-medium">{disabled ? '' : `${selectedPropertyCount} property selected`}</span>
                    <div className="flex gap-[4px] items-center">
                        <HelloMuiButton
                            variant="outlined"
                            className="!w-[36px] !px-0 !border-my-gray border-opacity-60 !min-w-max"
                            onClick={() => setShowEditCollectionModalFlag(true)}
                        >
                            <EditSvg />
                        </HelloMuiButton>

                        <HelloMuiButton
                            variant="outlined"
                            className="!w-[36px] !px-0 border !border-my-gray border-opacity-60 !min-w-max"
                            onClick={() => setShowDeleteModalFlag(true)}
                        >
                            <RemoveSvg />
                        </HelloMuiButton>

                        <Divider orientation="vertical" className="!h-[32px]" />

                        <HelloMuiButton
                            variant="contained"
                            className="!bg-my-black !w-[36px] !px-0 border !border-my-gray border-opacity-60 !min-w-max"
                            onClick={onClose}
                        >
                            <CloseOutlinedIcon />
                        </HelloMuiButton>
                    </div>
                </div>

                <CollectionItem item={item} isActive={false} border={false} />

                <div className="flex gap-[8px]">
                    <HelloMuiButton
                        variant="contained"
                        className={`!bg-my-black !text-[12px] !text-white ${disabled ? 'opacity-30' : ''}`}
                        disabled={disabled}
                        onClick={onCreateClicked}
                    >
                        <div className="flex items-center gap-[4px]">
                            <CreateShareLinkSvg />
                            <span>Create Shareable Link</span>
                        </div>
                    </HelloMuiButton>
                    <HelloMuiButton
                        variant="outlined"
                        className={`!border-my-green !text-my-green !text-[12px] ${disabled ? 'opacity-30' : ''}`}
                        disabled={disabled}
                        onClick={onDownloadClicked}
                    >
                        <div className="flex items-center gap-[4px]">
                            <ExportDataSvg />
                            <span className="text-my-green">Download .csv file</span>
                        </div>
                    </HelloMuiButton>
                </div>
            </div>

            <DeleteCollectionModal onCallBack={onClose} collectionId={item.id} visible={showDeleteModalFlag} onClose={() => setShowDeleteModalFlag(false)} />

            <EditCollectionModal
                collection={item}
                onSuccessCallback={onClose}
                visible={showEditCollectionModalFlag}
                onClose={() => setShowEditCollectionModalFlag(false)}
            />

            <CreateSharableLinkMoal
                isSaving={isPending}
                onCreate={createShareLink}
                visible={showCreateSharbleLinkModal}
                onClose={() => setShowCreateSharbleLinkModal(false)}
            />

            <ShareableLinkCreatedModal visible={showSuccessModalFlag} onClose={() => setShowSuccessModalFlag(false)} link={link} />
        </Fragment>
    );
};

interface CollectionProps {
    item: CollectionDto;
    onClose: () => void;
}

const Collection = ({ item, onClose }: CollectionProps) => {
    const [selectedProperties, setSelectedProperties] = useState<PropertyDTO[]>([]);

    const onSelectionChanged = (selectedList: PropertyDTO[]) => setSelectedProperties(selectedList);

    return (
        <Fragment>
            <Header item={item} onClose={onClose} selectedProperties={selectedProperties} />
            <div className="bg-white">
                <CollectionPropertyTable propertyList={item.properties} onCheckChanged={onSelectionChanged} />
            </div>
        </Fragment>
    );
};

export default Collection;
