import { ReactNode } from 'react';
import MainHedaer from './MainHeader';

const MainLayout = ({ children }: { children: ReactNode }) => {
    return (
        <div className="relative">
            {/* Mainheader height is 72 */}
            <div className="relative z-[1]">
                <MainHedaer />
                {children}
            </div>
        </div>
    );
};

export default MainLayout;
