import { CircularProgress } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { HelloMuiButton } from 'components/Mui';
import { useToast } from 'components/Providers/ToastProvider';
import { useModals } from 'pages/MainProvider';
import { useEffect, useState } from 'react';
import useCollectionStore from 'store/useCollection';
import { ModalLayoutProps } from '..';
import ModalLayout from '../ModalLayout';
import CollectionItem from './CollectionItem';

interface AddPropertyToCollectionModalProps extends ModalLayoutProps {
    propertyId: string;
}

const AddPropertyToCollectionModal = (props: AddPropertyToCollectionModalProps) => {
    const { propertyId, visible, onClose } = props;
    const { collectionList, savePropertiesToCollection } = useCollectionStore();
    const [selectedCollectionIdList, setSelectedCollectionIdList] = useState<string[]>([]);

    const { showCreateCollectionModal } = useModals();
    const { showMessage } = useToast();

    const getPreviousSelectedCollection = () => {
        const selectedIds: string[] = [];
        collectionList.map((collection) => {
            const propertiesList = collection?.properties?.filter((p) => p.propertyId === propertyId);
            if (propertiesList && propertiesList.length > 0) selectedIds.push(collection.id);
        });
        return selectedIds;
    };
    const prevIdList = getPreviousSelectedCollection();
    const toBeRemoved: string[] = prevIdList.filter((c) => !selectedCollectionIdList.includes(c));
    const toBeAdded: string[] = selectedCollectionIdList.filter((c) => !prevIdList.includes(c));

    const { isPending, mutate: savePropertyToCollectionMutation } = useMutation({
        mutationFn: async () => await savePropertiesToCollection(propertyId, toBeAdded, toBeRemoved),
        onSuccess: () => {
            showMessage(`Success.`, 'Property is successfully added.');
            onClose();
        },
        onError: (error) => {
            showMessage('Error', error.message);
        },
    });

    const onDoneClicked = () => savePropertyToCollectionMutation();

    const collectionClicked = (collectionId: string) => {
        if (selectedCollectionIdList.includes(collectionId)) {
            setSelectedCollectionIdList((prevItems) => prevItems.filter((id) => id !== collectionId));
        } else {
            setSelectedCollectionIdList([collectionId, ...selectedCollectionIdList]);
        }
    };
    useEffect(() => {
        const selectedIds: string[] = getPreviousSelectedCollection();
        setSelectedCollectionIdList(selectedIds);
    }, [propertyId, collectionList]);

    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <div className="rounded-[10px] p-[20px] bg-white">
                <div className="flex flex-col gap-[16px] md:min-w-[600px]">
                    <div className="h-[5px]"></div>
                    <div>
                        <div className="font-semibold text-[16px]">{`Add this property to a collection`}</div>
                        {selectedCollectionIdList.length > 0 && (
                            <div className="text-[12px] text-right">{`${selectedCollectionIdList.length} collection selected`}</div>
                        )}
                    </div>

                    <div className="md:max-h-[300px] max-h-[400px] overflow-auto pr-[8px] min-w-[300px]">
                        <div className="flex flex-col gap-[4px]">
                            {collectionList.map((item) => (
                                <CollectionItem
                                    item={item}
                                    key={item.id}
                                    isActive={selectedCollectionIdList.includes(item.id)}
                                    onClick={() => collectionClicked(item.id)}
                                />
                            ))}
                        </div>
                    </div>

                    <div className="flex justify-between">
                        <HelloMuiButton variant="outlined" className="!rounded-full" onClick={showCreateCollectionModal}>
                            Create Collection
                        </HelloMuiButton>

                        {isPending ? (
                            <CircularProgress size={24} className="!text-my-black mt-3 mr-2" />
                        ) : (
                            <HelloMuiButton
                                variant="contained"
                                className="!bg-my-black !text-white cursor-pointer"
                                onClick={onDoneClicked}
                                disabled={isPending || (toBeAdded.length === 0 && toBeRemoved.length === 0)}
                            >
                                Done
                            </HelloMuiButton>
                        )}
                    </div>
                </div>
            </div>
        </ModalLayout>
    );
};

export default AddPropertyToCollectionModal;
