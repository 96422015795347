import { Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import useCollectionStore from 'store/useCollection';
import { CollectionDto } from 'types/Collection';
import Collection from './Collection';
import CollectionListSection from './CollectionListPage';
import SharableLinkSection from './shareableLinks/ShareableLinks';

type TabItem = 'Listing collections' | 'Shareable Links';
const TabItemList: TabItem[] = ['Listing collections', 'Shareable Links'];

const SaveListingPage = () => {
    const [activetab, setActiveTab] = useState<TabItem>('Listing collections');
    const [selectedCollection, setSelectedCollection] = useState<CollectionDto | null>(null);
    const { collectionList } = useCollectionStore();

    useEffect(() => {
        if (selectedCollection) {
            const newItem = collectionList.filter((c) => c.id === selectedCollection.id)[0];
            setSelectedCollection(newItem);
        }
    }, [collectionList]);

    const onCollectionSelected = (item: CollectionDto | null) => {
        setSelectedCollection(item);
    };

    return (
        <>
            <div className="bg-white">
                <div className="lg:max-w-hello h-[36px] flex mx-auto px-[12px]">
                    {TabItemList.map((item, index) => {
                        return (
                            <div
                                className={`px-[24px] flex items-center justify-center font-medium text-[12px] cursor-pointer ${activetab === item ? 'border-b-2 border-my-blue text-my-blue' : ''}`}
                                key={index}
                                onClick={() => setActiveTab(item)}
                            >
                                {item}
                            </div>
                        );
                    })}
                </div>
            </div>

            <Divider />
            <div className="lg:max-w-hello mx-auto mt-[16px] px-[12px]">
                {activetab === 'Listing collections' && (
                    <>
                        {selectedCollection ? (
                            <Collection item={selectedCollection} onClose={() => setSelectedCollection(null)} />
                        ) : (
                            <CollectionListSection onSelected={onCollectionSelected} />
                        )}
                    </>
                )}

                {activetab === 'Shareable Links' && <SharableLinkSection />}
            </div>
        </>
    );
};

export default SaveListingPage;
