import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { User } from 'types/User';
import { SubscriptionPlans } from 'util/Constants';
import { PlanTypes } from 'util/Types';
import { create } from 'zustand';

interface UserState {
    verifyUserEmail: (email: string) => Promise<string>;
    userInfo: User | null;
    fetchUser: () => Promise<User>;
    updateUserInfo: (data: Partial<User>) => Promise<User>;
    uploadUserProfileImage: (data: FormData) => Promise<number>;
    deleteUserAccount: () => Promise<number>;
    getUserPlan: () => PlanTypes;
    isPremiumUser: () => boolean;
    isOnboardingUser: () => boolean;
    isExceed: () => boolean;
}

const useUserStore = create<UserState>((set, get) => ({
    verifyUserEmail: async (token: string) => {
        try {
            const { data } = await axiosInstance.get(`/user/verify-mail/?token=${token}`, {
                headers: {
                    'SKIP-AUTH': true,
                },
            });
            return data.data.message;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
        }
    },
    userInfo: null,
    fetchUser: async () => {
        try {
            const { data } = await axiosInstance.get('/user/get-user');
            set({ userInfo: data.data });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
        }
    },
    updateUserInfo: async (bodyData) => {
        try {
            const { data } = await axiosInstance.patch('/user/update-user', bodyData);
            const { fullName, phone } = data.data;
            const userInfo = get().userInfo!;
            set({
                userInfo: {
                    ...userInfo,
                    fullName,
                    phone,
                },
            });

            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    uploadUserProfileImage: async (formData) => {
        try {
            const { data, status } = await axiosInstance.patch('/user/upload-profile-picture', formData);
            const user = data.data.user;
            useUserStore.setState({
                userInfo: user,
            });
            return status;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    deleteUserAccount: async () => {
        try {
            const { status } = await axiosInstance.delete('/user/delete-user');
            return status;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    getUserPlan: () => {
        const user = get().userInfo;
        const { priceId = '', interval = 'month' } = user?.subscriptionDetails || {};
        return SubscriptionPlans.find((p) => p.priceId[interval] === priceId) || SubscriptionPlans[0];
    },
    isPremiumUser: () => get().getUserPlan().title === 'Premium',
    isOnboardingUser: () => {
        const user = get().userInfo;
        return !user || !user.accountType || !user.savedCompetitors || !user.subscriptionId || user.savedCompetitors.length === 0;
    },
    isExceed: () => {
        const user = get().userInfo;
        return (user?.savedCompetitors.length || 0) > get().getUserPlan().maxTrackUpCount;
    },
}));

export default useUserStore;
