type StatusType = 'Active' | 'Expired';

const StatusItem = ({ status }: { status: StatusType }) => {
    return (
        <div className={`rounded-[5px] px-[8px] bg-opacity-10 w-fit ${status === 'Active' ? 'bg-my-green' : 'bg-my-red'}`}>
            <span className={status === 'Active' ? 'text-[#34C759]' : 'text-my-red'}>{status}</span>
        </div>
    );
};

export default StatusItem;
