const PartnersSection = () => {
    return (
        <div className="lg:px-[90px] md:px-[32px] px-[12px] bg-my-green bg-opacity-5">
            <div className="max-w-[1030px] mx-auto lg:py-[90px] md:py-[60px] py-[30px] text-center">
                <div className="font-semibold text-[28px] pb-[12px]">
                    {'Collaborating with the best'}
                </div>
                <div className="text-[14px] leading-[28px] md:pb-[70px] pb-[30px]">
                    {
                        "We partner with the world's most innovative technology companies to deliver exceptional solutions to our valued customers and set them up for continued success."
                    }
                </div>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-[97px] md:gap-[50px] gap-[30px] items-center">
                    <img
                        alt={'OpenAI-Logo '}
                        src="/image/Landing/OpenAI-Logo.png"
                        className="mx-auto"
                    />
                    <img
                        alt={'Microsoft-Logo '}
                        src="/image/Landing/Landing-Microsoft-Logo.png"
                        className="mx-auto"
                    />
                    <img
                        alt={'DigitalOcean-Logo '}
                        src="/image/Landing/Landing-DigitalOcean-Logo.png"
                        className="mx-auto"
                    />
                    <img
                        alt={'Stripe-Logo '}
                        src="/image/Landing/Stripe-Logo.png"
                        className="mx-auto"
                    />
                </div>
            </div>
        </div>
    );
};

export default PartnersSection;
