import { Divider } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ReactComponent as ExplorerCompetitorSvg } from 'assets/svg/ExploreCompetitor.svg';
import { ReactComponent as FollowersSvg } from 'assets/svg/Followers.svg';
import { ReactComponent as LocationOnSvg } from 'assets/svg/LocationOn.svg';
import { ReactComponent as PlusSvg } from 'assets/svg/Plus.svg';
import FavAvatar from 'components/FavAvatar';
import LoadingAnimation from 'components/Loading/LoadingAnimation';
import LoadingOverlay from 'components/Loading/LoadingOverlay';
import { HelloMuiButton } from 'components/Mui';
import { CompetitorPropertyPagination } from 'components/Pagination';
import { CompetitorPropertyTable } from 'components/PropertyTable';
import UnsavedCompetitorPropertyTable from 'components/PropertyTable/UnsavedCompetitorPropertyTable';
import { useToast } from 'components/Providers/ToastProvider';
import { useModals } from 'pages/MainProvider';
import { useEffect, useState } from 'react';
import { queryClient } from 'routes';
import useCompetitorsStore from 'store/useCompetitorsStore';
import usePropertiesStore from 'store/usePropertiesStore';
import useUserStore from 'store/useUserStore';
import styled from 'styled-components';
import { Agent } from 'types/Agent';
import { DEFAULT_PAGE, MAX_LIMIT } from 'util/Constants';
import { PropertyDTO } from 'util/Types';
import { ModalLayoutProps } from '..';
import ModalLayout from '../ModalLayout';

const ContentContainer = styled.div`
    overflow: auto;
    height: calc(100vh - 440px);

    @media (max-width: 743px) {
        height: calc(100vh - 460px);
    }
`;

interface CompetitorDetailsProps extends ModalLayoutProps {
    competitor: Agent | null;
}

const Content = ({ competitor }: { competitor: Agent }) => {
    const [addSucceed, setAddSucceed] = useState<boolean>(false);
    const { isSelectCompetitorOpened, isPropertyDetailOpened } = useModals();
    const {
        fetchPropertiesForCompetitor,
        allPropertiesForCompetitor,
        fetchPropertiesForCompetitorCountPerPage,
        fetchPropertiesForCompetitorSetCurrentPage,
        fetchPropertiesForCompetitorCurrentPage,
        fetchPropertiesForCompetitorSetCountPerPage,
    } = usePropertiesStore();

    const { selectedCompetitors, setSelectedCompetitors, addCompetitorToSavedList } = useCompetitorsStore();
    const { userInfo: user, getUserPlan, isOnboardingUser, isPremiumUser } = useUserStore();

    const totalCount = competitor.propertyCount || 0;
    const totalPages = Math.ceil(totalCount / fetchPropertiesForCompetitorCountPerPage);

    const resetQueries = () => {
        queryClient.resetQueries({
            queryKey: ['fetchPropertiesForCompetitor', competitor.companyId],
        });
    };

    const setCurrentPage = (p: number) => {
        fetchPropertiesForCompetitorSetCurrentPage(p);
        resetQueries();
    };

    const onCountPerPageChanged = (c: number) => {
        fetchPropertiesForCompetitorSetCurrentPage(DEFAULT_PAGE);
        fetchPropertiesForCompetitorSetCountPerPage(c);
        resetQueries();
    };

    const { isLoading } = useQuery({
        queryKey: ['fetchPropertiesForCompetitor', competitor?.companyId],
        queryFn: () => fetchPropertiesForCompetitor(competitor?.companyId || ''),
        enabled: !!competitor?.companyId,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
    });

    const plan = getUserPlan();

    if (!user) return null;

    const isSaved: boolean = user.savedCompetitors.includes(competitor.companyId);
    const isAddable: boolean =
        !selectedCompetitors.includes(competitor.companyId) &&
        ((plan?.maxTrackUpCount < MAX_LIMIT && plan?.maxTrackUpCount > selectedCompetitors.length) || isPremiumUser());
    const propertiesToShow: PropertyDTO[] = isSaved ? allPropertiesForCompetitor : allPropertiesForCompetitor.slice(0, 2);

    const onExploreClicked = () => {
        if (competitor!.websiteUrl) {
            const url = competitor.websiteUrl ? new URL(competitor.websiteUrl) : null;
            if (url) window.open(url.origin);
        }
    };

    const { showMessage } = useToast();

    const { mutate: addCompetitorMutate } = useMutation({
        mutationFn: addCompetitorToSavedList,
        onSuccess: () => {
            invalidateQueries();
            setAddSucceed(true);
            showMessage('Success', 'Successfully added!');
        },
        onError: (error) => {
            showMessage('Error Occured', error.message);
        },
    });

    const invalidateQueries = () => {
        const queriesToInvalidate = [
            { queryKey: ['fetchPropertiesStatistics'], exact: true },
            { queryKey: ['fetchCompetitorAllProperties'] },
            { queryKey: ['fetchSavedCompetitors'] },
        ];

        queriesToInvalidate.forEach((query) => {
            queryClient.resetQueries(query);
        });
    };

    const AddComeptitorToTemp = () => {
        const newArray = Array.from(new Set([...selectedCompetitors, competitor.companyId]));

        if ((plan?.maxTrackUpCount < MAX_LIMIT && plan?.maxTrackUpCount >= newArray.length) || isPremiumUser()) {
            setSelectedCompetitors(newArray);
        } else {
            showMessage('Exceed', `You can not add more competiors than ${plan?.maxTrackUpCount}!`);
        }
    };

    const onAddClicked = () => {
        if (isAddable) {
            if (isOnboardingUser()) AddComeptitorToTemp();
            else {
                if (isSelectCompetitorOpened) AddComeptitorToTemp();
                else addCompetitorMutate(competitor.companyId);
            }
        }
    };
    useEffect(() => {
        fetchPropertiesForCompetitorSetCurrentPage(DEFAULT_PAGE);
        setAddSucceed(false);
    }, [competitor]);

    return (
        <>
            <div
                className="h-[140px] min-h-[140px] bg-cover"
                style={{
                    backgroundImage: "url('/image/competitor-background.png')",
                }}
            ></div>
            <div className="relative bg-white">
                <div className="h-[70px] relative p-[13px] md:block hidden">
                    <div className="absolute bottom-0 left-0 p-[13px]">
                        <FavAvatar url={competitor.websiteUrl!} imageSize={40} containerSize={95} />
                    </div>

                    <div className="pl-[100px] flex gap-[20px] justify-between items-center">
                        <div className="md:max-w-[350px] lg:max-w-[500px]">
                            <div className="font-semibold text-[16px] truncate" data-cy="right-panel-competitor-name">
                                {competitor?.name}
                            </div>
                            <div className="flex flex-row gap-4">
                                <div className="text-opacity-60 text-black flex items-center gap-1">
                                    <LocationOnSvg />
                                    <div
                                        className="text-[12px] truncate font-medium"
                                        data-cy="right-panel-competitor-location"
                                    >{`${competitor.city ? `${competitor.city},` : 'Unknown'} ${competitor.country || ''}`}</div>
                                </div>
                                <div className="text-opacity-60 text-black flex items-center gap-1">
                                    <FollowersSvg />
                                    <div className="text-[12px] truncate font-medium" data-cy="right-panel-competitor-followers">
                                        {`${competitor.followers ?? 0} Followers`}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-[10px] items-center">
                            {!addSucceed && isAddable && (
                                <HelloMuiButton variant="outlined" onClick={onAddClicked}>
                                    <div className="flex items-center gap-[4px]">
                                        <PlusSvg />
                                        <span className="text-my-blue">{'Add Competitor'}</span>
                                    </div>
                                </HelloMuiButton>
                            )}

                            <HelloMuiButton variant="contained" className="!bg-my-black !text-white" onClick={onExploreClicked}>
                                <div className="flex items-center gap-[4px]">
                                    <span className="text-white">{'Explore'}</span>
                                    <ExplorerCompetitorSvg />
                                </div>
                            </HelloMuiButton>
                        </div>
                    </div>
                </div>

                {/* for mobile */}
                <div className="md:hidden flex flex-col pb-[10px]">
                    <div className="relative h-[70px] flex justify-end">
                        <div className="absolute bottom-0 left-0 p-[13px] w-full flex justify-center">
                            <FavAvatar url={competitor.websiteUrl!} imageSize={40} containerSize={95} />
                        </div>
                    </div>

                    <div className="text-center flex flex-col gap-[5px] px-[10px]">
                        <div className="font-semibold text-[16px] truncate">{competitor?.name}</div>
                        <div className="text-opacity-60 text-black flex items-center justify-center">
                            <LocationOnSvg />
                            <div className="text-[12px] truncate font-medium">{`${competitor.city ? `${competitor.city},` : 'Unknown'} ${competitor.country || ''}`}</div>
                        </div>
                        <div className="flex gap-4 items-center justify-center">
                            {isAddable && (
                                <HelloMuiButton variant="outlined" onClick={onAddClicked}>
                                    <div className="flex items-center gap-[4px]">
                                        <PlusSvg />
                                        <span className="text-my-blue">{'Add Competitor'}</span>
                                    </div>
                                </HelloMuiButton>
                            )}

                            <HelloMuiButton variant="contained" className="!bg-my-black !text-white" onClick={onExploreClicked}>
                                <div className="flex items-center gap-[4px]">
                                    <span className="text-white">{'Explore'}</span>
                                    <ExplorerCompetitorSvg />
                                </div>
                            </HelloMuiButton>
                        </div>
                    </div>
                </div>
            </div>

            <Divider className="w-full" />

            <div className="p-[16px] flex flex-col gap-[8px]">
                <div className="font-medium">{`${totalCount} property listings`}</div>
                <ContentContainer className="relative">
                    {isLoading && <LoadingAnimation />}
                    {isSaved ? (
                        <CompetitorPropertyTable propertyList={propertiesToShow} onSort={() => {}} shouldShowDetail={!isPropertyDetailOpened} />
                    ) : (
                        <>
                            {!isLoading && (
                                <UnsavedCompetitorPropertyTable
                                    propertyList={propertiesToShow}
                                    lockedTitle="Add this competitor to access full details"
                                    shouldShowDetail={!isPropertyDetailOpened}
                                />
                            )}
                        </>
                    )}
                </ContentContainer>
                {isSaved && (
                    <div className="flex justify-end py-[5px] relative bg-white" data-cy="right-panel-property-pagination">
                        <CompetitorPropertyPagination
                            currentPage={fetchPropertiesForCompetitorCurrentPage}
                            setCurrentPage={setCurrentPage}
                            countPerPage={fetchPropertiesForCompetitorCountPerPage}
                            onCountPerPageChanged={onCountPerPageChanged}
                            totalCount={totalCount}
                            totalPages={totalPages}
                        />
                        {isLoading && <LoadingOverlay />}
                    </div>
                )}
            </div>
        </>
    );
};

const CompetitorDetailsModal = ({ visible, onClose, competitor }: CompetitorDetailsProps) => {
    if (!competitor) return null;

    return (
        <ModalLayout visible={visible} onClose={onClose} showCloseIcon={true}>
            <div className="md:block hidden relative bg-white">
                <div className="relative bg-white h-[40px]">
                    <span className="absolute right-2 top-2 cursor-pointer text-[18px] leading-[20px]" onClick={onClose}>
                        {'✖'}
                    </span>
                </div>
                <Content competitor={competitor} />
            </div>

            <div className="md:hidden fixed w-screen h-screen overflow-auto top-0 left-0 bg-white">
                <div className="relative bg-white h-[40px]">
                    <span className="absolute right-2 top-2 cursor-pointer text-[18px] leading-[20px]" onClick={onClose}>
                        {'✖'}
                    </span>
                </div>
                <Content competitor={competitor} />
            </div>
        </ModalLayout>
    );
};

export default CompetitorDetailsModal;
