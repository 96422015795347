import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { Property, PropertyAdditionalDetail } from 'types/Property';
import { PropertyFilterType, PropertySort } from 'types/PropertyFilters';
import { PropertyStatistics, PropertyStatisticsActionEnum } from 'types/PropertyStatistics';
import { create } from 'zustand';
// @ts-expect-error no type
import request from 'graphql-request';
import { allPropertiesForCompanyIdsQueryDocument } from 'queries/findAllPropertiesByCompanyIds';
import { getTokenFromLocalStorage } from 'util/localStorageHelper';
import { AllPropertiesResponse } from './AllPropertiesResponse';
import useUserStore from './useUserStore';

interface PropertiesState {
    loadingProperties: boolean;
    fetchPropertiesStatistics: () => Promise<PropertyStatistics>;
    propertyStatistics: PropertyStatistics;
    fetchCompetitorAllProperties: (filters: PropertyFilterType, sort: PropertySort) => Promise<AllPropertiesResponse>;
    totalPropertiesCount: number;
    countPerPage: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    totalPageCount: number;
    fetchAiProperties: (prompt: string) => Promise<Property[]>;
    fetchPropertiesForCompetitor: (competitorId: string) => Promise<Property[]>;
    fetchPropertiesForCompetitorCurrentPage: number;
    fetchPropertiesForCompetitorSetCurrentPage: (page: number) => void;
    allPropertiesForCompetitor: Property[];
    fetchPropertiesForCompetitorCountPerPage: number;
    fetchPropertiesForCompetitorSetCountPerPage: (count: number) => void;
    downloadCSVProperties: (bodyData: { filter?: PropertyFilterType; sort?: PropertySort; companyId?: string }) => Promise<string>;
    savePropertyStatistics: (propertyStatistics: { webPageId: string | undefined; action: PropertyStatisticsActionEnum }) => Promise<number>;
    defaultSort: PropertySort;
    setDefaultSort: (sort: PropertySort) => void;
    fetchCompetitorAllPropertiesSort: PropertySort;
    fetchCompetitorAllPropertiesSetSort: (sort: PropertySort) => void;
    fetchPropertyImages: (webPageId: string) => Promise<PropertyAdditionalDetail>;
    propertyImages: string[];
    loadImage: (imgUrl: string, minWidth: number, minHeight: number) => Promise<string | null>;
}

const usePropertiesStore = create<PropertiesState>((set, get) => ({
    loadingProperties: false,
    propertyStatistics: {
        totalProperties: 0,
        totalPrecentSinceYesterday: 0,
        propertiesOn: 0,
        propertiesAddedLast24Hours: 0,
        propertiesOff: 0,
    },
    totalPropertiesCount: 0,
    allCompetitorProperties: [],
    countPerPage: 50,
    currentPage: 0,
    filters: {
        country: '',
        city: '',
        type: '',
        price: { from: 0, to: 0 },
        constructionArea: { from: 0, to: 0 },
        plotArea: { from: 0, to: 0 },
        bedbath: { bedIdx: 0, bathIdx: 0 },
        listingType: '',
    },
    sort: {
        field: 'PropertyCreatedAt',
        order: 'DESC',
    },
    totalPageCount: 0,
    setCurrentPage: (page) => set({ currentPage: page }),
    fetchPropertiesForCompetitorCurrentPage: 1,
    fetchPropertiesForCompetitorSetCurrentPage: (page) => set({ fetchPropertiesForCompetitorCurrentPage: page }),
    allPropertiesForCompetitor: [],
    fetchPropertiesForCompetitorSetCountPerPage: (count) => set({ fetchPropertiesForCompetitorCountPerPage: count }),
    fetchPropertiesForCompetitorCountPerPage: 15,
    defaultSort: {
        field: 'PropertyCreatedAt',
        order: 'DESC',
    },

    setDefaultSort: (sort: PropertySort) => set({ defaultSort: sort }),
    fetchCompetitorAllPropertiesSort: {
        field: 'PropertyCreatedAt',
        order: 'DESC',
    },
    fetchCompetitorAllPropertiesSetSort: (sort: PropertySort) => set({ fetchCompetitorAllPropertiesSort: sort }),
    propertyImages: [],

    fetchPropertiesStatistics: async () => {
        try {
            const { data } = await axiosInstance.get('/properties/statistics');
            set({ propertyStatistics: data.data });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },

    fetchCompetitorAllProperties: async (filter, sort) => {
        const { isExceed, userInfo } = useUserStore.getState();
        if (isExceed() || userInfo?.savedCompetitors.length === 0) {
            set({
                totalPropertiesCount: 0,
                totalPageCount: 0,
            });

            return {
                findAllPropertiesByCompanyIds: {
                    properties: [],
                    total: 0,
                    totalPageCount: 0,
                },
            };
        }

        const { currentPage, countPerPage } = get();
        try {
            const response = await request(
                process.env.REACT_APP_BACKEND_GRAPHQL_HOST,
                allPropertiesForCompanyIdsQueryDocument,
                {
                    body: {
                        filter,
                        sort,
                    },
                    page: currentPage,
                    count: 50,
                },
                {
                    Authorization: `Bearer ${getTokenFromLocalStorage()}`,
                },
            );
            const { total } = response.findAllPropertiesByCompanyIds;
            set({
                totalPropertiesCount: total,
                totalPageCount: Math.ceil(total / countPerPage),
            });

            return response;
        } catch (error) {
            console.log('error', error);
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    fetchAiProperties: async (prompt: string) => {
        const { isExceed, userInfo } = useUserStore.getState();
        try {
            if (isExceed() || userInfo?.savedCompetitors.length === 0) {
                set({
                    totalPropertiesCount: 0,
                    totalPageCount: 0,
                });
                return [];
            }
            const { data } = await axiosInstance.post('/properties/ai-search', {
                prompt,
            });
            const { total } = data.data;
            set({
                totalPropertiesCount: total,
                totalPageCount: Math.ceil(total / get().countPerPage),
            });
            return data.data.properties;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },

    //
    fetchPropertiesForCompetitor: async (competitorId) => {
        const { fetchPropertiesForCompetitorCurrentPage, fetchPropertiesForCompetitorCountPerPage, defaultSort } = get();
        try {
            set({
                allPropertiesForCompetitor: [],
            });
            const { data } = await axiosInstance.get(
                `/properties/company/${competitorId}?page=${fetchPropertiesForCompetitorCurrentPage - 1}&count=${fetchPropertiesForCompetitorCountPerPage}&sortField=${defaultSort.field}&sortOrder=${defaultSort.order}
                `,
            );
            set({
                allPropertiesForCompetitor: data.data,
            });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    downloadCSVProperties: async (bodyData) => {
        const { filter, sort, companyId } = bodyData;
        try {
            const { data } = await axiosInstance.post(`/properties/download-csv`, {
                filter,
                sort,
                companyId,
            });
            return data.url;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    savePropertyStatistics: async (propertyStatistics) => {
        const { webPageId, action } = propertyStatistics;
        try {
            const { status } = await axiosInstance.post('/properties/save-statistic', {
                webPageId,
                action,
            });
            return status;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    fetchPropertyImages: async (webPageId) => {
        const MIN_W = 500;
        const MIN_H = 300;
        const { loadImage } = get();
        try {
            const { data } = await axiosInstance.get(`/properties/details/${webPageId}`);
            const { images } = data.data;
            const filteredImages = (await Promise.all(images.map((imgUrl: string) => loadImage(imgUrl, MIN_W, MIN_H)))).filter(Boolean);
            set({ propertyImages: filteredImages });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    loadImage: async (imgUrl: string, minWidth: number, minHeight: number): Promise<string | null> => {
        return new Promise((resolve) => {
            const img = new window.Image();
            img.src = imgUrl;
            img.onload = () => {
                if (img.width > minWidth && img.height >= minHeight) {
                    resolve(imgUrl);
                } else {
                    resolve(null);
                }
            };
            img.onerror = () => resolve(null);
        });
    },
}));

export default usePropertiesStore;
