import { ReactComponent as ProcessingSvg } from 'assets/svg/Processing.svg';

const ProcessingLabel = ({
    timestr,
}: {
    timestr: string;
    percentage?: number;
}) => {
    return (
        <div className="flex items-center gap-[10px] font-medium">
            <ProcessingSvg />
            <div className="truncate">{`Processing (${timestr})`}</div>
        </div>
    );
};

export default ProcessingLabel;
