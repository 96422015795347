import { LoadingButton } from '@mui/lab';
import { Button, ButtonProps, styled } from '@mui/material';

const HelloMuiButton = styled(Button)(({ theme }) => ({
    textTransform: 'none', // Prevents uppercase text
}));

const HelloMuiLoadingButton = styled(LoadingButton)(({ theme }) => ({
    textTransform: 'none', // Prevents uppercase text
}));

interface RectMuiButtonProps extends ButtonProps {
    width?: number;
}

const RectMuiButton = (props: RectMuiButtonProps) => {
    const { width = 36 } = props;
    return (
        <HelloMuiButton
            variant="outlined"
            className={`!px-0 border !border-my-gray border-opacity-60 !min-w-max`}
            sx={{
                width: width,
                height: width,
            }}
            {...props}
        >
            {props.children}
        </HelloMuiButton>
    );
};
export { HelloMuiButton, HelloMuiLoadingButton, RectMuiButton };
