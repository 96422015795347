import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import axiosInstance from 'config/axiosConfig';

async function getMinAndMaxValuesForProperties() {
    try {
        const { data } = await axiosInstance('/properties/min-max-values');
        return data.data;
    } catch (error) {
        if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
        throw new Error(error as string);
    }
}

export const useMinAndMaxValuesForProperties = () => {
    return useQuery({
        queryKey: ['min-max-values'],
        queryFn: getMinAndMaxValuesForProperties,
        refetchOnWindowFocus: false,
        staleTime: Infinity,
    });
};
