import { SavedFilter } from 'types/PropertyFilters';

const LOCAL_STORAGE_KEYS = {
    TOKEN: 'token',
    FLAGS: 'flags',
    REFRESH_TOKEN: 'refreshToken',
    FILTER_LIST: 'filterList',
};

export const logoutUserAndClearLocalStorage = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.FLAGS);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

    window.location.href = '/signin';
};

export const saveAccessAndRefreshTokenToLocalStorage = (accessToken: string, refreshToken: string) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, accessToken);
    localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken);
};

export const getTokenFromLocalStorage = () => localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

export const getRefreshTokenFromLocalStorage = () => localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

export const saveFiltersToLocalStorage = (filters: SavedFilter[]) => localStorage.setItem('filterList', JSON.stringify(filters));

export const getFiltersFromLocalStorage = () => JSON.parse(localStorage.getItem('filterList')!);
