import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Button, Checkbox } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FavAvatar, HelloMuiButton } from 'components';
import { ProcessingLabel, UnableExtractLabel } from 'components/Labels';
import { useToast } from 'components/Providers/ToastProvider';
import _ from 'lodash';
import { useModals } from 'pages/MainProvider';
import { useMemo, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { queryClient } from 'routes';
import useCompetitorsStore from 'store/useCompetitorsStore';
import useUserStore from 'store/useUserStore';
import styled from 'styled-components';
import { Agent } from 'types/Agent';
import { MAX_LIMIT } from 'util/Constants';
import { GetCountryCode } from 'util/Funcs';
import { PlanTypes } from 'util/Types';
import { OneLetterAvatar } from '../LetterAvatar';
import LoadingAnimation from '../Loading/LoadingAnimation';
import CompetitorPagination from '../Pagination/CompetitorPagination';
import AddedByYouLabel from './AddedByYou';
import styles from './CompetitorTable.module.css';

const ContentContainter = styled.div`
    height: calc(100vh - 320px);
    @media (max-width: 1280px) {
        height: calc(100vh - 390px);
    }
`;

const CompetitorTable = () => {
    const { showPreviewCompetitorModal } = useModals();
    const { userInfo, getUserPlan, isPremiumUser } = useUserStore();

    const isOnboardingUser = !userInfo?.subscriptionId;

    const {
        fetchAllCompetitors,
        competitors,
        totalPages,
        currentPage,
        setPage,
        filter,
        selectedCompetitors,
        setSelectedCompetitors,
        addCompetitors,
        addAllCompetitors,
    } = useCompetitorsStore();

    const [competitorId, setCompetitorId] = useState<string>('');

    const { showMessage } = useToast();

    const plan = getUserPlan();

    const showCompetitorDetailsModal = (c: Agent) => {
        showPreviewCompetitorModal(c);
    };

    const { isLoading, isError, error, isRefetching } = useQuery({
        queryKey: ['fetchAllCompetitors', currentPage, filter],
        queryFn: fetchAllCompetitors,
        retry: 2,
        retryDelay: 1000,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
    });

    const invalidateQueries = () => {
        const queriesToInvalidate = [
            { queryKey: ['fetchPropertiesStatistics'], exact: true },
            { queryKey: ['fetchCompetitorAllProperties'] },
            { queryKey: ['fetchSavedCompetitors'] },
        ];

        queriesToInvalidate.forEach((query) => {
            queryClient.resetQueries(query);
        });
    };

    const { isPending, mutate } = useMutation({
        mutationKey: ['addCompetitors'],
        mutationFn: addCompetitors,
        onError: (error) => {
            showMessage('Error', error.message);
        },
        onSuccess: () => {
            invalidateQueries();
            showMessage('Success', 'Competitors saved successfully');
        },
    });

    const { isPending: isSaveAllPending, mutate: mutateSaveAll } = useMutation({
        mutationKey: ['addAllCompetitors'],
        mutationFn: addAllCompetitors,
        onError: (error) => {
            showMessage('Error', error.message);
        },
        onSuccess: async () => {
            invalidateQueries();
            showMessage('Success', 'All Competitors saved successfully');
        },
    });

    const saveButtonDisabled = useMemo(() => {
        return (
            _.isEqual(_.sortBy(userInfo?.savedCompetitors), _.sortBy(selectedCompetitors)) ||
            selectedCompetitors.length === 0 ||
            plan.maxSwapCount - userInfo?.swapsThisMonth! === 0
        );
    }, [userInfo?.savedCompetitors, selectedCompetitors]);

    function validateCompetitorSelection(plan: PlanTypes, newSelectedCompetitors: string[], MAX_LIMIT: number) {
        if (plan?.maxTrackUpCount < MAX_LIMIT && plan?.maxTrackUpCount < newSelectedCompetitors.length) {
            showMessage('Error', `You cannot select more than ${plan.maxTrackUpCount} competitors!`);
            return false;
        }
        return true;
    }

    const onCompetitorClicked = (competitor: Agent, isChecked: boolean) => {
        const updatedSelectedCompetitors = isChecked
            ? [...selectedCompetitors, competitor.companyId]
            : selectedCompetitors.filter((item) => item !== competitor.companyId);

        if (isChecked && !validateCompetitorSelection(plan, updatedSelectedCompetitors, MAX_LIMIT)) return;

        setSelectedCompetitors(updatedSelectedCompetitors);
    };

    const onSelectAllButtonClicked = () => {
        mutateSaveAll();
    };

    function handleSaveSelectedCompetitors() {
        if (!validateCompetitorSelection(plan, selectedCompetitors, MAX_LIMIT)) return;
        mutate(selectedCompetitors);
    }

    if (isError) {
        showMessage('Error', error.message);
    }

    return (
        <>
            <div className={`relative ${isLoading || isRefetching ? 'opacity-40' : ''}`}>
                {(isLoading || isRefetching) && <LoadingAnimation />}

                <div>
                    <div className={`md:block hidden text-[12px] w-full font-medium`}>
                        <div
                            className={`grid grid-cols-7 gap-[5px] rounded-t-[10px] border border-b-0 border-my-gray overflow-hidden bg-[#D9D9D9] bg-opacity-20 font-semibold h-[50px] ${styles['tr']}`}
                        >
                            <div className="col-span-3 pl-[50px] my-auto">{'Name'}</div>
                            <div className="my-auto">{'COUNTRY'}</div>
                            <div className="my-auto">{'CITY'}</div>
                            <div className="my-auto truncate">{'LISTINGS'}</div>
                            <div></div>
                        </div>

                        <ContentContainter className={`border-x border-b border-my-gray overflow-auto ${styles['tbody']}`}>
                            {competitors.map((competitor, index) => {
                                const labelId = `enhanced-table-checkbox-${index}`;
                                const checked: boolean = selectedCompetitors.includes(competitor.companyId);

                                return (
                                    <div key={index} id={labelId} className={`grid grid-cols-7 gap-[5px] ${styles['tr']}`}>
                                        <div className="col-span-3 flex ga-[10px] justify-between items-center">
                                            <div className="flex items-center flex-grow gap-[10px]">
                                                <div data-cy="select-competitor">
                                                    <Checkbox
                                                        checked={checked}
                                                        color="primary"
                                                        onChange={(e) => onCompetitorClicked(competitor, e.target.checked)}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </div>
                                                {competitor.websiteUrl ? (
                                                    <FavAvatar url={competitor.websiteUrl} imageSize={16} containerSize={28} />
                                                ) : (
                                                    <OneLetterAvatar title={competitor.name} />
                                                )}

                                                <div>
                                                    <div className={`${checked ? 'text-my-green' : ''} font-medium truncate`}>{competitor.name} </div>
                                                    {competitor.followers > 0 && <div className="text-[10px]">{`${competitor.followers} followers`}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        {competitor.status?.status !== 'done' ? (
                                            <div className="col-span-3">
                                                {competitor.status?.status === 'failed' && <UnableExtractLabel />}

                                                {competitor.status?.status === 'in-progress' && (
                                                    <ProcessingLabel percentage={competitor.status?.percentage} timestr={'2hr left'} />
                                                )}
                                            </div>
                                        ) : (
                                            <>
                                                <div className="flex gap-[5px] flex-wrap items-center my-auto ">
                                                    {competitor.country && (
                                                        <ReactCountryFlag
                                                            svg
                                                            countryCode={GetCountryCode(competitor.country || '') || ''}
                                                            style={{
                                                                width: '2em',
                                                                height: '2em',
                                                            }}
                                                        />
                                                    )}
                                                    <span>{competitor.country}</span>
                                                </div>

                                                <div className="truncate my-auto ">{competitor.city}</div>
                                                <div className="truncate my-auto ">{competitor.propertyCount || 0}</div>
                                            </>
                                        )}
                                        <div className="flex justify-end items-center gap-[4px] flex-wrap">
                                            {competitor.addedById === userInfo?.userId && <AddedByYouLabel />}
                                            <HelloMuiButton className="!h-[24px]" onClick={() => showCompetitorDetailsModal(competitor)}>
                                                View Profile
                                            </HelloMuiButton>
                                        </div>
                                    </div>
                                );
                            })}
                        </ContentContainter>
                    </div>

                    <div className="md:hidden rounded-[10px] overflow-hidden">
                        <div className="bg-my-gray px-[20px] h-[50px] flex items-center">{'Name'}</div>
                        <div className={`${styles['mobileitems']}`}>
                            {competitors.map((competitor, index) => {
                                const labelId = `competitor-${index}`;
                                const addedUserid = competitor.addedById;

                                return (
                                    <div key={index} className={`${styles['mobileitem']}`}>
                                        <div id={labelId} key={index} className={`flex gap-[10px] items-center justify-between min-h-[48px] py-[5px]`}>
                                            <div className="flex gap-[10px] items-center">
                                                <Checkbox
                                                    checked={selectedCompetitors.includes(competitor.companyId)}
                                                    color="primary"
                                                    onChange={(e) => onCompetitorClicked(competitor, e.target.checked)}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                                {competitor.websiteUrl ? (
                                                    <FavAvatar url={competitor.websiteUrl} imageSize={16} containerSize={28} />
                                                ) : (
                                                    <OneLetterAvatar title={competitor.name} />
                                                )}
                                                <div>{competitor.name}</div>
                                            </div>

                                            <div
                                                className="border border-my-blue min-w-[42px] w-[42px] h-[42px] flex items-center justify-center"
                                                onClick={() => setCompetitorId(competitor.companyId)}
                                            >
                                                <span className="text-my-blue">
                                                    {competitorId === competitor.companyId ? (
                                                        <ArrowDropUpIcon fontSize="large" />
                                                    ) : (
                                                        <ArrowDropDownIcon fontSize="large" />
                                                    )}
                                                </span>
                                            </div>
                                        </div>

                                        {competitorId === competitor.companyId && (
                                            <div className="px-[20px] py-[10px] text-[14px] bg-my-gray bg-opacity-30">
                                                {competitor.status?.status === 'failed' && <UnableExtractLabel />}

                                                {competitor.status?.status === 'in-progress' && (
                                                    <ProcessingLabel percentage={competitor.status?.percentage} timestr={'2hr left'} />
                                                )}

                                                <div className="flex items-center justify-between">
                                                    <div>Country</div>
                                                    <div className="flex items-center flex-wrap gap-[5px]">
                                                        {competitor.country && (
                                                            <ReactCountryFlag
                                                                svg
                                                                countryCode={GetCountryCode(competitor.country || '') || ''}
                                                                style={{
                                                                    width: '2em',
                                                                    height: '2em',
                                                                }}
                                                            />
                                                        )}
                                                        <span>{competitor.country}</span>
                                                    </div>
                                                </div>

                                                <div className="flex items-center justify-between">
                                                    <div>City</div>
                                                    <div>{competitor.city}</div>
                                                </div>

                                                <div className="flex items-center justify-between">
                                                    <div>Property Listings</div>
                                                    <div>{competitor.propertyCount}</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className={`flex md:flex-row flex-col gap-[10px] ${isOnboardingUser ? 'justify-center' : 'justify-between'} pt-[10px]`}>
                    <div className="md:m-0 mx-auto flex items-center gap-[5px] flex-col md:flex-row">
                        <CompetitorPagination totalPages={totalPages} currentPage={currentPage} onPageChange={setPage} />
                    </div>

                    <div className="flex md:flex-row flex-col gap-[5px]">
                        {isPremiumUser() && (
                            <Button
                                onClick={onSelectAllButtonClicked}
                                className={`!rounded-[8px] !h-[42px] !bg-my-blue !text-white !px-[20px]`}
                                disabled={isSaveAllPending}
                            >
                                {isSaveAllPending ? 'Saving...' : 'Save All'}
                            </Button>
                        )}

                        {!isOnboardingUser && (
                            <Button
                                onClick={handleSaveSelectedCompetitors}
                                className={`!rounded-[8px] !h-[42px] ${saveButtonDisabled || isPending ? '!bg-my-gray' : '!bg-my-blue'}`}
                                disabled={saveButtonDisabled || isPending}
                            >
                                <span className={`px-[20px] text-[12px] ${saveButtonDisabled ? '!text-my-purple' : '!text-white'}`}>
                                    {isPending ? 'Saving...' : 'Save'}
                                </span>
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default CompetitorTable;
