import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { LocationType } from 'types/Azure';
import { DEFAULT_FILTER } from 'util/Constants';
import { CompetitorPropertyResponseDTO, FilterType, PropertyDTO } from 'util/Types';
import { create } from 'zustand';
import useUserStore from './useUserStore';

export const COUNT_PER_PAGE = 20;
interface PropertyState {
    drawingFlag: boolean;
    setDrawingFlag: (f: boolean) => void;
    totalProperties: PropertyDTO[];

    totalCount: number;
    propertiesInPage: PropertyDTO[];
    filter: FilterType;
    setFilter: (f: FilterType) => void;
    coordinates: LocationType[];
    currentPage: number;
    setCurrentPage: (page: number) => void;
    init: () => void;
    getMapSearch: (_points: { lat: number; lng: number }[]) => Promise<{ properties: PropertyDTO[]; total: number }>;
    getMapSearchWithPagination: (value: { coordinates: { lat: number; lng: number }[]; count: number; page: number }) => Promise<PropertyDTO[]>;
    getAISearch: (_query: string) => Promise<CompetitorPropertyResponseDTO>;
}

const useMapPropertyStore = create<PropertyState>((set, get) => ({
    drawingFlag: false,
    setDrawingFlag: (f: boolean) => {
        set({ drawingFlag: f });
    },

    totalProperties: [],
    totalCount: 0,
    propertiesInPage: [],

    filter: DEFAULT_FILTER,
    setFilter: (f: FilterType) => {
        set({ filter: f });
    },

    coordinates: [],

    currentPage: 0,
    setCurrentPage: (page: number) => {
        set({ currentPage: page });
    },

    init: () => {
        set({
            totalProperties: [],
            totalCount: 0,
            propertiesInPage: [],
            filter: DEFAULT_FILTER,
            coordinates: [],
            currentPage: 0,
        });
    },
    getMapSearchWithPagination: async (value) => {
        const { coordinates, count, page } = value;

        set({ propertiesInPage: [] });
        const { isExceed } = useUserStore.getState();

        const isExceedFlag = isExceed();
        if (isExceedFlag) return [];
        if (!coordinates || coordinates.length < 3) return [];

        try {
            const { data: result } = await axiosInstance.post(`/properties/map-search-with-pagination?count=${count}&page=${page}`, {
                coordinates,
            });

            const data: PropertyDTO[] = result.data.properties;
            const totalCount = result.data.totalCount;
            set({ propertiesInPage: data, totalCount: totalCount });
            return data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },

    getMapSearch: async (coordinates: { lat: number; lng: number }[]) => {
        const { init } = get();
        init();
        const { isExceed } = useUserStore.getState();

        const isExceedFlag = isExceed();
        if (isExceedFlag)
            return {
                properties: [],
                total: 0,
            };

        try {
            const { data: result } = await axiosInstance.post('/properties/map-search', {
                coordinates: coordinates,
            });

            const data: PropertyDTO[] = result.data.properties;
            const totalCount = result.data.totalCount;

            set({
                totalProperties: data,
                coordinates: coordinates,
                totalCount: totalCount,
                drawingFlag: false,
            });

            return {
                properties: data,
                total: totalCount,
            };
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    getAISearch: async (_query: string) => {
        const { isExceed } = useUserStore.getState();
        const isExceedFlag = isExceed();
        if (isExceedFlag) return [];

        try {
            const { data: responseData } = await axiosInstance.post('/properties/ai-search', { prompt: _query });
            const { data } = responseData;
            return data;
        } catch {
            return [];
        }
    },
}));

export default useMapPropertyStore;
