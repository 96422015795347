import React from 'react';
import { Navigate } from 'react-router-dom';
import { GetTokenFromStorage } from 'util/AppApi';
import { ROUTES } from 'util/Constants';

export const AuthRedirect = ({
    children,
}: Readonly<{ children?: React.ReactNode }>) => {
    const userToken = GetTokenFromStorage();

    if (userToken) {
        return <Navigate to={ROUTES.MAIN} />;
    }

    return <>{children}</>;
};
