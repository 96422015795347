// @ts-expect-error notype
import { gql } from 'graphql-request';

export const allPropertiesForCompanyIdsQueryDocument = gql`
    query findAllPropertiesByCompanyIdsQuery($body: PropertiesByCompanyIdsGraphQLDto!, $count: Int!, $page: Int!) {
        findAllPropertiesByCompanyIds(body: $body, count: $count, page: $page) {
            properties {
                propertyId
                description
                webPageUrl
                webPageId
                title
                imageUrls
                details {
                    propertyType
                    listingType
                    price
                    sizeLand
                    sizePlot
                    sizeLiving
                    features
                    currency
                    bedrooms
                    bathrooms
                }
                createdAt
                companyName
                address {
                    Country
                    City
                    State
                    StreetAddress
                    Neighborhood
                }
            }
            total
        }
    }
`;
