import axios from 'axios';
import { AzureLocation } from 'types/Azure';

export interface AzureLocationResult {
    locations: AzureLocation[];
    error?: string;
}

export const getAzureLocations = async (
    _query: string,
): Promise<AzureLocationResult> => {
    try {
        const response = await axios.get(
            `https://atlas.microsoft.com/search/address/json`,
            {
                params: {
                    'api-version': '1.0',
                    query: _query,
                    'subscription-key': process.env.REACT_APP_AZURE_MAPS_KEY,
                    language: 'en',
                },
            },
        );

        const results: AzureLocation[] = response.data.results.map(
            (result: any) => ({
                title: `${result.address.freeformAddress}, ${result.address.countryCodeISO3 || ''}`,
                country: result.address.country,
                city: result.address.municipality,
                position: {
                    lat: result.position.lat,
                    lng: result.position.lon,
                },
            }),
        );

        return { locations: results };
    } catch (error) {
        let errorMessage = 'An unknown error occurred.';

        if (axios.isAxiosError(error)) {
            errorMessage =
                error.response?.data.message ||
                'Failed to fetch locations from the server.';
        }

        return { locations: [], error: errorMessage };
    }
};
