import { Divider } from '@mui/material';
import { ReactComponent as TableNextSvg } from 'assets/svg/Table-Next.svg';
import styled from 'styled-components';

interface PropertyPaginationProps {
    handlePageChange: (page: number) => void;
    half?: boolean;
    totalPageCount?: number;
    currentPage?: number;
}
const PageItemDiv = styled.div`
    width: 40px;
    height: 40px;
    line-height: 40px;

    @media (max-width: 500px) {
        width: 30px;
    }
`;

const NextButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <div
            className="!w-fit md:flex hidden items-center gap-2 h-[41px] rounded-[5px] md:px-[35px] px-[12px] py-[10px] cursor-pointer"
            onClick={onClick}
            data-cy="property-pagination-next"
        >
            <span className="font-medium leading-[21px] normal-case md:block hidden">Next</span>
            <TableNextSvg />
        </div>
    );
};

const PrevButton = ({ onClick }: { onClick: () => void }) => {
    return (
        <div
            className="!w-fit md:flex hidden items-center gap-2 h-[41px] rounded-[5px] md:px-[35px] px-[12px] py-[10px] cursor-pointer"
            onClick={onClick}
            data-cy="property-pagination-prev"
        >
            <TableNextSvg className="rotate-180" />
            <span className="font-medium leading-[21px] normal-case md:block hidden">Prev</span>
        </div>
    );
};

const PageItem = ({
    title,
    sep,
    selected = false,
    onClick,
    'data-cy': dataCy,
}: {
    title: string;
    sep: boolean;
    selected?: boolean;
    onClick?: () => void;
    'data-cy'?: string;
}) => {
    return (
        <>
            <PageItemDiv
                className={`relative text-center cursor-pointer ${selected && 'outline outline-my-blue outline-1 bg-my-blue bg-opacity-10'}`}
                onClick={onClick}
                data-cy={dataCy}
            >
                {title}
            </PageItemDiv>
            {sep && <Divider orientation="vertical" className="!h-[38px] w-[1px]" />}
        </>
    );
};

const PropertyPagination: React.FC<PropertyPaginationProps> = ({ handlePageChange: onPageChanged, half = false, currentPage = 0, totalPageCount = 0 }) => {
    const isPrevButtonVisible = currentPage > 0 && totalPageCount > 1;
    const isNextButtonVisible = currentPage < totalPageCount - 1;
    const sw = half ? window.screen.width / 2 : window.screen.width;
    const boundaryCountLast = 2;
    const boundaryCountFirst = Math.min(Math.floor((sw > 600 ? (sw - 260) / 60 : (sw - 100) / 38) - boundaryCountLast), 7);

    const onNext = () => {
        const nextpage = currentPage + 1;
        if (nextpage < totalPageCount && nextpage >= 0) handlePageChange(nextpage);
    };

    const onPrev = () => {
        const nextpage = currentPage - 1;
        if (nextpage < totalPageCount && nextpage >= 0) handlePageChange(nextpage);
    };

    const handlePageChange = (page: number) => {
        if (page < totalPageCount && page >= 0) {
            onPageChanged(page);
        }
    };

    const isDotEnabled = totalPageCount > boundaryCountFirst + boundaryCountLast;

    return (
        <div className="p-2">
            <div className="flex justify-center items-center border border-my-gray border-opacity-60 rounded-[5px]">
                {isDotEnabled && (
                    <>
                        {isPrevButtonVisible && <PrevButton onClick={onPrev} />}
                        <Divider orientation="vertical" className="!h-[38px] w-[1px]" />
                    </>
                )}

                {isDotEnabled ? (
                    <>
                        {[...Array(boundaryCountFirst)].map((item, index) => {
                            return (
                                <PageItem
                                    title={`${index + 1}`}
                                    sep={true}
                                    onClick={() => handlePageChange(index)}
                                    key={index}
                                    selected={currentPage === index}
                                    data-cy={`property-pagination-item-${index}`}
                                />
                            );
                        })}
                        <PageItem
                            title={`...`}
                            sep={true}
                            selected={currentPage >= boundaryCountFirst && currentPage < totalPageCount - boundaryCountFirst - 1}
                            data-cy={`property-pagination-item-${boundaryCountFirst}`}
                        />
                        {[...Array(boundaryCountLast)].map((item, index) => {
                            const id = totalPageCount + index - boundaryCountLast + 1;
                            return (
                                <PageItem
                                    title={`${id}`}
                                    sep={true}
                                    onClick={() => handlePageChange(id - 1)}
                                    key={index}
                                    selected={currentPage === id - 1}
                                    data-cy={`property-pagination-item-${id}`}
                                />
                            );
                        })}
                    </>
                ) : (
                    <>
                        {[...Array(totalPageCount)].map((item, index) => {
                            return (
                                <PageItem
                                    title={`${index + 1}`}
                                    sep={index != totalPageCount - 1}
                                    onClick={() => handlePageChange(index)}
                                    key={index}
                                    selected={currentPage === index}
                                    data-cy={`property-pagination-item-${index}`}
                                />
                            );
                        })}
                    </>
                )}

                {isDotEnabled && isNextButtonVisible && <NextButton onClick={onNext} />}
            </div>
        </div>
    );
};

export default PropertyPagination;
