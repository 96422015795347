import { getDomainFavLink } from 'util/Funcs';
import { useState, useEffect } from 'react';

interface FavAvatarProps {
    url: string;
    className?: string;
    imageSize: number;
    containerSize: number;
}

const isValidUrl = (url: string) => {
    try {
        new URL(url);
        return true;
    } catch {
        return false;
    }
};

const FavAvatar = (props: FavAvatarProps) => {
    const { url, imageSize, containerSize, className } = props;
    const [isUrlValid, setIsUrlValid] = useState(false);

    useEffect(() => {
        setIsUrlValid(isValidUrl(url));
    }, [url]);

    if (!isUrlValid) {
        return null;
    }

    return (
        <div
            className={`rounded-full border-opacity-80 flex justify-center items-center overflow-hidden bg-white ${className || 'border border-my-gray '}`}
            title={url}
            style={{
                width: `${containerSize}px`,
                height: `${containerSize}px`,
                minWidth: `${containerSize}px`,
            }}
        >
            <img
                alt={'FAV ICON'}
                src={getDomainFavLink(url)}
                style={{
                    width: `${imageSize}px`,
                    height: `${imageSize}px`,
                    minWidth: `${imageSize}px`,
                }}
                className="rounded-full overflow-hidden"
            />
        </div>
    );
};

export default FavAvatar;
