import { useEffect, useState } from 'react';
import DefaultDropDown from './DefaultDropDown';

const titles = ['Any', '1', '2', '3', '4', '5', '6', '7', '>7'];

const NumSelector = ({
    onCallback,
    selectedIndex,
    'data-cy': dataCy,
}: {
    onCallback: (value: number) => void;
    selectedIndex: number;
    'data-cy'?: string;
}) => {
    return (
        <div className="flex pb-[20px]">
            {titles.map((title, index) => {
                let borderClasses = 'border-t border-r border-b';
                let borderColor = 'border-my-gray';
                let roundedClass = '';
                if (index === 0) {
                    roundedClass = 'rounded-l';
                    borderClasses = 'border';
                }
                if (index === titles.length - 1) roundedClass = 'rounded-r';

                if (index === selectedIndex) {
                    borderColor = 'border-my-green bg-my-gray';
                }
                if (index === selectedIndex - 1) {
                    borderColor = 'border-r-my-green';
                }

                return (
                    <div
                        key={index}
                        className={`${borderClasses} ${borderColor} ${roundedClass} text-center cursor-pointer hover:bg-my-gray w-[36px] h-[36px] leading-[36px]`}
                        onClick={() => onCallback(index)}
                        data-cy={dataCy}
                    >
                        {title}
                    </div>
                );
            })}
        </div>
    );
};

const BedBathFilter = ({
    onCallback,
    bedIdx,
    bathIdx,
}: {
    onCallback: (bed: number, bath: number) => void;
    className?: string;
    bedIdx: number;
    bathIdx: number;
}) => {
    const [bedCountIndex, setBedCountIndex] = useState(bedIdx || 0);
    const [bathCountIndex, setBathCountIndex] = useState(bathIdx || 0);
    const [title, setTitle] = useState('');

    useEffect(() => {
        setBedCountIndex(bedIdx || 0);
    }, [bedIdx]);

    useEffect(() => {
        setBathCountIndex(bathIdx || 0);
    }, [bathIdx]);

    useEffect(() => {
        setTitle(
            `${titles[bedCountIndex]} Bed, ${titles[bathCountIndex]} Bath`,
        );
    }, [bedCountIndex, bathCountIndex]);

    const onClearClicked = () => {
        setBathCountIndex(0);
        setBedCountIndex(0);

        onCallback(0, 0);
    };

    return (
        <DefaultDropDown
            Title={title}
            IsEmpty={bedCountIndex + bathCountIndex === 0}
            onClearClicked={onClearClicked}
        >
            <div className="px-[15px] w-fit flex flex-col gap-[12px]">
                <div className="h-[40px] leading-[40px]">
                    Number of Bedrooms
                </div>
                <NumSelector
                    onCallback={(newBedCount) => {
                        setBedCountIndex(newBedCount);
                        onCallback(newBedCount, bathCountIndex);
                    }}
                    selectedIndex={bedCountIndex}
                    data-cy="bed-filter"
                />
                <div className="h-[40px] leading-[40px]">
                    Number of Bathrooms
                </div>
                <NumSelector
                    onCallback={(newBathCount) => {
                        setBathCountIndex(newBathCount);
                        onCallback(bedCountIndex, newBathCount);
                    }}
                    selectedIndex={bathCountIndex}
                    data-cy="bath-filter"
                />
            </div>
        </DefaultDropDown>
    );
};

export default BedBathFilter;
