import { Button, Divider, Slider } from '@mui/material';

import { useEffect, useRef, useState } from 'react';
import { formatNumber } from 'util/Funcs';
import DefaultDropDown from './DefaultDropDown';

interface RangeFilterProps {
    title: string;
    value: [number, number];
    limit: [number, number];
    step?: number;
    unit: string;
    onChanged: (value: [number, number]) => void;
    'data-cy'?: string;
}

const RangeFilter = (props: RangeFilterProps) => {
    const {
        step = 100,
        title,
        unit,
        limit,
        value,
        onChanged,
        'data-cy': dataCy,
    } = props;

    const [headerTitle, setHeaderTitle] = useState<string>(title);
    const [tempValue, setTempValue] = useState<[number, number]>([0, 0]);
    const childRef = useRef<{ closePopup: () => void }>(null);

    useEffect(() => {
        setTempValue(value);

        const caption = `${value[0]}${unit} ~ ${value[1]}${unit}`;
        setHeaderTitle(value[0] + value[1] === 0 ? title : caption);
    }, [value]);

    const handleChange = (_: Event, newValue: number | number[]) => {
        if (Array.isArray(newValue)) setTempValue([newValue[0], newValue[1]]);
    };

    const onInputChange = (e: any, idx: number) => {
        let val = ~~e.target.value.replace(/\./g, '');
        val = Math.min(limit[1], val);
        val = Math.max(limit[0], val);

        if (idx === 0)
            setTempValue([Math.min(val, tempValue[1]), tempValue[1]]);
        if (idx === 1)
            setTempValue([tempValue[0], Math.max(val, tempValue[0])]);
    };

    const onApplyClicked = () => {
        onChanged([...tempValue]);

        if (childRef.current) {
            childRef.current.closePopup();
        }
    };

    const onClearClicked = () => {
        setTempValue([0, 0]);
        onChanged([0, 0]);
    };

    return (
        <DefaultDropDown
            ref={childRef}
            Title={<div className="truncate">{headerTitle}</div>}
            IsEmpty={value[0] + value[1] === 0}
            onClearClicked={onClearClicked}
        >
            <div className="flex flex-col gap-[20px] text-[12px] mt-[5px]">
                <div className="h-[60px] flex flex-col justify-between px-[20px] ">
                    <div className="text-[20px] font-semibold">{title}</div>
                    <div className="font-medium text-[14px]">
                        {'Select Range'}
                    </div>
                </div>
                <div className="px-[20px] ">
                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={tempValue}
                        onChange={handleChange}
                        getAriaValueText={(v) => `${v}${unit}`}
                        max={limit[1]}
                        min={limit[0]}
                        size="small"
                        step={step}
                        valueLabelDisplay="on"
                        valueLabelFormat={(v) => `${formatNumber(v)}${unit}`}
                        slotProps={{
                            valueLabel: {
                                className: 'bg-black',
                            },
                        }}
                        data-cy={dataCy}
                    />
                </div>

                <div className="grid grid-cols-2 gap-[26px] px-[20px]">
                    <div>
                        <div className="font-medium pb-[8px]">Minimum</div>
                        <div className="flex gap-[5px] justify-between border border-my-gray rounded-[5px] h-[36px] items-center px-[5px]">
                            <input
                                type="text"
                                className="w-full px-[5px]"
                                value={formatNumber(tempValue[0])}
                                onChange={(e) => onInputChange(e, 0)}
                                data-cy="min-input"
                            />
                            <span className="font-bold">{unit}</span>
                        </div>
                    </div>

                    <div>
                        <div className="font-medium pb-[8px]">Maximum</div>
                        <div className="flex gap-[5px] justify-between border border-my-gray rounded-[5px] h-[36px] items-center px-[5px]">
                            <input
                                type="text"
                                className="w-full px-[5px]"
                                value={formatNumber(tempValue[1])}
                                onChange={(e) => onInputChange(e, 1)}
                                data-cy="max-input"
                            />
                            <span className="font-bold">{unit}</span>
                        </div>
                    </div>
                </div>

                <Divider className="w-full" />

                <div className="pb-[16px] flex justify-between px-[20px]">
                    <Button onClick={onClearClicked} data-cy="clear-button">
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onApplyClicked}
                        data-cy="apply-button"
                    >
                        Apply
                    </Button>
                </div>
            </div>
        </DefaultDropDown>
    );
};

export default RangeFilter;
