import MapPropertyPanel from 'components/MapPropertyPanel';
import ModalLayout from './ModalLayout';
import { Divider } from '@mui/material';
import { ModalLayoutProps } from '.';

const MapPropertyTableModal = ({ visible, onClose }: ModalLayoutProps) => {
    return (
        <ModalLayout visible={visible} onClose={onClose} showCloseIcon={true}>
            <div className="fixed w-screen h-screen left-0 top-0 bg-white">
                {/* header */}
                <div className="text-my-blue font-medium p-[8px] flex justify-between">
                    <span>{'Map Search'}</span>

                    <span
                        onClick={onClose}
                        className="cursor-pointer text-[18px]"
                    >
                        {'✖'}
                    </span>
                </div>

                <Divider />
                <div style={{ height: 'calc(100vh - 45px)' }}>
                    <MapPropertyPanel />
                </div>
            </div>
        </ModalLayout>
    );
};

export default MapPropertyTableModal;
