import { ReactComponent as CompetitorChartSvg } from 'assets/svg/Main/TopTab-Competitor-Chart.svg';
import { ReactComponent as OverviewSvg } from 'assets/svg/Main/TopTab-Overview.svg';
import { ReactComponent as SaveListingSvg } from 'assets/svg/Main/TopTab-SaveListing-Heart.svg';
import { useNavigate } from 'react-router-dom';
import useUserStore from 'store/useUserStore';
import { useUtilsStore } from 'store/useUtilsStore';
import styled from 'styled-components';
import { ROUTES } from 'util/Constants';

const CustomTopTab = styled.div`
    display: flex;
    cursor: pointer;
    gap: 10px;
    height: 100%;
    align-items: center;
`;

const TopTabs = () => {
    const { topTabIndex, setTopTabIndex } = useUtilsStore();

    const navigate = useNavigate();
    const { userInfo } = useUserStore();

    const onboardingDidNotFinish = !userInfo?.accountType || !userInfo?.subscriptionId;
    const browserPath = window.location.pathname;

    const onTopTabClicked = (index: number) => {
        if (browserPath !== ROUTES.MAIN) navigate(ROUTES.MAIN);
        setTopTabIndex(index);
    };

    if (onboardingDidNotFinish) return null;

    return (
        <div className="rounded-[8px] bg-my-gray bg-opacity-40 text-[12px] font-medium leading-[24px] p-[4px] h-[48px] flex gap-[10px] items-center">
            <CustomTopTab
                className={`md:px-[20px] px-[5px] ${browserPath === ROUTES.MAIN && topTabIndex === 0 ? 'bg-white rounded-[6px]' : 'opacity-50'}`}
                data-cy="overview-tab"
                onClick={() => onTopTabClicked(0)}
            >
                <OverviewSvg />
                <div>Overview</div>
            </CustomTopTab>
            <CustomTopTab
                className={`md:px-[20px] px-[5px] ${browserPath === ROUTES.MAIN && topTabIndex === 1 ? 'bg-white rounded-[6px]' : 'opacity-50'}`}
                onClick={() => onTopTabClicked(1)}
                data-cy="competitors-tab"
            >
                <CompetitorChartSvg />
                <div>Competitors</div>
            </CustomTopTab>
            <CustomTopTab
                className={`md:px-[20px] px-[5px] ${browserPath === ROUTES.MAIN && topTabIndex === 2 ? 'bg-white rounded-[6px]' : 'opacity-50'}`}
                onClick={() => onTopTabClicked(2)}
                data-cy="savelisting-tab"
            >
                <SaveListingSvg />
                <div className="truncate">{'Save Listings'}</div>
            </CustomTopTab>
        </div>
    );
};

export default TopTabs;
