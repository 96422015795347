interface BottomCardProps {
    title: string;
    content: string;
}
const BottomCard = ({ title, content }: BottomCardProps) => {
    return (
        <div className="flex flex-col gap-[16px]">
            <div className="font-semibold text-[18px]">{title}</div>
            <div className="text-[14px] text-my-black text-opacity-80">
                {content}
            </div>
        </div>
    );
};

export default BottomCard;
