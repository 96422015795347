import { yupResolver } from '@hookform/resolvers/yup';
import ErrorMessage from 'components/ErrorMessage';
import OtpInput from 'components/OtpInput';
import { useCheckOtpMutation, useSendOptForUpdateEmailMutation, useSendOtpMutation, useUpdateUserEmailMutation } from 'hooks';
import { BlackButton } from 'pages/auth';
import { SubmitHandler, useForm } from 'react-hook-form';
import { otpSchema } from 'schemas/otpValidationSchema';
import useOtpStore from 'store/useOtpStore';
import useUserStore from 'store/useUserStore';
import { formatEmail } from 'util/formatEmail';

interface FormData {
    otp: string;
}

const EnterOtpSection = () => {
    const { handleSubmit, control, formState, reset, getValues } = useForm<FormData>({
        resolver: yupResolver<FormData>(otpSchema),
    });

    const { userInfo } = useUserStore();

    const { userNewEmail, setIsOldOtpEntered } = useOtpStore();

    const checkOtpMutation = useCheckOtpMutation({
        successMessage: 'Please Input New Email!',
        onSuccess: () => {
            setIsOldOtpEntered(true);
            reset();
        },
    });

    const sendOtpMutation = useSendOtpMutation({});

    const sendOtpforUpdateEmailMutation = useSendOptForUpdateEmailMutation({});

    const updateUserEmailMutation = useUpdateUserEmailMutation({});

    const onUpdateEmailClicked = async (email: string) => {
        const otp = getValues('otp');
        updateUserEmailMutation.mutate({ newEmail: email, otp });
    };

    const onChangeEmailClicked = async (email: string) => {
        const otp = getValues('otp');
        checkOtpMutation.mutate({ email, otp });
    };

    const onConfirmOTPClicked: SubmitHandler<FormData> = () => {
        if (!userNewEmail) onChangeEmailClicked(userInfo!.email!);
        else onUpdateEmailClicked(userNewEmail!);
    };

    const onSendAgainClicked = async () => {
        if (userNewEmail) sendOtpforUpdateEmailMutation.mutate(userNewEmail!);
        else sendOtpMutation.mutate(userInfo!.email!);
    };

    return (
        <form className={`flex flex-col gap-[15px]`} onSubmit={handleSubmit(onConfirmOTPClicked)}>
            <div className="flex flex-col gap-[10px]">
                <div className="text-black font-semibold text-[20px] leading-[21px]">Enter verification code</div>
                <div className="text-my-black text-[14px] leading-[24px] opacity-80">
                    {!userNewEmail && userInfo?.email && (
                        <>
                            To change your email, Make sure you are owner of this account first. We have sent a six-character password to {''}
                            {formatEmail(userInfo?.email || '')}. Please enter it below.
                        </>
                    )}
                    {userNewEmail && (
                        <>To change your email, We have sent a six-character password to new email - {formatEmail(userNewEmail)}. Please enter it below.</>
                    )}
                </div>
            </div>

            <div className="pt-[20px]">
                <OtpInput name="otp" control={control} />
            </div>

            {formState.errors.otp && <ErrorMessage msg={`${formState.errors.otp.message}`} />}

            <div className="text-my-black opacity-80 text-[14px]">
                <span>Didn't receive the code?</span>
                <span className="font-semibold text-my-blue cursor-pointer" onClick={onSendAgainClicked}>
                    Send again
                </span>
            </div>

            <BlackButton loading={checkOtpMutation.isPending || updateUserEmailMutation.isPending} type="submit">
                <div className="text-[14px]">
                    <span>Change Email </span>
                </div>
            </BlackButton>
        </form>
    );
};

export default EnterOtpSection;
