import DoneIcon from '@mui/icons-material/Done';
import { useEffect, useState } from 'react';
import { CollectionDto } from 'types/Collection';

const MIN_W = 400;
const MIN_H = 200;
const DEFAULT_IAMGE = '/image/Default-Collection.png';

const CollectionItem = ({ item, isActive, onClick, border = true }: { item: CollectionDto; isActive: boolean; onClick?: () => void; border?: boolean }) => {
    const [image, setImage] = useState<string>(DEFAULT_IAMGE);
    const uniqueAgents: string[] = item?.properties
        ?.map((p) => p.companyId)
        ?.reduce<string[]>((acc: string[], item: string) => {
            if (!acc.includes(item)) acc.push(item);
            return acc;
        }, []);

    const imageUrls: string[] = item?.properties?.[0]?.imageUrls || [];

    useEffect(() => {
        if (!imageUrls || imageUrls.length === 0) {
            setImage(DEFAULT_IAMGE);
            return;
        }

        const filterImages = async () => {
            for (const imgurl of imageUrls) {
                const img = new window.Image();
                img.src = imgurl;

                const isValid = await new Promise<boolean>((resolve) => {
                    img.onload = () => {
                        resolve(img.width >= MIN_W && img.height >= MIN_H);
                    };
                    img.onerror = () => resolve(false);
                });

                if (isValid) {
                    setImage(imgurl);
                    break;
                }
            }
        };

        filterImages();
    }, [imageUrls]);

    return (
        <div
            className={`${isActive ? 'border-my-blue' : 'border-my-gray border-opacity-40'} ${border ? 'border cursor-pointer' : ''} rounded-[8px] p-[8px]`}
            onClick={onClick}
        >
            <div className="h-[48px] flex gap-[8px] items-center">
                <div className="relative rounded-[5px] h-[48px] w-[48px] min-w-[48px] min-h-[48px] overflow-hidden flex items-center justify-center">
                    {isActive && (
                        <div className={`absolute top-0 left-0 w-full h-full flex items-center justify-center ${isActive ? 'bg-black bg-opacity-30' : ''}`}>
                            <DoneIcon className="!font-bold !text-[30px] !text-white" />
                        </div>
                    )}

                    <img src={image} width={48} height={48} />
                </div>

                <div className="flex flex-col justify-around">
                    <div className="font-medium truncate">{item.name}</div>
                    {item?.properties?.length > 0 && (
                        <div className="text-[12px] opacity-60 truncate">{`${item?.properties?.length} properties, ${uniqueAgents?.length} competitors`}</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CollectionItem;
