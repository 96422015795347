import { Divider } from '@mui/material';
import { useIsMutating, useQuery } from '@tanstack/react-query';
import { LoadingAnimation } from 'components';
import { PropertyPagination } from 'components/Pagination';
import { OverviewPropertyTable } from 'components/PropertyTable';
import useCollectionStore from 'store/useCollection';
import { useFiltersStore } from 'store/useFiltersStore';
import usePropertiesStore from 'store/usePropertiesStore';
import { PropertySort } from 'types/PropertyFilters';
import AISearchPanel from './AISearch';
import DrawMapPanel from './Sections/DrawMapPanel';
import ExportPanel from './Sections/ExportPanel';
import FilterPanel from './Sections/FilterPanel';
import InfoPanel from './Sections/InfoPanel';
import SavedFilterPanel from './Sections/SavedFilterPanel';

const OverViewContent = () => {
    const { currentFilter, savedFilterList } = useFiltersStore();
    const { currentPage, setCurrentPage, fetchCompetitorAllProperties, fetchCompetitorAllPropertiesSort, fetchCompetitorAllPropertiesSetSort } =
        usePropertiesStore();
    const { loadCollections } = useCollectionStore();

    const { isFetching, data, isPlaceholderData } = useQuery({
        queryKey: ['fetchCompetitorAllProperties', currentPage, currentFilter, fetchCompetitorAllPropertiesSort],
        queryFn: () => fetchCompetitorAllProperties(currentFilter, fetchCompetitorAllPropertiesSort),
        staleTime: Infinity,
        refetchOnWindowFocus: false,
        placeholderData: (previousData) => previousData,
    });

    useQuery({
        queryKey: ['loadCollections'],
        queryFn: loadCollections,
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
    });

    const isFetchingAIProperties = Boolean(useIsMutating({ mutationKey: ['fetchAiProperties'] }));

    const properties = data?.findAllPropertiesByCompanyIds?.properties || [];
    const total = data?.findAllPropertiesByCompanyIds?.total || 0;
    const totalPageCount = Math.ceil(total / 50);
    const showLoading = isFetching || isFetchingAIProperties || isPlaceholderData;

    const handleSort = (sort: PropertySort) => fetchCompetitorAllPropertiesSetSort(sort);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className="flex flex-col gap-[20px] py-[20px] lg:px-0 px-[20px]">
            <InfoPanel />
            <DrawMapPanel />
            <div className="rounded-[10px] bg-white border border-my-gray border-opacity-60 ">
                <div className={`px-[16px] py-[20px] ${showLoading ? 'pointer-events-none opacity-50' : ''}`} data-cy="filter-panel">
                    <FilterPanel />
                </div>
                {savedFilterList.length > 0 && <SavedFilterPanel />}
                <div className="px-[16px] py-[12px]">
                    <AISearchPanel />
                </div>
                <Divider className="w-full opacity-60" />

                <div className="px-[16px] py-[12px]">
                    <ExportPanel />
                </div>

                <div className="pb-[20px] relative">
                    {showLoading && <LoadingAnimation />}

                    <OverviewPropertyTable propertyList={properties} onSort={handleSort} />

                    {total! > 1 && (
                        <div className="mx-auto w-fit pt-[10px]" data-cy="property-pagination">
                            <PropertyPagination handlePageChange={handlePageChange} totalPageCount={totalPageCount} currentPage={currentPage} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OverViewContent;
