import { Divider } from '@mui/material';
import { ReactComponent as CompetitorHelpSvg } from 'assets/svg/Competitor-Help.svg';
import { ReactComponent as UploadCompetitorSvg } from 'assets/svg/Upload-Competitor.svg';
import { ReactComponent as DownloadCSVTemplateSvg } from 'assets/svg/Download-CSV-Template.svg';

import ModalLayout from '../../ModalLayout';
import styled from '@emotion/styled';
import { useRef, useState } from 'react';
import { useToast } from 'components/Providers/ToastProvider';
import AnalyzingPanel from './Analyzing';
import CSVLocalAgents, { SelectedFileItem } from './CSVLocalAgents';
import { ModalLayoutProps } from 'components/Modals';
import { HelloMuiButton } from 'components/Mui';

const FILE_TYPE = 'text/csv';
const MAX_SIZE = 1024 * 1024 * 20;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const UploadBulkCompetitorModal = ({ visible, onClose }: ModalLayoutProps) => {
    const [isParsed, setIsParsed] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const isImportable: boolean = selectedFile !== null;

    const { showMessage } = useToast();
    const onDownloadTemplateClicked = () => {
        const link = document.createElement('a');
        link.href = '/sample-bulk-competitor.csv';
        link.setAttribute('download', '/sample-bulk-competitor.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const onClickImport = () => {
        if (!selectedFile) return;

        setShowLoading(true);

        setTimeout(() => {
            setShowLoading(false);
            setIsParsed(true);
        }, 1000);
    };

    const validateFile = (file: File | undefined) => {
        if (!file) return;
        if (file.type.toLowerCase() !== FILE_TYPE) {
            showMessage('Warning', 'File Type should be CSV!');
            return;
        }

        if (file.size > MAX_SIZE) {
            showMessage('Warning', 'File Size should be less than 20MB!');
            return;
        }

        setSelectedFile(file);
    };

    const onFileSelected = (evt: React.ChangeEvent<HTMLInputElement>) => {
        validateFile(evt.target.files?.[0]);
    };
    // Handler to trigger the file input dialog
    const handleSelectClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger file input dialog
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault(); // Prevent default browser behavior
        event.stopPropagation();

        validateFile(event.dataTransfer.files?.[0]);
    };
    // Prevent default behavior for dragover and dragleave
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const onCancelParse = () => {
        setIsParsed(false);
        setSelectedFile(null);
        if (fileInputRef.current) fileInputRef.current.value = '';
    };

    return (
        <>
            <ModalLayout visible={visible} onClose={onClose}>
                <VisuallyHiddenInput type="file" accept=".csv" onChange={onFileSelected} ref={fileInputRef} />
                <div className="md:relative fixed md:h-auto h-screen md:w-auto w-screen left-0 top-0 bg-white overflow-auto">
                    <div className="rounded-[10px] bg-white lg:min-w-[770px] md:min-w-[600px]">
                        <div className="md:relative bg-white sticky top-0 left-0">
                            <div className="px-[16px] py-[18px] font-semibold text-[16px]">{`Import Competitors`}</div>

                            <Divider orientation="horizontal" />
                        </div>
                        {!isParsed && (
                            <div className="relative">
                                <div className="p-[16px] flex flex-col gap-[16px]">
                                    {/* description */}
                                    <div className="rounded-[5px] bg-my-green bg-opacity-10 p-[12px] flex flex-col gap-[8px]">
                                        <div className="flex items-center gap-[8px]">
                                            <CompetitorHelpSvg />
                                            <span className="font-semibold">{`CSV Preparation`}</span>
                                        </div>

                                        <ul className="flex flex-col gap-[8px] text-[12px]">
                                            <li className="list-disc list-inside">{`Supported Headings : Competitor Name, URL`}</li>
                                            {/* <li className="list-disc list-inside">{`If your competitor's URL is yourhome.com/property/stunning-designer-villa and you want to track their property listings, you could use /property as your PathFilter.`}</li> */}
                                            <li className="list-disc list-inside">{`You can import a maximum of 300 rows.`}</li>
                                        </ul>

                                        <div>
                                            <HelloMuiButton className="gap-[5px]" onClick={onDownloadTemplateClicked}>
                                                <span>{`Download CSV TEMPLATE`}</span>
                                                <DownloadCSVTemplateSvg />
                                            </HelloMuiButton>
                                        </div>
                                    </div>

                                    <div>
                                        {/* file panel */}
                                        <div
                                            className="rounded-[10px] border border-my-gray border-dashed h-[140px]"
                                            onDrop={handleDrop}
                                            onDragOver={handleDragOver}
                                            onDragLeave={handleDragOver}
                                        >
                                            <div className="flex flex-col items-center justify-center gap-[4px] text-center h-full">
                                                {selectedFile ? (
                                                    <SelectedFileItem filename={selectedFile.name} filesize={selectedFile.size} />
                                                ) : (
                                                    <UploadCompetitorSvg />
                                                )}

                                                <div>
                                                    <span className="underline font-semibold cursor-pointer" onClick={handleSelectClick}>
                                                        {`Select`}
                                                    </span>
                                                    <span>{` a CSV file to upload`}</span>
                                                </div>

                                                <div className="text-my-gray text-[12px]">{`or drag and drop it here`}</div>
                                            </div>
                                        </div>
                                        <div className="text-right">
                                            <span className="text-my-gray text-[12px]">{`Maximum size : 20MB`}</span>
                                        </div>
                                    </div>

                                    <div className="flex items-center gap-[4px]">
                                        <div className="flex-grow"></div>
                                        <HelloMuiButton variant="outlined" className="!mx-auto !text-black !border-my-gray" onClick={onClose}>
                                            {`Cancel`}
                                        </HelloMuiButton>
                                        <HelloMuiButton variant="contained" onClick={onClickImport} disabled={!isImportable}>
                                            {`Import`}
                                        </HelloMuiButton>
                                    </div>
                                </div>

                                {showLoading && <AnalyzingPanel />}
                            </div>
                        )}

                        {isParsed && !showLoading && <CSVLocalAgents file={selectedFile} onCancel={onCancelParse} onClose={onClose} />}
                    </div>
                </div>
            </ModalLayout>
        </>
    );
};

export default UploadBulkCompetitorModal;
