import { Button, Divider } from '@mui/material';
import React, { Fragment } from 'react';
import { ModalLayoutProps } from '..';
import ModalLayout from '../ModalLayout';
import { HelloMuiButton } from 'components/Mui';
interface SelectAddWayModalProps extends ModalLayoutProps {
    onAddOneByOneClicked: () => void;
    onBulkUpload: () => void;
}

interface OptionProps {
    imageSrc: string;
    title: string;
    description: string;
    onSelect: () => void;
    dataCy?: string;
    titleDataCy?: string;
}

const Option: React.FC<OptionProps> = ({
    imageSrc,
    title,
    description,
    onSelect,
    dataCy,
    titleDataCy,
}) => (
    <div className="md:py-[98px] py-[12px] px-[27px] flex flex-col gap-[8px] text-center">
        <img
            alt={title}
            src={imageSrc}
            className="mx-auto md:h-[141px] h-[80px]"
        />
        <div
            className="font-semibold md:text-[16px] text-[14px]"
            data-cy={titleDataCy}
        >
            {title}
        </div>
        <div className="md:text-base text-[12px]">{description}</div>
        <HelloMuiButton
            variant="outlined"
            className="!mx-auto !text-black !border-my-gray"
            onClick={onSelect}
            data-cy={dataCy}
        >
            Select
        </HelloMuiButton>
    </div>
);

const SelectAddWayModal: React.FC<SelectAddWayModalProps> = ({
    visible,
    onClose,
    onAddOneByOneClicked,
    onBulkUpload,
}) => {
    const options = [
        {
            imageSrc: '/image/One-by-One.png',
            title: 'Add Competitors One by One',
            description:
                'Start small and add each competitor individually. Perfect for when you have a few competitors to track.',
            onSelect: onAddOneByOneClicked,
            dataCy: 'add-competitor-one-by-one',
            titleDataCy: 'add-competitor-one-by-one-title',
        },
        {
            imageSrc: '/image/Bulk-Upload.png',
            title: 'Import Competitors in Bulk',
            description:
                "Save time and effort by uploading a CSV file with all your competitors' details at once. Ideal for a large list of competitors to monitor.",
            onSelect: onBulkUpload,
            dataCy: 'add-competitor-bulk-upload',
            titleDataCy: 'add-competitor-bulk-upload-title',
        },
    ];

    return (
        <ModalLayout visible={visible} onClose={onClose} showCloseIcon={true}>
            <div className="rounded-[10px] md:max-w-[770px] max-w-[450px] bg-white grid md:grid-cols-2 grid-cols-1">
                {options.map((option, index) => (
                    <Fragment key={index}>
                        {index === 0 ? (
                            <div className="flex md:flex-row flex-col">
                                <Option {...option} />
                                {index < options.length - 1 && (
                                    <Fragment>
                                        <Divider
                                            orientation="vertical"
                                            className="w-[1px] !h-auto md:block hidden"
                                        />
                                        <Divider
                                            orientation="horizontal"
                                            className="!w-auto md:hidden"
                                        />
                                    </Fragment>
                                )}
                            </div>
                        ) : (
                            <Fragment>
                                <Option {...option} />
                            </Fragment>
                        )}
                    </Fragment>
                ))}
            </div>
        </ModalLayout>
    );
};

export default SelectAddWayModal;
