import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress, OutlinedInput } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { HelloMuiButton } from 'components/Mui';
import { useToast } from 'components/Providers/ToastProvider';
import { Controller, useForm } from 'react-hook-form';
import useCollectionStore from 'store/useCollection';
import { CollectionDto } from 'types/Collection';
import * as yup from 'yup';
import { ModalLayoutProps } from '..';
import ModalLayout from '../ModalLayout';

interface EditCollectionModalProps extends ModalLayoutProps {
    collection: CollectionDto | null;
    onSuccessCallback?: () => void;
}

interface FormData {
    collectionName: string;
}
const schema = yup.object().shape({
    collectionName: yup.string().required('Name is required'),
});

const EditCollectionModal = (props: EditCollectionModalProps) => {
    const { collection, visible, onClose, onSuccessCallback } = props;

    const { saveCollection, updateCollection } = useCollectionStore();
    const { showMessage } = useToast();

    const { control, handleSubmit, getValues, reset } = useForm<FormData>({
        defaultValues: {
            collectionName: collection?.name || '',
        },
        resolver: yupResolver<FormData>(schema),
    });

    const { isPending, mutate } = useMutation({
        mutationFn: async () => {
            if (!collection) await saveCollection(getValues('collectionName'));
            else await updateCollection(collection.id, getValues('collectionName'));
        },
        mutationKey: ['saveCollection'],
        onSuccess: () => {
            reset();
            onClose();
            if (onSuccessCallback) onSuccessCallback();
            showMessage(`Success.`, `Successfully ${!collection ? 'added' : 'updated'}!`);
        },
        onError: (error) => {
            showMessage(`Error Occured`, error.message);
        },
    });

    const onCreateCollectionClicked = () => mutate();

    return (
        <ModalLayout visible={visible} onClose={onClose}>
            <form className="rounded-[10px] p-[20px] bg-white" onSubmit={handleSubmit(onCreateCollectionClicked)}>
                <div className="flex flex-col gap-[16px] md:min-w-[400px]">
                    <div className="h-[5px]"></div>
                    <div className="font-semibold text-[16px]">{`${!collection ? 'Create a new property' : 'Update'} collection`}</div>

                    <div className="flex flex-col gap-[4px]">
                        <div className="font-medium">Name</div>
                        <Controller
                            name="collectionName"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <OutlinedInput
                                    className="w-full h-[48px] !rounded-[10px]"
                                    value={value}
                                    onChange={onChange}
                                    endAdornment={<span>{`${getValues('collectionName')?.length || 0}/32`}</span>}
                                />
                            )}
                        />
                    </div>

                    {isPending ? (
                        <CircularProgress size={24} className="mx-auto" />
                    ) : (
                        <HelloMuiButton variant="contained" type="submit">
                            {`${!collection ? 'Create' : 'Update'} Collection`}
                        </HelloMuiButton>
                    )}
                </div>
            </form>
        </ModalLayout>
    );
};

export default EditCollectionModal;
