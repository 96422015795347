interface Props {
    item: string;
    itemSelected: (item: string) => void;
}

const UnLockedItem = ({ item, itemSelected }: Props) => {
    return (
        <div
            className="truncate px-[10px] py-[6px] hover:bg-my-gray hover:bg-opacity-60 cursor-pointer"
            onClick={() => itemSelected(item)}
            data-cy="city-filter-item"
        >
            {item}
        </div>
    );
};

export default UnLockedItem;
