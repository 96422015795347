'use client';
import { useSnackbar } from 'notistack';
import CustomSnackbar from './CustomSnackBar';
import { ReactNode, createContext, useContext } from 'react';

interface ToastContextType {
    showMessage: (title: string, message: string) => void;
}
const defaultValue: ToastContextType = {
    showMessage: () => {},
};

export const ToastContext = createContext<ToastContextType>(defaultValue);

const ToastProvider = ({ children }: { children: ReactNode }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showMessage = (title: string, message: string) => {
        enqueueSnackbar('', {
            content: (key) => (
                <CustomSnackbar
                    title={title}
                    message={message}
                    onClose={() => closeSnackbar(key)}
                />
            ),
        });
    };

    return (
        <ToastContext.Provider value={{ showMessage: showMessage }}>
            {children}
        </ToastContext.Provider>
    );
};

export const useToast = (): ToastContextType => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};

export default ToastProvider;
