import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Divider, Menu, MenuItem } from '@mui/material';
import { ReactComponent as SettingSvg } from 'assets/svg/Menu-Setting.svg';
import { TwoLetterAvatar } from 'components';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useOtpStore from 'store/useOtpStore';
import useUserStore from 'store/useUserStore';
import { ROUTES, SubscriptionPlans } from 'util/Constants';
import { GetTwoLetterName } from 'util/Funcs';
import { logoutUserAndClearLocalStorage } from 'util/localStorageHelper';
import { PlanTypes } from 'util/Types';
import HelloDataMark from '../HelloDataMark';
import HelpArea from './HelpArea';
import NotificationArea from './NotificationArea';
import TopTabs from './TopTabs';

const SettingAvatar = () => {
    const navigate = useNavigate();
    const { userInfo: user } = useUserStore();

    const { updateEmail } = useOtpStore();
    const { priceId, interval } = user?.subscriptionDetails || {
        interval: 'month',
        priceId: '',
    };

    const plan: PlanTypes = SubscriptionPlans.filter((p) => p.priceId[interval] === priceId)[0];

    const ref = useRef(null);

    const [expanded, setExpanded] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const userName = user?.fullName || '',
        email = user?.email || '';
    const twoLetterName = GetTwoLetterName(userName || '');
    const onboardingFinished = user?.accountType && user.subscriptionId;

    const onSettingClicked = () => navigate(ROUTES.SETTING);

    const onLogOutClicked = () => {
        logoutUserAndClearLocalStorage();
        updateEmail('');
        navigate(ROUTES.SIGN_IN);
    };

    const onReportProblemClicked = () => {
        window.open('https://gozenforms.com/6LBeTRxkF75Km2Ha2zjJ', '_blank');
    };

    const openMenuClicked = (evt: any) => {
        setAnchorEl(evt.currentTarget);
        setExpanded(!expanded);
    };

    return (
        <div className="relative">
            <div className="cursor-pointer" onClick={openMenuClicked} title={userName} data-cy="setting-avatar">
                <TwoLetterAvatar title={twoLetterName} profileUrl={user?.profileImageUrl!} />
            </div>
            <Menu
                open={expanded}
                ref={ref}
                sx={{
                    mt: '60px',
                    borderRadius: '10px',
                    padding: '0px',
                    '& .MuiPaper-root': {
                        width: '303px',
                        borderRadius: '10px',
                    },
                    '& .MuiList-root': {
                        padding: '0px',
                    },
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className="rounded-[10px] absolute"
                onClose={() => setExpanded(false)}
                anchorEl={anchorEl}
            >
                <MenuItem>
                    <div className="py-[10px] w-full text-left flex flex-col gap-[12px]">
                        <div className="text-black text-[18px] font-medium">{`${userName}`}</div>
                        <div className="text-[12px] text-opacity-60">{email}</div>

                        <div className="flex items-center gap-[10px]">
                            {plan ? (
                                <>
                                    <img alt={`Plan Icon`} src={plan.icon} className="w-[18px] h-[18px]" />
                                    <span className="text-my-blue text-[12px] font-medium">{`${plan.title} Plan`}</span>
                                </>
                            ) : (
                                <span>No Plan</span>
                            )}
                        </div>
                    </div>
                </MenuItem>

                {onboardingFinished && (
                    <MenuItem onClick={onSettingClicked} className="h-[48px]">
                        <div className="flex gap-[10px] items-center">
                            <SettingSvg fontSize="small" />
                            <div className="text-[14px]" data-cy="setting-menu">
                                Setting
                            </div>
                        </div>
                    </MenuItem>
                )}
                {onboardingFinished && (
                    <MenuItem onClick={onReportProblemClicked} className="h-[48px]">
                        <div className="flex gap-[10px] items-center">
                            <InfoOutlinedIcon fontSize="small" />
                            <span className="text-[14px]" data-cy="report-problem-menu">
                                Report a problem
                            </span>
                        </div>
                    </MenuItem>
                )}
                <Divider />
                {/* Logout */}
                <MenuItem onClick={onLogOutClicked} className="!py-[20px]">
                    <div className="flex gap-[10px] items-center text-[14px]">
                        <PowerSettingsNewIcon fontSize="small" />
                        <span className="text-[14px]" data-cy="logout-menu">
                            Log out
                        </span>
                    </div>
                </MenuItem>
            </Menu>
        </div>
    );
};

const MainHedaer = () => {
    return (
        <div className="border-b border-my-gray border-opacity-60 bg-white lg:px-0 px-[20px]">
            <div className="md:block hidden">
                <div className="h-[72px] lg:max-w-hello mx-auto flex justify-between items-center">
                    <HelloDataMark />
                    <TopTabs />

                    <div className="flex items-center gap-[12px]">
                        <NotificationArea />
                        <HelpArea />
                        <SettingAvatar />
                    </div>
                </div>
            </div>

            <div className="md:hidden">
                <div className="h-[72px] w-full flex justify-between items-center px-[10px]">
                    <HelloDataMark />

                    <div className="flex items-center gap-[12px]">
                        <NotificationArea />
                        <HelpArea />
                        <SettingAvatar />
                    </div>
                </div>
                <div className="flex justify-center">
                    <TopTabs />
                </div>
                <div className="h-[10px]" />
            </div>
        </div>
    );
};

export default MainHedaer;
