import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Divider } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import BlurImage from 'components/BlurImage';
import Skeleton from 'react-loading-skeleton';
import Markdown from 'react-markdown';
import usePropertiesStore from 'store/usePropertiesStore';
import { PropertyStatisticsActionEnum } from 'types/PropertyStatistics';
import { PropertyDTO } from 'util/Types';
import { ModalLayoutProps } from '..';
import ModalLayout, { DEFAULT_MODAL_Z_INDEX } from '../ModalLayout';
import DetailPane from './DetailPane';
import FeaturePane from './FeaturePane';
import HeaderPane from './HeaderPane';
import styles from './propertydetail.module.css';
import ImageGalleryModal from '../ImageGallery';
import { useState } from 'react';
interface ShareLinkPropertyDetailModalProps extends ModalLayoutProps {
    property: PropertyDTO | null;
    zIndex?: number;
}

const ShareLinkPropertyDetailModal = (props: ShareLinkPropertyDetailModalProps) => {
    const [showImageGalleryModal, setShowImageGalleryModal] = useState<boolean>(false);

    const { savePropertyStatistics, propertyImages, fetchPropertyImages } = usePropertiesStore();
    const { property, visible, onClose, zIndex = DEFAULT_MODAL_Z_INDEX } = props;

    let originUrl = '';

    if (property?.webPageUrl) {
        const originUrlStr = property?.webPageUrl ? new URL(property?.webPageUrl) : null;
        originUrl = originUrlStr ? `${originUrlStr.protocol}${originUrlStr.port ? `:${originUrlStr.port}` : ''}//${originUrlStr.hostname}` : '';
    }

    useQuery({
        queryKey: ['savePropertyStatistics', property?.webPageId],
        queryFn: () =>
            savePropertyStatistics({
                webPageId: property?.webPageId,
                action: PropertyStatisticsActionEnum.VIEW,
            }),
        enabled: () => !!property?.webPageId,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        staleTime: 0,
        retry: 1,
    });

    const { isLoading, data } = useQuery({
        queryKey: ['fetchPropertyImages', property?.webPageId],
        queryFn: () => fetchPropertyImages(property?.webPageId || ''),
        enabled: property ? property?.webPageId !== '' : false,
        refetchOnWindowFocus: false,
        staleTime: 60 * 5 * 1000,
        retry: 1,
    });

    const Content = () => (
        <div className="xl:rounded-[10px] bg-white xl:w-[1260px] w-full leading-[24px]" data-cy="property-detail-modal">
            {/* header containing back arrow button */}
            <div className="xl:relative fixed xl:w-auto w-screen h-[50px] flex justify-end items-center px-[16px] top-0 left-0 z-[3] bg-white border-b border-my-gray">
                <div className="w-[28px] h-[28px] rounded-[5px] flex items-center justify-center border border-my-gray cursor-pointer" onClick={onClose}>
                    <CloseRoundedIcon />
                </div>
            </div>

            <div className="xl:h-0 h-[50px]"></div>

            <div className={`relative px-[16px] py-[32px] flex flex-col gap-[12px] overflow-auto ${styles['content']}`}>
                <div
                    className="absolute left-0 top-0 w-full h-[100px] z-[0]"
                    style={{
                        background: `url('/image/Property-Detail-Background.png') center center / cover no-repeat`,
                    }}
                ></div>
                {/* information */}
                <div className="z-[1]">
                    <HeaderPane
                        property={property}
                        originalLocation={data?.originalLocation}
                        duplicateProperties={data?.duplicateProperties}
                        isDetailLoading={isLoading}
                        isPublic={true}
                    />
                </div>
                {/* price and details */}
                <DetailPane property={property} additionalDetails={data!} isDetailLoading={isLoading} isPublic={true} />

                <Divider className="w-full" />

                <div className="md:text-base text-[12px]">{property?.description || ''}</div>

                <FeaturePane property={property} />
                {!isLoading && propertyImages.length > 0 && (
                    <>
                        <div className="text-my-blue font-semibold">Property Images</div>

                        <div className="flex gap-[10px] flex-wrap py-[10px]" onClick={() => setShowImageGalleryModal(true)}>
                            {propertyImages.map((imageUrl: string, index: number) => {
                                let url = imageUrl;
                                if (!imageUrl.includes('http')) {
                                    url = `${originUrl}/${imageUrl}`;
                                }
                                return <BlurImage imgurl={url} key={index} isPublic={true} />;
                            })}
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    return (
        <ModalLayout visible={visible} onClose={onClose} showCloseIcon={false} zIndex={zIndex}>
            <div className="xl:block hidden">
                <Content />
            </div>

            <div className="xl:hidden block h-screen w-screen fixed overflow-auto top-0 left-0 bg-white">
                <Content />
            </div>

            <ImageGalleryModal visible={showImageGalleryModal} onClose={() => setShowImageGalleryModal(false)} images={propertyImages} />
        </ModalLayout>
    );
};

export default ShareLinkPropertyDetailModal;
