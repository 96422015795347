import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { ErrorMessage } from 'components';
import { useToast } from 'components/Providers/ToastProvider';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import OTPInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { otpSchema } from 'schemas/otpValidationSchema';
import useOtpStore from 'store/useOtpStore';
import { ROUTES } from 'util/Constants';
import BlackButton from '../BlackButton';

interface FormData {
    otp: string;
}

const originalClassName =
    'sm:h-[90px] lg:w-[72px] lg:leading-[90px] border text-[#4EBBB8] lg:text-[40px] sm:text-[30px] text-[25px] font-bold sm:w-[40px] h-[40px] w-[30px] leading-[40px] ';

const formatEmail = (email: string) => {
    const [localPart, domain] = email.split('@');
    const formattedLocalPart =
        localPart.length > 1 ? localPart.charAt(0) + '*'.repeat(5) : localPart;
    return `${formattedLocalPart}@${domain}`;
};

const EnterOtp = () => {
    const { showMessage } = useToast();
    const navigate = useNavigate();
    const { email, sendOtp, checkOtp } = useOtpStore();

    const { handleSubmit, control, formState, reset } = useForm<FormData>({
        resolver: yupResolver<FormData>(otpSchema),
    });

    const sendOtpMutation = useMutation({
        mutationFn: sendOtp,
        onSuccess: () => {
            reset();
            showMessage('Sent Otp again.', 'Enter your OTP!');
        },
        onError: (error) => {
            showMessage('Error Occured', error.message);
        },
    });

    const checkOtpMutation = useMutation({
        mutationFn: checkOtp,
        onSuccess: () => {
            navigate(ROUTES.MAIN);
        },
        onError: (error) => {
            showMessage('Error Occured', error.message);
        },
    });

    const checkOtpAndLogin: SubmitHandler<FormData> = ({ otp }) => {
        checkOtpMutation.mutate({ email, otp });
    };

    const onSendAgainClicked = () => {
        sendOtpMutation.mutate(email);
    };

    return (
        <form
            className={`otpDialog bg-white rounded-[20px] md:px-[36px] sm:px-[24px] px-[16px] py-[48px] flex flex-col gap-[25px]`}
            onSubmit={handleSubmit(checkOtpAndLogin)}
        >
            <div className="flex flex-col gap-[10px]">
                <div className="text-black font-semibold text-[20px] leading-[21px]">
                    {'Enter one time password'}
                </div>
                <div className="text-my-black text-[14px] leading-[24px] opacity-80">
                    {`We have sent a six-character password to ${formatEmail(
                        email,
                    )}. Please enter it below.`}
                </div>
            </div>

            <Controller
                name="otp"
                control={control}
                render={({ field: { onChange, value } }) => (
                    <OTPInput
                        value={value}
                        onChange={onChange}
                        inputType="number"
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => (
                            <input
                                {...props}
                                className={`flex flex-1 bg-[#FBFBFB] border border-[#E1E1E1] rounded-[8px] ${originalClassName}`}
                            />
                        )}
                        containerStyle={
                            'mt-[20px] md:gap-[10px] sm:gap-[4px] gap-[2px] grid grid-cols-6'
                        }
                    />
                )}
            />
            {formState.errors.otp && (
                <ErrorMessage msg={`${formState.errors.otp.message}`} />
            )}
            <div className="text-my-black opacity-80 text-[14px]">
                <span>{"Didn't receive the code?"}</span>
                <span
                    className="font-semibold text-my-blue cursor-pointer"
                    onClick={onSendAgainClicked}
                >
                    {' Send again'}
                </span>
            </div>

            <BlackButton
                loading={checkOtpMutation.isPending}
                type="submit"
                data-cy="otp-submit"
            >
                <div className="text-[14px]">
                    <span>{'Log in'} </span>
                </div>
            </BlackButton>
        </form>
    );
};

export default EnterOtp;
