import { IconButton } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as HelpSvg } from 'assets/svg/HelpIcon.svg';

const HelpArea = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const showHelp = Boolean(anchorEl);

    const handleShowHelpClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onCloseHelp = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleShowHelpClick}>
                <HelpSvg />
            </IconButton>
        </>
    );
};

export default HelpArea;
