import { ReactComponent as MainLogoSvg } from 'assets/svg/Main-Logo.svg';

import { ReactComponent as FeatureBedroomSvg } from 'assets/svg/feature-bedroom.svg';
import { ReactComponent as FeatureBathroomSvg } from 'assets/svg/feature-bathroom.svg';
import { ReactComponent as FeatureBuildAreaSvg } from 'assets/svg/feature-buildarea.svg';
import { ReactComponent as FeaturePlotSizeSvg } from 'assets/svg/feature-plotsize.svg';
import { ReactComponent as DefaultPropertyImageSvg } from 'assets/svg/Default-Property-Image.svg';

import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import { formatNumber } from 'util/Funcs';

import { ShareLinkDto } from 'types/SharableLink';
import { PropertyDTO } from 'util/Types';
import { ReactComponent as DotSvg } from 'assets/svg/address-dot.svg';
import { Divider } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { PropertyPagination } from 'components/Pagination';
import { useModals } from 'pages/MainProvider';

const MIN_W = 400;
const MIN_H = 200;

const PropertyItem = ({ item }: { item: PropertyDTO }) => {
    const details = item.details;
    const [image, setImage] = useState<string>('');

    useEffect(() => {
        if (!item.imageUrls) {
            setImage('');
            return;
        }

        const filterImages = async () => {
            for (const imgurl of item.imageUrls) {
                const img = new window.Image();
                img.src = imgurl;

                const isValid = await new Promise<boolean>((resolve) => {
                    img.onload = () => {
                        resolve(img.width >= MIN_W && img.height >= MIN_H);
                    };
                    img.onerror = () => resolve(false);
                });

                if (isValid) {
                    setImage(imgurl);
                    break;
                }
            }
        };

        filterImages();
    }, [item.imageUrls]);

    return (
        <div className="p-[16px] flex flex-col md:flex-row gap-[20px] rounded-[10px] shadow-hello-gray bg-white">
            <div className="relative h-[140px] md:w-[140px] min-h-[140px] min-w-[140px] rounded-[8px] overflow-hidden flex items-center justify-center">
                {image ? (
                    <>
                        <img src={image} className="w-full h-full object-cover blur-[2px]" />
                        <div className="absolute top-0 left-0 w-full h-full bg-my-black bg-opacity-40"></div>
                    </>
                ) : (
                    <img src={'/image/DefaultImageForShareProperty.png'} className="w-full h-full object-cover" />
                )}
            </div>

            <div className="flex flex-col gap-[10px]">
                <div className="flex items-center gap-[4px]">
                    <span className="font-semibold text-my-blue">{item.details.propertyType}</span>
                    <DotSvg className="!text-gray-400" />
                    <span>{item.address.City}</span>
                </div>

                <div className="font-semibold text-[18px]">{item.title}</div>

                <div className="relative flex flex-wrap gap-[12px]">
                    {details.bedrooms > 0 && (
                        <>
                            <div className="flex items-center gap-[8px]">
                                <FeatureBedroomSvg />
                                <span className="text-my-black font-semibold">{`${formatNumber(details.bedrooms)} Bed`}</span>
                            </div>
                            <Divider className="h-full" orientation="vertical" />
                        </>
                    )}
                    {details.bathrooms > 0 && (
                        <>
                            <div className="flex items-center gap-[8px]">
                                <FeatureBathroomSvg />

                                <span className="text-my-black font-semibold">{`${formatNumber(details.bathrooms)} Bath`}</span>
                            </div>
                            <Divider className="h-full" orientation="vertical" />
                        </>
                    )}

                    {details.sizeLand > 0 && (
                        <>
                            <div className="flex items-center gap-[8px]">
                                <FeatureBuildAreaSvg />

                                <span className="text-my-black font-semibold">{`${formatNumber(details.sizeLand)}m2`}</span>
                            </div>
                            <Divider className="h-full" orientation="vertical" />
                        </>
                    )}
                    {details.sizePlot > 0 && (
                        <div className="flex items-center gap-[8px]">
                            <FeaturePlotSizeSvg />

                            <span className="text-my-black font-semibold">{`${formatNumber(details.sizePlot)} m2`}</span>
                        </div>
                    )}
                </div>

                {/* Price */}
                <div className="text-[24px] font-semibold text-my-blue">
                    {details.price && details.price > 0 ? `${formatNumber(details.price)} ${getSymbolFromCurrency(details.currency) || ''} ` : 'On Request'}
                </div>
            </div>
        </div>
    );
};

const ContactContainer = styled('div')({
    padding: '16px',
    border: '1px solid #DADADA',
    background: 'white',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

const ViewPropertyInLinkSection = ({ linkitem }: { linkitem?: ShareLinkDto }) => {
    if (!linkitem) return null;

    const countPerPage = 5;
    const [page, setPage] = useState<number>(0);
    const maxPage = Math.round(linkitem.properties.length / countPerPage);

    const currentProperties: PropertyDTO[] = linkitem.properties.slice(page * countPerPage, page * countPerPage + countPerPage);

    const contact = linkitem.contactInformation;
    const { showShareLinkPropertyDetailModal } = useModals();

    const onPropertyClickd = (property: PropertyDTO) => {
        showShareLinkPropertyDetailModal(property);
    };

    const onHandlePageChange = (p: number) => {
        setPage(p);
    };

    return (
        <div>
            {/* icon */}
            <div className="bg-white h-[72px] flex items-center justify-center">
                <img src="/image/HelloHereIcon.png" className="mx-auto" />
            </div>

            {/* title */}
            <div
                className="h-[190px] bg-my-blue flex flex-col items-center justify-center text-white text-center"
                style={{
                    background: 'url(/image/ViewShareLinkHeaderBackground.png)',
                    backgroundColor: 'rgb(49 118 188)',
                }}
            >
                <div className="text-[20px] font-semibold">{linkitem.title}</div>
                <div className="pt-[5px]">{linkitem.customMessage}</div>
            </div>

            {/* properties */}
            <div className="py-[50px] md:px-[32px] px-[12px]">
                <div className="mx-auto max-w-[1200px]">
                    <div className="text-my-blue text-[20px] font-semibold">{`${linkitem.properties.length} Property Showcase`}</div>

                    <div className="flex flex-col gap-[12px] pt-[24px]">
                        {currentProperties.map((p, index) => (
                            <div onClick={() => onPropertyClickd(p)} key={index} className="cursor-pointer">
                                <PropertyItem item={p} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center">
                <PropertyPagination currentPage={page} totalPageCount={maxPage} handlePageChange={onHandlePageChange} />
            </div>
            {/* contact */}
            {contact && (
                <div className="mx-auto max-w-[1200px] flex flex-col gap-[36px] pb-[50px]  md:px-[32px] px-[12px]">
                    <div className="text-center">
                        <div className="text-my-blue">{`Get in Touch`}</div>
                        <div className="font-semibold text-[24px]">{contact.companyName}</div>
                    </div>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-[18px]">
                        <ContactContainer className="shadow-hello-gray-week">
                            <div className="rounded-[4px] w-[42px] h-[42px] border border-my-green bg-my-green bg-opacity-10 flex items-center justify-center">
                                <PlaceIcon className="!text-my-green" />
                            </div>

                            <div className="text-[16px] font-semibold">{`Address`}</div>
                            <div>{contact.officeAddress}</div>
                        </ContactContainer>

                        <ContactContainer className="shadow-hello-gray-week">
                            <div className="rounded-[4px] w-[42px] h-[42px] border border-my-green bg-my-green bg-opacity-10 flex items-center justify-center">
                                <EmailIcon className="!text-my-green" />
                            </div>

                            <div className="text-[16px] font-semibold">{`Email`}</div>
                            <div>{contact.emailAddress}</div>
                        </ContactContainer>

                        <ContactContainer className="shadow-hello-gray-week">
                            <div className="rounded-[4px] w-[42px] h-[42px] border border-my-green bg-my-green bg-opacity-10 flex items-center justify-center">
                                <PhoneIcon className="!text-my-green" />
                            </div>

                            <div className="text-[16px] font-semibold">{`Phone`}</div>
                            <div>{contact.phoneNumber}</div>
                        </ContactContainer>
                    </div>
                </div>
            )}

            {/* footer */}
            <div className="md:h-[55px] h-[50px] text-center flex items-center justify-center bg-white md:text-base text-[10px]">
                <span className="mr-[4px]">{`This property Share link was generated using `}</span>
                <span className="cursor-pointer flex items-center">
                    <span className="font-medium">{`hello:data`}</span>
                    <img src="/image/location.png" className="m:h-[21px] h-[14px]" />
                </span>
            </div>
        </div>
    );
};

export default ViewPropertyInLinkSection;
