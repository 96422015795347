import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { saveAccessAndRefreshTokenToLocalStorage } from 'util/localStorageHelper';
import { create } from 'zustand';
import useUserStore from './useUserStore';

interface OtpState {
    sendOtp: (email: string) => Promise<number | string | undefined>;
    email: string;
    updateEmail: (email: string) => void;
    checkOtp: (data: { email: string; otp: string }) => Promise<string>;
    isOldOtpEntered: boolean;
    setIsOldOtpEntered: (isOldOtpEntered: boolean) => void;
    userNewEmail: string;
    setUserNewEmail: (email: string) => void;
    sendOtpforUpdateEmail: (email: string) => Promise<number>;
    updateUserEmail: (data: { newEmail: string; otp: string }) => Promise<number>;
}

const useOtpStore = create<OtpState>((set) => ({
    sendOtp: async (email: string) => {
        try {
            const { status } = await axiosInstance.post(
                `/user/send-otp`,
                {
                    email,
                },
                {
                    headers: {
                        'SKIP-AUTH': true,
                    },
                },
            );
            if (status === 201) {
                set({ email });
                return status;
            }
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    email: '',
    updateEmail: (email: string) => set({ email }),
    checkOtp: async (bodyData) => {
        try {
            const { data } = await axiosInstance.post(
                `/user/check-otp`,
                {
                    email: bodyData.email,
                    otp: bodyData.otp,
                },
                {
                    headers: {
                        'SKIP-AUTH': true,
                    },
                },
            );
            const { accessToken, userInfo, refreshToken } = data.data;

            saveAccessAndRefreshTokenToLocalStorage(accessToken, refreshToken);
            useUserStore.setState({
                userInfo,
            });
            return accessToken;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
        }
    },
    isOldOtpEntered: false,
    setIsOldOtpEntered: (isOldOtpEntered: boolean) => set({ isOldOtpEntered }),
    userNewEmail: '',
    setUserNewEmail: (email: string) => set({ userNewEmail: email }),
    sendOtpforUpdateEmail: async (email: string) => {
        try {
            const { status } = await axiosInstance.post(
                `/user/send-otp-for-update-email`,
                {
                    email,
                },
                {
                    headers: {
                        'SKIP-AUTH': true,
                    },
                },
            );
            return status;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    updateUserEmail: async (bodyData) => {
        try {
            const { status } = await axiosInstance.patch(`/user/update-email`, bodyData);
            return status;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
}));

export default useOtpStore;
