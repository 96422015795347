import { FormControl, MenuItem, Select } from '@mui/material';
import { DEFAULT_PAGE } from 'util/Constants';

const stringlist = [15, 50];

const PrevButton = ({ isActive }: { isActive: boolean }) => {
    return (
        <div
            className={`${isActive ? 'border-opacity-40' : 'border-opacity-30 opacity-25'} cursor-pointer flex items-center justify-center w-[36px] h-[28px] rounded-l-[5px] border border-[#121212]`}
        >
            <span className="text-[20px]">{'<'}</span>
        </div>
    );
};

const NextButton = ({ isActive }: { isActive: boolean }) => {
    return (
        <div
            className={`${isActive ? 'border-opacity-40' : 'border-opacity-20 opacity-25'} cursor-pointer flex items-center justify-center w-[36px] h-[28px] rounded-r-[5px] border border-[#121212]`}
        >
            <span className="text-[20px]">{'>'}</span>
        </div>
    );
};

const CompetitorPropertyPagination = ({
    totalCount,
    totalPages,
    countPerPage,
    setCurrentPage,
    currentPage,
    onCountPerPageChanged,
}: {
    totalCount: number;
    totalPages: number;
    countPerPage: number;
    setCurrentPage: (p: number) => void;
    currentPage: number;
    onCountPerPageChanged: (p: number) => void;
}) => {
    if (totalPages < 2) return null;

    const increaseCurrentPage = () => {
        const newpage = Math.max(currentPage + 1, DEFAULT_PAGE);
        if (newpage <= totalPages) {
            setCurrentPage(newpage);
        }
    };

    const decreaseCurrentPage = () => {
        const newpage = Math.min(currentPage - 1, totalPages);
        if (newpage >= DEFAULT_PAGE) {
            setCurrentPage(newpage);
        }
    };

    return (
        <div className="flex gap-[10px] items-center w-fit sm:text-[14px] text-[12px] font-medium">
            <div className="h-[28px]">
                <FormControl fullWidth className="!h-full">
                    <Select
                        value={countPerPage}
                        onChange={(e) =>
                            onCountPerPageChanged(Number(e.target.value))
                        }
                        displayEmpty
                        renderValue={(selected) => {
                            return `${selected} per page`;
                        }}
                        className="!w-full !h-full rounded-[8px] border-[#DFE1E1] leading-6 px-1 !text-[12px]"
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            },
                        }}
                    >
                        {stringlist.map((item: number, idx: number) => (
                            <MenuItem
                                value={item}
                                key={idx}
                                sx={{ height: '24px' }}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div>
                <span>{`${(currentPage - 1) * countPerPage + 1} - ${Math.min(currentPage * countPerPage, totalCount)} of ${totalCount}`}</span>
            </div>

            <div className="flex">
                <div
                    onClick={decreaseCurrentPage}
                    data-cy="previous-button-competitor-pagination"
                >
                    <PrevButton isActive={currentPage > 1} />
                </div>
                <div
                    onClick={increaseCurrentPage}
                    data-cy="next-button-competitor-pagination"
                >
                    <NextButton isActive={currentPage <= totalPages - 1} />
                </div>
            </div>
        </div>
    );
};

export default CompetitorPropertyPagination;
