import { ModalLayoutProps } from '.';
import ModalLayout from './ModalLayout';
import Slider from 'react-slick';
import './ImageGallery.module.css';

interface ImageGalleryModalProps extends ModalLayoutProps {
    images: string[];
}

const ImageGalleryModal = ({ visible, onClose, images }: ImageGalleryModalProps) => {
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <ModalLayout visible={visible} onClose={onClose} showCloseIcon={false} showShadow={false}>
            <div>
                <Slider {...settings}>
                    {images.map((image: string, index: number) => (
                        <div key={index}>
                            <div className="min-h-[80vh] flex justify-center items-center px-[20px]">
                                <img src={image} className="max-h-full max-w-full object-contain" alt={`Slide ${index}`} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </ModalLayout>
    );
};

export default ImageGalleryModal;
