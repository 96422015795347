import { ReactComponent as PreviewSvg } from 'assets/svg/PreviewShareableLink.svg';
import { Divider } from '@mui/material';
import { ModalLayoutProps } from '..';
import ModalLayout from '../ModalLayout';
import { Link } from 'react-router-dom';
import { HelloMuiButton } from 'components/Mui';

interface ShareableLinkCreatedModalProps extends ModalLayoutProps {
    link: string;
}
const ShareableLinkCreatedModal = (props: ShareableLinkCreatedModalProps) => {
    const { visible, onClose, link } = props;

    const onCopyClicked = () => {
        navigator.clipboard.writeText(link);
    };

    return (
        <ModalLayout visible={visible} onClose={onClose} showCloseIcon={false}>
            <div className="rounded-[10px] bg-white overflow-hidden max-w-[510px]">
                <div className="p-[16px]">
                    <div className="flex flex-col items-center gap-[28px]">
                        <img
                            alt={'No Shareable Links'}
                            src="/image/ShareableLinkCreated.png"
                        />

                        <div className="flex flex-col gap-[8px] text-center">
                            <div className="text-[18px] font-semibold">
                                {`Shareable link Successfully Generated!`}
                            </div>
                            <div className="text-[12px]">
                                <span className="font-semibold">{`Copy `}</span>

                                <span>
                                    {`it below and send it to your client.`}
                                </span>
                            </div>
                        </div>

                        <div className="rounded-[5px] bg-my-gray bg-opacity-20 flex md:flex-row flex-col items-center justify-between md:gap-[24px] gap-[4px] md:p-[6px] p-[12px] w-full">
                            <div className="truncate md:w-max w-full text-[12px]">
                                {link}
                            </div>
                            <div className="text-my-gray">
                                <HelloMuiButton
                                    variant="outlined"
                                    color="inherit"
                                    onClick={onCopyClicked}
                                    className="w-full !bg-white"
                                >
                                    <span className="text-my-black">
                                        {`Copy`}
                                    </span>
                                </HelloMuiButton>
                            </div>
                        </div>
                    </div>
                </div>
                <Divider />
                <div className="p-[16px] bg-my-gray bg-opacity-10 flex items-center justify-between">
                    <a href={link} target="_blank">
                        <div className="flex items-center gap-[4px]">
                            <PreviewSvg />
                            <span> {'Preview your shareable link'}</span>
                        </div>
                    </a>

                    <HelloMuiButton variant="contained" onClick={onClose}>
                        {`Done`}
                    </HelloMuiButton>
                </div>
            </div>
        </ModalLayout>
    );
};

export default ShareableLinkCreatedModal;
