import axios from 'axios';
import axiosInstance from 'config/axiosConfig';
import { LocalShareLinkDto, ShareLinkDto } from 'types/SharableLink';
import { create } from 'zustand';

interface SharableLinkStore {
    sharablelinks: ShareLinkDto[];
    loadShareableLinks: () => Promise<ShareLinkDto[]>;
    createShareableLinks: (info: LocalShareLinkDto) => Promise<string>;
    removeSharableLinks: (id: string) => Promise<boolean>;
    downloadCSV: () => Promise<string>;
    loadFullLinkInfo: (linkId: string) => Promise<ShareLinkDto>;
    udpateLink: (linkId: string, value: string, key?: keyof ShareLinkDto) => Promise<void>;
    loadShareableLinkWithPassword: ({ link, password }: { link: string; password: string }) => Promise<void>;
    loadShareableLinkWithoutPassword: (link: string) => Promise<ShareLinkDto>;
}

const useSharableLinkStore = create<SharableLinkStore>((set, get) => ({
    sharablelinks: [],
    loadShareableLinks: async () => {
        try {
            const { data } = await axiosInstance.get(`/shareable-links`);

            set({ sharablelinks: data.data });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    createShareableLinks: async (info: LocalShareLinkDto) => {
        try {
            const { data } = await axiosInstance.post(`/shareable-links`, info);
            set(() => ({
                sharablelinks: [...data.data],
            }));
            return data.data[data.data.length - 1].link;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    removeSharableLinks: async (id: string) => {
        try {
            await axiosInstance.delete(`/shareable-links/${id}`);
            set((state) => ({
                sharablelinks: state.sharablelinks.filter((link) => link.id !== id),
            }));
            return true;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    downloadCSV: async () => {
        try {
            const { data } = await axiosInstance.get(`/shareable-links/download-csv`);
            return data.data.url as string;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    loadFullLinkInfo: async (linkId: string) => {
        try {
            const { data } = await axiosInstance.get(`/shareable-links/${linkId}`);
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    udpateLink: async (linkId: string, value: string, key: keyof ShareLinkDto = 'expirationDate') => {
        try {
            const { data } = await axiosInstance.patch(`/shareable-links/${linkId}`, {
                [key]: value,
            });
            set((state) => ({
                sharablelinks: state.sharablelinks.map((link) => (link.id === linkId ? { ...link, [key]: value } : link)),
            }));
            return data.data.url;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    loadShareableLinkWithPassword: async ({ link, password }: { link: string; password: string }) => {
        try {
            const { data } = await axiosInstance.post(`/shareable-links/share`, {
                link: link,
                password: password,
            });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
    loadShareableLinkWithoutPassword: async (link: string) => {
        try {
            const { data } = await axiosInstance.post(`/shareable-links/public`, {
                link: link,
            });
            return data.data;
        } catch (error) {
            if (axios.isAxiosError(error)) throw new Error(error.response?.data.message);
            throw new Error(error as string);
        }
    },
}));

export default useSharableLinkStore;
