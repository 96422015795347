import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Divider } from '@mui/material';
import useCompetitorsStore from 'store/useCompetitorsStore';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import styles from './competitors.module.css';

const CompetitorsTab = () => {
    const { showSavedCompetitorInfo, setShowSavedCompetitorInfo } =
        useCompetitorsStore();

    const onBackClicked = () => {
        setShowSavedCompetitorInfo(false);
    };

    return (
        <>
            <div
                className={`mx-[20px] lg:flex h-full hidden ${styles['container']}`}
            >
                <Divider orientation="vertical" className="h-full" />
                <div className="w-[360px] relative bg-white">
                    <LeftPanel />
                </div>
                <Divider orientation="vertical" className="h-full" />
                <div className="flex-grow">
                    <RightPanel />
                </div>
            </div>

            {/* for mobile */}
            <div className="m-[20px] h-full lg:hidden">
                <div
                    className={`rounded-[10px] bg-white border border-my-gray h-full ${!showSavedCompetitorInfo ? 'hidden' : ''}`}
                >
                    <div className="h-[40px] p-[10px]">
                        <span
                            onClick={onBackClicked}
                            className="cursor-pointer"
                        >
                            <KeyboardBackspaceIcon />
                        </span>
                    </div>
                    <RightPanel />
                </div>
                <div
                    className={`rounded-[10px] bg-white border border-my-gray h-full ${showSavedCompetitorInfo ? 'hidden' : ''}`}
                >
                    <LeftPanel />
                </div>
            </div>
        </>
    );
};

export default CompetitorsTab;
