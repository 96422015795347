import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ReactComponent as ProtectedSvg } from 'assets/svg/Protected.svg';
import OtpInput from 'components/OtpInput';
import { useToast } from 'components/Providers/ToastProvider';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ClipLoader } from 'react-spinners';
import { otpSchema } from 'schemas/otpValidationSchema';
import useSharableLinkStore from 'store/sharableLinksStore';
import ViewPropertyInLinkSection from './properties';

interface FormData {
    otp: string;
}

const CheckViewShareLinkSection = () => {
    const link = window.location.href;
    const { loadShareableLinkWithoutPassword, loadShareableLinkWithPassword } = useSharableLinkStore();
    const { showMessage } = useToast();

    const { handleSubmit, control, formState, reset } = useForm<FormData>({
        resolver: yupResolver<FormData>(otpSchema),
    });

    const { data: linkData, isPending } = useQuery({
        queryKey: ['loadShareableLinkWithoutPassword', link],
        queryFn: () => loadShareableLinkWithoutPassword(link),
    });

    const {
        mutate: loadShareLinkMutation,
        data,
        isPending: isLoading,
    } = useMutation({
        mutationFn: loadShareableLinkWithPassword,
        onError: (error) => {
            showMessage('Error', error.message);
        },
        onSettled: () => reset(),
    });

    if (isPending) {
        return (
            <div className="absolute inset-0 flex items-center justify-center">
                <ClipLoader size={40} color="#4F46E5" />
            </div>
        );
    }

    if (linkData?.expirationDate) {
        const expirationDate = new Date(linkData.expirationDate);
        const currentDate = new Date();
        if (expirationDate < currentDate) {
            return (
                <div className="w-screen h-screen flex items-center justify-center">
                    <div className="max-w-[600px] text-center flex flex-col gap-[20px] md:p-0 px-[12px]">
                        <img src="/image/Logo_65_80.png" className="mx-auto" />
                        <div className="text-[20px] font-semibold">This Link Is Expired.</div>
                    </div>
                </div>
            );
        }
    }
    const onSubmitClicked: SubmitHandler<FormData> = (data) => loadShareLinkMutation({ link, password: data.otp });

    return (
        <>
            {data ? (
                <ViewPropertyInLinkSection linkitem={data!} />
            ) : (
                <div className="w-screen h-screen flex items-center justify-center">
                    <form onSubmit={handleSubmit(onSubmitClicked)}>
                        <div className="max-w-[600px] text-center flex flex-col gap-[20px] md:p-0 px-[12px]">
                            <img src="/image/Logo_65_80.png" className="mx-auto" />
                            <div>
                                <div className="text-[20px] font-semibold">{linkData?.title}</div>
                                <div className="pt-[5px]">{linkData?.customMessage}</div>
                            </div>

                            <div className="rounded-[5px] border border-white bg-white bg-opacity-20 p-[20px] mx-[20px]">
                                <OtpInput name="otp" control={control} />
                            </div>

                            <div className="text-my-black text-opacity-80 text-[10px]">
                                <ProtectedSvg className="inline-block" />
                                <span>{` This page is protected. Please enter the passcode to view the property listings.`}</span>
                            </div>
                            <div>
                                {isLoading ? (
                                    <ClipLoader size={35} color="#4F46E5" />
                                ) : (
                                    <Button variant="contained" className="!h-[42px]" disabled={!formState.isValid} type="submit">
                                        UNLOCK LISTINGS
                                    </Button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};

export default CheckViewShareLinkSection;
