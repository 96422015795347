import { useRef, useState } from 'react';

import {
    Button,
    CircularProgress,
    Divider,
    Menu,
    MenuItem,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

import { ReactComponent as TabletHeaderCollapse } from 'assets/svg/Tablet-Header-Collapse.svg';
import { ROUTES } from 'util/Constants';
import HelloDataMark from '../HelloDataMark';
import { GetTokenFromStorage } from 'util/AppApi';
import {
    HelloMuiButton,
    HelloMuiLoadingButton,
    OpenAppButton,
} from 'components';

const HeaderSection = () => {
    const userToken = GetTokenFromStorage();

    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const expandMobileHeader: boolean = Boolean(anchorEl);

    const onClickExpand = () => {
        if (ref) setAnchorEl(ref.current);
    };

    const onOpenAppClicked = () => {
        navigate(ROUTES.MAIN);
    };
    const onGetStartedClicked = () => {
        navigate(ROUTES.SIGN_UP);
    };

    const onLogInClicked = () => {
        navigate(ROUTES.SIGN_IN);
    };

    const onCloseMenu = () => {
        setAnchorEl(null);
    };

    const CustomMenuItem = ({
        title,
        href,
    }: {
        title: string;
        href: string;
    }) => {
        return (
            <MenuItem
                className="!text-[16px] !font-medium !py-[15px]"
                onClick={onCloseMenu}
            >
                <a className="w-full" href={href}>
                    {title}
                </a>
            </MenuItem>
        );
    };

    return (
        <div className="bg-white">
            <div className="lg:flex hidden px-[90px] py-[20px] h-[88px] text-[14px] text-my-black justify-between border-b border-my-black border-opacity-10">
                <div className="flex gap-[90px] items-center">
                    <HelloDataMark />
                    <div className="flex gap-[48px] items-center">
                        <a href="#featured">{'Features'}</a>
                        <a href="#pricing">{'Pricing'}</a>
                        <a href="#faq">{'FAQ'}</a>
                        <a href="#contact">{'Contact'}</a>
                    </div>
                </div>
                {userToken ? (
                    <div onClick={onOpenAppClicked}>
                        <OpenAppButton />
                    </div>
                ) : (
                    <div className="flex gap-[30px] items-center">
                        <a href="#requestdemo">{'Hello:data guide'} </a>

                        <Divider orientation="vertical" />

                        <Link to={ROUTES.SIGN_IN}>{'Log In'} </Link>

                        <HelloMuiLoadingButton
                            onClick={() => navigate(ROUTES.SIGN_UP)}
                            variant="outlined"
                            className={`!rounded-[5px] h-[48px] w-fit font-semibold`}
                            // loading={loading}
                            loadingIndicator={
                                <CircularProgress color="primary" size={24} />
                            }
                            sx={{
                                color: 'white',
                                backgroundColor: '#121212',
                                '&:hover': {
                                    color: 'black',
                                },
                            }}
                        >
                            {'Get Started'}
                        </HelloMuiLoadingButton>
                    </div>
                )}
            </div>

            <div className="lg:hidden block relative">
                <div
                    className={`flex md:px-[32px] px-[12px] py-[15px] justify-between border-b border-my-gray border-opacity-60 ${expandMobileHeader ? 'bg-my-black bg-opacity-60' : ''}`}
                    ref={ref}
                >
                    <HelloDataMark />

                    <div
                        className="cursor-pointer hover:bg-my-blue text-my-blue hover:text-white bg-opacity-40 rounded-[5px] border border-my-blue flex items-center justify-center shadow-hello-blue h-[48px] w-[48px]"
                        onClick={onClickExpand}
                    >
                        <TabletHeaderCollapse width={28} height={28} />
                    </div>
                </div>
                {/* Popups */}
                <Menu
                    anchorEl={anchorEl}
                    open={expandMobileHeader}
                    onClose={() => setAnchorEl(null)}
                    sx={{
                        margin: 0,
                        padding: '0px',
                        '& .MuiList-root': {
                            padding: '0px',
                        },
                        '& .MuiMenu-paper': {
                            left: '0px!important',
                            maxWidth: '100%',
                        },
                    }}
                >
                    <div className="w-screen">
                        <CustomMenuItem title={'Features'} href={'#featured'} />
                        <CustomMenuItem title={'Pricing'} href={'#pricing'} />
                        <CustomMenuItem title={'FAQ'} href={'#faq'} />
                        <CustomMenuItem title={'Contact'} href={'#contact'} />
                        <CustomMenuItem
                            title={'Hello:data guide'}
                            href={'#requestdemo'}
                        />
                        <div className="h-[96px] flex items-center justify-center text-[14px] font-semibold">
                            {userToken ? (
                                <div onClick={onOpenAppClicked}>
                                    <OpenAppButton />
                                </div>
                            ) : (
                                <div className="flex gap-[36px] items-center justify-center">
                                    <HelloMuiButton
                                        onClick={onLogInClicked}
                                        sx={{
                                            color: '#121212',
                                            backgroundColor: '#FFF',
                                            fontSize: '14px',
                                            '&:hover': {
                                                color: 'black',
                                            },
                                        }}
                                    >
                                        {'Login'}
                                    </HelloMuiButton>

                                    <HelloMuiLoadingButton
                                        onClick={onGetStartedClicked}
                                        variant="outlined"
                                        className={`!rounded-[10px] h-[48px] `}
                                        // loading={loading}
                                        loadingIndicator={
                                            <CircularProgress
                                                color="primary"
                                                size={24}
                                            />
                                        }
                                        sx={{
                                            color: '#FFF',
                                            backgroundColor: '#121212',
                                            fontSize: '14px',
                                            '&:hover': {
                                                color: 'black',
                                            },
                                        }}
                                    >
                                        {'Get Started'}
                                    </HelloMuiLoadingButton>
                                </div>
                            )}
                        </div>
                    </div>
                </Menu>
            </div>
        </div>
    );
};

export default HeaderSection;
