import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'util/Constants';
import { HelloMuiLoadingButton } from 'components';

const IntroductionSection = () => {
    const navigate = useNavigate();
    const onJoinFreeClick = () => {
        navigate(ROUTES.SIGN_UP);
    };

    return (
        <div className="lg:px-[100px] md:px-[32px] px-[12px] grid lg:grid-cols-2 grid-cols-1 gap-[70px] lg:max-w-hello mx-auto">
            <div className="text-[14px] max-w-[550px] flex flex-col justify-center">
                <div className="md:text-[42px] text-[36px] md:leading-[60px] font-nunito font-extrabold">
                    <span className="text-my-black">{'Find Your Next '}</span>
                    <span className="text-gradient bg-gradient-to-b from-my-blue to-my-green">
                        {'Big Real Estate Deal'}
                    </span>
                </div>

                <div className=" text-my-black text-[14px] leading-[24px] opacity-80">
                    {
                        'Hello:data provides the data you need to discover prime real estate opportunities, ensuring you make the best investment choices.'
                    }
                </div>

                <div className="pt-[20px]">
                    <HelloMuiLoadingButton
                        onClick={onJoinFreeClick}
                        variant="outlined"
                        className={`!rounded-[5px] h-[48px] w-fit font-semibold`}
                        // loading={loading}
                        loadingIndicator={
                            <CircularProgress color="primary" size={24} />
                        }
                        sx={{
                            color: 'white',
                            backgroundColor: '#121212',
                            '&:hover': {
                                color: 'black',
                            },
                        }}
                    >
                        {'Join Hello:data for Free'}
                    </HelloMuiLoadingButton>
                </div>
            </div>

            <div className="flex items-center justify-center">
                <img
                    alt={'Landing Logo '}
                    src="/image/Landing/Landing-Head-Logo.png"
                />
            </div>
        </div>
    );
};

export default IntroductionSection;
