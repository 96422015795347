import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useIsFetching } from '@tanstack/react-query';
import { ReactComponent as CompetitorDatabaseSvg } from 'assets/svg/Competitor-Database.svg';
import { ReactComponent as CompetitorSelectedSvg } from 'assets/svg/Competitor-Selected.svg';
import { ReactComponent as CompetitorSwappedSvg } from 'assets/svg/Competitor-Swapped.svg';
import { ReactComponent as PlusSvg } from 'assets/svg/Plus.svg';
import { AddManualCompetitorModal, SelectAddWayModal, UploadBulkCompetitorModal } from 'components/Modals';
import { HelloMuiButton } from 'components/Mui';
import { useCallback, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import useCompetitorsStore from 'store/useCompetitorsStore';
import useUserStore from 'store/useUserStore';
import { MAX_LIMIT } from 'util/Constants';

const HeaderSection = () => {
    const { isExceed, getUserPlan, userInfo } = useUserStore();
    const isExceedFlag = isExceed();
    const plan = getUserPlan();

    const [showChoiceModal, setShowChoiceModal] = useState<boolean>(false);
    const [showOneByOne, setShowOneByOne] = useState<boolean>(false);
    const [showBulkUpload, setShowBulkUpload] = useState<boolean>(false);

    const { totalCount, selectedCompetitors } = useCompetitorsStore();

    const isFetchingCompetitors = useIsFetching({
        queryKey: ['fetchAllCompetitors'],
    });

    const onChoiceClicked = useCallback(() => {
        setShowChoiceModal(true);
    }, []);

    const onAddOneByOneClicked = () => {
        setShowOneByOne(true);
        setShowChoiceModal(false);
    };

    const onBulkUpload = () => {
        setShowBulkUpload(true);
        setShowChoiceModal(false);
    };

    return (
        <>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-[16px] gap-[10px] px-[20px] py-[8px] bg-my-gray bg-opacity-40">
                <div className="rounded-[5px] h-[48px] flex justify-between py-[10px] px-[12px] bg-white border border-my-gray">
                    <div>
                        {isFetchingCompetitors ? (
                            <Skeleton className="h-full w-28" />
                        ) : (
                            <>
                                <div className="text-[10px]">Competitor Database</div>
                                <div className="font-medium">{totalCount}</div>
                            </>
                        )}
                    </div>
                    <CompetitorDatabaseSvg />
                </div>

                <div
                    className={`rounded-[5px] h-[48px] flex justify-between gap-[12px] py-[10px] px-[12px] ${isExceedFlag ? 'bg-[#FFC034] bg-opacity-10 border-[#FFC034] border-opacity-40' : 'bg-white border-my-gray'} border`}
                >
                    <div className={`flex-grow ${isExceedFlag ? 'text-[#FFC034]' : ''}`}>
                        <div className="text-[10px] text-black">Total Selected</div>

                        <div className="flex items-center">
                            <div className="font-medium flex-grow">
                                {`${selectedCompetitors.length} / `}
                                {plan.maxTrackUpCount === MAX_LIMIT ? 'Unlimited' : `${plan?.maxTrackUpCount}`}
                            </div>
                            {isExceedFlag && (
                                <div className="text-[#ECA200] text-[10px] flex gap-[2px] items-center">
                                    <WarningRoundedIcon className="!text-[16px]" />
                                    <span>Exceed limit!</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <CompetitorSelectedSvg />
                </div>

                <div className="rounded-[5px] h-[48px] flex justify-between py-[10px] px-[12px] bg-white border border-my-gray">
                    <div>
                        <div className="text-[10px]">{'Can be swapped this month'}</div>
                        <div className="font-medium">
                            {plan.maxSwapCount === MAX_LIMIT ? 'Unlimited' : plan.maxSwapCount - userInfo?.swapsThisMonth!}/
                            {plan.maxSwapCount === MAX_LIMIT ? 'Unlimited' : plan.maxSwapCount}
                        </div>
                    </div>
                    <CompetitorSwappedSvg />
                </div>

                <HelloMuiButton variant="outlined" onClick={onChoiceClicked}>
                    <div className="text-my-blue flex items-center gap-[4px]">
                        <PlusSvg />
                        <span>{`Add New Competitor`}</span>
                    </div>
                </HelloMuiButton>
            </div>

            <SelectAddWayModal
                visible={showChoiceModal}
                onClose={() => setShowChoiceModal(false)}
                onAddOneByOneClicked={onAddOneByOneClicked}
                onBulkUpload={onBulkUpload}
            />
            <AddManualCompetitorModal visible={showOneByOne} onClose={() => setShowOneByOne(false)} />
            <UploadBulkCompetitorModal visible={showBulkUpload} onClose={() => setShowBulkUpload(false)} />
        </>
    );
};

export default HeaderSection;
