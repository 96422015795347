import { Button } from '@mui/material';
import { useModals } from 'pages/MainProvider';
import { PropertyDTO } from 'util/Types';

const AdditionalDetailForMobile = ({
    property,
    onDetailClicked,
}: {
    property: PropertyDTO | null;
    onDetailClicked: () => void;
}) => {
    if (!property) return null;

    return (
        <div className="px-[12px] min-w-[330px] flex flex-col gap-[12px]">
            <div className="flex flex-col">
                <div className="flex justify-between items-center">
                    <span>{`Area`}</span>
                    <span className="font-bold">
                        {property?.address.City || ''}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span>{`Class`}</span>
                    <span className="font-bold">
                        {property?.details.propertyType || ''}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span>{`User principal`}</span>
                    <span className="font-bold">
                        {property?.details.listingType || ''}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span>{`Consruction Area`}</span>
                    <span className="font-bold">{`${property?.details.sizeLand || 0} sqm`}</span>
                </div>
            </div>
            <Button variant="outlined" onClick={onDetailClicked}>
                {'Details'}
            </Button>
        </div>
    );
};

export default AdditionalDetailForMobile;
